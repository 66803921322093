import React from 'react';
import { MdCircle } from 'react-icons/md';

import { Button } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { TimeTrackerEngineState } from '@/gql/graphql';
import { useTimeMachineContext } from '@/hooks';

type Props = any;

const TimeTrackerButton = (props: Props) => {
  const { engineState, setEngineState } = useTimeMachineContext();
  const handleTrackingEnabled = () => {
    if (engineState === TimeTrackerEngineState.Offline) {
      setEngineState(TimeTrackerEngineState.Running);
    } else {
      setEngineState(TimeTrackerEngineState.Offline);
    }
  };
  return (
    <Button onClick={handleTrackingEnabled} style={{ ...MIDDLE_STYLE }}>
      <MdCircle
        style={{
          marginRight: 15,
          color:
            engineState === TimeTrackerEngineState.Offline
              ? GLOBAL_THEME_COLOR.$love_color
              : GLOBAL_THEME_COLOR.$success_color,
        }}
      />
      Tracking {engineState === TimeTrackerEngineState.Offline ? 'On' : 'Off'}
    </Button>
  );
};

export default TimeTrackerButton;
