import Browser from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import { TabTreeDirectory, TreeNode } from '@/models';

import { X_WEB_API } from '../constants/x-web-api';
import { IBrowserDevService } from './IBrowserDevService';
import { PhantomRemoteService } from './PhantomRemoteService';

export class BrowserDevExtensionService extends PhantomRemoteService implements IBrowserDevService {
  async authenticateUser(token: string): Promise<void> {
    await Browser.storage.sync.set({ token: token });
    return;
  }

  async groupTabs(
    tabIds: number[],
    windowProperties?: chrome.tabGroups.UpdateProperties,
    groupId?: number,
    windowId?: number
  ) {
    const _groupId = await chrome.tabs.group({
      tabIds: tabIds,
      groupId,
      createProperties: {
        windowId,
      },
    });
    if (windowProperties) {
      await X_WEB_API.tabGroups.update(_groupId, windowProperties);
    }
    return _groupId;
  }

  async fetchWindows() {
    const windows = await Browser.windows.getAll({
      populate: true,
      windowTypes: ['normal', 'app'],
    });
    return windows.sort((wA, wB) => (wA.focused && !wB.focused ? -1 : 1));
  }

  async selectTab(windowId: number | undefined, tabId: number | undefined) {
    if (!tabId || !windowId) return;
    await Browser.tabs.update(tabId, { active: true });
    await Browser.windows.update(windowId, { focused: true });
  }

  async closeWindow(window: Browser.Windows.Window) {
    if (!window.id) return;
    await Browser.windows.remove(window.id);
  }

  async deleteTab(tabId: number | undefined) {
    if (!tabId) return;
    await Browser.tabs.remove([tabId]);
  }

  async createTab(tab: RepositoryTab) {
    if (!tab.url) return;
    await Browser.tabs.create({
      url: tab.url,
    });
  }

  async openTabs(tabs: RepositoryTab[]) {
    if (!tabs) return;
    await Browser.windows.create({
      url: tabs.map(tab => tab.url as string),
    });
  }

  async moveTreeItems(treeItems: TreeNode[]) {
    if (!treeItems) return;
    let totalIndex = treeItems.length;
    for (const item of treeItems) {
      if (item.type === 'directory') {
        totalIndex += (item.value as TabTreeDirectory).items.length;
      }
    }
    for (let i = 0; i < totalIndex; i++) {
      const treeItem = treeItems[i];
      if (treeItem.type === 'tab') {
        const tab = treeItem.value as RepositoryTab;
        await Browser.tabs.move([parseInt(tab.id)], {
          index: i,
        });
      } else if (treeItem.type === 'directory') {
        const directory = treeItem.value as TabTreeDirectory;
        await X_WEB_API.tabGroups.move(parseInt(directory.id as string), {
          index: i,
        });
      }
    }
  }

  async queryTabGroups(windowId: number): Promise<chrome.tabGroups.TabGroup[]> {
    return X_WEB_API.tabGroups.query({
      windowId,
    });
  }

  async ungroupTab(tabIds: number[]) {
    return X_WEB_API.tabs.ungroup(tabIds);
  }

  async getTabGroup(groupId: number): Promise<chrome.tabGroups.TabGroup> {
    return X_WEB_API.tabGroups.get(groupId);
  }

  async updateTabGroup(groupId: number, windowProperties: chrome.tabGroups.UpdateProperties) {
    return X_WEB_API.tabGroups.update(groupId, windowProperties);
  }

  moveTabGroup(groupId: number, moveProperties: { index: number; windowId?: number | undefined }) {
    return X_WEB_API.tabGroups.move(groupId, moveProperties);
  }
}
