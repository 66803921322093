/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';

import { HelmetWrapper, LoadableContainer, SplashScreen, WorkspaceList } from '@/components';
import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useBreakpoint } from '@/hooks';
import { useAuthStore, useModalStore, useWorkspaceStore } from '@/stores';

type Props = Record<string, any>;

const WorkspaceScreen = (props: Props) => {
  const history = useHistory();
  const { isMobile } = useBreakpoint();
  const { openModal } = useModalStore();
  const { user } = useAuthStore();
  const { fetchWorkspaces, workspaces, isRefreshing } = useWorkspaceStore();
  const [loading, setLoading] = useState<boolean>(true);

  const handleCreateNewWorkspace = () => {
    openModal('newWorkspace');
  };

  const handleGoToSignIn = () => {
    history.push('/auth');
  };

  const handleFetchWorkspaces = async () => {
    setLoading(true);
    try {
      await fetchWorkspaces();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      await handleFetchWorkspaces();
    };
    init();
  }, [isRefreshing, user]);

  return (
    <HelmetWrapper title="Workspaces">
      <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
        <div style={{ padding: '10px 30px' }}>
          <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1 style={{ textAlign: 'left' }}>
                Workspace <Tag style={{ marginLeft: 10 }}>{workspaces.length} workspaces</Tag>
              </h1>
              <div style={MIDDLE_STYLE}>
                {user ? (
                  <Button onClick={handleCreateNewWorkspace}>
                    {isMobile ? <PlusOutlined /> : '🗂 Create new workspace'}
                  </Button>
                ) : (
                  <Button onClick={handleGoToSignIn}>🧑‍💻 Sign in to sync your data</Button>
                )}
              </div>
            </div>
            <p style={{ textAlign: 'left', color: GLOBAL_THEME_COLOR.$dark_text_color }}>
              Workspace can be used to store bookmarks and repositories on remote server.
            </p>
            <div style={{ marginTop: 30 }}>
              <WorkspaceList workspaces={workspaces} />
            </div>
          </React.Fragment>
        </div>
      </LoadableContainer>
    </HelmetWrapper>
  );
};

export default WorkspaceScreen;
