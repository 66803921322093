import React, { useMemo } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { CloseOutlined, SelectOutlined } from '@ant-design/icons';
import { Badge, Card, Space, Tooltip } from 'antd';
import Browser from 'webextension-polyfill';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { useBrowserDevService, useWindowTabActions, useWindowTabActionsWithContext } from '@/hooks';
import { TreeNode } from '@/models';
import { useContextMenuStore, useTabManagerStore } from '@/stores';
import { reorder } from '@/utils';

import DraggableTreeNodeList from '../draggable-tree-node-list';
import WindowItemContextMenu from '../window-item-context-menu';

type Props = { browserWindow: Browser.Windows.Window };

const WindowColumnContainer = ({ browserWindow }: Props) => {
  const browserDevService = useBrowserDevService();
  const windowId = useMemo(() => browserWindow.id as any, [browserWindow]);
  const { setContextMenuContent } = useContextMenuStore();
  const { handleCloseAll } = useWindowTabActions();
  const { selectedMultiverseTreeNodes, multiverseTreeNodes, setMultiverseTreeNodes } =
    useTabManagerStore();
  const { handleDirectoryClicked, handleTabClicked, handleWindowSelect } =
    useWindowTabActionsWithContext(browserWindow);

  const onDragEnd: OnDragEndResponder = async (result: any) => {
    const items: TreeNode[] = reorder(
      multiverseTreeNodes[windowId],
      result.source.index,
      result.destination.index
    );
    try {
      await browserDevService.moveTreeItems(items);
    } catch (error) {
      console.log(error);
    }
    setMultiverseTreeNodes(windowId, items);
  };

  return (
    <Card
      hoverable
      title={
        <div className="card-header-inner" style={{ flexWrap: 'wrap' }}>
          <p>
            <Badge
              color={browserWindow.focused ? 'green' : 'orange'}
              text={browserWindow.focused ? 'Active window' : 'Window'}
            />
          </p>
          <Space>
            <Tooltip title="Select window tabs">
              <SelectOutlined
                onClick={() => handleWindowSelect(browserWindow)}
                style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, cursor: 'pointer' }}
              />
            </Tooltip>
            <Tooltip title="Close window">
              <CloseOutlined
                onClick={() => handleCloseAll(browserWindow)}
                style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, cursor: 'pointer' }}
              />
            </Tooltip>
          </Space>
        </div>
      }
      style={{
        overflow: 'hidden',
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
      }}>
      <div
        style={{
          height: '65vh',
          width: 300,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
        <DraggableTreeNodeList
          isForceMobile
          nodeOnContextMenu={(treeNode: TreeNode) => {
            setContextMenuContent(() => (
              <WindowItemContextMenu browserWindow={browserWindow} treeNode={treeNode} />
            ));
          }}
          treeNodes={multiverseTreeNodes[windowId] || []}
          itemOnClickedEvent={handleTabClicked}
          directoryOnClickedEvent={handleDirectoryClicked}
          itemSelectedCondition={tab =>
            (tab && !!tab.id && !!selectedMultiverseTreeNodes?.[windowId]?.[tab.id]) || false
          }
          onItemDragEvent={onDragEnd}
        />
      </div>
    </Card>
  );
};

export default WindowColumnContainer;
