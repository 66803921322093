import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { LOCAL_WORKSPACE_ID, buildWorkspaceKey } from '@/constants';
import { AccessVisibility, Directory, Repository, RepositoryTab } from '@/gql/graphql';
import {
  getAllPublicRepositoriesQuery,
  getRepositoryBannerQuery,
  getRepositoryByIdQuery,
  getRepositoryBySlugQuery,
  getUserRepositoriesQuery,
  getWorkspaceRepositoriesQuery,
  setRepositoryTabsMutation,
  updateRepositoryInfoMutation,
} from '@/graphql/repositories';
import { BrowserStorageService, WorkspaceService } from '@/services';
import { apolloClient } from '@/services/ApolloService';

export interface RepositoryStoreState {
  refreshing: number;
  setRefreshing: () => void;
  repositories: Repository[];
  pinnedRepositories: Repository[];
  setPinnedRepositories: (repositories: Repository[]) => void;
  setRepositories: (repositories: Repository[]) => void;
  fetchRepositories: (workspaceId: string) => Promise<Repository[]>;
  fetchRepositoryById: (
    workspaceId: string | undefined,
    id: string
  ) => Promise<Repository | undefined>;
  fetchRepositoryBySlug: (
    workspaceId: string,
    slug: string
  ) => Promise<Repository | undefined | null>;
  updateRepositoryInfo: (
    repository: Repository,
    name: string | undefined,
    icon: string | undefined,
    descripiton: string | undefined,
    visibility: AccessVisibility | undefined
  ) => Promise<Repository | undefined>;
  deleteRepositoryById: (id: string) => Promise<void>;
  fetchUserRepositories: (userId: string) => Promise<Repository[]>;
  // Handle fetch pinned repositories: To fetch all pinned repo of user, we can iterate user workspaces
  fetchAllPublicRepositories: (limit: number, offset: number) => Promise<Repository[]>;
  fetchWorkspacePinnedRepositories: (
    ownerId: string | undefined,
    workspaceId: string
  ) => Promise<Repository[]>;
  setRepositoryTabs: (
    repository: Repository,
    tabs: RepositoryTab[],
    directories: Directory[]
  ) => Promise<void>;
  fetchWorkspaceUnpinnedRepositories: (
    ownerId: string | undefined,
    workspaceId: string
  ) => Promise<Repository[]>;
}

export const useRepositoryStore = create<RepositoryStoreState>()(
  devtools(set => ({
    refreshing: +new Date(),
    setRefreshing: () =>
      set(_ => ({
        refreshing: +new Date(),
      })),
    repositories: [],
    pinnedRepositories: [],
    setPinnedRepositories: (repositories: Repository[]) =>
      set(_ => ({ pinnedRepositories: repositories })),
    setRepositories: (repositories: Repository[]) => set(_ => ({ repositories: repositories })),
    deleteRepositoryById: async id => {
      return;
    },

    updateRepositoryInfo: async (
      repository,
      name,
      icon,
      descripiton,
      visibility
    ): Promise<Repository | undefined> => {
      if (repository.workspaceId === LOCAL_WORKSPACE_ID) {
        return handleUpdateLocalRepo(
          repository,
          icon || repository.icon,
          name || repository.name,
          descripiton || repository.description || ''
        );
      } else {
        return handleUpdateRemoteRepo(
          repository,
          icon || repository.icon,
          name || repository.name,
          descripiton || repository.description || '',
          visibility || repository.visibility
        );
      }
    },
    fetchWorkspacePinnedRepositories: async (
      ownerId: string | undefined,
      workspaceId: string
    ): Promise<Repository[]> => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
        return localRepositories.filter(repository =>
          repository.pinned.some(userWhoPin => userWhoPin === ownerId || 'PINNED')
        );
      } else {
        const remoteRepositories = await fetchRemoteRepositories(workspaceId);
        return remoteRepositories.filter(repo =>
          repo.pinned.some(userWhoPin => userWhoPin === ownerId)
        );
      }
    },
    fetchWorkspaceUnpinnedRepositories: async (
      ownerId: string | undefined,
      workspaceId: string
    ): Promise<Repository[]> => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
        return localRepositories.filter(repository => repository.pinned.length === 0);
      } else {
        const remoteRepositories = await fetchRemoteRepositories(workspaceId);
        return remoteRepositories.filter(repo =>
          repo.pinned.some(userWhoPin => userWhoPin !== ownerId)
        );
      }
    },
    fetchAllPublicRepositories: async (limit: number, offset: number) => {
      // Handle fetch all public repositories
      const { data } = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: getAllPublicRepositoriesQuery,
        variables: {
          limit,
          offset,
        },
      });
      return data.getAllPublicRepositories;
    },
    setRepositoryTabs: async (
      repository: Repository,
      tabs: RepositoryTab[],
      directories: Directory[]
    ) => {
      const workspaceId = repository.workspaceId;
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        await setRepositoryTabsLocal(repository, tabs, directories);
      } else {
        await setRepositoryTabsRemote(repository.id, tabs, directories);
      }
    },
    fetchRepositories: async (workspaceId: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        const key = buildWorkspaceKey(workspaceId);
        const records = await BrowserStorageService.getValue(key);
        return records || [];
      } else {
        return fetchRemoteRepositories(workspaceId);
      }
    },
    fetchRepositoryBySlug: async (workspaceId: string, slug: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        return findLocalRepositoryBySlug(workspaceId, slug);
      } else {
        return fetchRemoteRepositoryBySlug(workspaceId, slug);
      }
    },
    fetchRepositoryById: async (workspaceId: string | undefined, id: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        return findLocalRepositoryById(workspaceId, id);
      } else {
        return fetchRepositoryByIdRemote(id);
      }
    },
    fetchUserRepositories: async (userId: string) => {
      const { data } = await apolloClient.query({
        query: getUserRepositoriesQuery,
        variables: {
          userId,
        },
      });
      return data.getUserRepositories;
    },
  }))
);

const fetchRemoteRepositories = async (workspaceId: string) => {
  const { data } = await apolloClient.query({
    query: getWorkspaceRepositoriesQuery,
    fetchPolicy: 'network-only',
    variables: {
      workspaceId: workspaceId,
    },
  });
  return data.getWorkspaceRepositories;
};

const fetchRemoteRepositoryBySlug = async (workspaceId: string, slug: string) => {
  const { data } = await apolloClient.query({
    query: getRepositoryBySlugQuery,
    fetchPolicy: 'network-only',
    variables: {
      slug,
      workspaceId,
    },
  });
  return data.getRepositoryBySlug;
};

const fetchRepositoryByIdRemote = async (id: string) => {
  const { data } = await apolloClient.query({
    query: getRepositoryByIdQuery,
    fetchPolicy: 'network-only',
    variables: {
      repositoryId: id,
    },
  });
  return data.getRepositoryById;
};

const setRepositoryTabsLocal = async (
  repository: Repository,
  tabs: RepositoryTab[],
  directories: Directory[]
) => {
  const workspaceId = repository.workspaceId;
  const key = buildWorkspaceKey(workspaceId);
  const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
  const _repositories: Repository[] = [];
  for (const repo of localRepositories) {
    if (repo.id === repository.id) {
      repo.tabs = tabs;
      repo.directories = directories;
    }
    _repositories.push(repo);
  }
  await BrowserStorageService.setValue(key, _repositories);
};

const setRepositoryTabsRemote = async (
  repositoryId: string,
  tabs: RepositoryTab[],
  directories: Directory[]
) => {
  await apolloClient.mutate({
    mutation: setRepositoryTabsMutation,
    variables: {
      repositoryId,
      directories: directories,
      repositoryTabs: tabs.map(item => ({
        favIconUrl: item.favIconUrl,
        id: item.id,
        title: item.title,
        url: item.url,
        customName: item.customName,
        pinned: item.pinned,
        repositoryId: item.repositoryId,
        parentDirectory: item.parentDirectory,
        description: item.description,
        labels: item.labels,
      })),
    },
  });
};

const findLocalRepositoryBySlug = async (
  workspaceId: string,
  slug: string
): Promise<Repository | undefined> => {
  const key = buildWorkspaceKey(workspaceId);
  const records: Repository[] = await BrowserStorageService.getValue(key);
  return records.find(record => record.slug === slug);
};

const findLocalRepositoryById = async (
  workspaceId: string,
  id: string
): Promise<Repository | undefined> => {
  const key = buildWorkspaceKey(workspaceId);
  const records: Repository[] = await BrowserStorageService.getValue(key);
  return records.find(record => record.id === id);
};

const handleUpdateLocalRepo = async (
  repository: Repository,
  icon: string,
  name: string,
  description: string
) => {
  const workspaceId = repository.workspaceId;
  const key = buildWorkspaceKey(workspaceId);
  const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
  const _repositories: Repository[] = [];
  let updated_repository: Repository | undefined = undefined;
  for (const repo of localRepositories) {
    if (repo.id === repository.id) {
      repo.icon = icon as any;
      repo.name = name as any;
      repo.description = description as any;
      updated_repository = repo;
    }
    _repositories.push(repo);
  }
  await BrowserStorageService.setValue(key, _repositories);
  return updated_repository;
};

const handleUpdateRemoteRepo = async (
  repository: Repository,
  icon: string,
  name: string,
  description: string,
  visibility: AccessVisibility
) => {
  const { data } = await apolloClient.mutate({
    mutation: updateRepositoryInfoMutation,
    variables: {
      id: repository.id,
      name: name,
      icon: icon,
      description: description,
      visibility: visibility,
    },
  });
  return data?.updateRepositoryInfo;
};

export const handleFetchBanner = async (repositoryId: string, isCached?: boolean) => {
  const { data } = await apolloClient.query({
    query: getRepositoryBannerQuery,
    fetchPolicy: isCached ? 'cache-first' : 'network-only',
    variables: {
      repositoryId,
    },
  });
  const bannerImage = data.getRepositoryBanner;
  return bannerImage;
};
