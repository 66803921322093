/* eslint-disable no-case-declarations */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import { TimeTrackerEngineState, TimeTrackerProductivityMode } from '@/gql/graphql';
import { TimeMachineContext, useBrowserDevService, useBrowsingEventOnMount } from '@/hooks';
import { BrowsingTabTask, TaskEventType, TimeTrackSheet } from '@/models';
import { useAuthStore, useTimeTrackerStore } from '@/stores';
import {
  getCurrentHoursTimestamp,
  getLastHourDateRange,
  getSecondTimestamp,
  getURLHost,
  maybeZero,
} from '@/utils';
import {
  collectTotalTimeFromTimeSheet,
  getCurrentSessionKey,
  handleCollectAllTabs,
  handleCollectTimeTrackSheet,
  handleUpdateTimeTrackSheet,
  lambdaFilterByTimeRange,
  lambdaSortByTimestamp,
  sortAndCoalesceTabs,
  writeTimeTrackerTask,
} from '@/utils/time-tracker.util';

type Props = {
  children: React.ReactNode;
  defaultTrackedTabs: BrowsingTabTask[];
};

const ON_TAB_LENS_UPDATED = 'ON_TAB_LENS_UPDATED';
const ON_TAB_LENS_IDLE = 'ON_TAB_LENS_IDLE';
const blacklistWebsites = [];

const TimeMachineProvider = ({ children, defaultTrackedTabs }: Props) => {
  const { userTimeTrackerInfo } = useTimeTrackerStore();
  /** State for session time period */
  const { user } = useAuthStore();
  const timeTrackerSetting = useMemo(
    () => userTimeTrackerInfo.time_tracker_setting,
    [userTimeTrackerInfo]
  );
  const [mode, setMode] = useState<TimeTrackerProductivityMode | undefined>(
    timeTrackerSetting?.mode
  );
  /** Subscribe to Pub/Sub event with the core engine (running in background script of the extension) */
  const { data } = useBrowsingEventOnMount([ON_TAB_LENS_UPDATED, ON_TAB_LENS_IDLE]);
  /** Time range of a time tracker session */
  const [sessionStartTime, setSessionStartTime] = useState<number>(
    getCurrentHoursTimestamp(timeTrackerSetting?.session_start_time)
  );
  const [sessionEndTime, setSessionEndTime] = useState<number>(
    getCurrentHoursTimestamp(timeTrackerSetting?.session_end_time)
  );

  const [mounted, setMounted] = useState<boolean>(false);
  const browserDevService = useBrowserDevService();
  /** Tabs must be used at least every 10 minutes to not be in the list of least used tabs */
  const [garbageCollectedTabs, setGarbageCollectedTabs] = useState<BrowsingTabTask[]>([]);
  const [limitLeastUsedTime, setLimitLeastUsedTime] = useState<number>(0);
  const [trackedTabs, setTrackedTabs] = useState<BrowsingTabTask[]>([]);
  /** Aggregated time track sheet of tabTimeTrack, grouped by url host */
  const [urlHostTimeTrack, setUrlHostTimeTrack] = useState<TimeTrackSheet>({});
  const [realtimeUrlHostTimeTrack, setRealtimeUrlHostTimeTrack] = useState<TimeTrackSheet>({});
  /** Time track sheet for a specific tab url, not aggregated */
  const [tabTimeTrack, setTabTimeTrack] = useState<TimeTrackSheet>({});
  const [realtimeTabTimeTrack, setRealtimeTabTimeTrack] = useState<TimeTrackSheet>({});
  /** Support realtime UI rendering of time pointer */
  const [realtimePointer, setRealTimePointer] = useState(0);
  const [engineState, setEngineState] = useState<TimeTrackerEngineState>(
    TimeTrackerEngineState.Idle
  );
  /** Time range selection, user can select a range a view from it */
  const [selectedRangeEnabled, setSelectedRangeEnabled] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<[Date, Date] | undefined>(undefined);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<[Date, Date]>(
    getLastHourDateRange(0, 1)
  );
  /** Time diff between the current accessed tab with the current time */
  const [currentTimeDiff, setCurrentTimeDiff] = useState<number>(0);
  const [currentBrowsingTab, setCurrentBrowsingTab] = useState<BrowsingTabTask | undefined>(
    undefined
  );

  const rawTrackedTabs = useMemo(() => {
    const _coalescedTrackedTabs: BrowsingTabTask[] = [];
    sortAndCoalesceTabs(trackedTabs, startTab => _coalescedTrackedTabs.push(startTab), 'asc');
    return _coalescedTrackedTabs;
  }, [trackedTabs]);

  const transformedTrackedTabs = useMemo(() => {
    /** Sort tracked tabs by time lambda and specific time range selection */
    return rawTrackedTabs
      .filter(tab =>
        selectedRangeEnabled ? selectedRange && lambdaFilterByTimeRange(tab, selectedRange) : true
      )
      .filter(tab => lambdaFilterByTimeRange(tab, selectedTimePeriod));
  }, [rawTrackedTabs, selectedRange, selectedTimePeriod]);
  /** REALTIME: Total time spent */
  const realtimeTotalTime = useMemo<number>(() => {
    const baseValue = collectTotalTimeFromTimeSheet(urlHostTimeTrack);
    if (engineState === TimeTrackerEngineState.Offline) return baseValue;
    return baseValue + currentTimeDiff;
  }, [urlHostTimeTrack, currentTimeDiff]);

  /** Update the time track sheet of tabs */
  const handleUpdateTimeTrack = (url: string, timeDiff: number) => {
    /** Update time tracked for tab url */
    handleUpdateTimeTrackSheet(
      [setTabTimeTrack, setRealtimeTabTimeTrack],
      tabTimeTrack,
      url,
      maybeZero(tabTimeTrack[url]?.value) + timeDiff
    );
    /** Aggregate the data and update time tracked for url host */
    const urlHost = getURLHost(url);
    handleUpdateTimeTrackSheet(
      [setUrlHostTimeTrack, setRealtimeUrlHostTimeTrack],
      urlHostTimeTrack,
      urlHost,
      maybeZero(urlHostTimeTrack[urlHost]?.value) + timeDiff
    );
  };

  /** Action dispatched when the tracked on button is turn on or running */
  const handleTrackOnRunning = (
    browsingTabTask: BrowsingTabTask,
    _currentBrowsingTab: BrowsingTabTask | undefined,
    forceNotAccessed?: boolean
  ) => {
    const url: string = browsingTabTask.url || '';
    const blacklisted = blacklistWebsites.some(website => url?.includes(website));
    const notAccessed =
      forceNotAccessed || !_currentBrowsingTab || url !== _currentBrowsingTab?.url;
    if (!blacklisted && notAccessed) {
      if (_currentBrowsingTab && _currentBrowsingTab?.url) {
        const currentTimestamp = browsingTabTask.timestamp;
        const timeDiff = currentTimestamp - (_currentBrowsingTab?.timestamp || sessionStartTime);
        const url = _currentBrowsingTab.url;
        handleUpdateTimeTrack(url, timeDiff);
      }
      handleAddTrackedTabs(browsingTabTask);
    }
  };

  /** Action to add tracked tab item to local storage and update its state */
  const handleAddTrackedTabs = (browsingTabTask: BrowsingTabTask) => {
    if (timeTrackerSetting && user)
      writeTimeTrackerTask(
        user.username,
        getCurrentSessionKey(timeTrackerSetting),
        browsingTabTask
      );
    setTrackedTabs(_trackedTabs => [..._trackedTabs, browsingTabTask]);
  };

  const generateInteruptTask = () => {
    const browsingTabTask = {
      ...trackedTabs[0],
      timestamp: getSecondTimestamp(),
      type: TaskEventType.Interupt,
    };
    return browsingTabTask;
  };

  /** EVENT: Last accessed tab or current accessed tab */
  useEffect(() => {
    setCurrentBrowsingTab(transformedTrackedTabs[0] || undefined);
  }, [transformedTrackedTabs]);

  /** Update time diff between the current browsing tab with the current time */
  useEffect(() => {
    if (engineState === TimeTrackerEngineState.Offline) return;
    const timestamp = getSecondTimestamp();
    setCurrentTimeDiff(timestamp - (currentBrowsingTab?.timestamp || sessionStartTime));
  }, [currentBrowsingTab, realtimePointer]);

  /** RUNNING: Handle interrupt event on engine state changed */
  useEffect(() => {
    if (!mounted) return;
    if (trackedTabs.length > 0) {
      if (engineState === TimeTrackerEngineState.Running) {
        const browsingTabTask = {
          ...trackedTabs[0],
          timestamp: getSecondTimestamp(),
          type: TaskEventType.Normal,
        };
        setCurrentBrowsingTab(browsingTabTask);
        handleTrackOnRunning(browsingTabTask, browsingTabTask, true);
      } else {
        const interuptTask = generateInteruptTask();
        if (currentBrowsingTab) handleUpdateTimeTrack(currentBrowsingTab.url || '', 0);
        if (transformedTrackedTabs[0]?.type !== TaskEventType.Interupt) {
          /** Update time when adding interupt task */
          if (currentBrowsingTab)
            handleUpdateTimeTrack(
              currentBrowsingTab.url || '',
              interuptTask.timestamp - currentBrowsingTab.timestamp
            );
          setCurrentTimeDiff(0);
          /** Adding new interupt task into the list of tracking tabs */
          handleAddTrackedTabs(interuptTask);
        }
      }
    }
  }, [engineState, mounted]);

  /** REALTIME: Track url host time spent in real time */
  useEffect(() => {
    if (!mounted) return;
    if (engineState === TimeTrackerEngineState.Offline) return;
    const url = currentBrowsingTab?.url;
    if (engineState === TimeTrackerEngineState.Running && url) {
      handleUpdateTimeTrackSheet(
        [setRealtimeTabTimeTrack],
        realtimeTabTimeTrack,
        url,
        maybeZero(tabTimeTrack[url]?.value) + currentTimeDiff
      );
      const urlHost = getURLHost(url);
      handleUpdateTimeTrackSheet(
        [setRealtimeUrlHostTimeTrack],
        realtimeUrlHostTimeTrack,
        urlHost,
        maybeZero(urlHostTimeTrack[urlHost]?.value) + currentTimeDiff
      );
    }
  }, [currentBrowsingTab, currentTimeDiff, engineState, mounted]);

  /** EVENT: Collect garbage tabs */
  useEffect(() => {
    if (!mounted) return;
    const handleCollectGarbage = async () => {
      const tabs = await handleCollectAllTabs(browserDevService);
      const collectedTabs: BrowsingTabTask[] = [];
      for (const tab of tabs) {
        if (!tab.url) continue;
        const now = moment().unix();
        const lastAccessAt =
          tabTimeTrack[tab.url]?.lastAccessedAt ||
          transformedTrackedTabs[transformedTrackedTabs.length - 1]?.timestamp ||
          sessionStartTime;
        if (now - lastAccessAt > limitLeastUsedTime) {
          collectedTabs.push({
            ...tab,
            timestamp: lastAccessAt,
            type: TaskEventType.Normal,
          });
        }
      }
      setGarbageCollectedTabs(
        collectedTabs.sort((tabA, tabB) => lambdaSortByTimestamp(tabA, tabB, 'dsc'))
      );
    };
    handleCollectGarbage();
  }, [tabTimeTrack, realtimePointer, limitLeastUsedTime, engineState]);

  /** EVENT: Update the realtime pointer if the engine is running */
  useEffect(() => {
    if (!mounted) return;
    if (engineState === TimeTrackerEngineState.Offline) return;
    if (trackedTabs.length > 0) {
      const timer = setInterval(() => {
        setRealTimePointer(time => (time = time + 1));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [trackedTabs.length === 1, engineState]);

  /** EVENT: Watch for event from the subcription server and update event */
  useEffect(() => {
    if (!data) return;
    if (!mounted) return;
    if (engineState === TimeTrackerEngineState.Offline) return;
    switch (data.onBrowsingEventUpdated.event_name) {
      case ON_TAB_LENS_IDLE:
        setEngineState(TimeTrackerEngineState.Idle);
        return;
      case ON_TAB_LENS_UPDATED:
        if (!data.onBrowsingEventUpdated.properties) return;
        setEngineState(TimeTrackerEngineState.Running);
        const browsingTabTask: BrowsingTabTask = JSON.parse(data.onBrowsingEventUpdated.properties);
        handleTrackOnRunning(browsingTabTask, currentBrowsingTab);
        return;
    }
  }, [data, engineState, mounted]);

  /** EVENT: If the time pointer is out of sync, sync back with the total time */
  useEffect(() => {
    if (!mounted) return;
    if (engineState === TimeTrackerEngineState.Offline) return;
    if (Math.abs(realtimePointer - realtimeTotalTime) > 60) {
      setRealTimePointer(realtimeTotalTime);
    }
  }, [realtimeTotalTime, realtimePointer]);

  /** EVENT: Sync the state with the remote time tracker setting */
  useEffect(() => {
    setTrackedTabs(defaultTrackedTabs);
    const timeTrackSheet = handleCollectTimeTrackSheet(defaultTrackedTabs);
    [
      setTabTimeTrack,
      setRealtimeTabTimeTrack,
      setUrlHostTimeTrack,
      setRealtimeUrlHostTimeTrack,
    ].map(action => action(timeTrackSheet));
  }, [defaultTrackedTabs]);

  /** EVENT: Update time track sheet based on select date range */
  useEffect(() => {
    const timeTrackSheet = handleCollectTimeTrackSheet(
      trackedTabs.filter(tab => lambdaFilterByTimeRange(tab, selectedTimePeriod))
    );
    [
      setTabTimeTrack,
      setRealtimeTabTimeTrack,
      setUrlHostTimeTrack,
      setRealtimeUrlHostTimeTrack,
    ].map(action => action(timeTrackSheet));
  }, [trackedTabs, selectedTimePeriod, selectedRangeEnabled, selectedRangeEnabled]);

  /** EVENT: Wait for the engine to be ready */
  useEffect(() => {
    const [_sessionStartTime, _sessionEndTime] = [
      getCurrentHoursTimestamp(timeTrackerSetting?.session_start_time),
      getCurrentHoursTimestamp(timeTrackerSetting?.session_end_time),
    ];
    setLimitLeastUsedTime(maybeZero(timeTrackerSetting?.limit_least_used_time));
    setSessionStartTime(_sessionStartTime);
    setSessionEndTime(_sessionEndTime);

    if (trackedTabs[0]?.type === TaskEventType.Interupt) {
      setEngineState(TimeTrackerEngineState.Offline);
    }
    setTimeout(() => {
      setMounted(true);
    }, 1000);
  }, [trackedTabs, timeTrackerSetting]);

  /** EVENT: If the engine is unloaded (close or refresh) => Handle unloaded events */
  useEffect(() => {
    const eventHandler = function (e) {
      e.preventDefault();
      if (engineState !== TimeTrackerEngineState.Offline) {
        setEngineState(TimeTrackerEngineState.Offline);
      }
    };
    window.addEventListener('beforeunload', eventHandler);
    return () => {
      window.removeEventListener('beforeunload', eventHandler);
    };
  }, [engineState]);

  return (
    <TimeMachineContext.Provider
      value={{
        isTimeMachineReady: mounted,
        productivityMode: mode,
        selectedTimePeriod,
        sessionStartTime,
        sessionEndTime,
        currentBrowsingTab,
        currentTimeDiff,
        engineState,
        garbageCollectedTabs,
        limitLeastUsedTime,
        realtimePointer,
        realtimeTabTimeTrack,
        realtimeTotalTime,
        realtimeUrlHostTimeTrack,
        tabTimeTrack,
        trackedTabs: transformedTrackedTabs,
        rawTrackedTabs: rawTrackedTabs,
        urlHostTimeTrack,
        selectedRange,
        selectedRangeEnabled,

        setSelectedTimePeriod,
        setProductivityMode: setMode,
        setSessionStartTime,
        setSessionEndTime,
        setSelectedRangeEnabled,
        setSelectedRange,
        setRealtimePointer: setRealTimePointer,
        setEngineState,
        setGarbageCollectedTabs,
        setLimitLeastUsedTime,
        setRealtimeUrlHostTimeTrack: setRealtimeUrlHostTimeTrack,
        setTabTimeTrack,
        setTrackedTabs,
        setUrlHostTimeTrack,
      }}>
      {children}
    </TimeMachineContext.Provider>
  );
};

export default TimeMachineProvider;
