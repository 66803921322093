import React from 'react';

type Props = {
  visible: boolean;
  content?: () => React.ReactNode;
  x: number;
  y: number;
};

const ContextMenuPopup = (props: Props) => {
  return props.visible && props.content ? (
    <div className="context-menu-popup" style={{ left: `${props.x}px`, top: `${props.y}px` }}>
      {props.content()}
    </div>
  ) : (
    <></>
  );
};

export default ContextMenuPopup;
