/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import dayjs from 'dayjs';

import {
  CreateNewTimeTrackerArgs,
  TimeTrackerProductivityMode,
  TimeTrackerSessionSetting,
} from '@/gql/graphql';
import { createTimeTrackerSessionMutation } from '@/graphql/time-tracker';
import { apolloClient } from '@/services/ApolloService';
import { fetchUserTimeTrackerInfo, useAuthStore, useTimeTrackerStore } from '@/stores';
import { getCurrentSessionKey } from '@/utils/time-tracker.util';

const storeTimeTrackerSessionSetting = (
  username: string | undefined,
  setting: TimeTrackerSessionSetting
) => {
  localStorage.setItem(
    `time-tracker-setting/${username}/${getCurrentSessionKey(setting)}`,
    JSON.stringify(setting)
  );
};

const getTimeTrackerSessionSettingKey = (username: string | undefined, infoPart: string) => {
  const settingKey = `time-tracker-setting/${username}/${infoPart}`;
  return settingKey;
};

const getTimeTrackerSessionSetting = (
  username: string | undefined,
  infoPart: string
): TimeTrackerSessionSetting | undefined => {
  try {
    const settingKey = getTimeTrackerSessionSettingKey(username, infoPart);
    const settingData = localStorage.getItem(settingKey);
    if (!settingData) return undefined;
    return JSON.parse(settingData) as TimeTrackerSessionSetting;
  } catch (error) {
    return undefined;
  }
};

export const useLoadTimeTrackerInfo = () => {
  const { user } = useAuthStore();
  const timeTrackerStore = useTimeTrackerStore();
  const { setTimeTrackerLoading, setUserTimeTrackerInfo } = timeTrackerStore;

  const syncTimeTrackerSessionData = async () => {
    const promises: Promise<any>[] = [];
    for (const [key, value] of Object.entries(localStorage)) {
      const prefix = `time-tracker/${user?.username}/`;
      if (key.includes(prefix)) {
        try {
          /** Data is stored in a local storage with a format
           * time-tracker/tin-chung/15-07-2023/CasualMode
           * => time-tracker/:username/:session_date/:session_mode
           * => time-tracker-setting/:username/:sesison_date/:session_mdoe
           */
          const infoPart = key.slice(prefix.length);
          const [dateInfo, mode] = infoPart.split('/');
          const [day, month, year] = dateInfo.split('-');
          const setting = getTimeTrackerSessionSetting(user?.username, infoPart);
          const dateComp = `${year}-${month}-${day}`;
          const createdAt = dayjs(dateComp).unix();
          if (dayjs().isSame(dateComp, 'date') || dayjs().isBefore(dateComp, 'date')) continue;
          if (!setting) throw new Error('No setting found');
          const promise = async () => {
            const payload: CreateNewTimeTrackerArgs = {
              sessionStartTime: setting.session_start_time,
              sessionEndTime: setting.session_end_time,
              date: createdAt,
              mode: TimeTrackerProductivityMode[mode],
              trackedTabs: JSON.stringify(value),
            };
            await apolloClient.mutate({
              mutation: createTimeTrackerSessionMutation,
              variables: {
                createNewTimeTrackerSessionArgs: payload,
              },
            });
            localStorage.removeItem(key);
            localStorage.removeItem(getTimeTrackerSessionSettingKey(user?.username, infoPart));
          };
          promises.push(promise());
        } catch (error) {
          continue;
        }
      }
    }
    await Promise.all(promises);
  };

  const fetchTimeTrackerData = async () => {
    try {
      const timeTrackerInfo = await fetchUserTimeTrackerInfo();
      if (timeTrackerInfo) {
        const setting = timeTrackerInfo.time_tracker_setting;
        if (user && setting) storeTimeTrackerSessionSetting(user?.username, setting);
        setUserTimeTrackerInfo(timeTrackerInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      setTimeTrackerLoading(true);
      if (user) {
        await syncTimeTrackerSessionData();
        await fetchTimeTrackerData();
      }
      setTimeTrackerLoading(false);
    };
    init();
  }, [user]);

  return {};
};
