import React, { useState } from 'react';

import { Button, Tooltip } from 'antd';

import { Repository } from '@/gql/graphql';
import { useAuthStore, useWorkspaceStore } from '@/stores';

import AuthPinIcon from '../auth-pin-icon';

type Props = {
  repository: Repository;
};

const RepositoryPinButton = ({ repository }: Props) => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { pinRepository, unpinRepository } = useWorkspaceStore();
  const [pins, setPins] = useState<number>(repository.pinned.length);
  const [isPinned, setPinned] = useState<boolean>(
    !!repository?.pinned.some(userWhoPin => user?.id === userWhoPin || userWhoPin === 'PINNED')
  );

  const handleUnpin = async () => {
    setLoading(true);
    await unpinRepository(repository.workspaceId, repository.id);
    setLoading(false);
    setPinned(false);
    setPins(pin => pin - 1);
  };
  const handlePin = async () => {
    setLoading(true);
    await pinRepository(repository.workspaceId, repository.id);
    setLoading(false);
    setPinned(true);
    setPins(pin => pin + 1);
  };
  return (
    <Tooltip title={isPinned ? 'Unpin' : 'Pin'}>
      <Button
        loading={loading}
        style={{ cursor: 'pointer' }}
        onClick={isPinned ? handleUnpin : handlePin}>
        <AuthPinIcon repository={repository} isPinned={isPinned} /> {pins}
      </Button>
    </Tooltip>
  );
};

export default RepositoryPinButton;
