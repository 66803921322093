/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useHistory, useLocation } from 'react-router';

import { GithubOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  signInWithPopup,
} from 'firebase/auth';

import { AppBar, LoadableContainer } from '@/components';
import { GLOBAL_THEME_COLOR, LOGIN_IMG, MIDDLE_STYLE } from '@/constants';
import { createNewUserMutation } from '@/graphql/user';
import { useBreakpoint } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { auth } from '@/services/FirebaseService';
import { useAuthStore, useSnackbarStore } from '@/stores';
import { buildSlug } from '@/utils';

type Props = any;

const AuthScreen = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueNotification } = useSnackbarStore();
  const { fetchUserByEmail, user } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useBreakpoint();

  async function handleSignInWithProvider(provider: GoogleAuthProvider | GithubAuthProvider) {
    const result = await signInWithPopup(auth, provider);
    if (!result) return;
    const user = result.user;
    if (!user.email) return;
    const existingUser = await fetchUserByEmail(user.email);
    if (!existingUser) {
      const token = await user.getIdToken();
      localStorage.setItem('token', token);
      const username = buildSlug(user.displayName as string);
      await apolloClient.mutate({
        mutation: createNewUserMutation,
        fetchPolicy: 'network-only',
        variables: {
          uid: user.uid,
          profile_image: user.photoURL,
          email: user.email as string,
          full_name: user.displayName,
          username,
          provider: result.providerId,
        },
      });
    }
    await enqueueNotification({
      name: 'Sign in successfully',
      description: 'Authenticating user successfully',
      type: 'Success',
    });
    history.push({
      pathname: '/home',
      search: location.search,
    });
  }

  async function handleSignInWithGoogle() {
    try {
      setLoading(true);
      await auth.setPersistence(indexedDBLocalPersistence);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        allow_signup: 'false',
        prompt: 'select_account',
      });
      await handleSignInWithProvider(provider);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  async function handleSignInWithGithub() {
    try {
      setLoading(true);
      await auth.setPersistence(indexedDBLocalPersistence);
      const provider = new GithubAuthProvider();
      provider.setCustomParameters({
        allow_signup: 'false',
        prompt: 'select_account',
        auth_type: 'reauthenticate',
      });
      await handleSignInWithProvider(provider);
    } catch (error: any) {
      await enqueueNotification({
        name: 'Sign in failed',
        description: 'Error authenticating user',
        type: 'Error',
      });
    }
    setLoading(false);
  }

  const AuthForm = () => {
    return (
      <div
        style={{
          flexDirection: 'row',
          width: '100%',
          height: '600px',
          borderRadius: '5px !important',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'stretch',
          ...MIDDLE_STYLE,
        }}>
        <img
          alt="login-img"
          src={LOGIN_IMG}
          style={{
            flex: '1 1 0%',
            height: '600px',
            objectFit: 'cover',
            borderRadius: '8px 0px 0px 8px',
            display: isMobile ? 'none' : 'flex',
          }}
        />
        <div style={{ flex: '2 1 0%', marginLeft: '10px' }}>
          <h1 style={{ marginRight: 10, alignItems: 'center' }}>Welcome to TabHub</h1>
          <div style={{ marginRight: 10, marginBottom: 20 }}>
            <span style={{ color: '#00c2ff', fontWeight: 'bold', alignItems: ' center' }}>
              All-in-one{' '}
            </span>
            tab & link management
          </div>
          <div
            style={{ marginBottom: 20, marginTop: 50, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            Sign in with
          </div>
          <LoadableContainer
            isLoading={loading}
            loadComponent={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  flexDirection: 'column',
                }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactLoading
                    type={'cylon'}
                    color={GLOBAL_THEME_COLOR.$highlight_color}
                    height={'10%'}
                    width={'10%'}
                  />
                </div>
              </div>
            }>
            <Space direction="vertical" style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space direction="horizontal">
                <div style={{ fontSize: '14px', marginRight: '40px' }}>
                  <Button
                    size="large"
                    onClick={handleSignInWithGoogle}
                    style={{
                      width: '60px',
                      height: '60px',
                      marginBottom: '10px',
                      ...MIDDLE_STYLE,
                    }}>
                    <Avatar src={'/google-logo.png'} />
                  </Button>
                  Google
                </div>
                <div style={{ fontSize: '14px' }}>
                  <Button
                    size="large"
                    onClick={handleSignInWithGithub}
                    style={{
                      width: '60px',
                      height: '60px',
                      marginBottom: '10px',
                      ...MIDDLE_STYLE,
                    }}>
                    <GithubOutlined />
                  </Button>
                  Github
                </div>
              </Space>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 150 }}>
                {[
                  {
                    title: 'Docs',
                    url: `https://docs.tabhub.io`,
                  },
                  {
                    title: 'Contact',
                    url: `https://www.tabhub.io/#contact`,
                  },
                  {
                    title: 'Blog',
                    url: `https://blog.tabhub.io`,
                  },
                ].map(item => (
                  <a
                    style={{ fontSize: 13, color: GLOBAL_THEME_COLOR.$dark_text_color }}
                    href={item.url}>
                    {item.title}
                  </a>
                ))}
              </div>
            </Space>
          </LoadableContainer>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (user) {
      history.push({
        pathname: '/dashboard',
        search: location.search,
      });
    }
  }, [user]);

  return (
    <div>
      <AppBar />
      <div
        style={{
          backgroundColor: GLOBAL_THEME_COLOR.$background_color,
          height: '90vh',
          overflow: 'hidden',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          ...MIDDLE_STYLE,
        }}>
        <div
          style={{
            maxWidth: 700,
            height: isMobile ? '100vh' : 'fit-content',
            width: '100%',
            borderRadius: isMobile ? '0px' : '10px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
          }}>
          <AuthForm />
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;
