import moment from 'moment';
import Browser from 'webextension-polyfill';

import { TimeTrackerSessionSetting } from '@/gql/graphql';
import { IBrowserDevService } from '@/messaging';
import { BrowsingTabTask, TaskEventType, TimeTrackSheet } from '@/models';

import { generateHSLColor } from './string.util';

export function getTimeTrackerSessionTasks(
  email: string,
  timeTrackerIdentifier: string
): BrowsingTabTask[] {
  const key = `time-tracker/${email}/${timeTrackerIdentifier}`;
  const timeTrackerSessions = localStorage.getItem(key);
  let data = [];
  try {
    data = JSON.parse(timeTrackerSessions || '') || [];
  } catch {
    data = [];
  }
  return data;
}

export function writeTimeTrackerTask(
  username: string,
  timeTrackerIdentifier: string,
  trackedTab: BrowsingTabTask
) {
  const key = `time-tracker/${username}/${timeTrackerIdentifier}`;
  const timeTrackerSessions = getTimeTrackerSessionTasks(username, timeTrackerIdentifier);
  localStorage.setItem(key, JSON.stringify([...timeTrackerSessions, trackedTab]));
}

export const lambdaSortByTimestamp = (
  tabA: BrowsingTabTask,
  tabB: BrowsingTabTask,
  order: 'asc' | 'dsc'
) => (order === 'dsc' ? tabA.timestamp - tabB.timestamp : tabB.timestamp - tabA.timestamp);

export const lambdaFilterByTimeRange = (tabA: BrowsingTabTask, timeRange: [Date, Date]) => {
  const [start, end] = timeRange.map(t => moment(t).unix());
  return tabA.timestamp > start && tabA.timestamp < end;
};

export const getCurrentSessionKey = (timeTrackerSetting: TimeTrackerSessionSetting) =>
  `${moment().format('DD-MM-YYYY')}/${timeTrackerSetting?.mode}`;

export const coalesceTabs = (
  browsingTabTasks: BrowsingTabTask[],
  callback: (startTab: BrowsingTabTask, endTab?: BrowsingTabTask) => void
) => {
  for (let startIndex = 0; startIndex < browsingTabTasks.length; startIndex++) {
    const startTab = browsingTabTasks[startIndex];
    if (startIndex === browsingTabTasks.length - 1 || startTab.type === TaskEventType.Interupt) {
      callback(startTab);
      continue;
    }
    for (let endIndex = startIndex + 1; endIndex < browsingTabTasks.length; endIndex++) {
      const endTab = browsingTabTasks[endIndex];
      if (startTab.url !== endTab.url || endTab.type === TaskEventType.Interupt) {
        callback(startTab, endTab);
        startIndex = endIndex - 1;
        break;
      }
    }
  }
};

export const sortAndCoalesceTabs = (
  _trackedTabs: BrowsingTabTask[],
  callback: (startTab: BrowsingTabTask, endTab?: BrowsingTabTask) => void,
  order: 'asc' | 'dsc'
) => {
  /** Sort tracked tabs by time lambda and specific time range selection */
  const _sortedTrackedTabs = _trackedTabs.sort((tabA, tabB) =>
    lambdaSortByTimestamp(tabA, tabB, order)
  );
  /** Coalescing tracked tabs */
  return coalesceTabs(_sortedTrackedTabs, callback);
};

export const handleCollectAllTabs = async (
  browserDevService: IBrowserDevService
): Promise<Browser.Tabs.Tab[]> => {
  let tabs: Browser.Tabs.Tab[] = [];
  const windows = await browserDevService.fetchWindows();
  for (const window of windows) {
    tabs = tabs.concat(window.tabs || []);
  }
  return tabs;
};

export const handleUpdateTimeTrackSheet = (
  dispatchers: React.Dispatch<any>[],
  existingTrackSheet: TimeTrackSheet,
  url: string,
  value: number
) => {
  dispatchers.map(dispatcher =>
    dispatcher({
      ...existingTrackSheet,
      [url]: {
        lastAccessedAt: moment().unix(),
        value: value,
      },
    })
  );
};

export const handleCollectTimeTrackSheet = (trackedTabs: BrowsingTabTask[]) => {
  let timeTrackSheet: TimeTrackSheet = {};
  sortAndCoalesceTabs(
    trackedTabs,
    (startTab, endTab) => {
      if (startTab.url && endTab?.url) {
        const currentValue = timeTrackSheet[startTab.url]?.value || 0;
        const timeDiff = endTab.timestamp - startTab.timestamp;
        timeTrackSheet = {
          ...timeTrackSheet,
          [startTab.url]: {
            lastAccessedAt: moment().unix(),
            value: currentValue + timeDiff,
          },
        };
      }
    },
    'dsc'
  );
  return timeTrackSheet;
};

export const collectTotalTimeFromTimeSheet = (timeTrackSheet: TimeTrackSheet): number => {
  const timestamps = (Object.keys(timeTrackSheet) || {}).map(key => timeTrackSheet[key].value);
  const baseValue = timestamps.length > 0 ? timestamps.reduce((tA, tB) => tA + tB) : 0;
  return baseValue;
};

export const convertPieChartDateFromTimeSheet = (timeTrackSheet: TimeTrackSheet) => {
  return Object.keys(timeTrackSheet).map(urlHost => {
    return {
      id: urlHost,
      color: generateHSLColor(urlHost),
      label: urlHost,
      value: timeTrackSheet[urlHost].value,
    };
  });
};
