/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { User, Workspace } from '@/gql/graphql';
import { useAuthStore, useWorkspaceStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import SplashScreen from '../splash-screen';
import WorkspaceList from '../workspace-list';

type Props = {
  user: User | undefined;
};

const UserWorkspacesTab = ({ user }: Props) => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const { user: currentUser } = useAuthStore();
  const { fetchWorkspaces, fetchUserWorkspaces } = useWorkspaceStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const _isCurrentUser = user.id === currentUser?.id;
        const _workspaces = _isCurrentUser
          ? await fetchWorkspaces()
          : await fetchUserWorkspaces(user.id);
        setWorkspaces(_workspaces);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };
    init();
  }, [user, currentUser]);

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <WorkspaceList workspaces={workspaces} />
    </LoadableContainer>
  );
};

export default UserWorkspacesTab;
