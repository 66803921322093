import React from 'react';

import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { useRepositoryTabStore } from '@/stores';

type Props = { pinned: boolean; repositoryTab: RepositoryTab };

const PinTabButton = ({ pinned, repositoryTab }: Props) => {
  const { storeLocalPinnedTab, removeLocalPinnedTab, getLocalPinnedTabs } = useRepositoryTabStore();
  const handlePinTab = async () => {
    await storeLocalPinnedTab(repositoryTab);
    await getLocalPinnedTabs();
  };

  const handleUnpinTab = async () => {
    await removeLocalPinnedTab(repositoryTab);
    await getLocalPinnedTabs();
  };

  return (
    <React.Fragment>
      {pinned ? (
        <Tooltip title="Unpin">
          <PushpinFilled
            style={{ color: GLOBAL_THEME_COLOR.$love_color }}
            onClick={e => {
              e.stopPropagation();
              handleUnpinTab();
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Pin">
          <PushpinOutlined
            style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}
            onClick={e => {
              e.stopPropagation();
              handlePinTab();
            }}
          />
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default PinTabButton;
