import Browser, { Windows } from 'webextension-polyfill';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { IBrowserDevService } from '@/messaging/IBrowserDevService';
import { blacklistedTabs } from '@/utils';

export interface WindowStoreState {
  windows: Windows.Window[];
  setWindows: (windows: Windows.Window[]) => void;
  fetchWindows: (browserDevService: IBrowserDevService) => Promise<void>;
  deleteTab: (browserDevService: IBrowserDevService, tab: number) => Promise<void>;
}

export const useWindowStore = create<WindowStoreState>()(
  devtools(set => ({
    windows: [],
    setWindows: (windows: Windows.Window[]) => set(_ => ({ windows: windows })),

    fetchWindows: async (browserDevService: IBrowserDevService) => {
      const windows = await browserDevService.fetchWindows();
      const transformedWindows: Browser.Windows.Window[] = [];
      for (const window of windows) {
        const transformedWindowTabs = (window.tabs || []).filter(
          tab => !blacklistedTabs.some(_blacklistedTab => tab.url?.includes(_blacklistedTab))
        );
        transformedWindows.push({
          ...window,
          tabs: transformedWindowTabs,
        });
      }
      set(_ => ({ windows: transformedWindows }));
    },
    deleteTab: async (browserDevService: IBrowserDevService, tabId: number) => {
      await browserDevService.deleteTab(tabId);
      const windows = await browserDevService.fetchWindows();
      set(_ => ({ windows }));
    },
  }))
);
