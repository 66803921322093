/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CSVLink } from 'react-csv';

import { Button } from 'antd';

import { Repository, Workspace } from '@/gql/graphql';

type Props = { repository: Repository; workspace: Workspace };

const actionSpanStyle: React.CSSProperties = {
  fontWeight: 'bold',
  cursor: 'pointer',
  color: '#2474ff',
};

const RepositoryExportDropdown = ({ repository, workspace }: Props) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: 10 }}>
        <CSVLink
          filename={`[${workspace.name}][${repository.name}].csv`}
          style={actionSpanStyle}
          data={[
            ['ID', 'Name', 'Icon', 'URL'],
            ...repository.tabs.map(tab => [tab.id, tab.customName, tab.favIconUrl, tab.url]),
          ]}>
          <Button style={{ width: '100%' }}>Export as CSV</Button>
        </CSVLink>
      </div>
    </React.Fragment>
  );
};

export default RepositoryExportDropdown;
