/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { Alert, Layout } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { useBreakpoint } from '@/hooks';
import { useAppNavigation } from '@/hooks/useAppNavigation';
import {
  DashboardScreen,
  ExploreScreen,
  GettingStartedScreen,
  PinnedScreen,
  ScheduleScreen,
} from '@/screens/.';
import { useAuthStore } from '@/stores';
import { AppRoutes } from '@/utils/.';

import BookmarkAppExtension from '../app-extensions/extensions/app:bookmark';
import WebSearchAppExtension from '../app-extensions/extensions/app:web-search';
import AppBar from '../appbar';
import LeftSider from '../left-sider';
import TimeTrackerRouter from '../time-tracker-router';
import UserRouter from '../user-router';

/** Routers */
import WorkspaceRouter from '../workspace-router';

const screens: Record<AppRoutes, any> = {
  home: <GettingStartedScreen />,
  'time-tracker': <TimeTrackerRouter />,
  dashboard: <DashboardScreen />,
  schedule: <ScheduleScreen />,
  workspace: <WorkspaceRouter />,
  bookmark: <BookmarkAppExtension />,
  'web-search': <WebSearchAppExtension />,
  pinned: <PinnedScreen />,
  explore: <ExploreScreen />,
  user: <UserRouter />,
};

const { Content } = Layout;

type Props = Record<string, any>;

const DashboardLayout: React.FC = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const { user, loadingUser } = useAuthStore();
  const { navigate } = useAppNavigation();
  const { tabName } = useParams<{ tabName: AppRoutes }>();

  useEffect(() => {
    if (!screens[tabName]) {
      navigate(user ? 'user' : 'dashboard');
    }
  }, [tabName, user]);

  return (
    <Layout style={{ height: '100vh', overflow: 'hidden' }}>
      <AppBar />
      <Layout>
        {!isTablet && <LeftSider />}
        <Content
          style={{
            height: tabName === 'time-tracker' ? '100vh' : '91vh',
            overflow: 'auto',
            paddingBottom: tabName === 'time-tracker' ? 0 : 50,
            background: GLOBAL_THEME_COLOR.$secondary_color,
          }}>
          {!user && !loadingUser && (
            <Alert
              banner
              style={{
                backgroundColor: GLOBAL_THEME_COLOR.$background_color,
                borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              }}
              message={'You are using TabHub in offline mode. Sign in to access all feature'}
            />
          )}
          {screens[tabName] || <React.Fragment>No route found</React.Fragment>}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
