import { graphql } from '@/gql';

export const setRepositoryTabsMutation = graphql(`
  mutation SetRepositoryTabs(
    $repositoryId: String!
    $directories: [DirectoryAsInput!]!
    $repositoryTabs: [FullRepositoryTabAsInput!]!
  ) {
    setRepositoryTabs(
      setRepositoryTabsArgs: { id: $repositoryId, tabs: $repositoryTabs, directories: $directories }
    ) {
      message
      type
    }
  }
`);

export const getAllPublicRepositoriesQuery = graphql(`
  query GetAllPublicRepositories($limit: Float!, $offset: Float!) {
    getAllPublicRepositories(getAllPublicRepositoriesArgs: { limit: $limit, offset: $offset }) {
      icon
      contributors
      created_date
      description
      favorites
      favorite_count
      id
      name
      slug
      readme
      bannerUrl
      owner
      pinned
      tabs {
        customName
        favIconUrl
        id
        pinned
        lastAccessed
        repositoryId
        description
        parentDirectory
        labels
        title
        url
      }
      directories {
        id
        color
        name
        parentDirectory
      }
      accessPermission
      permittedUsers
      updated_date
      visibility
      workspaceId
    }
  }
`);

export const getWorkspaceRepositoriesQuery = graphql(`
  query GetWorkspaceRepositories($workspaceId: String!) {
    getWorkspaceRepositories(getWorkspaceRepositoriesArgs: { workspaceId: $workspaceId }) {
      icon
      contributors
      created_date
      description
      favorites
      favorite_count
      id
      name
      slug
      readme
      bannerUrl
      owner
      pinned
      tabs {
        customName
        favIconUrl
        id
        pinned
        labels
        lastAccessed
        description
        repositoryId
        parentDirectory
        title
        url
      }
      directories {
        id
        color
        name
        parentDirectory
      }
      accessPermission
      permittedUsers
      updated_date
      visibility
      workspaceId
    }
  }
`);

export const getUserRepositoriesQuery = graphql(`
  query GetUserRepositories($userId: String!) {
    getUserRepositories(getUserRepositoriesArgs: { userId: $userId }) {
      icon
      contributors
      created_date
      description
      favorites
      favorite_count
      id
      name
      slug
      readme
      bannerUrl
      owner
      pinned
      tabs {
        customName
        favIconUrl
        id
        pinned
        labels
        lastAccessed
        description
        repositoryId
        parentDirectory
        title
        url
      }
      directories {
        id
        color
        name
        parentDirectory
      }
      accessPermission
      permittedUsers
      updated_date
      visibility
      workspaceId
    }
  }
`);

export const getRepositoryByIdQuery = graphql(`
  query GetRepositoryById($repositoryId: String!) {
    getRepositoryById(getRepositoryByIdArgs: { id: $repositoryId }) {
      icon
      contributors
      created_date
      description
      favorites
      favorite_count
      id
      name
      slug
      readme
      bannerUrl
      owner
      pinned
      tabs {
        customName
        favIconUrl
        id
        pinned
        labels
        lastAccessed
        description
        repositoryId
        parentDirectory
        title
        url
      }
      directories {
        id
        color
        name
        parentDirectory
      }
      accessPermission
      permittedUsers
      updated_date
      visibility
      workspaceId
    }
  }
`);

export const getRepositoryBySlugQuery = graphql(`
  query GetRepositoryBySlug($workspaceId: String!, $slug: String!) {
    getRepositoryBySlug(getRepositoryBySlugArgs: { workspaceId: $workspaceId, slug: $slug }) {
      icon
      contributors
      created_date
      description
      favorites
      favorite_count
      id
      name
      slug
      readme
      bannerUrl
      owner
      pinned
      tabs {
        customName
        favIconUrl
        id
        pinned
        labels
        lastAccessed
        description
        parentDirectory
        repositoryId
        title
        url
      }
      directories {
        id
        color
        name
        parentDirectory
      }
      accessPermission
      permittedUsers
      updated_date
      visibility
      workspaceId
    }
  }
`);

export const updateRepositoryInfoMutation = graphql(`
  mutation UpdateRepository(
    $id: String!
    $icon: String
    $name: String
    $description: String
    $visibility: AccessVisibility
  ) {
    updateRepositoryInfo(
      updateRepositoryInfoArgs: {
        id: $id
        icon: $icon
        name: $name
        description: $description
        visibility: $visibility
      }
    ) {
      icon
      contributors
      created_date
      description
      favorites
      favorite_count
      id
      name
      slug
      readme
      bannerUrl
      owner
      pinned
      tabs {
        customName
        favIconUrl
        id
        pinned
        lastAccessed
        labels
        description
        parentDirectory
        repositoryId
        title
        url
      }
      directories {
        id
        color
        name
        parentDirectory
      }
      accessPermission
      permittedUsers
      updated_date
      visibility
      workspaceId
    }
  }
`);

export const createNewRepositoryMutation = graphql(`
  mutation CreateNewRepository(
    $repositorySlug: String
    $icon: String!
    $name: String!
    $description: String!
    $visibility: AccessVisibility!
    $tabs: [RepositoryTabAsInput!]!
    $directories: [DirectoryAsInput!]!
    $workspaceId: String!
  ) {
    createNewRepository(
      createRepositoryArgs: {
        repositorySlug: $repositorySlug
        icon: $icon
        name: $name
        description: $description
        tabs: $tabs
        directories: $directories
        visibility: $visibility
        workspaceId: $workspaceId
      }
    ) {
      message
      type
    }
  }
`);

export const deleteRepositoryMutation = graphql(`
  mutation DeleteRepository($id: String!) {
    deleteRepository(deleteRepositoryArgs: { id: $id }) {
      message
      type
    }
  }
`);

export const pinRepositoryMutation = graphql(`
  mutation PinRepository($id: String!) {
    pinRepository(pinRepositoryArgs: { id: $id }) {
      message
      type
    }
  }
`);

export const unpinRepositoryMutation = graphql(`
  mutation UnpinRepository($id: String!) {
    unpinRepository(unpinRepositoryArgs: { id: $id }) {
      message
      type
    }
  }
`);

export const removeTabsFromRepositoryMutation = graphql(`
  mutation RemoveTabsFromRepository($tabIds: [String!]!, $repositoryId: String!) {
    removeTabsFromRepository(removeTabsFromRepositoryArgs: { tabs: $tabIds, id: $repositoryId }) {
      message
      type
    }
  }
`);

export const removeContributorMutation = graphql(`
  mutation RemoveContributor($repositoryId: String!, $contributorEmail: String!) {
    removeRepositoryContributor(
      removeRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }
    ) {
      message
      type
    }
  }
`);

export const addContributorMutation = graphql(`
  mutation AddContributor($repositoryId: String!, $contributorEmail: String!) {
    addRepositoryContributor(
      addRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }
    ) {
      message
      type
    }
  }
`);

export const toggleLikeRepositoryMutation = graphql(`
  mutation ToggleLikeRepository($repositoryId: String!) {
    toggleLikeRepository(toggleLikeRepositoryArgs: { id: $repositoryId }) {
      message
      type
    }
  }
`);

export const updateRepositoryPermissionMutation = graphql(`
  mutation UpdateRepositoryAccess(
    $accessPermission: AccessPermission!
    $repositoryId: String!
    $permittedUsers: [String!]!
  ) {
    updateRepositoryAccess(
      updateRepositoryAccessArgs: {
        accessPermission: $accessPermission
        id: $repositoryId
        permittedUsers: $permittedUsers
      }
    ) {
      message
      type
    }
  }
`);

export const updateRepositoryReadmeMutation = graphql(`
  mutation UpdateREADME($repositoryId: String!, $readmeData: String!) {
    updateReadme(updateReadmeArgs: { repositoryId: $repositoryId, readmeData: $readmeData }) {
      message
      type
    }
  }
`);

export const updateRepositoryBannerMutation = graphql(`
  mutation UpdateRepositoryBanner(
    $repositoryId: String!
    $bannerData: Upload!
    $mimeType: String!
  ) {
    updateRepositoryBanner(
      updateRepositoryBannerArgs: {
        repositoryId: $repositoryId
        bannerData: $bannerData
        mimeType: $mimeType
      }
    ) {
      message
      type
    }
  }
`);

export const getRepositoryReadmeQuery = graphql(`
  query GetReadmeContent($repositoryId: String!) {
    getReadmeContent(readReadmeArgs: { repositoryId: $repositoryId })
  }
`);

export const getRepositoryBannerQuery = graphql(`
  query GetRepositoryBanner($repositoryId: String!) {
    getRepositoryBanner(getRepositoryBannerArgs: { repositoryId: $repositoryId })
  }
`);
