import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TimeTrackerSessionSetting, TimeTrackerSessionSettingInputType, User } from '@/gql/graphql';
import { getCurrentUserTimeTrackerQuery } from '@/graphql';
import {
  toggleTimeTrackerMutation,
  updateTimeTrackerSettingMutation,
} from '@/graphql/time-tracker';
import { SessionStatus } from '@/models';
import { apolloClient } from '@/services/ApolloService';
import { getCurrentHours } from '@/utils';

export type UserTimeTrackerInfo = Pick<
  User,
  'time_tracker_enabled' | 'time_tracker_setting' | 'time_tracker_sessions'
>;

export const fetchUserTimeTrackerInfo = async (): Promise<UserTimeTrackerInfo> => {
  const { data } = await apolloClient.query({
    query: getCurrentUserTimeTrackerQuery,
    fetchPolicy: 'network-only',
  });
  const user = data.getCurrentUser;
  return user;
};

export const updateUserTimeTrackerInfo = async (
  args: Partial<TimeTrackerSessionSettingInputType>
) => {
  const info = await fetchUserTimeTrackerInfo();
  const { __typename, ...payload } = {
    ...info.time_tracker_setting,
    ...args,
  };
  console.log(args);
  const { data } = await apolloClient.mutate({
    mutation: updateTimeTrackerSettingMutation,
    variables: {
      setting: payload,
    },
  });
  const updatedSetting = data.updateTimeTrackerSetting as TimeTrackerSessionSetting;
  return updatedSetting;
};

export const getTimeTrackerSessionStatus = (
  sessionSetting: TimeTrackerSessionSetting | undefined | null
): SessionStatus => {
  const currentHours = getCurrentHours();
  if (!sessionSetting) return SessionStatus.Invalid;
  if (currentHours < sessionSetting.session_start_time) return SessionStatus.NotStarted;
  if (currentHours > sessionSetting.session_end_time) return SessionStatus.Ended;
  return SessionStatus.Running;
};

export interface TimeTrackerStoreState {
  userTimeTrackerInfo: UserTimeTrackerInfo;
  timeTrackerLoading: boolean;
  toggleTimeTracker: () => Promise<void>;
  updateUserTimeTrackerInfo: (args: Partial<TimeTrackerSessionSettingInputType>) => Promise<void>;
  setTimeTrackerLoading: (loading: boolean) => void;
  setUserTimeTrackerInfo: (info: UserTimeTrackerInfo) => void;
}

export const useTimeTrackerStore = create<TimeTrackerStoreState>()(
  devtools(set => ({
    userTimeTrackerInfo: {
      time_tracker_enabled: false,
      time_tracker_sessions: [],
      time_tracker_setting: undefined,
    },
    timeTrackerLoading: true,
    /** TURN ON / TURN OFF recurring time tracker, time tracker is set as automatically run */
    toggleTimeTracker: async () => {
      await apolloClient.mutate({
        mutation: toggleTimeTrackerMutation,
      });
    },

    /** Update user time tracker info mutation */
    updateUserTimeTrackerInfo: async (
      args: Partial<TimeTrackerSessionSettingInputType>
    ): Promise<void> => {
      const updatedSetting = await updateUserTimeTrackerInfo(args);
      set(data => ({
        ...data,
        userTimeTrackerInfo: {
          ...data.userTimeTrackerInfo,
          time_tracker_setting: updatedSetting,
        },
      }));
    },
    setUserTimeTrackerInfo: (info: UserTimeTrackerInfo | undefined) => {
      set({
        userTimeTrackerInfo: info,
      });
    },

    setTimeTrackerLoading(loading) {
      set(_ => ({ timeTrackerLoading: loading }));
    },
  }))
);
