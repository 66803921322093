import React from 'react';

import { Breadcrumb, Button, Popover, Space } from 'antd';
import { Emoji, EmojiStyle } from 'emoji-picker-react';

import { GLOBAL_THEME_COLOR, LOCAL_WORKSPACE_ID, MIDDLE_STYLE } from '@/constants';
import { AccessVisibility } from '@/gql/graphql';
import { useBreakpoint, usePersonal, useRepositoryContext } from '@/hooks';
import { shortenString } from '@/utils';

import RenderUpdatedTime from '../render-updated-time';
import RepositoryFavoriteButton from '../repository-favorite-button';
import RepositoryPinButton from '../repository-pin-button';
import RepositoryShareButton from '../repository-share-button';

type Props = any;

const RepositoryMetaInfo = (props: Props) => {
  const { isTablet, isMobile } = useBreakpoint();
  const { isLocalRepository } = usePersonal();
  const { repository, workspace } = useRepositoryContext();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        flexWrap: 'wrap',
        marginTop: isTablet ? 15 : 0,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
        }}>
        <div>
          {workspace && (
            <div style={isTablet ? {} : { ...MIDDLE_STYLE }}>
              <div style={{ ...MIDDLE_STYLE }}>
                <Emoji unified={repository?.icon as any} emojiStyle={EmojiStyle.APPLE} />
                <Breadcrumb
                  style={{ marginLeft: 20 }}
                  items={
                    isMobile
                      ? [
                          {
                            href: `#/workspace/${workspace?.slug}`,
                            title: workspace?.name || 'Unknown',
                          },
                          {
                            href: `#/workspace/${workspace?.slug}/${repository?.slug}/`,
                            title: repository?.name || 'Unknown',
                          },
                        ]
                      : [
                          {
                            href: '#/workspace/browse',
                            title: 'Workspaces',
                          },
                          {
                            href: `#/workspace/${workspace?.slug}`,
                            title: workspace?.name || 'Unknown',
                          },
                          {
                            href: `#/workspace/${workspace?.slug}/${repository?.slug}/`,
                            title: repository?.name || 'Unknown',
                          },
                        ]
                  }
                />
              </div>
              {repository?.description && (
                <Popover
                  trigger={'hover'}
                  title={'Description'}
                  content={repository?.description || 'No description'}>
                  <span
                    style={{
                      color: GLOBAL_THEME_COLOR.$dark_text_color,
                      marginLeft: isTablet ? 0 : 20,
                    }}>
                    {shortenString(repository?.description || 'No description', 30)}
                  </span>
                </Popover>
              )}
            </div>
          )}
        </div>
      </div>
      {repository && (
        <div
          style={{
            display: isMobile ? 'block' : 'flex',
            textAlign: 'left',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div
            style={{
              fontSize: 12,
              color: GLOBAL_THEME_COLOR.$dark_text_color,
              marginRight: 10,
            }}>
            <RenderUpdatedTime time={repository?.updated_date as number} />
          </div>
          <Space style={{ display: 'flex' }}>
            <RepositoryPinButton repository={repository as any} />
            {repository?.workspaceId !== LOCAL_WORKSPACE_ID && (
              <RepositoryFavoriteButton repository={repository as any} />
            )}
            <Button>
              {repository?.visibility === AccessVisibility.Private ? 'Private' : 'Public'}
            </Button>
            {!isLocalRepository(repository) && (
              <RepositoryShareButton repository={repository as any} workspace={workspace as any} />
            )}
          </Space>
        </div>
      )}
    </div>
  );
};

export default RepositoryMetaInfo;
