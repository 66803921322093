/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Avatar, Tabs, TabsProps } from 'antd';

import { LoadableContainer, SplashScreen, UserSettingTab, UserWorkspacesTab } from '@/components';
import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { User } from '@/gql/graphql';
import { getUserByUsernameQuery } from '@/graphql';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore } from '@/stores';

type Props = Record<string, any>;

enum UserProfileTab {
  Settings = 'settings',
  Workspaces = 'workspaces',
  Repositories = 'repositories',
  QuickAccess = 'quick-access',
}

const UserProfileScreen = (props: Props) => {
  const { user: currentUser } = useAuthStore();
  const { username } = useParams<{ username: string }>();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [tab, setTab] = useState<string>(UserProfileTab.QuickAccess);
  const [loading, setLoading] = useState<boolean>(true);

  const handleTabChanged = (newTab: string) => {
    setTab(newTab);
  };

  const isCurrentUser = useMemo(() => user?.id === currentUser?.id, [user, currentUser]);

  const items: TabsProps['items'] = [
    {
      key: UserProfileTab.Workspaces,
      label: `Workspaces`,
      children: <UserWorkspacesTab user={user} />,
    },
    ...(isCurrentUser
      ? [
          {
            key: UserProfileTab.Settings,
            label: `Settings`,
            children: <UserSettingTab />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const { data } = await apolloClient.mutate({
          mutation: getUserByUsernameQuery,
          variables: {
            username,
          },
        });
        const _user = data?.getUserByUsername;
        if (!_user) return;
        setUser(_user as User);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };
    init();
  }, [username]);

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <React.Fragment>
        {user ? (
          <div style={{ padding: '30px 30px' }}>
            <div style={{ ...MIDDLE_STYLE, width: 'fit-content', marginBottom: 20 }}>
              <Avatar size={55} src={user.profile_image} />
              <div style={{ marginLeft: 20, textAlign: 'left' }}>
                <div style={{ fontSize: 20, margin: '0px !important' }}>{user.full_name}</div>
                <h3
                  style={{
                    fontSize: 14,
                    fontWeight: 'normal',
                    margin: 0,
                    color: GLOBAL_THEME_COLOR.$dark_text_color,
                  }}>
                  {user.email}
                </h3>
              </div>
            </div>
            <Tabs defaultActiveKey={tab} items={items} onChange={handleTabChanged} />
          </div>
        ) : (
          <div>No user found</div>
        )}
      </React.Fragment>
    </LoadableContainer>
  );
};

export default UserProfileScreen;
