import React from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Lottie from 'lottie-react';

import { ManLookingLottieFile } from '@/animations';
import { GLOBAL_THEME_COLOR } from '@/constants';

import SplashScreenPlaceholder from '../splash-screen-placeholder';

type Props = any;

const ExtensionNotInstalled = (props: Props) => {
  const handleReadDocumentation = () => {
    window.open('https://docs.tabhub.io/get-started/overview');
  };
  return (
    <SplashScreenPlaceholder>
      <div>
        <Lottie
          style={{
            height: 300,
          }}
          animationData={ManLookingLottieFile}
        />
        <h2>
          <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>TabHub extension</span> is
          not installed!
        </h2>
        <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
          Please install TabHub extension to use this feature.
        </div>
        <Button
          onClick={handleReadDocumentation}
          type="primary"
          style={{ width: 'fit-content', fontWeight: 'bold', marginTop: 20 }}>
          Read documentation <ArrowRightOutlined />
        </Button>
      </div>
    </SplashScreenPlaceholder>
  );
};

export default ExtensionNotInstalled;
