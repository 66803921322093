import { ApolloClient, ApolloLink, InMemoryCache, gql, split } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { AppEnvironment, DATA_FIREBASE_KEY } from '@/constants/env';
import {
  CORE_USER_FIELDS_FRAGMENT,
  CORE_USER_WORKSPACE_FRAGMENT,
  USER_TIME_TRACKER_SETTING_FRAGMENT,
} from '@/graphql';
import { FirebaseAuthData } from '@/typings/auth.type';
import { getSecondTimestamp } from '@/utils';
import { getPersistedAuthData, persistAuthData, requestRefreshTokenData } from '@/utils/auth.util';

export const GRAPHQL_BACKEND_ENDPOINT = `${AppEnvironment.REACT_APP_BACKEND_URL}/graphql`;
export const GRAPHQL_WEBSOCKET_ENDPOINT = `${AppEnvironment.REACT_APP_BACKEND_WS_URL}/graphql`;

export const buildCustomHeaders = async (originalHeaders: any) => {
  const headers = {
    ...originalHeaders,
    'keep-alive': 'true',
    'Apollo-Require-Preflight': 'true',
  };
  const localData = localStorage.getItem(DATA_FIREBASE_KEY);
  if (localData) {
    const data: FirebaseAuthData | null = getPersistedAuthData();
    // Firebase token expires every 1 hour, refresh the token if it is invalid
    if (data && getSecondTimestamp() - data.lastAccessedAt > 3600) {
      const responseData = await requestRefreshTokenData(data.refreshToken);
      persistAuthData({
        ...data,
        token: responseData.access_token,
        refreshToken: responseData.refresh_token,
        lastAccessedAt: getSecondTimestamp(),
      });
      data.token = responseData.access_token;
      data.refreshToken = responseData.refresh_token;
    }
    Object.assign(headers, {
      authorization: data?.token ? `Bearer ${data.token}` : '',
    });
  }
  return headers;
};

const uploadLink = createUploadLink({
  uri: GRAPHQL_BACKEND_ENDPOINT,
});

const wsLink = new WebSocketLink({
  uri: GRAPHQL_WEBSOCKET_ENDPOINT,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  uploadLink
);

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const customHeaders = await buildCustomHeaders(headers);
  // return the headers to the context so httpLink can read them
  return {
    headers: customHeaders,
  };
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, link]),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(gql`
      ${CORE_USER_FIELDS_FRAGMENT}
      ${CORE_USER_WORKSPACE_FRAGMENT}
      ${USER_TIME_TRACKER_SETTING_FRAGMENT}
    `),
  }),
});
