import React, { useMemo } from 'react';

import Browser from 'webextension-polyfill';

import { TabTreeDirectory, TreeNode } from '@/models';
import { useTabManagerStore } from '@/stores';

import { useWindowTabActions } from './useWindowTabActions';

export const useWindowTabActionsWithContext = (window: Browser.Windows.Window) => {
  const windowId = useMemo(() => window.id as any, [window]);
  const { selectedMultiverseTreeNodes, selectMultiverseTreeNodes, deselectMultiverseTreeNodes } =
    useTabManagerStore();
  const { handleSwitchTab } = useWindowTabActions();

  const handleWindowSelect = (window: Browser.Windows.Window) => {
    for (const windowTab of window.tabs || []) {
      handleSelectTab(windowTab);
    }
  };

  const handleTabClicked = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    windowTab: any
  ) => {
    switch (event.detail) {
      case 2:
        await handleSwitchTab(window, windowTab);
        return;
      default:
        handleSelectTab(windowTab);
        return;
    }
  };

  const handleTreeNodeClicked = async (
    windowId: string,
    treeNode: TreeNode,
    selected?: boolean
  ) => {
    if (!treeNode.value.id) return;
    const isSelected = selectedMultiverseTreeNodes?.[windowId]?.[treeNode.value.id];
    if (selected || isSelected) {
      deselectMultiverseTreeNodes(windowId, [treeNode]);
    } else {
      selectMultiverseTreeNodes(windowId, [treeNode]);
    }
  };

  const handleDirectoryClicked = async (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    directoryNode: TabTreeDirectory
  ) => {
    handleTreeNodeClicked(windowId, {
      type: 'directory',
      value: directoryNode,
    });
  };

  const handleSelectTab = (windowTab: any) => {
    if (!windowTab.id) return;
    handleTreeNodeClicked(windowTab.windowId, {
      type: 'tab',
      value: windowTab,
    });
  };

  return {
    handleTreeNodeClicked,
    handleWindowSelect,
    handleDirectoryClicked,
    handleSelectTab,
    handleSwitchTab,
    handleTabClicked,
  };
};
