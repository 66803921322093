import React from 'react';

import WebSearchBlankPage from '@/components/jumbotrons/websearch-blank-page';
import RepositoryTableView from '@/components/repository-table-view';
import { useWebSearchContext } from '@/hooks';

type Props = any;

const WebSearchAppExtensionContent = (props: Props) => {
  const { searchedUrls } = useWebSearchContext();

  return (
    <React.Fragment>
      {searchedUrls.length > 0 ? (
        <div>
          <RepositoryTableView actionable={false} />
        </div>
      ) : (
        <WebSearchBlankPage />
      )}
    </React.Fragment>
  );
};

export default WebSearchAppExtensionContent;
