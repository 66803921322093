import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Empty, Input, Space } from 'antd';
import Fuse from 'fuse.js';

import { GENERAL_EMPTY_IMAGE, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { User } from '@/gql/graphql';
import { getAllUsersQuery, getUserByIdQuery } from '@/graphql';
import { addContributorMutation } from '@/graphql/repositories';
import { useRepositoryContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { stringInArray } from '@/utils';

import LoadableContainer from '../loadable-container';
import RepositoryContributorList from '../repository-contributor-list';

type Props = any;

const RepositoryContributors = (props: Props) => {
  const { repository, onRefresh } = useRepositoryContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [contributors, setContributors] = useState<Partial<User>[]>([]);
  const [refreshing, setRefreshing] = useState(+new Date());
  const [users, setUsers] = useState<Partial<User>[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterUsers, setFilterUsers] = useState<Partial<User>[]>([]);

  const handleSearchUser = async (text: string) => {
    const { data } = await apolloClient.query({
      query: getAllUsersQuery,
    });
    setUsers(data.getAllUsers);
    const fuse = new Fuse(data.getAllUsers, { keys: ['email', 'username'] });
    const results = fuse.search(text);
    setFilterUsers(results.map(result => result.item));
    setSearchInput(text);
  };

  const handleAddContributor = async () => {
    if (!repository) return;
    setLoading(true);
    try {
      await apolloClient.mutate({
        mutation: addContributorMutation,
        variables: {
          contributorEmail: searchInput,
          repositoryId: repository.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    handleRefresh();
    onRefresh();
  };

  const handleRefresh = () => {
    setRefreshing(+new Date());
  };

  useEffect(() => {
    const init = async () => {
      if (!repository) return;
      setLoading(true);
      try {
        const fetchUsers: Partial<User>[] = [];
        for (const contributor of repository.contributors) {
          const { data } = await apolloClient.query({
            query: getUserByIdQuery,
            variables: {
              id: contributor,
            },
          });
          if (data.getUserById) {
            fetchUsers.push(data.getUserById);
          }
        }
        setContributors(fetchUsers);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    init();
  }, [repository, refreshing]);

  return (
    <div>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>
          <TeamOutlined /> Contributors
        </h3>
        <Space style={{ display: 'flex' }}>
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            style={{ width: '350px' }}
            onChange={(value: string) => handleSearchUser(value)}
            placeholder="Search user by username or email address"
            options={filterUsers.map(user => ({
              value: user.email,
            }))}>
            <Input.Search size="middle" value={searchInput} style={{ marginRight: 10 }} />
          </AutoComplete>
          <Button
            disabled={
              stringInArray(
                contributors.map(contributor => contributor.email || '') || [],
                searchInput
              ) || !stringInArray(users.map(user => user.email || '') || [], searchInput)
            }
            type="primary"
            onClick={handleAddContributor}>
            <UserAddOutlined /> Add new contributor
          </Button>
        </Space>
      </Space>
      <LoadableContainer
        isLoading={loading}
        loadComponent={
          <div style={{ ...MIDDLE_STYLE }}>
            <ReactLoading
              type={'cylon'}
              color={GLOBAL_THEME_COLOR.$highlight_color}
              height={'10%'}
              width={'10%'}
            />
          </div>
        }>
        <LoadableContainer
          isLoading={contributors.length === 0}
          loadComponent={
            <div
              style={{
                ...MIDDLE_STYLE,
                width: '100%',
                height: '200px',
              }}>
              <Empty
                image={GENERAL_EMPTY_IMAGE}
                imageStyle={{ height: 60 }}
                description={<span>No contributors found</span>}></Empty>
            </div>
          }>
          <RepositoryContributorList repository={repository} contributors={contributors} />
        </LoadableContainer>
      </LoadableContainer>
    </div>
  );
};

export default RepositoryContributors;
