type AppEnvVariables = {
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_BACKEND_URL: string;
  REACT_APP_BACKEND_WS_URL: string;
  REACT_APP_WEB_APP_URL: string;
  REACT_APP_FIREBASE_MEASUREMENT: string;
};

const ProductionEnvironment: AppEnvVariables = {
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyBpW5qc3SjZYnRVYcu5MGt2YSJcLj17yNo',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'tabhub-io.firebaseapp.com',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'tabhub-io.appspot.com',
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID: '509158984468',
  REACT_APP_FIREBASE_APP_ID: '1:509158984468:web:e5513137a2f6afb90b1001',
  REACT_APP_FIREBASE_PROJECT_ID: 'tabhub-io',
  REACT_APP_FIREBASE_MEASUREMENT: 'G-PJJXFEBDG3',

  REACT_APP_BACKEND_URL: 'https://api.tabhub.io',
  REACT_APP_BACKEND_WS_URL: 'wss://api.tabhub.io',
  REACT_APP_WEB_APP_URL: 'https://app.tabhub.io/#',
};

const DevelopmentEnvironment: AppEnvVariables = {
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyA2DUeqEviK8o2TnVuQkAKTCoy-st-U6-g',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'project-onetab.firebaseapp.com',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'project-onetab.appspot.com',
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID: '232477810505',
  REACT_APP_FIREBASE_APP_ID: '1:232477810505:web:ea0ae104ae4d9aff7a8498',
  REACT_APP_FIREBASE_PROJECT_ID: 'project-onetab',
  REACT_APP_FIREBASE_MEASUREMENT: 'G-HK0CZ7JP6P',

  REACT_APP_BACKEND_URL: 'http://localhost:8080',
  REACT_APP_BACKEND_WS_URL: 'ws://localhost:8080',
  REACT_APP_WEB_APP_URL: 'http://localhost:3000/#',
};

export const DATA_FIREBASE_KEY = 'FIREBASE_AUTH_DATA';
export const DATA_FIREBASE_ENCRYPTION_PASS = process.env.REACT_APP_SECRET_PASS || 'dev-secret-pass';
export const isDevelopment = process.env.REACT_APP_ENV === 'DEVELOPMENT';
export const EXTENSION_ID = 'lmofhhaibahhojhinmpgmifdmennlcjn';
export const AppEnvironment = isDevelopment ? DevelopmentEnvironment : ProductionEnvironment;
export const APP_WEBSITE_PROD = 'https://app.tabhub.io';
export const APP_WEBSITE_DEV = 'http://localhost:3000';
