import { buildPinnedTabKey, buildWorkspaceKey } from '@/constants';
import { Repository, RepositoryTab } from '@/gql/graphql';

import BrowserStorageService from './BrowserStorageService';

export default class WorkspaceService extends BrowserStorageService {
  public static async getLocalPinnedTabs(): Promise<RepositoryTab[]> {
    const key = buildPinnedTabKey();
    const records = await BrowserStorageService.getValue(key);
    const localRepositories: RepositoryTab[] = records;
    return localRepositories || [];
  }
  public static async getLocalWorkspaceRepositories(workspaceId: string): Promise<Repository[]> {
    const key = buildWorkspaceKey(workspaceId);
    const records = await BrowserStorageService.getValue(key);
    const localRepositories: any[] = records;
    return localRepositories || [];
  }
}
