import React from 'react';

import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { Repository } from '@/gql/graphql';
import { usePersonal } from '@/hooks';

type Props = { repository: Repository; isPinned?: boolean };

const AuthPinIcon = ({ repository, isPinned }: Props) => {
  const { isAuthPinned } = usePersonal();
  return isPinned || isAuthPinned(repository) ? (
    <PushpinFilled style={{ marginRight: 3, color: GLOBAL_THEME_COLOR.$love_color }} />
  ) : (
    <PushpinOutlined style={{ marginRight: 3, color: GLOBAL_THEME_COLOR.$love_color }} />
  );
};

export default AuthPinIcon;
