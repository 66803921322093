export default class BrowserStorageService {
  public static async setValue(key: string, value: any) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  public static async getValue(key: string) {
    try {
      const item = localStorage.getItem(key);
      if (!item) throw new Error('No item found');
      return JSON.parse(item);
    } catch (error) {
      return undefined;
    }
  }
}
