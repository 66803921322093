import React from 'react';

type Props = {
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const SplashScreenPlaceholder = ({ style, children }: Props) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '80vh',
      width: '100%',
      flexDirection: 'column',
      ...style,
    }}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>{children}</div>
  </div>
);

export default SplashScreenPlaceholder;
