import { graphql } from '@/gql';

export const getAllWorkspacesQuery = graphql(`
  query GetAllWorkspaces {
    getAllWorkspaces {
      created_date
      description
      id
      slug
      members
      roles
      name
      owner
      repositories
      updated_date
      visibility
    }
  }
`);

export const getUserWorkspacesQuery = graphql(`
  query GetUserWorkspaces($userId: String!) {
    getUserWorkspaces(getUserWorkspacesArgs: { userId: $userId }) {
      created_date
      description
      id
      slug
      members
      roles
      name
      owner
      repositories
      updated_date
      visibility
    }
  }
`);

export const getWorkspaceByIdQuery = graphql(`
  query GetWorkspaceById($id: String!) {
    getWorkspaceById(getWorkspaceByIdArgs: { id: $id }) {
      created_date
      description
      id
      slug
      members
      roles
      name
      owner
      repositories
      updated_date
      visibility
    }
  }
`);

export const getWorkspaceByNameQuery = graphql(`
  query GetWorkspaceByName($workspaceName: String!) {
    getWorkspaceByName(getWorkspaceByNameArgs: { workspace_name: $workspaceName }) {
      created_date
      description
      id
      slug
      members
      roles
      name
      owner
      repositories
      updated_date
      visibility
    }
  }
`);

export const getWorkspaceBySlugQuery = graphql(`
  query GetWorkspaceBySlug($workspaceSlug: String!) {
    getWorkspaceBySlug(getWorkspaceBySlugArgs: { workspace_slug: $workspaceSlug }) {
      created_date
      description
      id
      slug
      members
      roles
      name
      owner
      repositories
      updated_date
      visibility
    }
  }
`);

export const getMyWorkspacesQuery = graphql(`
  query GetMyWorkspaces {
    getMyWorkspaces {
      id
      slug
      name
      owner
      repositories
      visibility
      members
      roles
      created_date
      description
      updated_date
    }
  }
`);

export const createWorkspaceMutation = graphql(`
  mutation CreateNewWorkspace($name: String!, $description: String!, $visibility: Float!) {
    createNewWorkspace(
      createNewWorksapceArgs: { name: $name, description: $description, visibility: $visibility }
    ) {
      message
      type
    }
  }
`);

export const updateWorkspaceMutation = graphql(`
  mutation UpdateWorkspace(
    $id: String!
    $name: String!
    $description: String
    $visibility: AccessVisibility!
  ) {
    updateWorkspace(
      updateWorkspaceArgs: {
        id: $id
        name: $name
        description: $description
        visibility: $visibility
      }
    ) {
      message
      type
    }
  }
`);

export const deleteWorkspaceMutation = graphql(`
  mutation DeleteWorkspace($workspaceId: String!) {
    deleteWorkspace(deleteWorkspaceArgs: { id: $workspaceId }) {
      message
      type
    }
  }
`);

export const addWorkspaceMemberMutation = graphql(`
  mutation AddWorkspaceMember($workspaceId: String!, $memberEmail: String!, $role: UserRole!) {
    addWorkspaceMember(
      addWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail, role: $role }
    ) {
      message
      type
    }
  }
`);

export const removeWorkspaceMemberMutation = graphql(`
  mutation RemoveWorkspaceMember($workspaceId: String!, $memberEmail: String!) {
    removeWorkspaceMember(
      removeWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail }
    ) {
      message
      type
    }
  }
`);

export const selectQuickAccessWorkspaceMutation = graphql(`
  mutation SelectQuickAccessWorkspace($workspaceId: String) {
    selectQuickAccessWorkspace(selectQuickAccessWorkspaceArgs: { id: $workspaceId }) {
      message
      type
    }
  }
`);
