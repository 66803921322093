import * as yup from 'yup';

import { AccessVisibility } from '@/gql/graphql';

export interface NewWorkspaceValues {
  workspaceName: string;
  workspaceDescription: string;
  visibility: AccessVisibility;
}

export const newWorkspaceValidationSchema = yup.object({
  workspaceName: yup
    .string()
    .min(4, 'Name must be at least 4 characters')
    .max(30, 'Name must be under 30 characters')
    .required('Name is required'),
  workspaceDescription: yup
    .string()
    .max(255, 'Repository description must be under 255 characters'),
} as Record<keyof NewWorkspaceValues, any>);

export const newWorkspaceDefaultValues: NewWorkspaceValues = {
  workspaceName: '',
  workspaceDescription: '',
  visibility: AccessVisibility.Public,
};
