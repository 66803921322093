import React from 'react';

import { Avatar, Popover } from 'antd';

import { useAuthStore } from '@/stores';

import AppBarPopoverDesktop from '../appbar-popover-desktop';
import SignInButton from '../signin-button';

type Props = any;

const AppBarUserSectionDesktop = (props: Props) => {
  const { user } = useAuthStore();
  return user ? (
    <Popover content={<AppBarPopoverDesktop />} trigger="click">
      <div
        style={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Avatar src={user?.profile_image} />
        <h3 style={{ marginLeft: 15 }}>{user?.full_name}</h3>
      </div>
    </Popover>
  ) : (
    <SignInButton />
  );
};

export default AppBarUserSectionDesktop;
