import React from 'react';

import { Modal } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants/theme';
import { useModalStore } from '@/stores';
import { ModalType } from '@/utils';

type Props = {
  children: React.ReactElement;
  modalName: ModalType;
  title: any;
  onOk?: any;
  okText?: string;
  onCancel?: any;
  closable?: boolean;
  okButtonDisabled?: boolean;
  cancelText?: string;
  width: number | string;
  footer?: React.ReactNode;
};

const ReusableModalWrapper = ({
  children,
  modalName,
  title,
  onOk,
  okButtonDisabled,
  onCancel,
  closable,
  width,
  okText,
  cancelText,
  footer,
}: Props) => {
  const { modalName: selectedModal } = useModalStore();
  return (
    <Modal
      closable={closable}
      footer={footer}
      bodyStyle={{
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
        accentColor: GLOBAL_THEME_COLOR.$primary_color,
      }}
      width={width}
      title={title}
      open={selectedModal === modalName}
      okText={okText}
      okButtonProps={{
        disabled: okButtonDisabled,
      }}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={onCancel}>
      {children}
    </Modal>
  );
};

export default ReusableModalWrapper;
