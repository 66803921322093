import React from 'react';

import { Tag } from 'antd';

import { TimeTrackerEngineState } from '@/gql/graphql';

type Props = {
  state: TimeTrackerEngineState;
};

const TimeTrackerEngineStateBadge = ({ state }: Props) => {
  switch (state) {
    case TimeTrackerEngineState.Idle:
      return <Tag color="processing">Idle</Tag>;
    case TimeTrackerEngineState.Running:
      return <Tag color="green">Running</Tag>;
    case TimeTrackerEngineState.Offline:
      return <Tag color="default">Offline</Tag>;
    default:
      return <></>;
  }
};

export default TimeTrackerEngineStateBadge;
