import React from 'react';

import { Space } from 'antd';

import { RepositoryTab } from '@/gql/graphql';
import { TabTreeDirectory, TreeNode } from '@/models';

import AnimatedComponent from '../animated-component';
import DirectoryNodeCardItem from '../directory-node-card-item';
import LoadableContainer from '../loadable-container';
import WindowTabCard from '../window-tab-card';

type Props = {
  treeNodes: TreeNode[];
  itemSelectedCondition: (tabItem: RepositoryTab) => boolean;
  itemOnClickedEvent: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab: RepositoryTab
  ) => void;
};

const TreeNodeCardList = ({ treeNodes, itemOnClickedEvent, itemSelectedCondition }: Props) => {
  return (
    <LoadableContainer isLoading={treeNodes.length <= 0} loadComponent={<div>No tabs found</div>}>
      <Space style={{ flexWrap: 'wrap' }}>
        {treeNodes.map((treeItem, index) => (
          <AnimatedComponent.OpacityFadeInDiv delay={index * 50 + 100}>
            <React.Fragment>
              {treeItem.type === 'tab' ? (
                <WindowTabCard
                  key={treeItem.value.id}
                  isSelected={itemSelectedCondition(treeItem.value as RepositoryTab)}
                  tab={treeItem.value as RepositoryTab}
                  onClick={itemOnClickedEvent}
                />
              ) : (
                <DirectoryNodeCardItem
                  key={treeItem.value.id}
                  itemOnClickedEvent={itemOnClickedEvent}
                  itemSelectedCondition={itemSelectedCondition}
                  node={treeItem.value as TabTreeDirectory}
                />
              )}
            </React.Fragment>
          </AnimatedComponent.OpacityFadeInDiv>
        ))}
      </Space>
    </LoadableContainer>
  );
};

export default TreeNodeCardList;
