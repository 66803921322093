import React, { useState } from 'react';

import ContextMenuProvider from '@/components/context-menu-provider';
import SelectedRepositoryProvider from '@/components/selected-repository-provider';

import SmartTabGroupingDrawer from '.';

type Props = any;

const SmartGroupingProvider = (props: Props) => {
  const [loading, setLoading] = useState(false);
  return (
    <ContextMenuProvider>
      <SelectedRepositoryProvider loading={loading} setLoading={setLoading}>
        {() => <SmartTabGroupingDrawer />}
      </SelectedRepositoryProvider>
    </ContextMenuProvider>
  );
};

export default SmartGroupingProvider;
