const TabCardImageBanner = ({ photoUrl, height }: { photoUrl: string; height?: number }) => {
  return (
    <div
      style={{
        background: `url("${photoUrl}")`,
        height: height || 150,
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        padding: '10px 20px',
      }}></div>
  );
};

export default TabCardImageBanner;
