import React, { useContext } from 'react';

import { Workspace } from '@/gql/graphql';

export const WorkspaceContext = React.createContext<{
  workspace: Workspace | undefined;
  onRefresh: () => void;
}>({
  workspace: undefined,
  onRefresh: () => {
    return;
  },
});

export const useWorkspaceContext = () => {
  const data = useContext(WorkspaceContext);
  return data;
};
