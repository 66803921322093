import Browser from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import { queryOpenGraphMetadata } from '@/graphql';
import { TreeNode, UnionTab } from '@/models';
import { apolloClient } from '@/services/ApolloService';
import { useModalStore, useSnackbarStore, useWindowStore } from '@/stores';
import { sanitizeWindowTabs } from '@/utils';

import { useBrowserDevService } from './useBrowserDevService';

export const useWindowTabActions = () => {
  const { openModal } = useModalStore();
  const { fetchWindows } = useWindowStore();
  const { enqueueNotification } = useSnackbarStore();
  const browserDevService = useBrowserDevService();

  const onRefresh = async () => {
    await fetchWindows(browserDevService);
  };

  const handleSwitchTab = async (window, tab: Browser.Tabs.Tab) => {
    const tabId = parseInt(tab.id as any);
    const tabs = sanitizeWindowTabs(window);
    const switchedTab = tabs.find(tab => tab?.id === tabId);
    if (!switchedTab) return;
    await browserDevService.selectTab(switchedTab.windowId, switchedTab.id);
  };

  const handleSaveTabs = (
    window: any | undefined,
    treeNodes: TreeNode[],
    selectedTreeNodes: Record<string, boolean>
  ) => {
    openModal('saveTabs', {
      window,
      treeNodes,
      selectedTreeNodes: selectedTreeNodes || {},
    });
  };

  const handleCloseTab = async (tab: UnionTab) => {
    await browserDevService.deleteTab(parseInt(tab.id as any));
    await onRefresh();
  };

  const handleCloseAll = async (window: Browser.Windows.Window) => {
    await browserDevService.closeWindow(window);
    await enqueueNotification({
      name: 'Tabs closed successfully',
      description: 'Finished closing all tabs',
      type: 'Success',
    });
    await onRefresh();
  };

  const handleSavePreflightTabs = async (
    window: any | undefined,
    treeNodes: TreeNode[],
    selectedTreeNodes: Record<string, boolean>
  ) => {
    const _preflightTreeNodes: TreeNode[] = [];
    const treeNodesQueue: TreeNode[][] = [treeNodes];
    while (treeNodesQueue.length > 0) {
      const _curTreeNodes = treeNodesQueue.pop();
      if (!_curTreeNodes) break;
      for (const treeNode of _curTreeNodes) {
        if (treeNode.type === 'tab') {
          const tab = treeNode.value as RepositoryTab;
          if (tab.title.length > 0 || !selectedTreeNodes[tab.id.toString()]) {
            continue;
          }
          try {
            const { data } = await apolloClient.query({
              query: queryOpenGraphMetadata,
              fetchPolicy: 'cache-first',
              variables: {
                websiteUrl: tab.url,
              },
            });
            const og = JSON.parse(data.queryOpenGraphMetadata.message);
            _preflightTreeNodes.push({
              type: 'tab',
              value: {
                ...tab,
                title: og.ogTitle || tab.url,
                description: og.ogDescription || '',
              },
            });
            continue;
          } catch (error) {
            console.log(error);
          }
          _preflightTreeNodes.push({
            type: 'tab',
            value: {
              ...tab,
              title: tab.url,
            },
          });
        }
      }
    }
    handleSaveTabs(window, _preflightTreeNodes, selectedTreeNodes);
  };

  return {
    handleCloseTab,
    handleSaveTabs,
    handleSavePreflightTabs,
    handleSwitchTab,
    handleCloseAll,
    onRefresh,
  };
};
