import React from 'react';

import { HourglassOutlined } from '@ant-design/icons';
import { Card, Empty } from 'antd';

import { GENERAL_EMPTY_IMAGE, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useTimeMachineContext } from '@/hooks';
import { TaskEventType } from '@/models';
import { voidCallback } from '@/utils';

import LoadableContainer from '../loadable-container';
import TimeTrackerEngineStateBadge from '../time-tracker-engine-state-badge';
import TimelessWindowTabItem from '../timeless-window-tab-item';

type Props = any;

const TimeTrackerActiveLogSection = (props: Props) => {
  const { engineState, trackedTabs } = useTimeMachineContext();
  return (
    <Card
      style={{ marginTop: 10 }}
      title={
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            padding: '0px 20px',
          }}>
          <h4 style={{ margin: 0 }}>
            <HourglassOutlined /> Activity
          </h4>
          <TimeTrackerEngineStateBadge state={engineState} />
        </div>
      }>
      <LoadableContainer
        isLoading={trackedTabs.length === 0}
        loadComponent={
          <Empty
            image={GENERAL_EMPTY_IMAGE}
            imageStyle={{ height: 60 }}
            description={<span>No tabs found</span>}></Empty>
        }>
        <div
          style={{
            height: 'fit-content',
            maxHeight: '500px',
            overflow: 'auto',
            overflowX: 'hidden',
          }}>
          {trackedTabs.map((tab, index) => (
            <div className={index % 2 === 0 ? 'even-treenode' : 'odd-treenode'}>
              <TimelessWindowTabItem
                key={`${tab.id}-${tab.timestamp}`}
                isSelected={false}
                style={
                  tab.type === TaskEventType.Interupt
                    ? { backgroundColor: GLOBAL_THEME_COLOR.$light_highlight_color }
                    : {}
                }
                windowTab={tab as any}
                timestamp={tab.timestamp}
                onClick={voidCallback}
              />
            </div>
          ))}
        </div>
      </LoadableContainer>
    </Card>
  );
};

export default TimeTrackerActiveLogSection;
