import _ from 'lodash';
import moment from 'moment';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { LOCAL_WORKSPACE_ID, buildWorkspaceKey, localWorkspace } from '@/constants';
import {
  AccessPermission,
  AccessVisibility,
  Directory,
  Repository,
  RepositoryTab,
  Workspace,
} from '@/gql/graphql';
import {
  getAllWorkspacesQuery,
  getUserWorkspacesQuery,
  getMyWorkspacesQuery,
  getWorkspaceByIdQuery,
  getWorkspaceBySlugQuery,
} from '@/graphql';
import {
  createNewRepositoryMutation,
  deleteRepositoryMutation,
  pinRepositoryMutation,
  removeTabsFromRepositoryMutation,
  unpinRepositoryMutation,
} from '@/graphql/repositories';
import { BrowserStorageService, WorkspaceService } from '@/services';
import { apolloClient } from '@/services/ApolloService';
import { buildSlug } from '@/utils';

export interface WorkspaceStoreState {
  isRefreshing: number;
  setRefreshing: () => void;
  workspaces: Workspace[];
  selectedWorkspaceId: string | undefined | null;
  pinRepository: (workspaceId: string, repositoryId: string) => Promise<void>;
  unpinRepository: (workspaceId: string, repositoryId: string) => Promise<void>;
  setWorkspaces: (workspaces: Workspace[]) => void;
  addWorkspace: (workspace: Workspace) => void;
  fetchAllPublicWorkspaces: () => Promise<Workspace[]>;
  fetchWorkspaces: () => Promise<Workspace[]>;
  fetchUserWorkspaces: (userId: string) => Promise<Workspace[]>;
  fetchWorkspaceById: (workspaceId: string) => Promise<Workspace | undefined | null>;
  fetchWorkspaceBySlug: (workspaceSlug: string) => Promise<Workspace | undefined | null>;
  selectWorkspace: (workspaceId: string) => void;
  unselectWorkspace: () => void;
  deleteRepository: (workspaceId: string, repositoryId: string) => Promise<void>;
  removeTabsFromRepository: (
    workspaceId: string,
    tabs: RepositoryTab[],
    repositoryId: string
  ) => Promise<void>;
  upsertTabsToRepository: (
    workspaceId: string,
    icon: string,
    repositoryName: string,
    tabs: RepositoryTab[],
    visibility: AccessVisibility,
    directories?: Directory[],
    repositoryDescription?: string
  ) => Promise<void>;
  getQuickAccessWorkspace: () => Promise<Workspace>;
}

export const useWorkspaceStore = create<WorkspaceStoreState>()(
  devtools(set => ({
    isRefreshing: +new Date(),
    setRefreshing: () => set(_ => ({ isRefreshing: +new Date() })),
    workspaces: [] as Workspace[],
    selectedWorkspaceId: undefined,
    setWorkspaces: (workspaces: Workspace[]) => set(_ => ({ workspaces: workspaces })),
    selectWorkspace: (workspaceId: string) => set(_ => ({ selectedWorkspaceId: workspaceId })),
    unselectWorkspace: () => set(_ => ({ selectedWorkspaceId: undefined })),
    addWorkspace: (workspace: Workspace) =>
      set(state => ({ workspaces: state.workspaces.concat(workspace) })),
    getQuickAccessWorkspace: async () => {
      /** If there is no workspace or user not sign in, default return local workspace */
      return localWorkspace;
    },
    fetchWorkspaceBySlug: async (workspaceSlug: string) => {
      if (workspaceSlug === localWorkspace.slug) {
        return localWorkspace;
      } else {
        const { data } = await apolloClient.query({
          query: getWorkspaceBySlugQuery,
          fetchPolicy: 'cache-first',
          variables: {
            workspaceSlug,
          },
        });
        return data.getWorkspaceBySlug;
      }
    },
    fetchWorkspaceById: async (workspaceId: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        return localWorkspace;
      } else {
        const { data } = await apolloClient.query({
          query: getWorkspaceByIdQuery,
          fetchPolicy: 'network-only',
          variables: {
            id: workspaceId,
          },
        });
        return data.getWorkspaceById;
      }
    },
    fetchAllPublicWorkspaces: async () => {
      // Handle fetch all public workspaces
      const { data } = await apolloClient.query({
        fetchPolicy: 'cache-first',
        query: getAllWorkspacesQuery,
      });
      return data.getAllWorkspaces;
    },
    fetchUserWorkspaces: async (userId: string) => {
      const { data } = await apolloClient.query({
        fetchPolicy: 'cache-first',
        query: getUserWorkspacesQuery,
        variables: {
          userId,
        },
      });
      const workspaces = [...(data.getUserWorkspaces as Workspace[])];
      set(_ => ({ workspaces }));
      return workspaces;
    },
    fetchWorkspaces: async () => {
      try {
        const { data } = await apolloClient.query({
          query: getMyWorkspacesQuery,
          fetchPolicy: 'cache-first',
        });
        const workspaces = [localWorkspace, ...(data.getMyWorkspaces as Workspace[])];
        set(_ => ({ workspaces }));
        return workspaces;
      } catch (error: any) {
        const workspaces = [localWorkspace];
        set(_ => ({ workspaces }));
        return workspaces;
      }
    },
    deleteRepository: async (workspaceId: string, repositoryId: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        await deleteRepositoryLocal(workspaceId, repositoryId);
      } else {
        await deleteRepositoryRemote(repositoryId);
      }
    },
    pinRepository: async (workspaceId: string, repositoryId: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        const key = buildWorkspaceKey(workspaceId);
        const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
        const newRepositories: Repository[] = [];
        for (const localRepository of localRepositories) {
          if (localRepository.id === repositoryId) {
            localRepository.pinned = ['PINNED'];
          }
          newRepositories.push(localRepository);
        }
        await BrowserStorageService.setValue(key, newRepositories);
      } else {
        await apolloClient.mutate({
          mutation: pinRepositoryMutation,
          variables: {
            id: repositoryId,
          },
        });
      }
    },
    unpinRepository: async (workspaceId: string, repositoryId: string) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        const key = buildWorkspaceKey(workspaceId);
        const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
        const newRepositories: Repository[] = [];
        for (const localRepository of localRepositories) {
          if (localRepository.id === repositoryId) {
            localRepository.pinned = [];
          }
          newRepositories.push(localRepository);
        }
        await BrowserStorageService.setValue(key, newRepositories);
      } else {
        await apolloClient.mutate({
          mutation: unpinRepositoryMutation,
          variables: {
            id: repositoryId,
          },
        });
      }
    },
    removeTabsFromRepository: async (
      workspaceId: string,
      tabs: RepositoryTab[],
      repositoryId: string
    ) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        await removeTabsFromRepositoryLocal(workspaceId, repositoryId, tabs);
      } else {
        await removeTabsFromRepositoryRemote(repositoryId, tabs);
      }
    },
    upsertTabsToRepository: async (
      workspaceId: string,
      icon: string,
      repositoryName: string,
      tabs: RepositoryTab[],
      visibility: AccessVisibility,
      directories: Directory[] = [],
      repositoryDescription?: string
    ) => {
      if (workspaceId === LOCAL_WORKSPACE_ID) {
        await createNewRepositoryLocal(
          workspaceId,
          icon,
          repositoryName,
          tabs,
          directories,
          repositoryDescription
        );
      } else {
        await createNewRepositoryRemote(
          workspaceId,
          icon,
          repositoryName,
          directories,
          tabs,
          visibility,
          repositoryDescription
        );
      }
    },
  }))
);

const deleteRepositoryLocal = async (workspaceId: string, repositoryId: string) => {
  const key = buildWorkspaceKey(workspaceId);
  const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
  await BrowserStorageService.setValue(
    key,
    localRepositories.filter(repository => repository.id !== repositoryId)
  );
};

const deleteRepositoryRemote = async (repositoryId: string) => {
  await apolloClient.mutate({
    mutation: deleteRepositoryMutation,
    variables: {
      id: repositoryId,
    },
  });
};

const removeTabsFromRepositoryLocal = async (
  workspaceId: string,
  repositoryId: string,
  tabs: RepositoryTab[]
) => {
  const key = buildWorkspaceKey(workspaceId);
  const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
  const repositories: Repository[] = [];
  for (const localRepository of localRepositories) {
    if (localRepository.id === repositoryId) {
      const repository: Repository = {
        ...localRepository,
        tabs: localRepository.tabs.filter(
          tab => !tabs.some(_tab => (_tab.url as string) === (tab.url as string))
        ),
      };
      repositories.push(repository);
    } else {
      repositories.push(localRepository);
    }
  }
  await BrowserStorageService.setValue(key, repositories);
};

const removeTabsFromRepositoryRemote = async (repositoryId: string, tabs: RepositoryTab[]) => {
  await apolloClient.mutate({
    mutation: removeTabsFromRepositoryMutation,
    variables: {
      repositoryId: repositoryId,
      tabIds: tabs.map(tab => tab.id),
    },
  });
};

const createNewRepositoryRemote = async (
  workspaceId: string,
  icon: string,
  repositoryName: string,
  directories: Directory[],
  tabs: RepositoryTab[],
  visibility: AccessVisibility,
  repositoryDescription?: string
) => {
  await apolloClient.mutate({
    mutation: createNewRepositoryMutation,
    variables: {
      icon,
      name: repositoryName,
      description: repositoryDescription || '',
      visibility,
      directories,
      tabs: tabs.map(tab => ({
        url: tab.url,
        title: tab.title,
        favIconUrl: tab.favIconUrl || '',
        customName: tab.customName,
        parentDirectory: tab.parentDirectory?.toString(),
      })),
      workspaceId: workspaceId,
    },
  });
};

const createNewRepositoryLocal = async (
  workspaceId: string,
  icon: string,
  repositoryName: string,
  tabs: RepositoryTab[],
  directories: Directory[],
  repositoryDescription?: string
) => {
  const key = buildWorkspaceKey(workspaceId);
  const localRepositories = await WorkspaceService.getLocalWorkspaceRepositories(workspaceId);
  const repositories: Repository[] = [];
  let isFound = false;
  for (const localRepository of localRepositories) {
    if (localRepository.slug === buildSlug(repositoryName)) {
      isFound = true;
      const repository: Repository = {
        ...localRepository,
        description: repositoryDescription,
        tabs: _.uniqBy(localRepository.tabs.concat(tabs), 'url' as keyof RepositoryTab),
        updated_date: moment().unix(),
      };
      repositories.push(repository);
    } else {
      repositories.push(localRepository);
    }
  }
  if (isFound) {
    await BrowserStorageService.setValue(key, repositories);
  } else {
    const date = moment().unix();
    const repository: Repository = {
      name: repositoryName,
      favorite_count: 0,
      slug: buildSlug(repositoryName),
      icon,
      workspaceId: workspaceId,
      contributors: [],
      id: `random-local-repository-${date.toString()}`,
      created_date: date,
      favorites: [],
      pinned: [],
      updated_date: date,
      owner: 'guest-account',
      visibility: AccessVisibility.Private,
      description: repositoryDescription,
      tabs: tabs,
      accessPermission: AccessPermission.OnlyPeopleWhoHasAccess,
      permittedUsers: [],
      directories: directories,
    };
    await BrowserStorageService.setValue(key, (localRepositories || []).concat(repository));
  }
};
