import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import loadable from '@loadable/component';

const TimeTrackerScreen = loadable(() => import('../../screens/TimeTrackerScreen'));
const TimeTrackerLandingRedirectScreen = loadable(
  () => import('../../screens/TimeTrackerLandingRedirectScreen')
);

export interface CustomRouteProps {
  isExact: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | React.ComponentType<any>;
  path: string;
}

export const timeTrackerRouteList: CustomRouteProps[] = [
  {
    isExact: true,
    component: () => <TimeTrackerLandingRedirectScreen />,
    path: '/time-tracker',
  },
  {
    isExact: true,
    component: () => <TimeTrackerScreen />,
    path: '/time-tracker/:tabLensTabName',
  },
];
