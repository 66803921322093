/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useAuthStateChanged } from '@/hooks';

interface Props {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  useAuthStateChanged(false);
  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProvider;
