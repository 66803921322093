import React from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter as Router } from 'react-router-dom';

import { AppProvider, AuthProvider } from '@/components';
import { routeList } from '@/components/app-router';
import AppRoutesRenderer from '@/components/app-router/route-render';
import { ModalContainer } from '@/components/modals';

import CrispChatProvider from './components/crisp/crisp';
import DrawerContainer from './components/drawers/DrawerContainer';
import { BRAND_LOGO_CONSTRAST } from './constants';

type Props = any;

const App = (props: Props) => {
  return (
    <React.Fragment>
      <CrispChatProvider>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title lang="en">TabHub</title>
            <html lang="en" />
            <link rel="canonical" href="https://tabhub.io/" />
            <meta name="description" content="All-in-one tab & link management platform" />
            <meta name="keywords" content="productivity, link, software" />
            <link rel="apple-touch-icon" href={`/${BRAND_LOGO_CONSTRAST}`} />
            <link rel="apple-touch-icon" sizes="72x72" href={`/${BRAND_LOGO_CONSTRAST}`} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://app.tabhub.io/" />
            <meta property="og:title" content="TabHub App" />
            <meta property="og:description" content="All-in-one tab & link management platform" />
          </Helmet>
          <AppProvider>
            <Router>
              <AuthProvider>
                <AppRoutesRenderer routes={routeList} />
              </AuthProvider>
              <DrawerContainer />
              <ModalContainer />
            </Router>
          </AppProvider>
        </div>
      </CrispChatProvider>
    </React.Fragment>
  );
};

export default App;
