import React, { useState } from 'react';

import { Calendar } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { useModalStore } from '@/stores';
import { ModalType } from '@/utils';

import ReusableModalWrapper from '../ReusableModalWrapper';

type Props = {
  defaultValue: dayjs.Dayjs;
  title: string;
  modalName: ModalType;
  onSubmit: (value: Dayjs) => void;
  okText: string;
  disabledDate: (date: dayjs.Dayjs) => boolean;
};

const CalendarPickerModal = ({
  defaultValue,
  disabledDate,
  title,
  modalName,
  okText,
  onSubmit,
}: Props) => {
  const [value, setValue] = useState<dayjs.Dayjs>(defaultValue || dayjs());
  const { closeModal } = useModalStore();
  const handleSubmit = () => {
    onSubmit(value);
    closeModal();
  };

  const onChange = (value: Dayjs) => {
    setValue(value);
  };

  return (
    <ReusableModalWrapper
      width={500}
      title={<div>{title}</div>}
      modalName={modalName}
      okText={okText}
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={handleSubmit}>
      <Calendar disabledDate={disabledDate} value={value} fullscreen={false} onChange={onChange} />
    </ReusableModalWrapper>
  );
};

export default CalendarPickerModal;
