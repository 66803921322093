import * as yup from 'yup';

import { AccessVisibility } from '@/gql/graphql';

export interface SaveTabsValues {
  workspaceId: string;
  repositoryIcon: string;
  repositoryName: string;
  visibility: AccessVisibility;
  repositoryDescription: string | undefined;
}

export const newRepositoryValidationSchema = yup.object({
  repositoryName: yup
    .string()
    .min(4, 'Repository name must be at least 4 characters')
    .max(100, 'Repository name must be under 100 characters')
    .required('Repository name is required'),
  repositoryDescription: yup
    .string()
    .max(255, 'Repository description must be under 255 characters'),
} as Record<keyof SaveTabsValues, any>);
