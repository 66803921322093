import React from 'react';
import { useHistory } from 'react-router';

import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select } from 'antd';
import { useFormik } from 'formik';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { NewWorkspaceValues, newWorkspaceValidationSchema } from '@/forms';
import { AccessVisibility } from '@/gql/graphql';
import { deleteWorkspaceMutation, updateWorkspaceMutation } from '@/graphql';
import { useAppNavigation, usePersonal, useWorkspaceContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { buildSlug } from '@/utils';

import LoadableContainer from '../loadable-container';

type Props = any;

const WorkspaceGeneralSetting = (props: Props) => {
  const history = useHistory();
  const { isWorkspaceOwner } = usePersonal();
  const { navigate } = useAppNavigation();
  const { workspace, onRefresh } = useWorkspaceContext();
  const formik = useFormik<NewWorkspaceValues>({
    validationSchema: newWorkspaceValidationSchema,
    initialValues: {
      workspaceName: workspace?.name || '',
      workspaceDescription: workspace?.description || '',
      visibility: workspace?.visibility || AccessVisibility.Public,
    },
    onSubmit: async values => {
      if (!workspace) return;
      const newWorkspaceName = values.workspaceName;
      await apolloClient.mutate({
        mutation: updateWorkspaceMutation,
        variables: {
          ...workspace,
          id: workspace.id,
          name: newWorkspaceName,
          description: values.workspaceDescription,
          visibility: values.visibility,
        },
      });
      history.push(`/workspace/${buildSlug(newWorkspaceName, true)}`);
    },
  });

  const handleDeleteWorkspace = async (id: string | undefined) => {
    Modal.confirm({
      title: 'Delete Workspace',
      content:
        'This action will remove the workspace permanently and all repositories in the workspace will be deleted',
      onOk: async () => {
        if (!workspace || !id) return;
        await apolloClient.mutate({
          mutation: deleteWorkspaceMutation,
          variables: {
            workspaceId: id,
          },
        });
        navigate('workspace');
        onRefresh();
      },
      closable: true,
    });
  };

  return (
    <LoadableContainer
      isLoading={!workspace}
      loadComponent={
        <div style={{ ...MIDDLE_STYLE, height: 200 }}>Local workspace is not editable</div>
      }>
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>General Settings</h3>
          {!isWorkspaceOwner(workspace) && (
            <p>⚠️ You don't have permission to update the workspace</p>
          )}
          <Button
            type="primary"
            className="btn-success"
            disabled={
              !isWorkspaceOwner(workspace) ||
              !(
                formik.values.visibility !== workspace?.visibility ||
                formik.values.workspaceName !== workspace?.name ||
                formik.values.workspaceDescription !== workspace?.description
              )
            }
            onClick={() => formik.handleSubmit()}>
            Save changes
          </Button>
        </div>
        <h4>Workspace name</h4>
        <Input
          disabled={!isWorkspaceOwner(workspace)}
          status={formik.errors.workspaceName ? 'error' : ''}
          value={formik.values.workspaceName}
          onChange={e => formik.setFieldValue('workspaceName', e.target.value)}
          placeholder="Enter workspace name"
        />
        {formik.errors.workspaceName && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
            {formik.errors.workspaceName}
          </p>
        )}
        <h4>Workspace description (Optional)</h4>
        <Input.TextArea
          disabled={!isWorkspaceOwner(workspace)}
          status={formik.errors.workspaceDescription ? 'error' : ''}
          value={formik.values.workspaceDescription}
          onChange={e => formik.setFieldValue('workspaceDescription', e.target.value)}
          autoSize={{ minRows: 6, maxRows: 5 }}
          maxLength={255}
          placeholder="Enter workspace description"
        />
        {formik.errors.workspaceDescription && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
            {formik.errors.workspaceDescription}
          </p>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Workspace visibility</h4>
          <div style={MIDDLE_STYLE}>
            <Select
              disabled={!isWorkspaceOwner(workspace)}
              style={{ width: 120 }}
              value={formik.values.visibility}
              onChange={value => formik.setFieldValue('visibility', value)}
              options={[
                {
                  value: AccessVisibility.Public,
                  label: (
                    <span>
                      <EyeOutlined /> Public
                    </span>
                  ),
                },
                {
                  value: AccessVisibility.Private,
                  label: (
                    <span>
                      <EyeInvisibleOutlined /> Private
                    </span>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Delete Workspace</h4>
          <Button
            disabled={!isWorkspaceOwner(workspace)}
            onClick={() => handleDeleteWorkspace(workspace?.id)}
            type="primary"
            className="btn-error">
            <span>
              <DeleteOutlined /> Delete
            </span>
          </Button>
        </div>
      </React.Fragment>
    </LoadableContainer>
  );
};

export default WorkspaceGeneralSetting;
