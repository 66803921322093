import React from 'react';

import { Select } from 'antd';

import { ALL_SESSION, useTabManagerStore, useWindowStore } from '@/stores';

type Props = any;

const TabManagerSessionSelect = (props: Props) => {
  const { windows } = useWindowStore();
  const { switchSession, selectedSession } = useTabManagerStore();
  return (
    <div>
      <Select
        value={selectedSession}
        onChange={value => switchSession(value)}
        options={[
          {
            value: ALL_SESSION,
            label: <span>All windows</span>,
          },
          ...(windows || []).map((window, index) => ({
            value: window.id?.toString(),
            label: <span>Window #{index}</span>,
          })),
        ]}
      />
    </div>
  );
};

export default TabManagerSessionSelect;
