/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';

import { User, UserRole, Workspace } from '@/gql/graphql';
import { findUserRoleInWorkspace } from '@/utils';

export const useWorkspacePersonal = (workspace: Workspace | undefined | null) => {
  const getRole = useCallback(
    (member: Partial<User>) => findUserRoleInWorkspace(workspace as any, member.id as any),
    [workspace]
  );
  const isRole = useCallback(
    (member: Partial<User>, role: UserRole) => getRole(member) === role,
    [workspace, getRole]
  );

  const includeRoles = useCallback(
    (member: Partial<User>, roles: UserRole[]) => roles.some(role => getRole(member) === role),
    [workspace, getRole]
  );

  return { getRole, isRole, includeRoles };
};
