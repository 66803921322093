import React from 'react';
import { useHistory } from 'react-router';

import { Button } from 'antd';

import { useDrawerStore } from '@/stores';

type Props = any;

const SignInButton = (props: Props) => {
  const history = useHistory();
  const { closeDrawer } = useDrawerStore();

  const handleSignIn = () => {
    history.push('/auth');
    closeDrawer();
  };

  return (
    <Button type="primary" onClick={handleSignIn}>
      Sign in
    </Button>
  );
};

export default SignInButton;
