import { useCallback } from 'react';

import { LOCAL_WORKSPACE_ID } from '@/constants';
import { Repository, User, UserRole, Workspace } from '@/gql/graphql';
import { useAuthStore } from '@/stores';
import { includeUserRoleInWorkspace } from '@/utils';

export const usePersonal = () => {
  const { user } = useAuthStore();

  const isLocalRepository = (repository: Repository | undefined | null) => {
    return repository?.workspaceId === LOCAL_WORKSPACE_ID;
  };

  const isMyFavoriteRepository = (repository: Repository | undefined) => {
    return repository?.favorites.some(favorite => favorite === user?.id);
  };

  const isAuthPinned = useCallback(
    (repository: Repository | undefined) => {
      return repository?.pinned.some(pin => pin === user?.id);
    },
    [user]
  );

  const isUserFavorite = (repository: Repository | undefined, user: User) => {
    return repository?.favorites.some(favorite => favorite === user.id);
  };

  const isUserPinned = (repository: Repository | undefined, user: User) => {
    return repository?.pinned.some(pin => pin === user.id);
  };

  const hasAssetAuthority = useCallback(
    (workspace: Workspace | undefined, repository: Repository | undefined) => {
      /** Is workspace owner or is repository owner */
      return workspace?.owner === user?.id || repository?.owner === user?.id;
    },
    [user]
  );

  const isWorkspaceOwner = useCallback(
    (workspace: Workspace | undefined) => {
      return workspace?.id === LOCAL_WORKSPACE_ID || workspace?.owner === user?.id;
    },
    [user]
  );

  const isWorkspaceAdmin = useCallback(
    (workspace: Workspace | undefined | null) => {
      return (
        (workspace &&
          (workspace?.id === LOCAL_WORKSPACE_ID ||
            (user &&
              includeUserRoleInWorkspace(workspace, user.id as any, [
                UserRole.WorkspaceModerator,
                UserRole.WorkspaceOwner,
              ])))) ||
        false
      );
    },
    [user]
  );

  return {
    isMyFavoriteRepository,
    hasAssetAuthority,
    isWorkspaceOwner,
    isUserPinned,
    isAuthPinned,
    isUserFavorite,
    isLocalRepository,
    isWorkspaceAdmin,
  };
};
