/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { PushpinOutlined, SaveOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import AnimatedComponent from '@/components/animated-component';
import { ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { useBrowserDevService, useRepositoryContext, useWindowTabActions } from '@/hooks';
import { TreeNode } from '@/models';
import { useRepositoryTabStore, useSnackbarStore } from '@/stores';
import { breakdownTreeNodes, buildTree, sortTreeNodes } from '@/utils';

import { BuildAppEditorButton } from '../../layout-builder';
import WebSearchAppExtensionSearchBar from './searchbar';

type Props = any;

const WebSearchAppExtensionEditor = (props: Props) => {
  const [saving, setSaving] = useState(false);
  const {
    treeNodes: currentTreeNodes,
    setTreeNodes: setCurrentTreeNodes,
    setSelectedTreeNodes,
    selectedTreeNodes,
    repositoryTabs,
  } = useRepositoryContext();
  const browserDevService = useBrowserDevService();
  /** Tab state */
  const { enqueueNotification } = useSnackbarStore();
  const { handleSavePreflightTabs } = useWindowTabActions();
  const { storeLocalPinnedTab, removeLocalPinnedTab, getLocalPinnedTabs, findDuplicateTabs } =
    useRepositoryTabStore();

  const collectSelectedTabs = (treeNodes: TreeNode[]): RepositoryTab[] => {
    const { tabs } = breakdownTreeNodes(treeNodes);
    const selectedTabs = tabs.filter(tab => selectedTreeNodes[tab.id as any]);
    return selectedTabs as RepositoryTab[];
  };

  const handleCreateNewWindow = async () => {
    const tabs = collectSelectedTabs(currentTreeNodes);
    await browserDevService.openTabs(tabs as any);
  };

  const handleOpenTabs = async () => {
    const tabs = collectSelectedTabs(currentTreeNodes);
    for (const tab of tabs) {
      window.open(tab.url);
    }
  };

  const handleSortTreeNodes = () => {
    const sortedTreeNodes = sortTreeNodes(currentTreeNodes);
    setCurrentTreeNodes(sortedTreeNodes);
  };

  const handlePinTabs = async () => {
    const { tabs } = breakdownTreeNodes(currentTreeNodes);
    for (const repositoryTab of tabs) {
      if (!repositoryTab.id) continue;
      if (selectedTreeNodes[repositoryTab.id]) {
        await storeLocalPinnedTab(repositoryTab as any);
      }
    }
    await getLocalPinnedTabs();
  };

  const handleUnpinTabs = async () => {
    const { tabs } = breakdownTreeNodes(currentTreeNodes);
    for (const repositoryTab of tabs) {
      if (!repositoryTab.id) continue;
      if (selectedTreeNodes[repositoryTab.id]) {
        await removeLocalPinnedTab(repositoryTab as any);
      }
    }
    await getLocalPinnedTabs();
  };

  const handleDeselectAll = () => {
    setSelectedTreeNodes({});
  };

  const handleSelectAll = () => {
    const _selectedTabs: Record<string, boolean> = {};
    const { tabs: currentTabs } = breakdownTreeNodes(currentTreeNodes);
    for (const repositoryTab of currentTabs) {
      if (!repositoryTab.id) continue;
      _selectedTabs[repositoryTab.id] = true;
    }
    setSelectedTreeNodes(_selectedTabs);
  };

  const handleRemoveDuplicate = () => {
    const { tabs: currentTabs, directories } = breakdownTreeNodes(currentTreeNodes);
    const [, remainingTabs] = findDuplicateTabs((currentTabs as any) || []);
    setCurrentTreeNodes(buildTree(remainingTabs, directories));
  };

  const handleSaveTabs = async () => {
    setSaving(true);
    try {
      await handleSavePreflightTabs(undefined, currentTreeNodes, selectedTreeNodes);
    } catch (error) {
      await enqueueNotification({
        name: 'Error saving tabs',
        description: 'Error saving tabs from web',
        type: 'Error',
      });
    }
    setSaving(false);
  };

  const selectedNumber = useMemo(
    () =>
      Object.values(selectedTreeNodes).reduce((acc, value) => acc + (value === true ? 1 : 0), 0),
    [selectedTreeNodes]
  );

  useEffect(() => {
    const originalTreeNodes = buildTree(repositoryTabs, []);
    setCurrentTreeNodes(originalTreeNodes);
  }, [repositoryTabs]);

  return (
    <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', width: '100%' }}>
      <AnimatedComponent.OpacityFadeInDiv delay={50}>
        <WebSearchAppExtensionSearchBar />
        {repositoryTabs.length > 0 && (
          <Space>
            <BuildAppEditorButton
              action={{
                actionHandler: handleSelectAll,
                title: 'Select All',
                icon: ICON_REGISTRY.SELECT_ALL,
              }}
            />
            <BuildAppEditorButton
              action={{
                actionHandler: handleDeselectAll,
                title: 'Deselect All',
                icon: ICON_REGISTRY.DESELECT_ALL,
              }}
            />
            <BuildAppEditorButton
              action={{
                hidden: !(selectedNumber > 0),
                actionHandler: handleOpenTabs,
                tooltipTitle: 'Open in this window',
                icon: ICON_REGISTRY.NEW_TABS,
              }}
            />
            <BuildAppEditorButton
              action={{
                hidden: !(selectedNumber > 0),
                actionHandler: handleCreateNewWindow,
                tooltipTitle: 'Create new window',
                icon: ICON_REGISTRY.OPEN_WINDOW,
              }}
            />
            <BuildAppEditorButton
              action={{
                actionHandler: handleRemoveDuplicate,
                tooltipTitle: 'Remove duplicate',
                icon: ICON_REGISTRY.REMOVE_DUPLICATE,
              }}
            />
            <BuildAppEditorButton
              action={{
                actionHandler: handleSortTreeNodes,
                title: 'Sort',
                icon: ICON_REGISTRY.SORT,
              }}
            />
            <BuildAppEditorButton
              action={{
                hidden: !(selectedNumber > 0),
                actionHandler: handleUnpinTabs,
                title: 'Unpin',
                icon: <PushpinOutlined />,
              }}
            />
            <BuildAppEditorButton
              action={{
                hidden: !(selectedNumber > 0),
                actionHandler: handlePinTabs,
                title: 'Pin',
                icon: <PushpinOutlined />,
              }}
              props={{
                type: 'primary',
              }}
            />
            <BuildAppEditorButton
              action={{
                hidden: !(selectedNumber > 0),
                actionHandler: handleSaveTabs,
                title: 'Save tabs',
                icon: <SaveOutlined />,
              }}
              props={{
                loading: saving,
                type: 'primary',
                className: 'btn-success',
              }}
            />
          </Space>
        )}
      </AnimatedComponent.OpacityFadeInDiv>
    </div>
  );
};

export default WebSearchAppExtensionEditor;
