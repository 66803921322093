import React from 'react';

import Meta from 'antd/es/card/Meta';

import { Card, Skeleton, Tag } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useOpengraphMetadata } from '@/hooks';
import { renderTime, shortenString } from '@/utils';

import AnimatedComponent from '../animated-component';
import LoadableContainer from '../loadable-container';

type Props = {
  url: string;
  timestamp: number;
};

const TimelessTabsPieTooltip = ({ timestamp, url }: Props) => {
  const { ogLoading, ogMetadata } = useOpengraphMetadata(url);
  return (
    <AnimatedComponent.OpacityFadeInDiv delay={200}>
      <Card
        hoverable
        style={{
          width: 400,
          top: 100,
          right: 200,
          textAlign: 'left',
          overflow: 'hidden',
        }}>
        <LoadableContainer isLoading={ogLoading} loadComponent={<Skeleton />}>
          <React.Fragment>
            <Meta
              title={
                <span
                  style={{
                    color: GLOBAL_THEME_COLOR.$text_color,
                    fontSize: 'smaller',
                  }}>
                  {ogMetadata?.ogTitle}
                </span>
              }
              description={
                <span style={{ fontSize: 'smaller' }}>
                  {shortenString(ogMetadata?.ogDescription || 'No description', 100)}
                </span>
              }
            />
            <div style={{ ...MIDDLE_STYLE, marginTop: 25, justifyContent: 'space-between' }}>
              <div>{shortenString(url, 30)}</div>
              <Tag color="blue">{renderTime(timestamp)}</Tag>
            </div>
          </React.Fragment>
        </LoadableContainer>
      </Card>
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default TimelessTabsPieTooltip;
