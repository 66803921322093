import React from 'react';

import { Layout, Radio, RadioChangeEvent, Space, Tag, Tooltip } from 'antd';

import { GLOBAL_THEME_COLOR, ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { ViewMode, useTabManagerStore, useWindowStore } from '@/stores';

import IconContainer from '../icon-container';
import TabManagerEditor from '../tab-manager-editor';
import TabManagerSessionSelect from '../tab-manager-session-select';

type Props = any;

const TabManagerAppBar = (props: Props) => {
  const { windows } = useWindowStore();
  const { setViewMode, viewMode } = useTabManagerStore();

  const onViewModeChange = (e: RadioChangeEvent) => {
    setViewMode(e.target.value);
  };

  return (
    <Layout.Header
      style={{
        padding: '0px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'fit-content',
        flexWrap: 'wrap',
        borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
      }}>
      <Space style={{ ...MIDDLE_STYLE }}>
        {viewMode === ViewMode.TableMode && <TabManagerSessionSelect />}
        <TabManagerEditor />
      </Space>
      <Space style={{ ...MIDDLE_STYLE }}>
        <Tag color="blue">{`${windows.length} active windows`}</Tag>
        <Radio.Group value={viewMode} defaultValue={viewMode} onChange={onViewModeChange}>
          <Tooltip title="Column Mode">
            <Radio.Button value={ViewMode.ColumnMode}>
              <IconContainer icon={ICON_REGISTRY.VIEW_COLUMN_MDOE} />
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Table Mode">
            <Radio.Button value={ViewMode.TableMode}>
              <IconContainer icon={ICON_REGISTRY.VIEW_TABLE_MODE} />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Space>
    </Layout.Header>
  );
};

export default TabManagerAppBar;
