import React, { useState } from 'react';

import { Button, Row } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { User, UserRole, Workspace } from '@/gql/graphql';
import { removeWorkspaceMemberMutation } from '@/graphql';
import { useBreakpoint, useWorkspaceContext } from '@/hooks';
import { useWorkspacePersonal } from '@/hooks/useWorkspacePersonal';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore } from '@/stores';
import { findUserRoleInWorkspace } from '@/utils';

import CenteredCardColumn from '../centered-card-column';
import LoadableContainer from '../loadable-container';
import UserRoleTag from '../user-role-tag';

type Props = {
  workspace: Workspace | undefined;
  members: Partial<User>[];
};

const WorkspaceMemberList = ({ workspace, members }: Props) => {
  const { isMobile } = useBreakpoint();
  const { user } = useAuthStore();
  const { isRole } = useWorkspacePersonal(workspace);
  const { onRefresh } = useWorkspaceContext();
  const [loading, setLoading] = useState<number | undefined>(undefined);

  const handleRemoveWorkspaceMember = async (index: number, memberEmail: string) => {
    if (!workspace) return;
    setLoading(index);
    try {
      await apolloClient.mutate({
        mutation: removeWorkspaceMemberMutation,
        variables: {
          memberEmail,
          workspaceId: workspace.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(index);
    onRefresh();
  };

  return (
    <LoadableContainer isLoading={members.length === 0} loadComponent={<div>No member found</div>}>
      <React.Fragment>
        {members.map((member, index) => (
          <div
            style={{
              padding: '15px 0px ',
              borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            }}>
            <Row>
              <CenteredCardColumn span={2}>
                <img
                  referrerPolicy="no-referrer"
                  style={{ marginLeft: 20, width: 40, borderRadius: '50%' }}
                  src={member.profile_image || ''}
                  alt={member.profile_image || ''}
                />
              </CenteredCardColumn>
              <CenteredCardColumn span={14}>
                <React.Fragment>
                  <div style={{ display: 'flex' }}>
                    <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                      {member.full_name}
                    </h3>
                  </div>
                  {!isMobile && member.email}
                </React.Fragment>
              </CenteredCardColumn>
              <CenteredCardColumn span={2}>
                <UserRoleTag
                  userRole={findUserRoleInWorkspace(workspace as any, member.id as any)}
                />
              </CenteredCardColumn>
              <CenteredCardColumn span={4}>
                <React.Fragment>
                  {(isRole(user as any, UserRole.WorkspaceOwner) ||
                    (isRole(user as any, UserRole.WorkspaceModerator) &&
                      isRole(member, UserRole.WorkspaceMember))) &&
                    member.id !== user?.id && (
                      <Button
                        loading={loading === index}
                        onClick={() => handleRemoveWorkspaceMember(index, member.email as string)}
                        style={{ width: 110, marginLeft: 50 }}>
                        🗑 Remove
                      </Button>
                    )}
                </React.Fragment>
              </CenteredCardColumn>
            </Row>
          </div>
        ))}
      </React.Fragment>
    </LoadableContainer>
  );
};

export default WorkspaceMemberList;
