import React from 'react';

import { Tag } from 'antd';

import { UserRole } from '@/gql/graphql';
import { convertRoleToColor, convertRoleToStr } from '@/utils';

type Props = {
  userRole: UserRole | undefined;
};

const UserRoleTag = ({ userRole }: Props) => {
  return userRole ? (
    <Tag style={{ textAlign: 'center' }} color={convertRoleToColor(userRole)}>
      {convertRoleToStr(userRole)}
    </Tag>
  ) : (
    <Tag color="default">Unknown</Tag>
  );
};

export default UserRoleTag;
