import React from 'react';

import { Button } from 'antd';

import { ICON_REGISTRY } from '@/constants';
import { Repository, Workspace } from '@/gql/graphql';
import { useModalStore } from '@/stores';

import IconContainer from '../icon-container';
import RepositoryShareModal from '../repository-share-modal';

type Props = { repository: Repository; workspace: Workspace };

const RepositoryShareButton = ({ repository, workspace }: Props) => {
  const { openModal } = useModalStore();
  const handleOpenShare = () => {
    openModal('shareRepository');
  };
  return (
    <React.Fragment>
      <Button
        type="primary"
        onClick={handleOpenShare}
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        Share <IconContainer icon={ICON_REGISTRY.SHARE} style={{ marginLeft: 10 }} />
      </Button>
      <RepositoryShareModal repositoryId={repository.id} workspace={workspace} />
    </React.Fragment>
  );
};

export default RepositoryShareButton;
