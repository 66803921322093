/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Button, Popover } from 'antd';

import { Repository } from '@/gql/graphql';
import { toggleLikeRepositoryMutation } from '@/graphql/repositories';
import { usePersonal } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';

import AuthFavoriteIcon from '../auth-favorite-icon';
import MemberAvatarsGroup from '../member-avatars-group';

type Props = {
  repository: Repository;
};

const RepositoryFavoriteButton = ({ repository }: Props) => {
  const { isMyFavoriteRepository } = usePersonal();
  const [loading, setLoading] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<number>(repository.favorites.length);
  const [isFavorite, setIsFavorite] = useState<boolean>(!!isMyFavoriteRepository(repository));

  const handleButtonClicked = async () => {
    setLoading(true);
    try {
      await apolloClient.mutate({
        mutation: toggleLikeRepositoryMutation,
        variables: {
          repositoryId: repository.id,
        },
      });

      if (isFavorite) {
        setFavorites(favorites => (favorites = favorites - 1));
        setIsFavorite(isFavorite => (isFavorite = false));
      } else {
        setFavorites(favorites => (favorites = favorites + 1));
        setIsFavorite(isFavorite => (isFavorite = true));
      }
    } catch (error: any) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Popover
      content={<MemberAvatarsGroup members={repository.favorites as any} />}
      trigger={'hover'}
      placement={'left'}
      title="Favorites">
      <Button loading={loading} onClick={handleButtonClicked}>
        <AuthFavoriteIcon repository={repository} isFavorite={isFavorite} /> {favorites}
      </Button>
    </Popover>
  );
};

export default RepositoryFavoriteButton;
