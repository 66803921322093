import React from 'react';

import { Popover } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';

import IconContainer from '../../icon-container';

type Props = {
  appName: string;
  appIcon: React.ReactNode;
  description: string;
  editor: React.ReactNode;
  extraAction?: React.ReactNode;
};

const AppExtensionAppBar = ({ extraAction, appName, appIcon, description, editor }: Props) => {
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          flexWrap: 'wrap',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'left',
            flexWrap: 'wrap',
          }}>
          <div style={{ ...MIDDLE_STYLE, flexWrap: 'wrap' }}>
            <IconContainer icon={appIcon} style={{ fontSize: 20 }} />
            <h3 style={{ margin: 0, padding: 0, marginLeft: 20 }}>{appName}</h3>
            {description && (
              <Popover
                trigger={'hover'}
                title={'Description'}
                content={description || 'No description'}>
                <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginLeft: 20 }}>
                  {description || 'No description'}
                </span>
              </Popover>
            )}
          </div>
        </div>
      </div>
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {editor}
        {extraAction}
      </div>
    </React.Fragment>
  );
};

export default AppExtensionAppBar;
