/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Layout } from 'antd';

import { useBreakpoint } from '@/hooks';

import NavigationMenu from '../navigation-menu';

type Props = Record<string, any>;

const LeftSider = (props: Props) => {
  const { isDesktop } = useBreakpoint();

  return (
    <Layout.Sider
      width={250}
      style={{ textAlign: 'left' }}
      collapsed={isDesktop}
      breakpoint="md"
      theme="dark">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '90%',
        }}>
        <div>
          <NavigationMenu />
        </div>
      </div>
    </Layout.Sider>
  );
};

export default LeftSider;
