/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { CgChevronDown, CgChevronUp } from 'react-icons/cg';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Divider, Input, Popover, Select, Skeleton } from 'antd';
import { useFormik } from 'formik';
import _ from 'lodash';
import Browser from 'webextension-polyfill';

import DraggableTreeNodeList from '@/components/draggable-tree-node-list';
import EmojiPickerButton from '@/components/emoji-picker-button';
import LoadableContainer from '@/components/loadable-container';
import WorkspaceAvatar from '@/components/workspace-avatar';
import { LOCAL_WORKSPACE_ID, MIDDLE_STYLE } from '@/constants';
import { APP_WEBSITE_DEV, APP_WEBSITE_PROD } from '@/constants/env';
import { GLOBAL_THEME_COLOR } from '@/constants/theme';
import { SaveTabsValues, newRepositoryValidationSchema } from '@/forms/new-repository-validation';
import { AccessVisibility, Repository, Workspace } from '@/gql/graphql';
import { useBreakpoint, useBrowserDevService, usePersonal } from '@/hooks';
import { TreeNode } from '@/models';
import {
  useAuthStore,
  useModalStore,
  useRepositoryStore,
  useSnackbarStore,
  useWorkspaceStore,
} from '@/stores';
import {
  breakdownTreeNodes,
  buildSlug,
  convertWindowTabToRepositoryTab,
  tabTreeWalk,
  voidCallback,
} from '@/utils';

import ReusableModalWrapper from '../ReusableModalWrapper';

type Props = Record<string, any>;

const SaveTabsModal = (props: Props) => {
  const browserDevService = useBrowserDevService();
  const { user } = useAuthStore();
  const { isTablet } = useBreakpoint();
  const [collapse, setCollapse] = useState(true);
  const { isWorkspaceAdmin } = usePersonal();
  const [loadingWorkspaceRepositories, setLoadingWorkspaceRepositories] = useState(false);
  const { closeModal, extraParams, modalName } = useModalStore();
  const { fetchRepositories, setRepositoryTabs } = useRepositoryStore();
  const { selectedWorkspaceId, upsertTabsToRepository, setRefreshing, fetchWorkspaces } =
    useWorkspaceStore();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [workspaceRepositories, setWorkspaceRepositories] = useState<Repository[]>([]);
  const [selectedRepository, setSelectedRepository] = useState<Repository | undefined | null>(
    undefined
  );
  const { enqueueNotification } = useSnackbarStore();
  const window = useMemo<Browser.Windows.Window>(() => extraParams?.window, [extraParams]);
  const [selectedTreeNodes, setSelectedTreeNodes] = useState<TreeNode[]>([]);
  const treeNodes = useMemo<TreeNode[]>(() => extraParams?.treeNodes, [extraParams]);
  /** 19/06/2023: Migrate from repository tabs to treeNodes */
  const treeNodesChecked = useMemo<Record<string, boolean>>(
    () => extraParams?.selectedTreeNodes,
    [extraParams]
  );
  const currentTreeNodes = useMemo<TreeNode[]>(() => treeNodes || [], [window, treeNodes]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues: SaveTabsValues = {
    repositoryName: '',
    repositoryDescription: '',
    repositoryIcon: '1f604',
    visibility: AccessVisibility.Private,
    workspaceId: selectedWorkspaceId || LOCAL_WORKSPACE_ID,
  };
  const formik = useFormik<SaveTabsValues>({
    validationSchema: newRepositoryValidationSchema,
    initialValues: defaultValues,
    onSubmit: voidCallback,
  });

  useEffect(() => {
    const init = async () => {
      const repo = workspaceRepositories.find(repo => {
        return buildSlug(repo.name, true) === buildSlug(formik.values.repositoryName, true);
      });
      setSelectedRepository(repo);
    };
    init();
  }, [formik.values.repositoryName]);

  const handleWorkspaceChanged = (workspaceId: string) => {
    formik.setFieldValue('workspaceId', workspaceId);
  };

  const handleSave = async () => {
    try {
      const values = formik.values;
      setIsLoading(true);
      const { directories, tabs: windowTabs } = breakdownTreeNodes(selectedTreeNodes);
      const tabs = convertWindowTabToRepositoryTab(windowTabs as any);

      if (selectedRepository) {
        const [updatedTabs, updatedDirectories] = [
          selectedRepository.tabs.concat(tabs),
          selectedRepository.directories.concat(directories),
        ];
        const sanitizedDirectories = _.uniqBy(updatedDirectories, 'name').map(directory => {
          const { __typename, ...directoryData } = directory;
          return directoryData;
        });
        await setRepositoryTabs(selectedRepository, updatedTabs, sanitizedDirectories);
      } else {
        await upsertTabsToRepository(
          values.workspaceId,
          values.repositoryIcon,
          values.repositoryName,
          tabs,
          values.visibility,
          directories,
          values.repositoryDescription
        );
      }
      setRefreshing();
      closeModal();
      formik.setValues(defaultValues);
      await enqueueNotification({
        name: 'Tabs saved successfully',
        description: 'Finished saving tabs',
        type: 'Success',
      });
    } catch (error) {
      await enqueueNotification({
        name: 'Tabs saved failed',
        description: `Failed to save tabs: ${error}`,
        type: 'Error',
      });
    }
    setIsLoading(false);
  };

  const handleSaveAndClose = async () => {
    await handleSave();
    const deletedTabs = (window.tabs || []).filter(
      tab => !(tab.url?.includes(APP_WEBSITE_DEV) || tab.url?.includes(APP_WEBSITE_PROD))
    );
    await Promise.all(deletedTabs.map(async tab => await browserDevService.deleteTab(tab.id)));
  };

  const handleRepositoryNameChanged = (name: string) => {
    formik.setFieldValue('repositoryName', name);
  };

  const handleRepositoryDescriptionChanged = (descripiton: string) => {
    formik.setFieldValue('repositoryDescription', descripiton);
  };

  const handleCloseModal = () => {
    formik.resetForm();
    closeModal();
  };

  const handleSelectEmoji = (emoji: string) => {
    formik.setFieldValue('repositoryIcon', emoji);
  };

  const tabsCount = useMemo(() => {
    let count = 0;
    tabTreeWalk({
      treeNodes: selectedTreeNodes,
      handleTabItem(_: any) {
        count = count + 1;
      },
    });
    return count;
  }, [selectedTreeNodes]);

  const existingRepository = useMemo<boolean>(
    () =>
      workspaceRepositories.some(
        repository =>
          repository.workspaceId === formik.values.workspaceId &&
          repository.name === formik.values.repositoryName
      ),
    [formik.values.repositoryName, formik.values.workspaceId]
  );

  useEffect(() => {
    let _selectedTreeNodes: TreeNode[] = [];
    const selectedKeys = Object.keys(treeNodesChecked || {});
    if (selectedKeys.length > 0) {
      for (const key of selectedKeys) {
        if (treeNodesChecked[key]) {
          const treeNode = currentTreeNodes.find(_node => _node.value.id?.toString() === key);
          if (treeNode) _selectedTreeNodes.push(treeNode);
        }
      }
    } else {
      _selectedTreeNodes = currentTreeNodes;
    }
    setSelectedTreeNodes(_selectedTreeNodes);
    formik.setFieldValue('selectedTreeNodes', treeNodes);
  }, [currentTreeNodes, treeNodesChecked]);

  useEffect(() => {
    /** Fetch all repositories of the selected workspace */
    if (!formik.values.workspaceId) return;
    const fetchWorkspaceRepositories = async () => {
      setLoadingWorkspaceRepositories(true);
      const workspaceId = formik.values.workspaceId;
      const _repositories = await fetchRepositories(workspaceId);
      setWorkspaceRepositories(_repositories || []);
      setLoadingWorkspaceRepositories(false);
    };
    fetchWorkspaceRepositories();
  }, [formik.values.workspaceId, modalName]);

  useEffect(() => {
    /** Used for auto complete text field, only allow to select workspace with edit permission */
    const init = async () => {
      const workspaces = await fetchWorkspaces();
      /** Check that user have permission to create */
      setWorkspaces(
        workspaces.filter(
          workspace => workspace.id === LOCAL_WORKSPACE_ID || isWorkspaceAdmin(workspace)
        )
      );
    };
    if (modalName === 'saveTabs') {
      init();
    }
  }, [user, modalName]);

  useEffect(() => {
    formik.setFieldValue('repositoryDescription', selectedRepository?.description);
    formik.setFieldValue('repositoryIcon', selectedRepository?.icon || '1f604');
  }, [selectedRepository]);

  const disabled = useMemo(
    () =>
      Object.keys(formik.errors).some(key => formik.errors[key as keyof SaveTabsValues]) ||
      selectedTreeNodes.length === 0,
    [formik.errors]
  );

  return (
    <ReusableModalWrapper
      width={isTablet ? '100%' : '500px'}
      title={
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', marginTop: 20 }}>
          <h3>Save Repository</h3>
          <Popover
            content={
              <div
                style={{
                  maxHeight: '450px',
                  width: '500px',
                  height: 'fit-content',
                  padding: isTablet ? '15px 5px' : '15px 20px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  borderRadius: 10,
                }}>
                <DraggableTreeNodeList
                  isForceMobile
                  nodeOnContextMenu={voidCallback}
                  treeNodes={selectedTreeNodes}
                  directoryOnClickedEvent={voidCallback}
                  itemOnClickedEvent={voidCallback}
                  itemSelectedCondition={tab => false}
                  onItemDragEvent={voidCallback}
                />
              </div>
            }>
            <Button type="primary" style={{ width: 'fit-content' }} color="blue">
              <EyeOutlined /> {tabsCount} tabs
            </Button>
          </Popover>
        </div>
      }
      modalName="saveTabs"
      footer={
        <React.Fragment>
          <Button disabled={disabled} onClick={handleSaveAndClose} type="primary" size="large">
            Save & Close All
          </Button>
          <Button
            disabled={disabled}
            onClick={handleSave}
            type="primary"
            size="large"
            className="btn-success">
            Save
          </Button>
        </React.Fragment>
      }
      onCancel={handleCloseModal}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          <Divider />
          <h4>Workspace</h4>
          <Select
            size="large"
            defaultValue={formik.values.workspaceId}
            style={{ width: '100%' }}
            onChange={handleWorkspaceChanged}
            options={workspaces.map(workspace => ({
              value: workspace.id,
              label: (
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    ...MIDDLE_STYLE,
                    justifyContent: 'flex-start',
                  }}>
                  <WorkspaceAvatar workspace={workspace} />{' '}
                  <span style={{ marginLeft: 10 }}>{workspace.name}</span>
                </div>
              ),
            }))}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Repository name</h4>
            {selectedRepository && (
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Already exist with {selectedRepository.tabs.length} tabs
              </p>
            )}
          </div>
          <LoadableContainer isLoading={loadingWorkspaceRepositories} loadComponent={<Skeleton />}>
            <React.Fragment>
              <AutoComplete
                popupClassName="select-popup-style"
                style={{ width: '100%' }}
                value={formik.values.repositoryName}
                onChange={(value: string) => handleRepositoryNameChanged(value)}
                options={(workspaceRepositories || []).map(repository => ({
                  label: repository.name,
                  value: repository.name,
                }))}>
                <Input
                  allowClear
                  autoFocus
                  size="large"
                  status={formik.errors.repositoryName ? 'error' : ''}
                  value={formik.values.repositoryName}
                  onChange={e => handleRepositoryNameChanged(e.target.value)}
                  placeholder="Enter repository name"
                />
              </AutoComplete>
              {formik.errors.repositoryName && (
                <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
                  {formik.errors.repositoryName}
                </p>
              )}
              <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start', marginTop: 15 }}>
                <span
                  onClick={() => setCollapse(!collapse)}
                  style={{
                    color: GLOBAL_THEME_COLOR.$highlight_color,
                    cursor: 'pointer',
                    ...MIDDLE_STYLE,
                  }}>
                  Advanced Settings {collapse ? <CgChevronDown /> : <CgChevronUp />}
                </span>
              </div>
              {!collapse && (
                <React.Fragment>
                  <h4>Repository description (Optional)</h4>
                  <Input.TextArea
                    disabled={existingRepository}
                    status={formik.errors.repositoryDescription ? 'error' : ''}
                    autoSize={{ minRows: 6, maxRows: 6 }}
                    maxLength={255}
                    value={formik.values.repositoryDescription || ''}
                    onChange={e => handleRepositoryDescriptionChanged(e.target.value)}
                    placeholder="Enter repository description"
                  />
                  {formik.errors.repositoryDescription && (
                    <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
                      {formik.errors.repositoryDescription}
                    </p>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4>Visibility / Icon</h4>
                    <div style={MIDDLE_STYLE}>
                      <Select
                        disabled={existingRepository}
                        style={{ width: 120 }}
                        value={formik.values.visibility}
                        onChange={value => formik.setFieldValue('visibility', value)}
                        options={[
                          {
                            value: AccessVisibility.Public,
                            label: (
                              <span>
                                <EyeOutlined /> Public
                              </span>
                            ),
                          },
                          {
                            value: AccessVisibility.Private,
                            label: (
                              <span>
                                <EyeInvisibleOutlined /> Private
                              </span>
                            ),
                          },
                        ]}
                      />
                      <EmojiPickerButton
                        disabled={existingRepository}
                        onEmojiPicked={handleSelectEmoji}
                        value={formik.values.repositoryIcon}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </LoadableContainer>
        </div>
      )}
    </ReusableModalWrapper>
  );
};

export default SaveTabsModal;
