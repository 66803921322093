import React from 'react';

import { UpSquareFilled, UpSquareOutlined } from '@ant-design/icons';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { Repository } from '@/gql/graphql';
import { usePersonal } from '@/hooks';

type Props = { repository: Repository; isFavorite?: boolean };

const AuthFavoriteIcon = ({ repository, isFavorite }: Props) => {
  const { isMyFavoriteRepository } = usePersonal();
  return (isFavorite !== undefined ? isFavorite : isMyFavoriteRepository(repository)) ? (
    <UpSquareFilled style={{ marginRight: 3, color: GLOBAL_THEME_COLOR.$success_color }} />
  ) : (
    <UpSquareOutlined style={{ marginRight: 3, color: GLOBAL_THEME_COLOR.$success_color }} />
  );
};

export default AuthFavoriteIcon;
