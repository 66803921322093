import * as is from 'is_js';

import BrowserDevService from '@/messaging/BrowserDevService';
import { PhantomRemoteService } from '@/messaging/PhantomRemoteService';
import { SafariDevRemoteService } from '@/messaging/SafariDevRemoteService';
import { isChromeCompatible } from '@/utils';

export const useBrowserDevService = () => {
  if (isChromeCompatible) {
    return new BrowserDevService(!!window.chrome?.extension || !!window.browser?.extension);
  } else if (is.safari()) {
    return new SafariDevRemoteService();
  } else {
    return new PhantomRemoteService();
  }
};
