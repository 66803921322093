import React from 'react';

import { Avatar } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { UnionTab } from '@/models';

type Props = {
  windowTab: UnionTab;
};

const WindowTabIcon = ({ windowTab }: Props) => {
  return (
    <div
      style={{
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        height: 20,
        width: 20,
        borderRadius: '5px',
        boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
        ...MIDDLE_STYLE,
      }}>
      <Avatar size={15} shape="square" src={windowTab.favIconUrl} alt={windowTab.favIconUrl}>
        {windowTab.title?.slice(0, 1)}
      </Avatar>
    </div>
  );
};

export default WindowTabIcon;
