import React, { useState } from 'react';

import Lottie from 'lottie-react';

import { ExploreSearchLottieFile } from '@/animations';
import LoadableContainer from '@/components/loadable-container';
import SelectedRepositoryProvider from '@/components/selected-repository-provider';
import SplashScreenPlaceholder from '@/components/splash-screen-placeholder';
import { ICON_REGISTRY } from '@/constants';
import { UrlWithImage, WebSearchContext } from '@/hooks';
import { makeid } from '@/utils';

import AppExtensionAppBar from '../../app-extension-appbar';
import { RenderAppExtensionLayoutBuilder } from '../../layout-builder';
import WebSearchAppExtensionContent from './content';
import WebSearchAppExtensionEditor from './editor';

type Props = any;

const WebSearchAppExtension = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [started, setStarted] = useState(false);
  const [searchedUrls, setSearchedUrls] = useState<UrlWithImage[]>([]);

  return (
    <WebSearchContext.Provider
      value={{
        started,
        websiteUrl,
        searchedUrls,
        setStarted,
        setSearchedUrls,
        setWebsiteUrl,
      }}>
      <LoadableContainer
        isLoading={loading}
        loadComponent={
          <SplashScreenPlaceholder>
            <Lottie
              style={{
                height: 300,
                marginTop: '-30px',
              }}
              animationData={ExploreSearchLottieFile}
            />
          </SplashScreenPlaceholder>
        }>
        <SelectedRepositoryProvider
          repositoryTabs={searchedUrls.map(({ image, url }) => ({
            url,
            title: '',
            labels: [],
            id: makeid(10),
            description: '',
            favIconUrl: image,
            pinned: [],
          }))}
          loading={loading}
          setLoading={setLoading}>
          {values => (
            <RenderAppExtensionLayoutBuilder
              values={values}
              appBar={
                <AppExtensionAppBar
                  appIcon={<React.Fragment>{ICON_REGISTRY.WEBSEARCH}</React.Fragment>}
                  appName="Search on Web"
                  description="Collect URLs from a webpage"
                  editor={<WebSearchAppExtensionEditor />}
                />
              }
              content={<WebSearchAppExtensionContent />}
            />
          )}
        </SelectedRepositoryProvider>
      </LoadableContainer>
    </WebSearchContext.Provider>
  );
};

export default WebSearchAppExtension;
