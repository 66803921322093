import React from 'react';

import { Button } from 'antd';

import { MIDDLE_STYLE } from '@/constants';

type Props = {
  handler: () => void;
  title: string;
};

const ContextMenuButton = ({ handler, title }: Props) => {
  return (
    <Button
      size="middle"
      style={{ ...MIDDLE_STYLE, width: '100%', border: 'none' }}
      onClick={handler}>
      <div style={{ width: '100%', textAlign: 'left', fontSize: 'smaller' }}>{title}</div>
    </Button>
  );
};

export default ContextMenuButton;
