/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { AppBar, RepositoryLayout } from '@/components';
import { GLOBAL_THEME_COLOR } from '@/constants';

type Props = any;

const WorkspaceRepositoryPreviewScreen = (props: Props) => {
  return (
    <div style={{ backgroundColor: GLOBAL_THEME_COLOR.$primary_color }}>
      <AppBar />
      <div
        style={{
          overflow: 'auto',
          height: '95vh',
          paddingBottom: 100,
          backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
        }}>
        <RepositoryLayout />
      </div>
    </div>
  );
};

export default WorkspaceRepositoryPreviewScreen;
