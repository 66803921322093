/* eslint-disable jsx-a11y/img-redundant-alt */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TbNote, TbNoteOff } from 'react-icons/tb';
import { useParams } from 'react-router';

import { Button, Tabs, TabsProps, Tooltip } from 'antd';

import {
  ContextMenuProvider,
  HelmetWrapper,
  LoadableContainer,
  RepositoryAppBar,
  RepositoryCardView,
  RepositoryCollapsibleReadmeView,
  RepositoryPageView,
  RepositorySettingTab,
  RepositoryTableView,
  SelectedRepositoryProvider,
  SplashScreen,
} from '@/components';
import { RenderAppExtensionLayoutBuilder } from '@/components/app-extensions';
import { MIDDLE_STYLE } from '@/constants';
import { RepositoryContextType, useBreakpoint } from '@/hooks';
import { ViewMode, useWorkspaceRepositoryLoad } from '@/stores';

import DirectorySearchModal from '../modals/directory-search-modal';

enum RepositoryTab {
  Page = 'page',
  Settings = 'settings',
  Tabs = 'tabs',
}

type Props = any;

const RepositoryLayout = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const { workspaceSlug, repositorySlug } = useParams<{
    workspaceSlug: string;
    repositorySlug: string;
  }>();
  const {
    repository,
    bannerImage,
    workspace,
    isLoadingWorkspace,
    isLoadingRepository,
    setRefreshing,
    readmeContent,
  } = useWorkspaceRepositoryLoad(workspaceSlug, repositorySlug);
  const [tab, setTab] = useState<string>(isTablet ? RepositoryTab.Page : RepositoryTab.Tabs);
  const [pageVisible, setPageVisible] = useState<boolean>(true);

  const handleTabChanged = (newTab: string) => {
    setTab(newTab);
  };

  const handleViewPage = () => {
    setPageVisible(true);
  };

  const handleHidePage = () => {
    setPageVisible(false);
  };

  const RepositoryAppContent = (values: RepositoryContextType) => {
    const items: TabsProps['items'] = isTablet
      ? [
          ...(readmeContent
            ? [
                {
                  key: RepositoryTab.Page,
                  label: `Page`,
                  children: (
                    <RepositoryPageView content={readmeContent} bannerImage={bannerImage} />
                  ),
                },
              ]
            : []),
          {
            key: RepositoryTab.Tabs,
            label: `Resources`,
            children: (
              <React.Fragment>
                {values.viewMode === ViewMode.TableMode && <RepositoryTableView />}
                {values.viewMode === ViewMode.CardMode && (
                  <RepositoryCardView repository={repository} />
                )}
              </React.Fragment>
            ),
          },
          {
            key: RepositoryTab.Settings,
            label: `Settings`,
            children: <RepositorySettingTab repository={repository} />,
          },
        ]
      : [
          {
            key: RepositoryTab.Tabs,
            label: `Resources`,
            children: (
              <RepositoryCollapsibleReadmeView
                bannerImage={bannerImage}
                pageVisible={pageVisible}
                content={readmeContent}>
                <React.Fragment>
                  {values.viewMode === ViewMode.TableMode && (
                    <RepositoryTableView actionable isForceMobile={pageVisible} />
                  )}
                  {values.viewMode === ViewMode.CardMode && (
                    <RepositoryCardView repository={repository} />
                  )}
                </React.Fragment>
              </RepositoryCollapsibleReadmeView>
            ),
          },
          {
            key: RepositoryTab.Settings,
            label: `Settings`,
            children: <RepositorySettingTab repository={repository} />,
          },
        ];
    return (
      <ContextMenuProvider offsetX={0} offsetY={0}>
        <Tabs
          tabBarExtraContent={{
            right:
              !!readmeContent &&
              !isTablet &&
              (pageVisible ? (
                <Tooltip title="Hide page">
                  <Button onClick={handleHidePage} style={{ ...MIDDLE_STYLE }}>
                    <TbNoteOff />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="View page">
                  <Button onClick={handleViewPage} style={{ ...MIDDLE_STYLE }}>
                    <TbNote />
                  </Button>
                </Tooltip>
              )),
          }}
          defaultActiveKey={tab}
          items={items}
          onChange={handleTabChanged}
        />
      </ContextMenuProvider>
    );
  };

  useEffect(() => {
    setPageVisible(!!readmeContent);
  }, [readmeContent]);

  return (
    <HelmetWrapper
      title={`${
        isLoadingWorkspace || isLoadingRepository
          ? 'Loading repository...'
          : `${repository?.name} - ${workspace?.slug}/${repository?.slug}`
      }`}
      noBrandName>
      <LoadableContainer
        isLoading={isLoadingWorkspace || isLoadingRepository}
        loadComponent={<SplashScreen />}>
        <SelectedRepositoryProvider
          workspace={workspace}
          repository={repository}
          defaultViewMode={ViewMode.TableMode}
          loading={false}
          setLoading={() => {
            //
          }}
          setRefreshing={setRefreshing}>
          {values => (
            <React.Fragment>
              <RenderAppExtensionLayoutBuilder
                values={values}
                appBar={<RepositoryAppBar />}
                content={<RepositoryAppContent {...values} />}
              />
              <DirectorySearchModal />
            </React.Fragment>
          )}
        </SelectedRepositoryProvider>
      </LoadableContainer>
    </HelmetWrapper>
  );
};

export default RepositoryLayout;
