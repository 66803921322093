import React, { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';

import { ANT_DESIGN_THEME } from '@/constants';
import { useHandshake } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { useContextMenuStore } from '@/stores';

import { SnackbarWrapper } from '..';

type Props = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: Props) => {
  const { setContextMenu } = useContextMenuStore();

  useHandshake();

  useEffect(() => {
    document.addEventListener(`click`, function onClickOutside() {
      setContextMenu({ visible: false, x: 0, y: 0, content: undefined });
      document.removeEventListener(`click`, onClickOutside);
    });
  });
  return (
    <ApolloProvider client={apolloClient}>
      <SnackbarWrapper>
        <ConfigProvider theme={ANT_DESIGN_THEME}>{children}</ConfigProvider>
      </SnackbarWrapper>
    </ApolloProvider>
  );
};

export default AppProvider;
