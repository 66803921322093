import React from 'react';

import { Modal } from 'antd';

import DirectorySearchContainer from '@/components/directory-search-container';
import { useModalStore } from '@/stores';

import './index.scss';

type Props = any;

const DirectorySearchModal = (props: Props) => {
  const { closeModal, modalName: selectedModal } = useModalStore();

  return (
    <Modal
      width={'100%'}
      className="directory-search-modal"
      footer={[]}
      closeIcon={<></>}
      onCancel={closeModal}
      open={selectedModal === 'directoryList'}>
      <DirectorySearchContainer onClose={closeModal} />
    </Modal>
  );
};

export default DirectorySearchModal;
