import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ModalType } from '@/utils';

export interface ModalStoreState {
  modalName: ModalType | undefined;
  extraParams?: Record<string, any>;
  openModal: (modalName: ModalType, extraParams?: Record<string, any>) => void;
  closeModal: () => void;
}

export const useModalStore = create<ModalStoreState>()(
  devtools(set => ({
    modalName: undefined,
    openModal: (modalName: ModalType, extraParams?: Record<string, any>) =>
      set(_ => ({ modalName: modalName as any, extraParams: extraParams })),
    closeModal: () => set(_ => ({ modalName: undefined, extraParams: {} })),
  }))
);
