/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router';

import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select } from 'antd';
import { useFormik } from 'formik';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { newRepositoryValidationSchema } from '@/forms/new-repository-validation';
import { AccessVisibility, CreateNewRepositoryArgs, Workspace } from '@/gql/graphql';
import { usePersonal, useRepositoryContext } from '@/hooks';
import { useRepositoryStore, useWorkspaceStore } from '@/stores';

import EmojiPickerButton from '../emoji-picker-button';
import LoadableContainer from '../loadable-container';
import RepositoryExportButton from '../repository-export-button';

type Props = any;

const RepositoryGeneralSetting = (props: Props) => {
  const history = useHistory();
  const [workspace, setWorkspace] = useState<Workspace | undefined | null>();
  const [loading, setLoading] = useState(true);
  const { isWorkspaceAdmin } = usePersonal();
  const { fetchWorkspaceById, deleteRepository } = useWorkspaceStore();
  const { updateRepositoryInfo } = useRepositoryStore();
  const { repository, onRefresh } = useRepositoryContext();
  const [updating, setUpdating] = useState<boolean>(false);
  const formik = useFormik<Partial<CreateNewRepositoryArgs>>({
    validationSchema: newRepositoryValidationSchema,
    initialValues: {
      name: repository?.name || '',
      icon: repository?.icon || '',
      description: repository?.description || '',
      visibility: repository?.visibility || AccessVisibility.Public,
    },
    onSubmit: () => {
      return;
    },
  });

  const handleUpdateGeneral = async () => {
    if (!repository) return;
    setUpdating(true);
    try {
      const newRepositoryName = formik.values.name;
      const repositoryIcon = formik.values.icon;
      const repositoryDescription = formik.values.description;
      const visibility = formik.values.visibility;
      const updatedRecord = await updateRepositoryInfo(
        repository,
        newRepositoryName,
        repositoryIcon,
        repositoryDescription,
        visibility
      );
      onRefresh();
      history.push(`/workspace/${workspace?.slug}/${updatedRecord?.slug}`);
    } catch (error) {
      console.log(error);
    }
    setUpdating(false);
  };

  const handleDeleteRepository = async (id: string | undefined) => {
    Modal.confirm({
      title: 'Delete Repository',
      content:
        'This action will remove the repository permanently and all data in the repository will be deleted',
      onOk: async () => {
        if (!repository || !id) return;
        setUpdating(true);
        await deleteRepository(repository.workspaceId, id);
        setUpdating(false);
        history.push(`/workspace/${workspace?.name}`);
        onRefresh();
      },
      closable: true,
    });
  };

  const handleSelectEmoji = (emoji: string) => {
    formik.setFieldValue('icon', emoji);
  };

  const hasEdittingPermission = isWorkspaceAdmin(workspace);

  useEffect(() => {
    const init = async () => {
      if (!repository) return;
      setLoading(true);
      const workspace = await fetchWorkspaceById(repository.workspaceId);
      setWorkspace(workspace);
      setLoading(false);
    };
    init();
  }, [repository]);

  return (
    <LoadableContainer
      isLoading={loading}
      loadComponent={
        <ReactLoading
          type={'cylon'}
          color={GLOBAL_THEME_COLOR.$highlight_color}
          height={'10%'}
          width={'10%'}
        />
      }>
      <LoadableContainer isLoading={updating} loadComponent={<div>Updating...</div>}>
        <LoadableContainer isLoading={!repository} loadComponent={<div>No repository found</div>}>
          <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>General Settings</h3>
              {!hasEdittingPermission && (
                <p>⚠️ You don't have permission to update the repository</p>
              )}
              <Button
                type="primary"
                className="btn-success"
                disabled={
                  !hasEdittingPermission ||
                  !(
                    formik.values.visibility !== repository?.visibility ||
                    formik.values.name !== repository?.name ||
                    formik.values.description !== repository?.description ||
                    formik.values.icon !== repository?.icon
                  )
                }
                onClick={handleUpdateGeneral}>
                Save changes
              </Button>
            </div>
            <h4>Repository name</h4>
            <Input
              disabled={!hasEdittingPermission}
              status={formik.errors.name ? 'error' : ''}
              value={formik.values.name}
              onChange={e => formik.setFieldValue('name', e.target.value)}
              placeholder="Enter repository name"
            />
            {formik.errors.name && (
              <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>{formik.errors.name}</p>
            )}
            <h4>Repository description (Optional)</h4>
            <Input.TextArea
              disabled={!hasEdittingPermission}
              status={formik.errors.description ? 'error' : ''}
              value={formik.values.description}
              onChange={e => formik.setFieldValue('description', e.target.value)}
              autoSize={{ minRows: 6, maxRows: 5 }}
              maxLength={255}
              placeholder="Enter repository description"
            />
            {formik.errors.description && (
              <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
                {formik.errors.description}
              </p>
            )}
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <h4>Visibility / Icon</h4>
              <div style={MIDDLE_STYLE}>
                <Select
                  disabled={!hasEdittingPermission}
                  style={{ width: 120 }}
                  value={formik.values.visibility}
                  onChange={value => formik.setFieldValue('visibility', value)}
                  options={[
                    {
                      value: AccessVisibility.Public,
                      label: (
                        <span>
                          <EyeOutlined /> Public
                        </span>
                      ),
                    },
                    {
                      value: AccessVisibility.Private,
                      label: (
                        <span>
                          <EyeInvisibleOutlined /> Private
                        </span>
                      ),
                    },
                  ]}
                />
                <EmojiPickerButton
                  disabled={!hasEdittingPermission}
                  onEmojiPicked={handleSelectEmoji}
                  value={formik.values.icon as any}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Delete Repository</h4>
              <Button
                disabled={!hasEdittingPermission}
                onClick={() => handleDeleteRepository(repository?.id)}
                type="primary"
                className="btn-error">
                <span>
                  <DeleteOutlined /> Delete
                </span>
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Backup</h4>
              <RepositoryExportButton repository={repository as any} workspace={workspace as any} />
            </div>
          </React.Fragment>
        </LoadableContainer>
      </LoadableContainer>
    </LoadableContainer>
  );
};

export default RepositoryGeneralSetting;
