/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';

import { Button, Empty, Row } from 'antd';
import Fuse from 'fuse.js';
import Lottie from 'lottie-react';

import { ExploreSearchLottieFile } from '@/animations';
import {
  HelmetWrapper,
  LoadableContainer,
  PowerfulSearchInput,
  RepositoryList,
  SplashScreenPlaceholder,
  WorkspaceList,
} from '@/components';
import { GENERAL_EMPTY_IMAGE, GLOBAL_THEME_COLOR, ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { Repository } from '@/gql/graphql';
import { useBreakpoint } from '@/hooks';
import { useRepositoryStore } from '@/stores';

type Props = any;

const BATCH_SIZE = 6;

const ExploreScreen = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const { fetchAllPublicRepositories } = useRepositoryStore();
  const [startIndx, setStartIndx] = useState(0);
  const [loadable, setLoadable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [input, setInput] = useState<string>('');

  // const { fetchAllPublicWorkspaces } = useWorkspaceStore();
  // const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const fetchedWorkspaces = await fetchAllPublicWorkspaces();
  //       setWorkspaces(fetchedWorkspaces);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const handleLoadMoreData = async () => {
    setStartIndx(startIndx + 1);
    setIsLoadingMore(true);
    try {
      await fetchData();
    } catch (error) {
      console.log(error);
    }
    setIsLoadingMore(false);
  };

  const fetchData = async () => {
    try {
      const fetchedRepositories = await fetchAllPublicRepositories(
        BATCH_SIZE,
        startIndx * BATCH_SIZE
      );
      const updatedRepositories = repositories.concat(fetchedRepositories);
      if (updatedRepositories.length === repositories.length) {
        setLoadable(false);
      } else {
        setRepositories(updatedRepositories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredDateRepositories = useMemo(() => {
    if (input.length === 0) return repositories || [];
    if (repositories) {
      const fuse = new Fuse(repositories, { keys: ['name'] });
      const results = fuse.search(input);
      return results.map(result => result.item);
    }
    return [];
  }, [input, repositories]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        await fetchData();
        setStartIndx(startIndx + 1);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    init();
  }, []);

  return (
    <HelmetWrapper title="Explore">
      <div style={{ padding: isMobile ? '10px 15px' : '10px 40px' }}>
        <LoadableContainer
          isLoading={isLoading}
          loadComponent={
            <SplashScreenPlaceholder>
              <Lottie
                style={{
                  height: 300,
                  marginTop: '-30px',
                }}
                animationData={ExploreSearchLottieFile}
              />
            </SplashScreenPlaceholder>
          }>
          <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
            <div style={{ ...MIDDLE_STYLE, width: '100%' }}>
              <div>
                {!isMobile && (
                  <div style={{ ...MIDDLE_STYLE }}>
                    <div>
                      <h1 style={{ fontSize: '1.5rem', fontWeight: 600 }}>Community Explore</h1>
                      <div className="powerful-search-container" style={{ marginBottom: 50 }}>
                        <PowerfulSearchInput
                          value={input}
                          handleValueChanged={value => setInput(value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {[
                  {
                    title: 'All repositories',
                    description: 'Every link, everywhere, all at once',
                    icon: ICON_REGISTRY.EXPLORE,
                    values: filteredDateRepositories.sort(
                      (repoA, repoB) =>
                        repoB.favorites.length - repoA.favorites.length ||
                        repoB.updated_date - repoA.updated_date
                    ),
                    type: 'repositoryList',
                  },
                ].map(section => (
                  <div>
                    <div style={{ ...MIDDLE_STYLE, width: 'fit-content', marginBottom: 30 }}>
                      <div style={{ fontSize: 30 }}>{section.icon}</div>
                      <div style={{ marginLeft: 20 }}>
                        <h1 style={{ textAlign: 'left', margin: 0, marginBottom: 5 }}>
                          {section.title}
                        </h1>
                        <p
                          style={{
                            textAlign: 'left',
                            margin: 0,
                            color: GLOBAL_THEME_COLOR.$dark_text_color,
                          }}>
                          {section.description}
                        </p>
                      </div>
                    </div>
                    <LoadableContainer
                      isLoading={section.values.length === 0}
                      loadComponent={
                        <div
                          style={{
                            ...MIDDLE_STYLE,
                            width: '100%',
                            height: '200px',
                          }}>
                          <Empty
                            image={GENERAL_EMPTY_IMAGE}
                            imageStyle={{ height: 60 }}
                            description={<span>No data found</span>}></Empty>
                        </div>
                      }>
                      <Row gutter={24} style={{ marginBottom: 50 }}>
                        {section.type === 'repositoryList' ? (
                          <RepositoryList
                            showWorkspaceMetadata
                            repositories={section.values as any}
                          />
                        ) : (
                          <WorkspaceList workspaces={section.values as any} />
                        )}
                      </Row>
                    </LoadableContainer>
                  </div>
                ))}
              </div>
            </div>
            {loadable && (
              <Button size="large" loading={isLoadingMore} onClick={handleLoadMoreData}>
                Load more
              </Button>
            )}
          </div>
        </LoadableContainer>
      </div>
    </HelmetWrapper>
  );
};

export default ExploreScreen;
