import React from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';

import { MIDDLE_STYLE } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { shortenString } from '@/utils';

import WindowTabIcon from '../window-tab-icon';

type Props = {
  windowTab: RepositoryTab;
  timestamp: number;
  actionable?: boolean;
  ref?: any;
  style?: React.CSSProperties;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, windowTab: RepositoryTab) => void;
};

const TimelessWindowTabItem = ({
  ref,
  actionable,
  timestamp,
  windowTab,
  onClick,
  isSelected,
  style,
  ...props
}: Props) => {
  return (
    <div ref={ref} {...props} style={{ cursor: 'pointer', ...style }}>
      <Row
        onClick={e => onClick && onClick(e, windowTab)}
        className={`window-tab-item ${isSelected ? 'window-tab-item-selected' : ''}`}
        style={{ textAlign: 'left', ...MIDDLE_STYLE }}
        gutter={20}>
        <Col span={2}>
          <span style={{ fontSize: 11 }}>{moment.unix(timestamp).format('HH:mm:ss')}</span>
        </Col>
        <Col span={1}>
          <WindowTabIcon windowTab={windowTab} />
        </Col>
        <Col span={8}>
          <div style={{ wordBreak: 'break-all', fontSize: 11 }}>
            {shortenString(windowTab.title.split(' - ')[0] as any, 35)}
          </div>
        </Col>
        <Col span={13}>
          <div style={{ fontSize: 10, wordBreak: 'break-word' }}>
            <div className="tab-href">{shortenString(windowTab.url, 100)}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TimelessWindowTabItem;
