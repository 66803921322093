import gql from 'graphql-tag';

export const updateTimeTrackerSettingMutation = gql(`
 mutation UpdateTimeTrackerSettingMutation($setting : TimeTrackerSessionSettingInputType!){
  updateTimeTrackerSetting(updateTimeTrackerSettingArgs: $setting) {
   limit_least_used_time
   mode
   session_start_time
   session_end_time
   setting_enabled_from
   setting_enabled_to
   interrupt_on_close
  }
 }
`);

export const toggleTimeTrackerMutation = gql(`
 mutation ToggleTimeTracker {
   toggleTimeTracker {
     message
     type
   }
 }
`);

export const createTimeTrackerSessionMutation = gql(`
  mutation CreateNewTimeTrackerSession($createNewTimeTrackerSessionArgs: CreateNewTimeTrackerArgs!){
  createNewTimeTrackerSession(
    createNewTimeTrackerArgs:$createNewTimeTrackerSessionArgs
  ){
    message
    type
  }
}
`);
