import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { ClockCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row } from 'antd';
import { getMinutes } from 'date-fns';
import moment from 'moment';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { TimeTrackerProductivityMode } from '@/gql/graphql';
import { useTimeMachineContext } from '@/hooks';
import { useAuthStore, useSnackbarStore, useTimeTrackerStore } from '@/stores';

type Props = any;

const TabLensLandingSection = (props: Props) => {
  const { user } = useAuthStore();
  const { enqueueNotification } = useSnackbarStore();
  const { toggleTimeTracker, updateUserTimeTrackerInfo } = useTimeTrackerStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { setProductivityMode: setMode } = useTimeMachineContext();
  const history = useHistory();

  const handleCasualModeClicked = async () => {
    if (!user) return history.push('/auth');
    setLoading(true);
    try {
      await updateUserTimeTrackerInfo({
        limit_least_used_time: getMinutes(30),
        mode: TimeTrackerProductivityMode.CasualMode,
        session_start_time: 0,
        session_end_time: 23,
        setting_enabled_from: moment().unix(),
        setting_enabled_to: undefined,
      });
      await toggleTimeTracker();
      await enqueueNotification({
        name: 'Updated time tracker setting successfully',
        description: 'Successfully update time tracker setting',
        type: 'Success',
      });
      history.push('/time-tracker/active?mode=casual');
    } catch (error) {
      console.log(error);
      await enqueueNotification({
        name: 'Failed to update time tracker setting',
        description: 'Error updating time tracker setting',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const handleFocusModeClicked = () => {
    if (!user) return history.push('/auth');
    setMode(TimeTrackerProductivityMode.FocusMode);
  };

  return (
    <React.Fragment>
      <Card style={{ maxWidth: 800, width: '100%', padding: '0px 30px', marginTop: 20 }}>
        <h2 style={{ margin: 0 }}>
          Track browsing time with{' '}
          <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>Time Tracker </span>
        </h2>
        <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, margin: 0 }}>
          Monitor website visits, track time spent, and gain valuable insights.
        </p>
        <Divider />
        <Row gutter={40}>
          <Col span={12}>
            <img
              style={{ width: '100%' }}
              src="tab-lens-placeholder.svg"
              alt="Time Tracker Banner"
            />
            <div style={{ textAlign: 'left' }}></div>
          </Col>
          <Col span={12} style={{ padding: '10px 20px', ...MIDDLE_STYLE, flexDirection: 'column' }}>
            <div style={{ textAlign: 'left' }}>
              <h3>Casual Mode</h3>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Explore freely in Casual Mode. Discover without goals, track activities, and indulge
                your curiosity with ease.
              </p>
            </div>
            <Button
              loading={loading}
              onClick={handleCasualModeClicked}
              size="large"
              style={{ width: '100%' }}>
              <PlayCircleTwoTone /> Casual Mode
            </Button>
            <div style={{ textAlign: 'left', marginTop: 50 }}>
              <h3>Focus Mode</h3>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Maximizes online productivity. Set goals, track progress, and stay focused. Achieve
                more with efficient browsing in Focus Mode.
              </p>
            </div>
            <Button
              disabled
              onClick={handleFocusModeClicked}
              size="large"
              style={{ width: '100%' }}>
              <ClockCircleTwoTone /> Focus Mode
            </Button>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default TabLensLandingSection;
