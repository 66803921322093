import React from 'react';

import { Col, Row } from 'antd';

import {
  TimeTrackerGarbageTabsSection,
  TimelessCurrentBrowsingTab,
  TimelessTimeAllocationSection,
  TimelessTimelineRangeContainer,
} from '@/components';

import TimeTrackerActiveLogSection from '../time-tracker-active-log-section';

type Props = any;

const TimeTrackerContainer = (props: Props) => {
  return (
    <Row gutter={10}>
      <Col span={16}>
        <TimelessTimelineRangeContainer />
        <TimeTrackerActiveLogSection />
        <TimeTrackerGarbageTabsSection />
      </Col>
      <Col span={8}>
        <TimelessCurrentBrowsingTab />
        <TimelessTimeAllocationSection />
      </Col>
    </Row>
  );
};

export default TimeTrackerContainer;
