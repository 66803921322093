import React from 'react';

import LoadableContainer from '@/components/loadable-container';
import SelectedRepositoryProvider from '@/components/selected-repository-provider';
import SplashScreen from '@/components/splash-screen';
import { usePinnedAssets } from '@/hooks';

import AppExtensionAppBar from '../../app-extension-appbar';
import { RenderAppExtensionLayoutBuilder } from '../../layout-builder';
import PinAppExtensionContent from './content';
import PinAppExtensionEditor from './editor';
import PinAppExtensionExtraAction from './extraAction';

type Props = any;

const PinAppExtension = (props: Props) => {
  const { tabs, loading, repositories, setLoading, handleRefresh } = usePinnedAssets();

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <SelectedRepositoryProvider
        repositoryTabs={tabs}
        loading={loading}
        setLoading={setLoading}
        setRefreshing={handleRefresh}>
        {values => (
          <RenderAppExtensionLayoutBuilder
            values={values}
            appBar={
              <AppExtensionAppBar
                appIcon={<React.Fragment>📌</React.Fragment>}
                appName="Pin"
                description="Pin to save time finding them"
                editor={<PinAppExtensionEditor />}
                extraAction={<PinAppExtensionExtraAction />}
              />
            }
            content={<PinAppExtensionContent repositories={repositories} tabs={tabs} />}
          />
        )}
      </SelectedRepositoryProvider>
    </LoadableContainer>
  );
};

export default PinAppExtension;
