import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Avatar, Button, Drawer } from 'antd';

import NavigationMenu from '@/components/navigation-menu';
import SignInButton from '@/components/signin-button';
import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { auth } from '@/services/FirebaseService';
import { useAuthStore, useDrawerStore, useSnackbarStore } from '@/stores';

type Props = any;

const AppBarDrawerMobile = (props: Props) => {
  const history = useHistory();
  const { closeDrawer, drawerName } = useDrawerStore();
  const { enqueueNotification } = useSnackbarStore();
  const { user, setUser } = useAuthStore();
  const [loading, setLoading] = useState(false);

  const handleLogOut = async () => {
    setLoading(true);
    try {
      await auth.signOut();
      localStorage.removeItem('token');
      setUser(undefined);
    } catch (error) {
      await enqueueNotification({
        name: 'Failed to logout',
        description: 'Failed to logout from the account',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const handleViewProfile = async () => {
    history.push(`/user/${user?.username}`);
  };
  return (
    <Drawer
      title={user ? user.full_name : 'Not sign in yet'}
      width={'100%'}
      placement={'right'}
      onClose={closeDrawer}
      open={drawerName === 'navigationBarMobile'}>
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
        {user ? (
          <React.Fragment>
            <Avatar size={70} src={user?.profile_image} />
            <h3>{user?.full_name}</h3>
            <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>{user?.email}</div>
            <Button
              style={{ width: '100%', marginTop: 10, textAlign: 'left' }}
              onClick={handleViewProfile}>
              🙎‍♂️ View profile
            </Button>
            <Button
              loading={loading}
              style={{ width: '100%', marginTop: 5, textAlign: 'left' }}
              onClick={handleLogOut}>
              🏃 Log out
            </Button>
          </React.Fragment>
        ) : (
          <SignInButton />
        )}
        <NavigationMenu />
      </div>
    </Drawer>
  );
};

export default AppBarDrawerMobile;
