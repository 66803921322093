import Browser from 'webextension-polyfill';

import { Directory, RepositoryTab } from '@/gql/graphql';

export type UnionTab = Browser.Tabs.Tab | RepositoryTab;
export type CustomTabName = Record<string, string | undefined>;
export type TabChecked = Record<string, boolean>;
export type GroupedTabRecord = Record<string, UnionTab[]>;

export type TabTreeDirectory = Directory & {
  items: TreeNode[];
};

export type TreeNode = { value: UnionTab | TabTreeDirectory; type: 'tab' | 'directory' };

export type TimelessTabChartData = {
  id: string;
  label: string;
  value: number;
  color: string;
};

export type OpengraphMetadataType = {
  ogDescription: string;
  ogUrl: string;
  ogTitle: string;
  ogType: string;
  ogBanner: string;
};

export enum TaskEventType {
  Interupt,
  Normal,
}

export type BrowsingTabTask = Browser.Tabs.Tab & {
  timestamp: number;
  type: TaskEventType;
};

export type TimeTrackItem = {
  value: number;
  lastAccessedAt: number;
};

export type TimeTrackSheet = Record<string, TimeTrackItem>;
