import React from 'react';

import { Tag } from 'antd';

// import { Switch } from 'antd';

type Props = any;

const PinAppExtensionExtraAction = (props: Props) => {
  return (
    <div>
      <Tag color="red">Your pin is stored locally</Tag>
    </div>
  );
};

export default PinAppExtensionExtraAction;
