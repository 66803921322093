import { Windows } from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import { TreeNode } from '@/models';

import { IBrowserDevService } from './IBrowserDevService';

export class SafariDevRemoteService implements IBrowserDevService {
  async authenticateUser(token: string): Promise<void> {
    return;
  }

  async ungroupTab(tabIds: number[]): Promise<void> {
    return;
  }

  async fetchWindows(): Promise<Windows.Window[]> {
    return [] as any;
  }
  async selectTab(windowId: number | undefined, tabId: number | undefined) {
    return;
  }
  async closeWindow(window: Windows.Window): Promise<void> {
    return;
  }
  async createTab(tab: RepositoryTab): Promise<void> {
    return;
  }
  async deleteTab(tabId: number | undefined): Promise<void> {
    return;
  }
  async openTabs(tabs: RepositoryTab[]): Promise<void> {
    return;
  }
  async moveTreeItems(treeItems: TreeNode[]): Promise<void> {
    return;
  }
  async groupTabs(
    tabIds: number[],
    windowProperties?: chrome.tabGroups.UpdateProperties | undefined,
    groupId?: number | undefined,
    windowId?: number | undefined
  ) {
    return -1;
  }
  queryTabGroups(windowId: number): void {
    return;
  }
  getTabGroup(groupId: number): void {
    return;
  }
  updateTabGroup(groupId: number, windowProperties: chrome.tabGroups.UpdateProperties): void {
    return;
  }
  moveTabGroup(
    groupId: number,
    moveProperties: { index: number; windowId?: number | undefined }
  ): void {
    return;
  }
}
