import React, { useMemo } from 'react';

import { ALL_SESSION, useTabManagerStore, useWindowStore } from '@/stores';

import WindowContainer from '../window-container';

const TabManagerTableView = () => {
  const { windows } = useWindowStore();
  const { selectedSession } = useTabManagerStore();

  const filteredWindows = useMemo(
    () =>
      windows.filter(
        window =>
          selectedSession === ALL_SESSION || (window.id as any) === parseInt(selectedSession)
      ),
    [windows, selectedSession]
  );

  return (
    <div
      style={{
        padding: '10px 30px',
        height: 'auto',
      }}>
      <div style={{ marginTop: 20 }}>
        <React.Fragment>
          {filteredWindows.map((browserWindow, index) => (
            <div key={browserWindow.id?.toString()} style={{ marginBottom: 20 }}>
              <WindowContainer browserWindow={browserWindow} />
            </div>
          ))}
        </React.Fragment>
      </div>
    </div>
  );
};

export default TabManagerTableView;
