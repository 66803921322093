import React from 'react';
import { TbApps } from 'react-icons/tb';

import { Avatar } from 'antd';

import { useAuthStore, useDrawerStore } from '@/stores';

type Props = any;

const AppBarUserSectionMobile = (props: Props) => {
  const { openDrawer } = useDrawerStore();
  const { user } = useAuthStore();
  const handleOnClick = () => {
    openDrawer('navigationBarMobile');
  };
  return user ? (
    <Avatar onClick={handleOnClick} src={user?.profile_image} />
  ) : (
    <TbApps onClick={handleOnClick} />
  );
};

export default AppBarUserSectionMobile;
