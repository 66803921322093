import React from 'react';

import AddRepositoryTabsModal from './add-repository-tabs-modal';
import NewWorkspaceModal from './new-workspace-modal';
import SaveTabsModal from './save-tabs-modal';
import SuperSearchModal from './super-search-modal';

type Props = any;

const ModalContainer = (props: Props) => {
  return (
    <React.Fragment>
      <SaveTabsModal />
      <NewWorkspaceModal />
      <SuperSearchModal />
      <AddRepositoryTabsModal />
    </React.Fragment>
  );
};

export default ModalContainer;
