import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
  children: React.ReactNode;
  noBrandName?: boolean;
};

const HelmetWrapper = ({ title, noBrandName, children }: Props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{noBrandName ? `${title}` : `TabHub - ${title}`}</title>
      </Helmet>
      {children}
    </React.Fragment>
  );
};

export default HelmetWrapper;
