import React from 'react';

import AppRoutesRenderer from '../app-router/route-render';
import { workspaceRouteList } from './workspace-router-list';

type Props = any;

const WorkspaceRouter = (props: Props) => {
  return <AppRoutesRenderer routes={workspaceRouteList} />;
};

export default WorkspaceRouter;
