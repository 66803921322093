/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\nmutation TriggerOnBrowsingEventUpdated($triggerBrowsingEventArgs:TriggerBrowsingEventArgs!) {\n  triggerOnBrowsingEventUpdated(triggerBrowsingEventArgs:$triggerBrowsingEventArgs){\n    trigger_at\n    browser_client_id\n    event_name\n    user_id\n    properties\n  }\n}\n':
    types.TriggerOnBrowsingEventUpdatedDocument,
  '\nsubscription OnBrowsingEventUpdated($onBrowsingEventUpdatedArgs: OnBrowsingEventUpdatedArgs!){\n  onBrowsingEventUpdated(onBrowsingEventArgs:$onBrowsingEventUpdatedArgs){\n    trigger_at\n    browser_client_id\n    event_name\n    user_id\n    properties\n  }\n}\n':
    types.OnBrowsingEventUpdatedDocument,
  '\nsubscription Pong {\n  pong {\n    pingId\n  }\n}': types.PongDocument,
  '\n  mutation SetRepositoryTabs(\n    $repositoryId: String!\n    $directories: [DirectoryAsInput!]!\n    $repositoryTabs: [FullRepositoryTabAsInput!]!\n  ) {\n    setRepositoryTabs(\n      setRepositoryTabsArgs: { id: $repositoryId, tabs: $repositoryTabs, directories: $directories }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.SetRepositoryTabsDocument,
  '\n  query GetAllPublicRepositories($limit: Float!, $offset: Float!) {\n    getAllPublicRepositories(getAllPublicRepositoriesArgs: { limit: $limit, offset: $offset }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        lastAccessed\n        repositoryId\n        description\n        parentDirectory\n        labels\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n':
    types.GetAllPublicRepositoriesDocument,
  '\n  query GetWorkspaceRepositories($workspaceId: String!) {\n    getWorkspaceRepositories(getWorkspaceRepositoriesArgs: { workspaceId: $workspaceId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n':
    types.GetWorkspaceRepositoriesDocument,
  '\n  query GetUserRepositories($userId: String!) {\n    getUserRepositories(getUserRepositoriesArgs: { userId: $userId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n':
    types.GetUserRepositoriesDocument,
  '\n  query GetRepositoryById($repositoryId: String!) {\n    getRepositoryById(getRepositoryByIdArgs: { id: $repositoryId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n':
    types.GetRepositoryByIdDocument,
  '\n  query GetRepositoryBySlug($workspaceId: String!, $slug: String!) {\n    getRepositoryBySlug(getRepositoryBySlugArgs: { workspaceId: $workspaceId, slug: $slug }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        parentDirectory\n        repositoryId\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n':
    types.GetRepositoryBySlugDocument,
  '\n  mutation UpdateRepository(\n    $id: String!\n    $icon: String\n    $name: String\n    $description: String\n    $visibility: AccessVisibility\n  ) {\n    updateRepositoryInfo(\n      updateRepositoryInfoArgs: {\n        id: $id\n        icon: $icon\n        name: $name\n        description: $description\n        visibility: $visibility\n      }\n    ) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        lastAccessed\n        labels\n        description\n        parentDirectory\n        repositoryId\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n':
    types.UpdateRepositoryDocument,
  '\n  mutation CreateNewRepository(\n    $repositorySlug: String\n    $icon: String!\n    $name: String!\n    $description: String!\n    $visibility: AccessVisibility!\n    $tabs: [RepositoryTabAsInput!]!\n    $directories: [DirectoryAsInput!]!\n    $workspaceId: String!\n  ) {\n    createNewRepository(\n      createRepositoryArgs: {\n        repositorySlug: $repositorySlug\n        icon: $icon\n        name: $name\n        description: $description\n        tabs: $tabs\n        directories: $directories\n        visibility: $visibility\n        workspaceId: $workspaceId\n      }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.CreateNewRepositoryDocument,
  '\n  mutation DeleteRepository($id: String!) {\n    deleteRepository(deleteRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n':
    types.DeleteRepositoryDocument,
  '\n  mutation PinRepository($id: String!) {\n    pinRepository(pinRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n':
    types.PinRepositoryDocument,
  '\n  mutation UnpinRepository($id: String!) {\n    unpinRepository(unpinRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n':
    types.UnpinRepositoryDocument,
  '\n  mutation RemoveTabsFromRepository($tabIds: [String!]!, $repositoryId: String!) {\n    removeTabsFromRepository(removeTabsFromRepositoryArgs: { tabs: $tabIds, id: $repositoryId }) {\n      message\n      type\n    }\n  }\n':
    types.RemoveTabsFromRepositoryDocument,
  '\n  mutation RemoveContributor($repositoryId: String!, $contributorEmail: String!) {\n    removeRepositoryContributor(\n      removeRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.RemoveContributorDocument,
  '\n  mutation AddContributor($repositoryId: String!, $contributorEmail: String!) {\n    addRepositoryContributor(\n      addRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.AddContributorDocument,
  '\n  mutation ToggleLikeRepository($repositoryId: String!) {\n    toggleLikeRepository(toggleLikeRepositoryArgs: { id: $repositoryId }) {\n      message\n      type\n    }\n  }\n':
    types.ToggleLikeRepositoryDocument,
  '\n  mutation UpdateRepositoryAccess(\n    $accessPermission: AccessPermission!\n    $repositoryId: String!\n    $permittedUsers: [String!]!\n  ) {\n    updateRepositoryAccess(\n      updateRepositoryAccessArgs: {\n        accessPermission: $accessPermission\n        id: $repositoryId\n        permittedUsers: $permittedUsers\n      }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.UpdateRepositoryAccessDocument,
  '\n  mutation UpdateREADME($repositoryId: String!, $readmeData: String!) {\n    updateReadme(updateReadmeArgs: { repositoryId: $repositoryId, readmeData: $readmeData }) {\n      message\n      type\n    }\n  }\n':
    types.UpdateReadmeDocument,
  '\n  mutation UpdateRepositoryBanner(\n    $repositoryId: String!\n    $bannerData: Upload!\n    $mimeType: String!\n  ) {\n    updateRepositoryBanner(\n      updateRepositoryBannerArgs: {\n        repositoryId: $repositoryId\n        bannerData: $bannerData\n        mimeType: $mimeType\n      }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.UpdateRepositoryBannerDocument,
  '\n  query GetReadmeContent($repositoryId: String!) {\n    getReadmeContent(readReadmeArgs: { repositoryId: $repositoryId })\n  }\n':
    types.GetReadmeContentDocument,
  '\n  query GetRepositoryBanner($repositoryId: String!) {\n    getRepositoryBanner(getRepositoryBannerArgs: { repositoryId: $repositoryId })\n  }\n':
    types.GetRepositoryBannerDocument,
  '\n  mutation PinRepositoryTab($tabId: String!, $repositoryId: String!) {\n    pinRepositoryTab(pinRepositoryTabArgs: { tabId: $tabId, repositoryId: $repositoryId }) {\n      message\n      type\n    }\n  }\n':
    types.PinRepositoryTabDocument,
  '\n  mutation UnpinRepositoryTab($tabId: String!) {\n    unpinRepositoryTab(unpinRepositoryTabArgs: { tabId: $tabId }) {\n      message\n      type\n    }\n  }\n':
    types.UnpinRepositoryTabDocument,
  '\n  query SearchTabOnWeb($websiteUrl: String!) {\n    searchOnWeb(searchTabOnWebArgs: { websiteUrl: $websiteUrl })\n  }\n':
    types.SearchTabOnWebDocument,
  '\n  query GatherUrlsMetadata($websiteUrls: [String!]!) {\n    gatherUrlsMetadata(gatherUrlsMetadataArgs: { websiteUrls: $websiteUrls }) {\n      customName\n      favIconUrl\n      id\n      pinned\n      labels\n      description\n      parentDirectory\n      repositoryId\n      title\n      url\n    }\n  }\n':
    types.GatherUrlsMetadataDocument,
  '\n  query QueryOpenGraphMetadata($websiteUrl: String!) {\n    queryOpenGraphMetadata(queryOpenGraphMetadata: { websiteUrl: $websiteUrl }) {\n      message\n      type\n    }\n  }\n':
    types.QueryOpenGraphMetadataDocument,
  '\n mutation UpdateTimeTrackerSettingMutation($setting : TimeTrackerSessionSettingInputType!){\n  updateTimeTrackerSetting(updateTimeTrackerSettingArgs: $setting) {\n   limit_least_used_time\n   mode\n   session_start_time\n   session_end_time\n   setting_enabled_from\n   setting_enabled_to\n   interrupt_on_close\n  }\n }\n':
    types.UpdateTimeTrackerSettingMutationDocument,
  '\n mutation ToggleTimeTracker {\n   toggleTimeTracker {\n     message\n     type\n   }\n }\n':
    types.ToggleTimeTrackerDocument,
  '\n  mutation CreateNewTimeTrackerSession($createNewTimeTrackerSessionArgs: CreateNewTimeTrackerArgs!){\n  createNewTimeTrackerSession(\n    createNewTimeTrackerArgs:$createNewTimeTrackerSessionArgs\n  ){\n    message\n    type\n  }\n}\n':
    types.CreateNewTimeTrackerSessionDocument,
  '\n  fragment CORE_USER_WORKSPACE_FRAGMENT on User {\n    pinned_repositories\n    pinned_tabs {\n      customName\n      favIconUrl\n      id\n      pinned\n      repositoryId\n      description\n      parentDirectory\n      labels\n      title\n      url\n    }\n    selected_workspace\n    workspaces\n  }\n':
    types.Core_User_Workspace_FragmentFragmentDoc,
  '\n  fragment CORE_USER_FIELDS_FRAGMENT on User {\n    created_date\n    email\n    followers\n    following\n    full_name\n    id\n    username\n    favorites\n    profile_image\n    provider\n    updated_date\n  }\n':
    types.Core_User_Fields_FragmentFragmentDoc,
  '\n  fragment USER_TIME_TRACKER_SETTING_FRAGMENT on User {\n    time_tracker_enabled\n    time_tracker_setting {\n      limit_least_used_time\n      mode\n      session_start_time\n      session_end_time\n      setting_enabled_from\n      setting_enabled_to\n    }\n    time_tracker_sessions {\n      id\n      mode\n      state\n      session_start_time\n      session_end_time\n      date\n      trackedTabs\n    }\n  }\n':
    types.User_Time_Tracker_Setting_FragmentFragmentDoc,
  '\n  mutation CreateNewUser(\n    $uid: String!\n    $profile_image: String!\n    $email: String!\n    $username: String!\n    $full_name: String\n    $provider: String\n  ) {\n    createNewUser(\n      createNewUserArgs: {\n        uid: $uid\n        profile_image: $profile_image\n        email: $email\n        username: $username\n        full_name: $full_name\n        provider: $provider\n      }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.CreateNewUserDocument,
  '\nmutation SmartTabGrouping($tabs:[RepositoryTabAsInput!]!, $group: [String!]!) {\n  smartTabGrouping(\n    smartTabGroupingArgs:{\n      tabs:$tabs,\n      groups:$group\n    }\n  ){\n    category\n    url\n  }\n}\n':
    types.SmartTabGroupingDocument,
  '\nmutation UpdateUser($username:String!, $full_name: String!, $profile_image: String!) {\n  updateUser(updateUserArgs:{\n    username:$username,\n    full_name:$full_name,\n    profile_image:$profile_image\n  }) {\n    type\n    message\n  }\n}\n':
    types.UpdateUserDocument,
  '\n  query GetUserById($id: String!) {\n    getUserById(getUserByIdArgs: { id: $id }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n':
    types.GetUserByIdDocument,
  '\n  query GetUserByEmail($email: String!) {\n    getUserByEmail(getUserByEmailArgs: { email: $email }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n':
    types.GetUserByEmailDocument,
  '\n  query GetUserByUsername($username: String!) {\n    getUserByUsername(getUserByUsernameArgs: { username: $username }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n':
    types.GetUserByUsernameDocument,
  '\n  query GetAllUsers {\n    getAllUsers {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n':
    types.GetAllUsersDocument,
  '\n  query GetAllWorkspaces {\n    getAllWorkspaces {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n':
    types.GetAllWorkspacesDocument,
  '\n  query GetUserWorkspaces($userId: String!) {\n    getUserWorkspaces(getUserWorkspacesArgs: { userId: $userId }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n':
    types.GetUserWorkspacesDocument,
  '\n  query GetWorkspaceById($id: String!) {\n    getWorkspaceById(getWorkspaceByIdArgs: { id: $id }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n':
    types.GetWorkspaceByIdDocument,
  '\n  query GetWorkspaceByName($workspaceName: String!) {\n    getWorkspaceByName(getWorkspaceByNameArgs: { workspace_name: $workspaceName }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n':
    types.GetWorkspaceByNameDocument,
  '\n  query GetWorkspaceBySlug($workspaceSlug: String!) {\n    getWorkspaceBySlug(getWorkspaceBySlugArgs: { workspace_slug: $workspaceSlug }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n':
    types.GetWorkspaceBySlugDocument,
  '\n  query GetMyWorkspaces {\n    getMyWorkspaces {\n      id\n      slug\n      name\n      owner\n      repositories\n      visibility\n      members\n      roles\n      created_date\n      description\n      updated_date\n    }\n  }\n':
    types.GetMyWorkspacesDocument,
  '\n  mutation CreateNewWorkspace($name: String!, $description: String!, $visibility: Float!) {\n    createNewWorkspace(\n      createNewWorksapceArgs: { name: $name, description: $description, visibility: $visibility }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.CreateNewWorkspaceDocument,
  '\n  mutation UpdateWorkspace(\n    $id: String!\n    $name: String!\n    $description: String\n    $visibility: AccessVisibility!\n  ) {\n    updateWorkspace(\n      updateWorkspaceArgs: {\n        id: $id\n        name: $name\n        description: $description\n        visibility: $visibility\n      }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.UpdateWorkspaceDocument,
  '\n  mutation DeleteWorkspace($workspaceId: String!) {\n    deleteWorkspace(deleteWorkspaceArgs: { id: $workspaceId }) {\n      message\n      type\n    }\n  }\n':
    types.DeleteWorkspaceDocument,
  '\n  mutation AddWorkspaceMember($workspaceId: String!, $memberEmail: String!, $role: UserRole!) {\n    addWorkspaceMember(\n      addWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail, role: $role }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.AddWorkspaceMemberDocument,
  '\n  mutation RemoveWorkspaceMember($workspaceId: String!, $memberEmail: String!) {\n    removeWorkspaceMember(\n      removeWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail }\n    ) {\n      message\n      type\n    }\n  }\n':
    types.RemoveWorkspaceMemberDocument,
  '\n  mutation SelectQuickAccessWorkspace($workspaceId: String) {\n    selectQuickAccessWorkspace(selectQuickAccessWorkspaceArgs: { id: $workspaceId }) {\n      message\n      type\n    }\n  }\n':
    types.SelectQuickAccessWorkspaceDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\nmutation TriggerOnBrowsingEventUpdated($triggerBrowsingEventArgs:TriggerBrowsingEventArgs!) {\n  triggerOnBrowsingEventUpdated(triggerBrowsingEventArgs:$triggerBrowsingEventArgs){\n    trigger_at\n    browser_client_id\n    event_name\n    user_id\n    properties\n  }\n}\n'
): typeof documents['\nmutation TriggerOnBrowsingEventUpdated($triggerBrowsingEventArgs:TriggerBrowsingEventArgs!) {\n  triggerOnBrowsingEventUpdated(triggerBrowsingEventArgs:$triggerBrowsingEventArgs){\n    trigger_at\n    browser_client_id\n    event_name\n    user_id\n    properties\n  }\n}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\nsubscription OnBrowsingEventUpdated($onBrowsingEventUpdatedArgs: OnBrowsingEventUpdatedArgs!){\n  onBrowsingEventUpdated(onBrowsingEventArgs:$onBrowsingEventUpdatedArgs){\n    trigger_at\n    browser_client_id\n    event_name\n    user_id\n    properties\n  }\n}\n'
): typeof documents['\nsubscription OnBrowsingEventUpdated($onBrowsingEventUpdatedArgs: OnBrowsingEventUpdatedArgs!){\n  onBrowsingEventUpdated(onBrowsingEventArgs:$onBrowsingEventUpdatedArgs){\n    trigger_at\n    browser_client_id\n    event_name\n    user_id\n    properties\n  }\n}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\nsubscription Pong {\n  pong {\n    pingId\n  }\n}'
): typeof documents['\nsubscription Pong {\n  pong {\n    pingId\n  }\n}'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SetRepositoryTabs(\n    $repositoryId: String!\n    $directories: [DirectoryAsInput!]!\n    $repositoryTabs: [FullRepositoryTabAsInput!]!\n  ) {\n    setRepositoryTabs(\n      setRepositoryTabsArgs: { id: $repositoryId, tabs: $repositoryTabs, directories: $directories }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation SetRepositoryTabs(\n    $repositoryId: String!\n    $directories: [DirectoryAsInput!]!\n    $repositoryTabs: [FullRepositoryTabAsInput!]!\n  ) {\n    setRepositoryTabs(\n      setRepositoryTabsArgs: { id: $repositoryId, tabs: $repositoryTabs, directories: $directories }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAllPublicRepositories($limit: Float!, $offset: Float!) {\n    getAllPublicRepositories(getAllPublicRepositoriesArgs: { limit: $limit, offset: $offset }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        lastAccessed\n        repositoryId\n        description\n        parentDirectory\n        labels\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'
): typeof documents['\n  query GetAllPublicRepositories($limit: Float!, $offset: Float!) {\n    getAllPublicRepositories(getAllPublicRepositoriesArgs: { limit: $limit, offset: $offset }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        lastAccessed\n        repositoryId\n        description\n        parentDirectory\n        labels\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetWorkspaceRepositories($workspaceId: String!) {\n    getWorkspaceRepositories(getWorkspaceRepositoriesArgs: { workspaceId: $workspaceId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'
): typeof documents['\n  query GetWorkspaceRepositories($workspaceId: String!) {\n    getWorkspaceRepositories(getWorkspaceRepositoriesArgs: { workspaceId: $workspaceId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserRepositories($userId: String!) {\n    getUserRepositories(getUserRepositoriesArgs: { userId: $userId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'
): typeof documents['\n  query GetUserRepositories($userId: String!) {\n    getUserRepositories(getUserRepositoriesArgs: { userId: $userId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetRepositoryById($repositoryId: String!) {\n    getRepositoryById(getRepositoryByIdArgs: { id: $repositoryId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'
): typeof documents['\n  query GetRepositoryById($repositoryId: String!) {\n    getRepositoryById(getRepositoryByIdArgs: { id: $repositoryId }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        repositoryId\n        parentDirectory\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetRepositoryBySlug($workspaceId: String!, $slug: String!) {\n    getRepositoryBySlug(getRepositoryBySlugArgs: { workspaceId: $workspaceId, slug: $slug }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        parentDirectory\n        repositoryId\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'
): typeof documents['\n  query GetRepositoryBySlug($workspaceId: String!, $slug: String!) {\n    getRepositoryBySlug(getRepositoryBySlugArgs: { workspaceId: $workspaceId, slug: $slug }) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        labels\n        lastAccessed\n        description\n        parentDirectory\n        repositoryId\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRepository(\n    $id: String!\n    $icon: String\n    $name: String\n    $description: String\n    $visibility: AccessVisibility\n  ) {\n    updateRepositoryInfo(\n      updateRepositoryInfoArgs: {\n        id: $id\n        icon: $icon\n        name: $name\n        description: $description\n        visibility: $visibility\n      }\n    ) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        lastAccessed\n        labels\n        description\n        parentDirectory\n        repositoryId\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'
): typeof documents['\n  mutation UpdateRepository(\n    $id: String!\n    $icon: String\n    $name: String\n    $description: String\n    $visibility: AccessVisibility\n  ) {\n    updateRepositoryInfo(\n      updateRepositoryInfoArgs: {\n        id: $id\n        icon: $icon\n        name: $name\n        description: $description\n        visibility: $visibility\n      }\n    ) {\n      icon\n      contributors\n      created_date\n      description\n      favorites\n      favorite_count\n      id\n      name\n      slug\n      readme\n      bannerUrl\n      owner\n      pinned\n      tabs {\n        customName\n        favIconUrl\n        id\n        pinned\n        lastAccessed\n        labels\n        description\n        parentDirectory\n        repositoryId\n        title\n        url\n      }\n      directories {\n        id\n        color\n        name\n        parentDirectory\n      }\n      accessPermission\n      permittedUsers\n      updated_date\n      visibility\n      workspaceId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNewRepository(\n    $repositorySlug: String\n    $icon: String!\n    $name: String!\n    $description: String!\n    $visibility: AccessVisibility!\n    $tabs: [RepositoryTabAsInput!]!\n    $directories: [DirectoryAsInput!]!\n    $workspaceId: String!\n  ) {\n    createNewRepository(\n      createRepositoryArgs: {\n        repositorySlug: $repositorySlug\n        icon: $icon\n        name: $name\n        description: $description\n        tabs: $tabs\n        directories: $directories\n        visibility: $visibility\n        workspaceId: $workspaceId\n      }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation CreateNewRepository(\n    $repositorySlug: String\n    $icon: String!\n    $name: String!\n    $description: String!\n    $visibility: AccessVisibility!\n    $tabs: [RepositoryTabAsInput!]!\n    $directories: [DirectoryAsInput!]!\n    $workspaceId: String!\n  ) {\n    createNewRepository(\n      createRepositoryArgs: {\n        repositorySlug: $repositorySlug\n        icon: $icon\n        name: $name\n        description: $description\n        tabs: $tabs\n        directories: $directories\n        visibility: $visibility\n        workspaceId: $workspaceId\n      }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteRepository($id: String!) {\n    deleteRepository(deleteRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation DeleteRepository($id: String!) {\n    deleteRepository(deleteRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation PinRepository($id: String!) {\n    pinRepository(pinRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation PinRepository($id: String!) {\n    pinRepository(pinRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UnpinRepository($id: String!) {\n    unpinRepository(unpinRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation UnpinRepository($id: String!) {\n    unpinRepository(unpinRepositoryArgs: { id: $id }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveTabsFromRepository($tabIds: [String!]!, $repositoryId: String!) {\n    removeTabsFromRepository(removeTabsFromRepositoryArgs: { tabs: $tabIds, id: $repositoryId }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation RemoveTabsFromRepository($tabIds: [String!]!, $repositoryId: String!) {\n    removeTabsFromRepository(removeTabsFromRepositoryArgs: { tabs: $tabIds, id: $repositoryId }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveContributor($repositoryId: String!, $contributorEmail: String!) {\n    removeRepositoryContributor(\n      removeRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation RemoveContributor($repositoryId: String!, $contributorEmail: String!) {\n    removeRepositoryContributor(\n      removeRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddContributor($repositoryId: String!, $contributorEmail: String!) {\n    addRepositoryContributor(\n      addRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation AddContributor($repositoryId: String!, $contributorEmail: String!) {\n    addRepositoryContributor(\n      addRepositoryContributorArgs: { id: $repositoryId, member_email: $contributorEmail }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ToggleLikeRepository($repositoryId: String!) {\n    toggleLikeRepository(toggleLikeRepositoryArgs: { id: $repositoryId }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation ToggleLikeRepository($repositoryId: String!) {\n    toggleLikeRepository(toggleLikeRepositoryArgs: { id: $repositoryId }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRepositoryAccess(\n    $accessPermission: AccessPermission!\n    $repositoryId: String!\n    $permittedUsers: [String!]!\n  ) {\n    updateRepositoryAccess(\n      updateRepositoryAccessArgs: {\n        accessPermission: $accessPermission\n        id: $repositoryId\n        permittedUsers: $permittedUsers\n      }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation UpdateRepositoryAccess(\n    $accessPermission: AccessPermission!\n    $repositoryId: String!\n    $permittedUsers: [String!]!\n  ) {\n    updateRepositoryAccess(\n      updateRepositoryAccessArgs: {\n        accessPermission: $accessPermission\n        id: $repositoryId\n        permittedUsers: $permittedUsers\n      }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateREADME($repositoryId: String!, $readmeData: String!) {\n    updateReadme(updateReadmeArgs: { repositoryId: $repositoryId, readmeData: $readmeData }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation UpdateREADME($repositoryId: String!, $readmeData: String!) {\n    updateReadme(updateReadmeArgs: { repositoryId: $repositoryId, readmeData: $readmeData }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRepositoryBanner(\n    $repositoryId: String!\n    $bannerData: Upload!\n    $mimeType: String!\n  ) {\n    updateRepositoryBanner(\n      updateRepositoryBannerArgs: {\n        repositoryId: $repositoryId\n        bannerData: $bannerData\n        mimeType: $mimeType\n      }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation UpdateRepositoryBanner(\n    $repositoryId: String!\n    $bannerData: Upload!\n    $mimeType: String!\n  ) {\n    updateRepositoryBanner(\n      updateRepositoryBannerArgs: {\n        repositoryId: $repositoryId\n        bannerData: $bannerData\n        mimeType: $mimeType\n      }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetReadmeContent($repositoryId: String!) {\n    getReadmeContent(readReadmeArgs: { repositoryId: $repositoryId })\n  }\n'
): typeof documents['\n  query GetReadmeContent($repositoryId: String!) {\n    getReadmeContent(readReadmeArgs: { repositoryId: $repositoryId })\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetRepositoryBanner($repositoryId: String!) {\n    getRepositoryBanner(getRepositoryBannerArgs: { repositoryId: $repositoryId })\n  }\n'
): typeof documents['\n  query GetRepositoryBanner($repositoryId: String!) {\n    getRepositoryBanner(getRepositoryBannerArgs: { repositoryId: $repositoryId })\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation PinRepositoryTab($tabId: String!, $repositoryId: String!) {\n    pinRepositoryTab(pinRepositoryTabArgs: { tabId: $tabId, repositoryId: $repositoryId }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation PinRepositoryTab($tabId: String!, $repositoryId: String!) {\n    pinRepositoryTab(pinRepositoryTabArgs: { tabId: $tabId, repositoryId: $repositoryId }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UnpinRepositoryTab($tabId: String!) {\n    unpinRepositoryTab(unpinRepositoryTabArgs: { tabId: $tabId }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation UnpinRepositoryTab($tabId: String!) {\n    unpinRepositoryTab(unpinRepositoryTabArgs: { tabId: $tabId }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchTabOnWeb($websiteUrl: String!) {\n    searchOnWeb(searchTabOnWebArgs: { websiteUrl: $websiteUrl })\n  }\n'
): typeof documents['\n  query SearchTabOnWeb($websiteUrl: String!) {\n    searchOnWeb(searchTabOnWebArgs: { websiteUrl: $websiteUrl })\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GatherUrlsMetadata($websiteUrls: [String!]!) {\n    gatherUrlsMetadata(gatherUrlsMetadataArgs: { websiteUrls: $websiteUrls }) {\n      customName\n      favIconUrl\n      id\n      pinned\n      labels\n      description\n      parentDirectory\n      repositoryId\n      title\n      url\n    }\n  }\n'
): typeof documents['\n  query GatherUrlsMetadata($websiteUrls: [String!]!) {\n    gatherUrlsMetadata(gatherUrlsMetadataArgs: { websiteUrls: $websiteUrls }) {\n      customName\n      favIconUrl\n      id\n      pinned\n      labels\n      description\n      parentDirectory\n      repositoryId\n      title\n      url\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query QueryOpenGraphMetadata($websiteUrl: String!) {\n    queryOpenGraphMetadata(queryOpenGraphMetadata: { websiteUrl: $websiteUrl }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  query QueryOpenGraphMetadata($websiteUrl: String!) {\n    queryOpenGraphMetadata(queryOpenGraphMetadata: { websiteUrl: $websiteUrl }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n mutation UpdateTimeTrackerSettingMutation($setting : TimeTrackerSessionSettingInputType!){\n  updateTimeTrackerSetting(updateTimeTrackerSettingArgs: $setting) {\n   limit_least_used_time\n   mode\n   session_start_time\n   session_end_time\n   setting_enabled_from\n   setting_enabled_to\n   interrupt_on_close\n  }\n }\n'
): typeof documents['\n mutation UpdateTimeTrackerSettingMutation($setting : TimeTrackerSessionSettingInputType!){\n  updateTimeTrackerSetting(updateTimeTrackerSettingArgs: $setting) {\n   limit_least_used_time\n   mode\n   session_start_time\n   session_end_time\n   setting_enabled_from\n   setting_enabled_to\n   interrupt_on_close\n  }\n }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n mutation ToggleTimeTracker {\n   toggleTimeTracker {\n     message\n     type\n   }\n }\n'
): typeof documents['\n mutation ToggleTimeTracker {\n   toggleTimeTracker {\n     message\n     type\n   }\n }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNewTimeTrackerSession($createNewTimeTrackerSessionArgs: CreateNewTimeTrackerArgs!){\n  createNewTimeTrackerSession(\n    createNewTimeTrackerArgs:$createNewTimeTrackerSessionArgs\n  ){\n    message\n    type\n  }\n}\n'
): typeof documents['\n  mutation CreateNewTimeTrackerSession($createNewTimeTrackerSessionArgs: CreateNewTimeTrackerArgs!){\n  createNewTimeTrackerSession(\n    createNewTimeTrackerArgs:$createNewTimeTrackerSessionArgs\n  ){\n    message\n    type\n  }\n}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CORE_USER_WORKSPACE_FRAGMENT on User {\n    pinned_repositories\n    pinned_tabs {\n      customName\n      favIconUrl\n      id\n      pinned\n      repositoryId\n      description\n      parentDirectory\n      labels\n      title\n      url\n    }\n    selected_workspace\n    workspaces\n  }\n'
): typeof documents['\n  fragment CORE_USER_WORKSPACE_FRAGMENT on User {\n    pinned_repositories\n    pinned_tabs {\n      customName\n      favIconUrl\n      id\n      pinned\n      repositoryId\n      description\n      parentDirectory\n      labels\n      title\n      url\n    }\n    selected_workspace\n    workspaces\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CORE_USER_FIELDS_FRAGMENT on User {\n    created_date\n    email\n    followers\n    following\n    full_name\n    id\n    username\n    favorites\n    profile_image\n    provider\n    updated_date\n  }\n'
): typeof documents['\n  fragment CORE_USER_FIELDS_FRAGMENT on User {\n    created_date\n    email\n    followers\n    following\n    full_name\n    id\n    username\n    favorites\n    profile_image\n    provider\n    updated_date\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment USER_TIME_TRACKER_SETTING_FRAGMENT on User {\n    time_tracker_enabled\n    time_tracker_setting {\n      limit_least_used_time\n      mode\n      session_start_time\n      session_end_time\n      setting_enabled_from\n      setting_enabled_to\n    }\n    time_tracker_sessions {\n      id\n      mode\n      state\n      session_start_time\n      session_end_time\n      date\n      trackedTabs\n    }\n  }\n'
): typeof documents['\n  fragment USER_TIME_TRACKER_SETTING_FRAGMENT on User {\n    time_tracker_enabled\n    time_tracker_setting {\n      limit_least_used_time\n      mode\n      session_start_time\n      session_end_time\n      setting_enabled_from\n      setting_enabled_to\n    }\n    time_tracker_sessions {\n      id\n      mode\n      state\n      session_start_time\n      session_end_time\n      date\n      trackedTabs\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNewUser(\n    $uid: String!\n    $profile_image: String!\n    $email: String!\n    $username: String!\n    $full_name: String\n    $provider: String\n  ) {\n    createNewUser(\n      createNewUserArgs: {\n        uid: $uid\n        profile_image: $profile_image\n        email: $email\n        username: $username\n        full_name: $full_name\n        provider: $provider\n      }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation CreateNewUser(\n    $uid: String!\n    $profile_image: String!\n    $email: String!\n    $username: String!\n    $full_name: String\n    $provider: String\n  ) {\n    createNewUser(\n      createNewUserArgs: {\n        uid: $uid\n        profile_image: $profile_image\n        email: $email\n        username: $username\n        full_name: $full_name\n        provider: $provider\n      }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\nmutation SmartTabGrouping($tabs:[RepositoryTabAsInput!]!, $group: [String!]!) {\n  smartTabGrouping(\n    smartTabGroupingArgs:{\n      tabs:$tabs,\n      groups:$group\n    }\n  ){\n    category\n    url\n  }\n}\n'
): typeof documents['\nmutation SmartTabGrouping($tabs:[RepositoryTabAsInput!]!, $group: [String!]!) {\n  smartTabGrouping(\n    smartTabGroupingArgs:{\n      tabs:$tabs,\n      groups:$group\n    }\n  ){\n    category\n    url\n  }\n}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\nmutation UpdateUser($username:String!, $full_name: String!, $profile_image: String!) {\n  updateUser(updateUserArgs:{\n    username:$username,\n    full_name:$full_name,\n    profile_image:$profile_image\n  }) {\n    type\n    message\n  }\n}\n'
): typeof documents['\nmutation UpdateUser($username:String!, $full_name: String!, $profile_image: String!) {\n  updateUser(updateUserArgs:{\n    username:$username,\n    full_name:$full_name,\n    profile_image:$profile_image\n  }) {\n    type\n    message\n  }\n}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserById($id: String!) {\n    getUserById(getUserByIdArgs: { id: $id }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'
): typeof documents['\n  query GetUserById($id: String!) {\n    getUserById(getUserByIdArgs: { id: $id }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserByEmail($email: String!) {\n    getUserByEmail(getUserByEmailArgs: { email: $email }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'
): typeof documents['\n  query GetUserByEmail($email: String!) {\n    getUserByEmail(getUserByEmailArgs: { email: $email }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserByUsername($username: String!) {\n    getUserByUsername(getUserByUsernameArgs: { username: $username }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'
): typeof documents['\n  query GetUserByUsername($username: String!) {\n    getUserByUsername(getUserByUsernameArgs: { username: $username }) {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAllUsers {\n    getAllUsers {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'
): typeof documents['\n  query GetAllUsers {\n    getAllUsers {\n      ...CORE_USER_FIELDS_FRAGMENT\n      ...CORE_USER_WORKSPACE_FRAGMENT\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAllWorkspaces {\n    getAllWorkspaces {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'
): typeof documents['\n  query GetAllWorkspaces {\n    getAllWorkspaces {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserWorkspaces($userId: String!) {\n    getUserWorkspaces(getUserWorkspacesArgs: { userId: $userId }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'
): typeof documents['\n  query GetUserWorkspaces($userId: String!) {\n    getUserWorkspaces(getUserWorkspacesArgs: { userId: $userId }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetWorkspaceById($id: String!) {\n    getWorkspaceById(getWorkspaceByIdArgs: { id: $id }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'
): typeof documents['\n  query GetWorkspaceById($id: String!) {\n    getWorkspaceById(getWorkspaceByIdArgs: { id: $id }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetWorkspaceByName($workspaceName: String!) {\n    getWorkspaceByName(getWorkspaceByNameArgs: { workspace_name: $workspaceName }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'
): typeof documents['\n  query GetWorkspaceByName($workspaceName: String!) {\n    getWorkspaceByName(getWorkspaceByNameArgs: { workspace_name: $workspaceName }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetWorkspaceBySlug($workspaceSlug: String!) {\n    getWorkspaceBySlug(getWorkspaceBySlugArgs: { workspace_slug: $workspaceSlug }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'
): typeof documents['\n  query GetWorkspaceBySlug($workspaceSlug: String!) {\n    getWorkspaceBySlug(getWorkspaceBySlugArgs: { workspace_slug: $workspaceSlug }) {\n      created_date\n      description\n      id\n      slug\n      members\n      roles\n      name\n      owner\n      repositories\n      updated_date\n      visibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMyWorkspaces {\n    getMyWorkspaces {\n      id\n      slug\n      name\n      owner\n      repositories\n      visibility\n      members\n      roles\n      created_date\n      description\n      updated_date\n    }\n  }\n'
): typeof documents['\n  query GetMyWorkspaces {\n    getMyWorkspaces {\n      id\n      slug\n      name\n      owner\n      repositories\n      visibility\n      members\n      roles\n      created_date\n      description\n      updated_date\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNewWorkspace($name: String!, $description: String!, $visibility: Float!) {\n    createNewWorkspace(\n      createNewWorksapceArgs: { name: $name, description: $description, visibility: $visibility }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation CreateNewWorkspace($name: String!, $description: String!, $visibility: Float!) {\n    createNewWorkspace(\n      createNewWorksapceArgs: { name: $name, description: $description, visibility: $visibility }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateWorkspace(\n    $id: String!\n    $name: String!\n    $description: String\n    $visibility: AccessVisibility!\n  ) {\n    updateWorkspace(\n      updateWorkspaceArgs: {\n        id: $id\n        name: $name\n        description: $description\n        visibility: $visibility\n      }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation UpdateWorkspace(\n    $id: String!\n    $name: String!\n    $description: String\n    $visibility: AccessVisibility!\n  ) {\n    updateWorkspace(\n      updateWorkspaceArgs: {\n        id: $id\n        name: $name\n        description: $description\n        visibility: $visibility\n      }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteWorkspace($workspaceId: String!) {\n    deleteWorkspace(deleteWorkspaceArgs: { id: $workspaceId }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation DeleteWorkspace($workspaceId: String!) {\n    deleteWorkspace(deleteWorkspaceArgs: { id: $workspaceId }) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddWorkspaceMember($workspaceId: String!, $memberEmail: String!, $role: UserRole!) {\n    addWorkspaceMember(\n      addWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail, role: $role }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation AddWorkspaceMember($workspaceId: String!, $memberEmail: String!, $role: UserRole!) {\n    addWorkspaceMember(\n      addWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail, role: $role }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveWorkspaceMember($workspaceId: String!, $memberEmail: String!) {\n    removeWorkspaceMember(\n      removeWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail }\n    ) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation RemoveWorkspaceMember($workspaceId: String!, $memberEmail: String!) {\n    removeWorkspaceMember(\n      removeWorkspaceMemberArgs: { id: $workspaceId, member_email: $memberEmail }\n    ) {\n      message\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SelectQuickAccessWorkspace($workspaceId: String) {\n    selectQuickAccessWorkspace(selectQuickAccessWorkspaceArgs: { id: $workspaceId }) {\n      message\n      type\n    }\n  }\n'
): typeof documents['\n  mutation SelectQuickAccessWorkspace($workspaceId: String) {\n    selectQuickAccessWorkspace(selectQuickAccessWorkspaceArgs: { id: $workspaceId }) {\n      message\n      type\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
