import React from 'react';

import { SplashScreen, TimeTrackerContainer } from '@/components';
import { useLoadTimeTrackerInfo, useTimeMachineContext } from '@/hooks';

type Props = any;

const TimeTrackerActiveSessionScreen = (props: Props) => {
  const { isTimeMachineReady } = useTimeMachineContext();
  useLoadTimeTrackerInfo();
  return isTimeMachineReady ? <TimeTrackerContainer /> : <SplashScreen />;
};

export default TimeTrackerActiveSessionScreen;
