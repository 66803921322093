import React from 'react';

import SkeletonAvatar from 'antd/es/skeleton/Avatar';

import { Layout, Space } from 'antd';

import { ANT_DESIGN_THEME, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useBreakpoint } from '@/hooks';
import { useAuthStore } from '@/stores';

import AppLogoSection from '../app-logo-section';
import AppBarUserSectionDesktop from '../appbar-user-section-desktop';
import AppBarUserSectionMobile from '../appbar-user-section-mobile';
import LoadableContainer from '../loadable-container';
import PowerfulSearchButton from '../powerful-search-button';

type Props = Record<string, any>;

const AppBar = (props: Props) => {
  const { loadingUser } = useAuthStore();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();

  return (
    <Layout.Header
      style={{
        padding: '10px 20px',
        ...MIDDLE_STYLE,
        justifyContent: 'space-between',
        borderBottom: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
        boxShadow: 'rgba(149, 157, 165, 0.065) 0px 8px 24px !important',
      }}>
      <Space size={50} style={{ ...MIDDLE_STYLE }}>
        <AppLogoSection />
        {!isDesktop && <PowerfulSearchButton />}
      </Space>
      <Space direction="horizontal" size={60}>
        {!isMobile && (
          <React.Fragment>
            {[
              { title: 'Features', url: '#features' },
              { title: 'Blog', url: 'https://blog.tabhub.io' },
              { title: 'Contact', url: '#contact' },
            ].map(item => (
              <a
                href={item.url}
                style={{ color: GLOBAL_THEME_COLOR.$text_color, fontWeight: '600' }}>
                {item.title}
              </a>
            ))}
          </React.Fragment>
        )}
        <LoadableContainer
          style={{ ...MIDDLE_STYLE }}
          loadComponent={<SkeletonAvatar style={{ marginTop: 15 }} active />}
          isLoading={loadingUser}>
          {isTablet ? <AppBarUserSectionMobile /> : <AppBarUserSectionDesktop />}
        </LoadableContainer>
      </Space>
    </Layout.Header>
  );
};

export default AppBar;
