/* eslint-disable react-hooks/exhaustive-deps */
import { useSubscription } from '@apollo/client';

import { OnBrowsingEventUpdatedDocument } from '@/gql/graphql';
import { getBrowserClientId } from '@/utils';

export const useBrowsingEventOnMount = (events: string[]) => {
  return useSubscription(OnBrowsingEventUpdatedDocument, {
    variables: {
      onBrowsingEventUpdatedArgs: {
        browser_client_id: getBrowserClientId(),
        subscribed_events: events,
      },
    },
    shouldResubscribe: false,
  });
};
