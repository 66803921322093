import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { User } from '@/gql/graphql';
import { getCurrentUserQuery, getUserByEmailQuery, getUserByIdQuery } from '@/graphql';
import { apolloClient } from '@/services/ApolloService';

export type BasicUser = Omit<User, 'setting' | 'timeTrackerSessions'>;

export interface AuthStoreState {
  user: BasicUser | undefined;
  loadingUser: boolean;
  fetchCurrentUser: () => Promise<BasicUser | undefined>;
  fetchUserByEmail: (email: string) => Promise<BasicUser | undefined | null>;
  fetchUserById: (id: string) => Promise<BasicUser | undefined | null>;
  setLoadingUser: (loading: boolean) => void;
  setUser: (user: BasicUser | undefined) => void;
}

export const useAuthStore = create<AuthStoreState>()(
  devtools(set => ({
    user: undefined,
    loadingUser: true,

    fetchCurrentUser: async () => {
      const { data } = await apolloClient.query({
        query: getCurrentUserQuery,
        fetchPolicy: 'network-only',
      });
      const user = data.getCurrentUser;
      return user;
    },
    fetchUserByEmail: async (email: string) => {
      const { data } = await apolloClient.query({
        query: getUserByEmailQuery,
        fetchPolicy: 'network-only',
        variables: {
          email: email,
        },
      });
      const user = data.getUserByEmail as any;
      return user;
    },
    fetchUserById: async (id: string) => {
      const { data } = await apolloClient.query({
        query: getUserByIdQuery,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      });
      const user = data.getUserById as any;
      return user;
    },
    setLoadingUser(loading) {
      set(_ => ({ loadingUser: loading }));
    },
    setUser: (user: BasicUser | undefined) => set(_ => ({ user })),
  }))
);
