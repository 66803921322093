import React from 'react';

import { Col, Row } from 'antd';

import AnimatedComponent from '../animated-component';
import RepositoryPageView from '../repository-page-view';

const RepositoryCollapsibleReadmeView = ({
  pageVisible,
  bannerImage,
  content,
  children,
}: {
  pageVisible: boolean;
  bannerImage: string | undefined;
  content: string | undefined;
  children: React.ReactNode;
}) => {
  return content && pageVisible ? (
    <AnimatedComponent.OpacityFadeInDiv delay={300}>
      <Row gutter={20}>
        <Col span={14}>
          <RepositoryPageView bannerImage={bannerImage} content={content} />
        </Col>
        <Col span={10}>{children}</Col>
      </Row>
    </AnimatedComponent.OpacityFadeInDiv>
  ) : (
    <AnimatedComponent.OpacityFadeInDiv delay={300}>
      <React.Fragment>{children}</React.Fragment>
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default RepositoryCollapsibleReadmeView;
