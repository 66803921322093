import React, { useMemo } from 'react';

import { LoadableContainer, SplashScreen, TimeTrackerSessionCard } from '@/components';
import { MIDDLE_STYLE } from '@/constants';
import { TimeTrackerSession } from '@/gql/graphql';
import { useTimeTrackerStore } from '@/stores';

type Props = any;

const TimeTrackerAllSessionScreen = (props: Props) => {
  const { userTimeTrackerInfo, timeTrackerLoading } = useTimeTrackerStore();
  const timeTrackerSessions = useMemo<TimeTrackerSession[]>(() => {
    return userTimeTrackerInfo.time_tracker_sessions;
  }, [userTimeTrackerInfo]);

  return (
    <LoadableContainer isLoading={timeTrackerLoading} loadComponent={<SplashScreen />}>
      <div style={{ ...MIDDLE_STYLE, flexWrap: 'wrap' }}>
        {timeTrackerSessions.map(timeTrackerSession => (
          <TimeTrackerSessionCard
            key={timeTrackerSession.id}
            style={{ margin: 10 }}
            session={timeTrackerSession}
          />
        ))}
      </div>
    </LoadableContainer>
  );
};

export default TimeTrackerAllSessionScreen;
