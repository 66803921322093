import React from 'react';
import { useHistory } from 'react-router';

import { Empty } from 'antd';

import { GENERAL_EMPTY_IMAGE, MIDDLE_STYLE } from '@/constants';
import { Workspace } from '@/gql/graphql';

import AnimatedComponent from '../animated-component';
import WorkspaceCard from '../workspace-card';

type Props = { workspaces: Workspace[] };

const WorkspaceList = ({ workspaces }: Props) => {
  const history = useHistory();
  const sortedWorkspaces = workspaces.sort((wA, wB) => wB.updated_date - wA.updated_date);
  return (
    <React.Fragment>
      {sortedWorkspaces.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {sortedWorkspaces.map(workspace => (
            <div style={{ marginRight: 15, marginBottom: 15 }}>
              {
                <AnimatedComponent.OpacityFadeInDiv delay={150}>
                  <WorkspaceCard
                    key={workspace.id}
                    workspace={workspace}
                    onClick={() => history.push(`/workspace/${workspace.slug}`)}
                  />
                </AnimatedComponent.OpacityFadeInDiv>
              }
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            ...MIDDLE_STYLE,
            width: '100%',
            height: '60vh',
          }}>
          <Empty
            image={GENERAL_EMPTY_IMAGE}
            imageStyle={{ height: 60 }}
            description={<span>No workspaces</span>}></Empty>
        </div>
      )}
    </React.Fragment>
  );
};

export default WorkspaceList;
