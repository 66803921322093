/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Repository } from '@/gql/graphql';
import {
  useAuthStore,
  useRepositoryStore,
  useRepositoryTabStore,
  useWorkspaceStore,
} from '@/stores';

export const usePinnedAssets = () => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(true);
  const { isRefreshing } = useWorkspaceStore();
  const { fetchRepositoryById, setPinnedRepositories, pinnedRepositories } = useRepositoryStore();
  const { getLocalPinnedTabs, pinnedTabs } = useRepositoryTabStore();
  const [pinnedRefreshing, setPinnedRefreshing] = useState(+new Date());

  const handleRefresh = () => {
    setPinnedRefreshing(+new Date());
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await getLocalPinnedTabs();
      const repositories: Repository[] = [];
      try {
        for (const repositoryId of user?.pinned_repositories || []) {
          const repository = await fetchRepositoryById(undefined, repositoryId);
          if (!repository) continue;
          repositories.push(repository);
        }
      } catch (error) {
        console.log(error);
      }
      setPinnedRepositories(repositories);
      setLoading(false);
    };
    init();
  }, [isRefreshing, pinnedRefreshing]);

  return {
    loading,
    setLoading,
    handleRefresh,
    pinnedRefreshing,
    repositories: pinnedRepositories,
    tabs: pinnedTabs,
  };
};
