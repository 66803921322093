/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Avatar, Tooltip } from 'antd';

import { User } from '@/gql/graphql';
import { useAuthStore } from '@/stores';

import LoadableContainer from '../loadable-container';

type Props = {
  members: string[];
  compacted?: number;
};

const MemberAvatarsGroup = ({ members, compacted }: Props) => {
  const history = useHistory();
  const { fetchUserById } = useAuthStore();
  const [users, setUsers] = useState<Partial<User>[]>([]);

  const handleViewUserProfile = (username: string) => {
    history.push(`/user/${username}`);
  };

  useEffect(() => {
    const init = async () => {
      const fetchUsers: Partial<User>[] = [];
      for (const member of members) {
        const user = await fetchUserById(member);
        if (user) {
          fetchUsers.push(user);
        }
      }
      setUsers(fetchUsers);
    };
    init();
  }, [members]);
  return (
    <LoadableContainer isLoading={members.length === 0} loadComponent={<></>}>
      <React.Fragment>
        <Avatar.Group
          maxCount={compacted || members.length}
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {users.map(user => (
            <Tooltip title={`<${user.username}> ${user.email}`} placement="top">
              <Avatar
                style={{ cursor: 'pointer' }}
                onClick={() => handleViewUserProfile(user.username || '')}
                src={user.profile_image}
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      </React.Fragment>
    </LoadableContainer>
  );
};

export default MemberAvatarsGroup;
