/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { Content } from 'antd/es/layout/layout';

import { Layout } from 'antd';

import { TimeMachineProvider, TimeTrackerAppBar } from '@/components';
import { GLOBAL_THEME_COLOR } from '@/constants';
import { useLoadTimeTrackerInfo } from '@/hooks';
import { TimeTrackerAppRoutes } from '@/models';
import { useAuthStore, useTimeTrackerStore } from '@/stores';
import { getCurrentSessionKey, getTimeTrackerSessionTasks } from '@/utils/time-tracker.util';

import TimeTrackerActiveSessionScreen from './TimeTrackerActiveSessionScreen';
import TimeTrackerAllSessionScreen from './TimeTrackerAllSessionScreen';
import TimeTrackerGoalSettingScreen from './TimeTrackerGoalSettingScreen';
import TimeTrackerSettingScreen from './TimeTrackerSettingScreen';

type Props = any;

const TimeTrackerScreen = (props: Props) => {
  useLoadTimeTrackerInfo();
  const { user } = useAuthStore();
  const { userTimeTrackerInfo } = useTimeTrackerStore();
  const storedTimeTrackerSessions = useMemo(() => {
    if (user && userTimeTrackerInfo.time_tracker_setting) {
      const key = getCurrentSessionKey(userTimeTrackerInfo.time_tracker_setting);
      return getTimeTrackerSessionTasks(user.username, key);
    }
    return [];
  }, [user, userTimeTrackerInfo]);

  const { tabLensTabName } = useParams<{
    tabLensTabName: TimeTrackerAppRoutes;
  }>();

  const screens: Record<TimeTrackerAppRoutes, React.ReactNode> = {
    landing: <TimeTrackerGoalSettingScreen />,
    active: <TimeTrackerActiveSessionScreen />,
    sessions: <TimeTrackerAllSessionScreen />,
    settings: <TimeTrackerSettingScreen />,
  };

  return (
    <TimeMachineProvider defaultTrackedTabs={storedTimeTrackerSessions}>
      <React.Fragment>
        <Layout>
          <TimeTrackerAppBar />
          <Content
            style={{
              padding: '20px 30px',
              background: GLOBAL_THEME_COLOR.$secondary_color,
              paddingBottom: 100,
            }}>
            {screens[tabLensTabName]}
          </Content>
        </Layout>
      </React.Fragment>
    </TimeMachineProvider>
  );
};

export default TimeTrackerScreen;
