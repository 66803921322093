import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { Button, Col, Input, Row } from 'antd';
import { useFormik } from 'formik';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { UpdateUserArgs, updateUserInfoValidationSchema } from '@/forms';
import { getUserByUsernameQuery, updateUserMutation } from '@/graphql';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore, useSnackbarStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import SplashScreen from '../splash-screen';

type Props = any;

const UserGeneralSetting = (props: Props) => {
  const history = useHistory();
  const { enqueueNotification } = useSnackbarStore();
  const { user, setUser, fetchCurrentUser } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const formik = useFormik<UpdateUserArgs>({
    validationSchema: updateUserInfoValidationSchema,
    onSubmit: values => handleUpdateData(values),
    initialValues: {
      username: user?.username || '',
      firstName: user?.full_name?.split(' ')[0] || '',
      lastName: user?.full_name?.split(' ')[1] || '',
      profile_image: user?.profile_image || '',
    },
  });

  const fullNameValue = useMemo(
    () => `${formik.values.firstName} ${formik.values.lastName}`,
    [formik.values.firstName, formik.values.lastName]
  );

  const handleUpdateData = async (values: UpdateUserArgs) => {
    setLoading(true);
    try {
      if (user?.username !== values.username) {
        const existingUserByUsername = await apolloClient.query({
          query: getUserByUsernameQuery,
          variables: {
            username: values.username,
          },
        });
        if (existingUserByUsername) throw new Error('Username is taken already');
      }
      await apolloClient.mutate({
        mutation: updateUserMutation,
        variables: {
          ...values,
          full_name: fullNameValue,
        },
      });
      const currentUser = await fetchCurrentUser();
      setUser(currentUser);
      history.push(`/user/${currentUser?.username}`);
    } catch (error: any) {
      await enqueueNotification({
        name: 'Failed to update user',
        description: error.message.toString(),
        type: 'Error',
      });
    }
    setLoading(false);
  };
  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <div style={{ maxWidth: '400px' }}>
        <Row gutter={20}>
          <Col span={12}>
            <h4>First name</h4>
            <Input
              status={formik.errors.firstName ? 'error' : ''}
              value={formik.values.firstName}
              onChange={e => formik.setFieldValue('firstName', e.target.value)}
              placeholder="Enter fist name"
            />
            {formik.errors.firstName && (
              <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
                {formik.errors.firstName}
              </p>
            )}
          </Col>
          <Col span={12}>
            <h4>Last name</h4>
            <Input
              status={formik.errors.lastName ? 'error' : ''}
              value={formik.values.lastName}
              onChange={e => formik.setFieldValue('lastName', e.target.value)}
              placeholder="Enter last name"
            />
            {formik.errors.lastName && (
              <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
                {formik.errors.lastName}
              </p>
            )}
          </Col>
        </Row>
        <h4>Username</h4>
        <Input
          status={formik.errors.username ? 'error' : ''}
          value={formik.values.username}
          onChange={e => formik.setFieldValue('username', e.target.value)}
          placeholder="Enter username"
        />
        {formik.errors.username && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>{formik.errors.username}</p>
        )}
        <Button
          style={{ width: '100%', marginTop: 20 }}
          type="primary"
          onClick={() => formik.handleSubmit()}
          disabled={formik.values.username === user?.username && fullNameValue === user?.full_name}>
          Update
        </Button>
      </div>
    </LoadableContainer>
  );
};

export default UserGeneralSetting;
