import React from 'react';

import { ResponsivePie } from '@nivo/pie';

import { TimelessTabChartData } from '@/models';

import TimelessTabsPieTooltip from '../timeless-tabs-pie-tooltip';

type Props = {
  data: TimelessTabChartData[];
  children: React.ReactNode | React.ReactNode[];
};

const margin = { top: 40, right: 40, bottom: 40, left: 40 };

const styles: Record<string, React.CSSProperties> = {
  root: {
    fontFamily: 'consolas, sans-serif',
    textAlign: 'center',
    position: 'relative',
    width: 300,
    height: 280,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: margin.right,
    bottom: 0,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    pointerEvents: 'none',
  },
};

const TimelessTabsPieChart = ({ data, children }: Props) => {
  return (
    <div style={styles.root}>
      <ResponsivePie
        enableArcLabels={false}
        enableArcLinkLabels={false}
        data={data}
        margin={margin}
        innerRadius={0.8}
        padAngle={1.3}
        colors={data.map(item => item.color)}
        cornerRadius={3}
        tooltip={({ datum }) => (
          <TimelessTabsPieTooltip timestamp={datum.data.value} url={datum.data.id} />
        )}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
      <div style={styles.overlay}>{children}</div>
    </div>
  );
};

export default TimelessTabsPieChart;
