import moment from 'moment';

import { TimeTrackItem, TimeTrackSheet } from '@/models';

export function sortTrackTimeSheetByValue(obj: TimeTrackSheet, order: 'asc' | 'desc') {
  // Convert the object to an array of key-value pairs
  const entries = Object.entries(obj);
  // Sort the array based on the values
  entries.sort((a: [string, TimeTrackItem], b: [string, TimeTrackItem]) =>
    order === 'asc' ? a[1].value - b[1].value : b[1].value - a[1].value
  );
  // Extract the sorted keys
  const sortedKeys = entries.map(entry => entry[0]);
  return sortedKeys;
}

export const getMinutes = (minutes: number) => {
  return minutes * 60;
};

export const getSecondTimestamp = () => {
  return moment().valueOf() / 1000;
};

export const millisecondToSecond = (timestamp: number) => {
  return timestamp / 1000;
};
