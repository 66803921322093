import React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks, CustomMode } from 'react-compound-slider';

import { scaleTime } from 'd3-scale';
import {
  format,
  addHours,
  startOfToday,
  endOfToday,
  differenceInMilliseconds,
  isBefore,
  isAfter,
  set,
  addMinutes,
} from 'date-fns';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { makeid, voidCallback } from '@/utils';

import TimelineHandle from './TimelineHandle';
import TimelineSliderRail from './TimelineSliderRail';
import TimelineTimeTick from './TimelineTimeTick';
import TimelineTimeTrack from './TimelineTimeTrack';
import './index.scss';

type TimeRange = [Date, Date];

type TimelineRangeSliderProps = {
  selectedEnable?: boolean;
  selectedInterval?: TimeRange;
  intervals: { range: TimeRange; color: string }[];
  timelineInterval: TimeRange;
  formatTick?: (ms: number) => string;
  step?: number;
  ticksNumber?: number;
  error?: boolean;
  mode?: 2 | 1 | 3 | CustomMode | undefined;
  sliderRailClassName?: string;
  containerClassName?: string;
  showNow?;
  onChangeCallback?: any;
  onUpdateCallback?: any;
};

const getTimelineConfig = (timelineStart, timelineLength) => date => {
  const percent = (differenceInMilliseconds(date, timelineStart) / timelineLength) * 100;
  const value = Number(format(date, 'T'));
  return { id: makeid(10), percent, value };
};

const getFormattedBlockedIntervals = (
  blockedDates: { range: TimeRange; color: string }[] = [],
  [startTime, endTime]
) => {
  if (!blockedDates.length) return null;
  const timelineLength = differenceInMilliseconds(endTime, startTime);
  const getConfig = getTimelineConfig(startTime, timelineLength);
  const formattedBlockedDates = blockedDates.map((interval, index) => {
    let [start, end] = interval.range;

    if (isBefore(start, startTime)) start = startTime;
    if (isAfter(end, endTime)) end = endTime;

    const source = getConfig(start);
    const target = getConfig(end);

    return { id: `blocked-track-${index}`, source, target, color: interval.color };
  });
  return formattedBlockedDates;
};

const getNowConfig = ([startTime, endTime]) => {
  const timelineLength = differenceInMilliseconds(endTime, startTime);
  const getConfig = getTimelineConfig(startTime, timelineLength);

  const source = getConfig(new Date());
  const target = getConfig(addMinutes(new Date(), 1));

  return { id: 'now-track', source, target };
};

const TimelessTimelineRangeSlider = ({
  selectedEnable,
  selectedInterval = [
    set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }),
    set(addHours(new Date(), 2), { minutes: 0, seconds: 0, milliseconds: 0 }),
  ],
  intervals = [],
  timelineInterval = [startOfToday(), endOfToday()],
  formatTick = ms => format(new Date(ms), 'HH:mm'),
  step = 1000 * 60 * 30,
  ticksNumber = 48,
  error = false,
  mode = 3,
  sliderRailClassName = '',
  containerClassName = '',
  showNow,
  onChangeCallback = voidCallback,
  onUpdateCallback = voidCallback,
}: TimelineRangeSliderProps) => {
  const getIntervals = () => {
    return getFormattedBlockedIntervals(intervals, timelineInterval);
  };

  const _intervals = getIntervals();

  const getNow = () => {
    return getNowConfig(timelineInterval);
  };

  const onChange = newTime => {
    const formattedNewTime = newTime.map(t => new Date(t));
    onChangeCallback(formattedNewTime);
  };

  const onUpdate = newTime => {
    const formattedNewTime = newTime.map(t => new Date(t));
    onUpdateCallback({ error: false, time: formattedNewTime });
  };

  const getDateTicks = () => {
    return scaleTime()
      .domain(timelineInterval)
      .ticks(ticksNumber)
      .map(t => +t);
  };

  const domain = timelineInterval.map(t => Number(t));

  return (
    <div className={containerClassName || 'react_time_range__time_range_container'}>
      <Slider
        mode={mode}
        step={step}
        domain={domain}
        onUpdate={onUpdate}
        onChange={onChange}
        values={selectedInterval.map(t => +t)}
        rootStyle={{ position: 'relative', width: '100%' }}>
        <Rail>
          {({ getRailProps }) => (
            <TimelineSliderRail className={sliderRailClassName} getRailProps={getRailProps} />
          )}
        </Rail>
        {selectedEnable && (
          <Handles>
            {({ handles, getHandleProps }) => (
              <React.Fragment>
                {handles.map(handle => (
                  <TimelineHandle
                    error={error}
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </React.Fragment>
            )}
          </Handles>
        )}
        {selectedEnable && (
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <React.Fragment>
                {tracks?.map(({ id, source, target }) => (
                  <TimelineTimeTrack
                    error={error}
                    key={id}
                    source={source}
                    target={target}
                    color={GLOBAL_THEME_COLOR.$light_highlight_color}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </React.Fragment>
            )}
          </Tracks>
        )}
        {_intervals?.length && (
          <Tracks left={false} right={false}>
            {({ getTrackProps }) => (
              <React.Fragment>
                {_intervals.map(({ id, source, target, color }) => (
                  <TimelineTimeTrack
                    key={id}
                    source={source}
                    target={target}
                    color={color}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </React.Fragment>
            )}
          </Tracks>
        )}
        {showNow && (
          <Tracks left={false} right={false}>
            {({ getTrackProps }) => (
              <TimelineTimeTrack
                key={getNow()?.id}
                source={getNow()?.source}
                target={getNow()?.target}
                color={GLOBAL_THEME_COLOR.$success_color}
                getTrackProps={getTrackProps}
              />
            )}
          </Tracks>
        )}
        <Ticks values={getDateTicks()}>
          {({ ticks }) => (
            <>
              {ticks.map(tick => (
                <TimelineTimeTick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  format={formatTick}
                />
              ))}
            </>
          )}
        </Ticks>
      </Slider>
    </div>
  );
};

export default TimelessTimelineRangeSlider;
