import { useEffect, useState } from 'react';

import { queryOpenGraphMetadata } from '@/graphql';
import { OpengraphMetadataType } from '@/models';
import { apolloClient } from '@/services/ApolloService';

export const useOpengraphMetadata = (url: string | undefined) => {
  const [loading, setLoading] = useState(false);
  const [ogMetadata, setOgMetadata] = useState<OpengraphMetadataType | undefined>(undefined);

  useEffect(() => {
    const initOpengraph = async () => {
      if (!url) return;
      setLoading(true);
      try {
        const { data } = await apolloClient.query({
          query: queryOpenGraphMetadata,
          fetchPolicy: 'cache-first',
          variables: {
            websiteUrl: url,
          },
        });
        const openGraphMetadata = JSON.parse(data.queryOpenGraphMetadata.message);
        if (openGraphMetadata) {
          setOgMetadata({
            ogBanner: openGraphMetadata?.ogImage?.[0]?.url || undefined,
            ogTitle: openGraphMetadata.ogTitle || '',
            ogDescription: openGraphMetadata.ogDescription || '',
            ogType: openGraphMetadata.ogType || '',
            ogUrl: openGraphMetadata.ogUrl || '',
          });
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    initOpengraph();
  }, [url]);
  return {
    ogMetadata,
    ogLoading: loading,
  };
};
