import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

import { AppEnvironment } from '@/constants/env';

const firebaseConfig = {
  apiKey: AppEnvironment.REACT_APP_FIREBASE_API_KEY,
  authDomain: AppEnvironment.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: AppEnvironment.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: AppEnvironment.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: AppEnvironment.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: AppEnvironment.REACT_APP_FIREBASE_APP_ID,
  measurementId: AppEnvironment.REACT_APP_FIREBASE_MEASUREMENT,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firebaseDB = getDatabase(app);
export const auth = getAuth(app);
