/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Content } from 'antd/es/layout/layout';

import { Button, ButtonProps, Layout, Tooltip } from 'antd';

import { IconContainer, LoadableContainer, SplashScreen } from '@/components';
import { GLOBAL_THEME_COLOR } from '@/constants';
import { RepositoryContextType, useBreakpoint } from '@/hooks';
import { AppLayoutSkeleton, EditorActionTemplate } from '@/typings';

const RenderEditorActionTooltip = (props: { tooltip?: string; children }) => {
  return props.tooltip ? (
    <Tooltip title={props.tooltip}>{props.children}</Tooltip>
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );
};
const RenderEditorActionContent = (props: { iconTitle?: string; icon: React.ReactNode }) => {
  return props.iconTitle ? (
    <div style={{ display: 'flex' }}>
      <IconContainer icon={props.icon} style={{ marginRight: 10 }} />
      <span>{props.iconTitle}</span>
    </div>
  ) : (
    <IconContainer icon={props.icon} />
  );
};

export const BuildAppEditorButton = (props: {
  action: EditorActionTemplate;
  props?: ButtonProps;
}) => {
  const { isMobile } = useBreakpoint();
  return (
    <React.Fragment>
      {!props.action.hidden && (
        <RenderEditorActionTooltip tooltip={props.action.tooltipTitle}>
          <Button {...(props.props || {})} onClick={props.action.actionHandler}>
            <RenderEditorActionContent
              icon={props.action.icon}
              iconTitle={isMobile ? '' : props.action.title}
            />
          </Button>
        </RenderEditorActionTooltip>
      )}
    </React.Fragment>
  );
};

export const RenderAppExtensionLayoutBuilder = ({
  values,
  appBar,
  content,
}: AppLayoutSkeleton & { values: RepositoryContextType }) => {
  const { isMobile } = useBreakpoint();
  return (
    <LoadableContainer isLoading={values.loading} loadComponent={<SplashScreen />}>
      <Layout style={{ minHeight: '100%' }}>
        <Layout.Header
          style={{
            padding: '0px 20px',
            height: 'fit-content',
            borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          }}>
          {appBar}
        </Layout.Header>
        <Layout>
          <Content
            style={{
              background: GLOBAL_THEME_COLOR.$secondary_color,
            }}>
            <div
              style={
                isMobile
                  ? {
                      padding: '0px 10px',
                    }
                  : {
                      padding: '10px 30px',
                    }
              }>
              {content}
            </div>
          </Content>
        </Layout>
      </Layout>
    </LoadableContainer>
  );
};
