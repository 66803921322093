import gql from 'graphql-tag';

import { graphql } from '@/gql';

export const getCurrentUserQuery = gql`
  query {
    getCurrentUser {
      ...CORE_USER_FIELDS_FRAGMENT
      ...CORE_USER_WORKSPACE_FRAGMENT
    }
  }
`;

export const getCurrentUserTimeTrackerQuery = gql`
  query {
    getCurrentUser {
      ...USER_TIME_TRACKER_SETTING_FRAGMENT
    }
  }
`;

export const getUserByIdQuery = graphql(`
  query GetUserById($id: String!) {
    getUserById(getUserByIdArgs: { id: $id }) {
      ...CORE_USER_FIELDS_FRAGMENT
      ...CORE_USER_WORKSPACE_FRAGMENT
    }
  }
`);

export const getUserByEmailQuery = graphql(`
  query GetUserByEmail($email: String!) {
    getUserByEmail(getUserByEmailArgs: { email: $email }) {
      ...CORE_USER_FIELDS_FRAGMENT
      ...CORE_USER_WORKSPACE_FRAGMENT
    }
  }
`);

export const getUserByUsernameQuery = graphql(`
  query GetUserByUsername($username: String!) {
    getUserByUsername(getUserByUsernameArgs: { username: $username }) {
      ...CORE_USER_FIELDS_FRAGMENT
      ...CORE_USER_WORKSPACE_FRAGMENT
    }
  }
`);

export const getAllUsersQuery = graphql(`
  query GetAllUsers {
    getAllUsers {
      ...CORE_USER_FIELDS_FRAGMENT
      ...CORE_USER_WORKSPACE_FRAGMENT
    }
  }
`);
