import React from 'react';

import SelectionArea from '@viselect/react';

type Props = {
  children: React.ReactNode;
  selectables: string;
  handleOnStart: any;
  handleOnMove: any;
  disabled?: boolean;
};

const DragToSelectContainer = ({
  children,
  selectables,
  disabled,
  handleOnMove,
  handleOnStart,
}: Props) => {
  return (
    <React.Fragment>
      {disabled ? (
        children
      ) : (
        <SelectionArea selectables={selectables} onStart={handleOnStart} onMove={handleOnMove}>
          {children}
        </SelectionArea>
      )}
    </React.Fragment>
  );
};

export default DragToSelectContainer;
