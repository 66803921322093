/* eslint-disable no-constant-condition */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { Button, Divider, Drawer, Progress, Space, Tooltip } from 'antd';
import * as uuid from 'uuid';

import DraggableTreeNodeList from '@/components/draggable-tree-node-list';
import LoadableContainer from '@/components/loadable-container';
import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { Directory, TabWithCategory } from '@/gql/graphql';
import { smartTabGroupingMutation } from '@/graphql';
import { useBreakpoint, useRepositoryContext, useRepositoryTabActions } from '@/hooks';
import { TabTreeDirectory, TreeNode, UnionTab } from '@/models';
import { apolloClient } from '@/services/ApolloService';
import { useDrawerStore, useSnackbarStore } from '@/stores';
import { buildTree, reorder, shortenString, tabTreeWalk, voidCallback } from '@/utils';

type Props = any;

const CHUNK_SIZE = 10;

enum SmartGroupingEngineState {
  InProgress,
  Aborted,
  Idle,
}

export type OnTabsGroupedHandler = (groupedTabs: TreeNode[]) => void;

const SmartTabGroupingDrawer = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const { treeNodes, setTreeNodes, selectedTreeNodes, setSelectedTreeNodes } =
    useRepositoryContext();
  const { drawerName, closeDrawer, extraParams } = useDrawerStore();
  const tabs = useMemo<UnionTab[]>(() => extraParams?.tabs || [], [extraParams]);
  const { enqueueNotification } = useSnackbarStore();
  const onTabsGrouped: OnTabsGroupedHandler = useMemo<OnTabsGroupedHandler>(
    () => extraParams?.onTabsGrouped || [],
    [extraParams]
  );
  const { handleSelectTab } = useRepositoryTabActions();
  const isSelected = useMemo(() => drawerName === 'smartTabGrouping', [drawerName]);
  const [engineState, setEngineState] = useState<SmartGroupingEngineState>(
    SmartGroupingEngineState.Idle
  );
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [totalProgress, setTotalProgress] = useState<number>(0);
  const [previewDirectories, setPreviewDirectories] = useState<Directory[]>([]);
  const [previewTabs, setPreviewTabs] = useState<UnionTab[]>([]);

  const previewTreeNodes = useMemo<TreeNode[]>(() => {
    return buildTree(previewTabs, previewDirectories);
  }, [previewDirectories, previewTabs]);

  /** Function call to start grouping */
  const startGrouping = async () => {
    setEngineState(SmartGroupingEngineState.InProgress);
    const convertTabs: { url: string; title: string }[] = [];
    /** Map the tab with its url to retrieve later */
    tabTreeWalk({
      treeNodes,
      handleTabItem(item) {
        if (item.url) {
          convertTabs.push({
            url: shortenString(item.url, 100),
            title: shortenString(item.title?.split('-')[0] || '', 100),
          });
        }
      },
    });
    const totalSize = convertTabs.length;
    if (totalSize === 0) return;
    try {
      setTotalProgress(totalSize);
      /** Call API to smart grouping */
      const mappedTabs: Record<string, UnionTab[]> = {};
      const visitedTabs: Record<string, boolean> = {};
      let continuousGroups: string[] = [];
      let startIndex = 0;
      while (true) {
        if (engineState === SmartGroupingEngineState.Aborted)
          throw new Error('Aborted the process');
        /** Paginated smart grouping tabs */
        const [batchStartIndex, batchEndIndex] = [
          startIndex * CHUNK_SIZE,
          (startIndex + 1) * CHUNK_SIZE,
        ];
        const tabChunks = tabs.slice(batchStartIndex, batchEndIndex);
        const convertedTabChunks = convertTabs.slice(batchStartIndex, batchEndIndex);
        const { data } = await apolloClient.mutate({
          mutation: smartTabGroupingMutation,
          variables: {
            tabs: convertedTabChunks,
            group: continuousGroups,
          },
        });
        const tabsWithCategory: TabWithCategory[] = data.smartTabGrouping;
        continuousGroups = continuousGroups.concat(tabsWithCategory.map(item => item.category));

        /** Map tabs with category */
        for (const tabWithCategory of tabsWithCategory) {
          const lambdaCompareURL = (tab: UnionTab) => {
            return tab.url?.includes(tabWithCategory.url.replace('...', ''));
          };
          const _tabItem = tabChunks.find(lambdaCompareURL);
          if (!_tabItem) continue;
          const category = tabWithCategory.category;
          if (category in mappedTabs) {
            mappedTabs[category] = mappedTabs[category].concat([_tabItem]);
          } else {
            mappedTabs[category] = [_tabItem];
          }
          visitedTabs[_tabItem.url || ''] = true;
        }

        /** Convert category map into directory */
        const _directories: Directory[] = [];
        let _tabs: UnionTab[] = [];
        for (const key of Object.keys(mappedTabs)) {
          const directoryId = `directory-${uuid.v4()}`;
          _tabs = _tabs.concat(
            mappedTabs[key].map(item => ({
              ...item,
              parentDirectory: directoryId,
            }))
          );
          const directory: Directory = {
            color: GLOBAL_THEME_COLOR.$highlight_color,
            id: directoryId,
            name: key,
          };
          _directories.push(directory);
        }

        setCurrentProgress(progress => progress + tabChunks.length);
        setPreviewDirectories(_directories);
        setPreviewTabs(_tabs.concat(tabs.filter(tab => !visitedTabs[tab.url || ''])));
        if (batchEndIndex >= tabs.length) break;
        startIndex++;
      }
      await enqueueNotification({
        name: 'Smart grouping successfully',
        description: 'Finished smart grouping tabs',
        type: 'Success',
      });
    } catch (error: any) {
      console.log(error);
      handleDiscard();
      await enqueueNotification({
        name: 'Smart grouping failed',
        description: 'Error smart grouping tabs',
        type: 'Error',
      });
    }
    /** Reset smart grouping machine */
    setTotalProgress(0);
    setCurrentProgress(0);
    setEngineState(SmartGroupingEngineState.Idle);
  };

  const handleGroupTabs = async () => {
    try {
      const selectedPreviewTreeNodes = previewTreeNodes.filter(
        node => selectedTreeNodes[node.value.id || '']
      );
      await onTabsGrouped(
        selectedPreviewTreeNodes.length === 0 ? previewTreeNodes : selectedPreviewTreeNodes
      );
      handleDiscard();
      closeDrawer();
    } catch (error) {
      console.log(error);
      await enqueueNotification({
        name: 'Smart grouping failed',
        description: 'Error smart grouping tabs',
        type: 'Error',
      });
    }
  };

  const handleRetry = async () => {
    handleDiscard();
    await startGrouping();
  };

  const handleAbort = async () => {
    setEngineState(SmartGroupingEngineState.Aborted);
    handleDiscard();
  };

  const handleDiscard = () => {
    setSelectedTreeNodes({});
    setPreviewDirectories([]);
    setPreviewTabs([]);
  };

  const handleDirectoryClicked = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    directoryNode: TabTreeDirectory
  ) => {
    const treeNode: TreeNode = {
      type: 'directory',
      value: directoryNode,
    };
    handleSelectTab(treeNode);
  };

  const onDragEnd: OnDragEndResponder = async (result: any) => {
    const items: TreeNode[] = reorder(
      previewTreeNodes.length > 0 ? previewTreeNodes : treeNodes,
      result.source.index,
      result.destination.index
    );
    setTreeNodes(items);
  };

  const handleItemSelectedCondition = tab =>
    (tab && !!tab.id && !!selectedTreeNodes[tab.id]) || false;

  useEffect(() => {
    setTreeNodes(buildTree(tabs, []));
  }, [tabs]);

  return (
    <Drawer
      title="🦄 Smart Grouping"
      width={isMobile ? '100%' : 500}
      placement={'right'}
      onClose={closeDrawer}
      open={isSelected}
      extra={
        previewTreeNodes.length > 0 && (
          <Space>
            <Button onClick={handleDiscard}>Discard</Button>
            <Button type="primary" onClick={handleGroupTabs}>
              Group Tabs
            </Button>
          </Space>
        )
      }>
      <React.Fragment>
        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
          <Tooltip title="Click to start grouping">
            <Button
              onClick={startGrouping}
              disabled={previewTreeNodes.length > 0}
              style={{
                ...MIDDLE_STYLE,
                fontSize: 100,
                minWidth: '180px',
                minHeight: '180px',
                fontWeight: 'bold',
                borderRadius: '50px',
                background: '#ffffff',
                boxShadow: '23px 23px 46px #d9d9d9, -23px -23px 46px #ffffff',
              }}>
              <LoadableContainer
                isLoading={engineState === SmartGroupingEngineState.InProgress}
                loadComponent={
                  <div style={{ ...MIDDLE_STYLE }}>
                    <Progress
                      type="circle"
                      percent={Number(
                        parseFloat(((currentProgress / totalProgress) * 100).toString()).toFixed(2)
                      )}
                      strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                    />
                  </div>
                }>
                <React.Fragment>{previewTreeNodes.length > 0 ? '✅' : '🦄'}</React.Fragment>
              </LoadableContainer>
            </Button>
          </Tooltip>
          {previewTreeNodes.length > 0 ? (
            <React.Fragment>
              <h1 style={{ margin: '40px 0px 0px 0px' }}>
                Tabs are grouped into {previewTreeNodes.length} groups
              </h1>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Click "Group Tabs" button to apply the changes
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h1 style={{ margin: '40px 0px 0px 0px' }}>
                {engineState === SmartGroupingEngineState.InProgress
                  ? 'In progress...'
                  : 'Smart grouping'}
              </h1>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                TabHub uses AI to organize your tabs into group
              </p>
            </React.Fragment>
          )}
          {engineState === SmartGroupingEngineState.InProgress ? (
            <Button type="primary" className="btn-error" onClick={handleAbort}>
              Abort
            </Button>
          ) : (
            <Button type="primary" onClick={handleRetry}>
              Retry
            </Button>
          )}
        </div>
        <Divider />
        <React.Fragment>
          <DraggableTreeNodeList
            isForceMobile
            nodeOnContextMenu={voidCallback}
            treeNodes={previewTreeNodes.length > 0 ? previewTreeNodes : treeNodes}
            itemOnClickedEvent={voidCallback}
            directoryOnClickedEvent={handleDirectoryClicked}
            itemSelectedCondition={handleItemSelectedCondition}
            onItemDragEvent={onDragEnd}
          />
        </React.Fragment>
      </React.Fragment>
    </Drawer>
  );
};

export default SmartTabGroupingDrawer;
