import React, { useMemo, useState } from 'react';
import ReactLoading from 'react-loading';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Tooltip } from 'antd';
import axios from 'axios';

import { LoadableContainer } from '@/components';
import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { Repository, RepositoryTab } from '@/gql/graphql';
import { useModalStore, useRepositoryStore, useWorkspaceStore } from '@/stores';

import ReusableModalWrapper from '../ReusableModalWrapper';

type Props = any;

type NewTabValue = {
  icon: string;
  name: string;
  url: string;
};

const defaultNewTabValue: NewTabValue = {
  icon: '',
  name: '',
  url: '',
};

const AddRepositoryTabsModal = (props: Props) => {
  const { closeModal, extraParams } = useModalStore();
  const [loading, setLoading] = useState<boolean>(false);
  const repository = useMemo<Repository | undefined>(() => extraParams?.repository, [extraParams]);
  const { upsertTabsToRepository } = useWorkspaceStore();
  const { setRefreshing } = useRepositoryStore();
  const [tabItems, setTabItems] = useState<NewTabValue[]>([defaultNewTabValue]);

  const handleSubmit = async () => {
    if (!repository) return;
    setLoading(true);
    const repositoryTabs = tabItems.map<Partial<RepositoryTab>>(item => ({
      favIconUrl: item.icon,
      customName: item.name,
      url: item.url,
      title: item.name,
    }));
    /** Don't have to cover the case of repository directories */
    await upsertTabsToRepository(
      repository.workspaceId,
      repository.icon,
      repository.name,
      repositoryTabs as any,
      repository.visibility,
      repository.description as any
    );
    setRefreshing();
    setLoading(false);
    closeModal();
  };

  const handleAddMoreItem = () => {
    setTabItems(tabItems => (tabItems = tabItems.concat([defaultNewTabValue])));
  };

  const handleFieldChanged = async (index: number, field: keyof NewTabValue, value: string) => {
    let icon = tabItems[index].url;
    if (field === 'url') {
      try {
        const response = await axios.get(
          `https://www.google.com/s2/favicons?domain=${value}&sz=128`
        );
        icon = response.data;
      } catch (error: any) {
        console.log(error);
      }
    }
    const _items: NewTabValue[] = [];
    for (let indx = 0; indx < tabItems.length; indx++) {
      if (indx === index) {
        _items.push({
          ...tabItems[indx],
          icon: icon,
          [field]: value,
        });
      } else {
        _items.push(tabItems[indx]);
      }
    }
    setTabItems(_items);
  };

  const handleRemoveItem = (index: number) => {
    const _items: NewTabValue[] = [];
    for (let indx = 0; indx < tabItems.length; indx++) {
      if (indx !== index) {
        _items.push(tabItems[indx]);
      }
    }
    setTabItems(_items);
  };

  const disabled = useMemo(() => {
    for (const item of tabItems) {
      if (item.url === '') {
        return true;
      }
    }
    return false;
  }, [tabItems]);

  return (
    <ReusableModalWrapper
      width={600}
      title={<div>Add repository tabs</div>}
      modalName="addRepositoryTabs"
      okText="Add"
      cancelText="Cancel"
      okButtonDisabled={disabled}
      onCancel={closeModal}
      onOk={handleSubmit}>
      <LoadableContainer
        isLoading={loading}
        loadComponent={
          <div style={{ ...MIDDLE_STYLE }}>
            <ReactLoading
              type={'cylon'}
              color={GLOBAL_THEME_COLOR.$highlight_color}
              height={'10%'}
              width={'10%'}
            />
          </div>
        }>
        <div style={{ paddingTop: 20 }}>
          {tabItems.map((tabItem, index) => (
            <Input
              size="large"
              style={{ marginBottom: 10, backgroundColor: GLOBAL_THEME_COLOR.$background_color }}
              value={tabItem.url}
              onChange={e => handleFieldChanged(index, 'url', e.target.value)}
              placeholder="Enter tab url"
              suffix={
                index > 0 ? (
                  <Tooltip title="Remove item">
                    <DeleteOutlined
                      style={{ color: GLOBAL_THEME_COLOR.$love_color }}
                      onClick={() => handleRemoveItem(index)}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
          ))}
          <Divider />
          <Button type="primary" style={{ width: '100%' }} size="large" onClick={handleAddMoreItem}>
            <PlusOutlined />
            Add more tab
          </Button>
        </div>
      </LoadableContainer>
    </ReusableModalWrapper>
  );
};

export default AddRepositoryTabsModal;
