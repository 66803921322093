import React from 'react';

import { BRAND_LOGO_CONSTRAST, BRAND_NAME, GLOBAL_THEME_COLOR } from '@/constants';

type Props = any;

const AppLogoSection = (props: Props) => {
  return (
    <a
      href="https://www.tabhub.io"
      style={{
        margin: '10px 0px 20px 0px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        cursor: 'pointer',
      }}>
      <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 25, borderRadius: '5px' }} />
      <span
        style={{
          fontWeight: 'bold',
          fontSize: 17,
          marginLeft: 10,
          color: GLOBAL_THEME_COLOR.$text_color,
        }}>
        TabHub
      </span>
    </a>
  );
};

export default AppLogoSection;
