import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TreeNode } from '@/models';
import { getUpdatedCheck } from '@/utils';

export enum ViewMode {
  ColumnMode,
  TableMode,
  AnyMode,
  BeautifulListMode,
  CardMode,
}

type WindowId = string | number;

export const ALL_SESSION = '*';

export interface TabManagerStoreState {
  selectedSession: string;
  viewMode: ViewMode;
  multiverseTreeNodes: Record<WindowId, TreeNode[]>;
  selectedMultiverseTreeNodes: Record<WindowId, Record<string, boolean>>;
  switchSession: (session: string) => void;
  setViewMode: (viewMode: ViewMode) => void;
  setMultiverseTreeNodes: (windowId: WindowId, treeNodes: TreeNode[]) => void;
  selectMultiverseTreeNodes: (windowId: WindowId, treeNodes: TreeNode[]) => void;
  deselectMultiverseTreeNodes: (windowId: WindowId, treeNodes: TreeNode[]) => void;
  deselectAllTreeNodes: (windowId: WindowId) => void;
}

export const useTabManagerStore = create<TabManagerStoreState>()(
  devtools(set => ({
    viewMode: ViewMode.TableMode,
    selectedSession: ALL_SESSION,
    switchSession(session) {
      set(state => ({ ...state, selectedSession: session }));
    },
    selectedMultiverseTreeNodes: {},
    multiverseTreeNodes: {},
    setViewMode: (viewMode: ViewMode) => {
      set(state => ({ ...state, viewMode: viewMode }));
    },

    setMultiverseTreeNodes: (windowId: WindowId, treeNodes: TreeNode[]) => {
      set(state => ({
        ...state,
        multiverseTreeNodes: {
          ...state.multiverseTreeNodes,
          [windowId]: treeNodes,
        },
      }));
    },
    selectMultiverseTreeNodes: (windowId: WindowId, treeNodes: TreeNode[]) => {
      set(state => ({
        ...state,
        selectedMultiverseTreeNodes: {
          ...state.selectedMultiverseTreeNodes,
          [windowId]: {
            ...state.selectedMultiverseTreeNodes[windowId],
            ...getUpdatedCheck(treeNodes, true),
          },
        },
      }));
    },
    deselectMultiverseTreeNodes: (windowId: WindowId, treeNode: TreeNode[]) => {
      set(state => {
        return {
          ...state,
          selectedMultiverseTreeNodes: {
            ...state.selectedMultiverseTreeNodes,
            [windowId]: {
              ...state.selectedMultiverseTreeNodes[windowId],
              ...getUpdatedCheck(treeNode, false),
            },
          },
        };
      });
    },
    deselectAllTreeNodes: (windowId: WindowId) => {
      set(state => ({
        ...state,
        selectedMultiverseTreeNodes: {
          ...state.selectedMultiverseTreeNodes,
          [windowId]: {},
        },
      }));
    },
  }))
);
