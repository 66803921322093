import { useHistory } from 'react-router';

import { AppRoutes } from '@/utils';

export const useAppNavigation = () => {
  const history = useHistory();
  const navigate = (route: AppRoutes) => {
    history.push(`/${route}`);
  };

  return { navigate };
};
