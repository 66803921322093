/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Row } from 'antd';

import { Repository } from '@/gql/graphql';
import { useRepositoryStore, useWorkspaceStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import RepositoryList from '../repository-list';

type Props = {
  workspaceId: string | undefined;
};

const WorkspaceRepositoriesTab = ({ workspaceId }: Props) => {
  const { isRefreshing } = useWorkspaceStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fetchRepositories } = useRepositoryStore();
  const [repositories, setRepositories] = useState<Repository[]>([]);

  useEffect(() => {
    const initWorkspace = async () => {
      if (workspaceId) {
        setIsLoading(true);
        const fetchedRepositories = await fetchRepositories(workspaceId);
        setRepositories(fetchedRepositories);
        setIsLoading(false);
      }
    };
    initWorkspace();
  }, [isRefreshing, workspaceId]);
  return (
    <LoadableContainer isLoading={isLoading} loadComponent={<LoadingOutlined />}>
      <Row gutter={24}>
        <RepositoryList repositories={repositories} />
      </Row>
    </LoadableContainer>
  );
};

export default WorkspaceRepositoriesTab;
