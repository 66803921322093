/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import MDEditor from '@uiw/react-md-editor';
import { Button, Space } from 'antd';

import { Repository } from '@/gql/graphql';
import { getRepositoryReadmeQuery, updateRepositoryReadmeMutation } from '@/graphql';
import { usePersonal, useRepositoryContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { useSnackbarStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import SplashScreen from '../splash-screen';

type Props = any;

const RepositoryReadmeEditorContent = (props: Props) => {
  const { enqueueNotification } = useSnackbarStore();
  const { isWorkspaceAdmin } = usePersonal();
  const { repository, workspace } = useRepositoryContext();
  const [originalContent, setOriginalContent] = useState('');
  const [readmeContent, setReadmeContent] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEditorContentChange = (value: string | undefined) => {
    setReadmeContent(value || '');
  };

  const handleSaveChanges = async () => {
    if (!repository) return;
    setLoading(true);
    try {
      await apolloClient.mutate({
        mutation: updateRepositoryReadmeMutation,
        variables: {
          repositoryId: repository.id,
          readmeData: readmeContent,
        },
      });
      await handleFetchReadme(repository);
      await enqueueNotification({
        name: 'Updated repository info',
        description: 'Successfully updating repository info',
        type: 'Success',
      });
    } catch (error) {
      console.log(error);
      await enqueueNotification({
        name: 'Failed to update repository info',
        description: 'Error updating repository info',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const handleDiscardChanges = () => {
    setReadmeContent(originalContent);
  };

  const handleFetchReadme = async (repository: Repository) => {
    const { data } = await apolloClient.query({
      query: getRepositoryReadmeQuery,
      fetchPolicy: 'network-only',
      variables: {
        repositoryId: repository.id,
      },
    });
    const readmeContent = data.getReadmeContent;
    return readmeContent;
  };

  const handleRefresh = async () => {
    if (!repository) return;
    try {
      if (repository.readme) {
        const _readmeContent = await handleFetchReadme(repository);
        setReadmeContent(_readmeContent);
        setOriginalContent(_readmeContent);
      } else {
        setReadmeContent('');
        setOriginalContent('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const initContent = async () => {
      if (!repository) return;
      setLoading(true);
      await handleRefresh();
      setLoading(false);
    };
    initContent();
  }, [repository]);

  const hasEdittingPermission = isWorkspaceAdmin(workspace);

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Page</h3>
          {!hasEdittingPermission && <p>⚠️ You don't have permission to update the repository</p>}
          <Space style={{ marginBottom: 20, marginTop: 10 }}>
            <Button
              type="primary"
              className="btn-error"
              disabled={readmeContent === originalContent}
              onClick={handleDiscardChanges}>
              Discard
            </Button>
            <Button
              type="primary"
              className="btn-success"
              disabled={readmeContent === originalContent}
              onClick={handleSaveChanges}>
              Save changes
            </Button>
          </Space>
        </div>
        <div data-color-mode="light">
          <MDEditor
            autoFocus
            height={'100vh'}
            highlightEnable={false}
            value={readmeContent}
            onChange={value => handleEditorContentChange(value)}
          />
        </div>
      </React.Fragment>
    </LoadableContainer>
  );
};

export default RepositoryReadmeEditorContent;
