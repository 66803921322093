import { useEffect } from 'react';

import { X_WEB_API } from '@/constants';
import { MESSAGE_GET_CLIENT_ID, communicatorService } from '@/messaging';
import { isChromeCompatible } from '@/utils';

/** Handshaking with the chrome extension to construct the communication channel */
export const useHandshake = () => {
  useEffect(() => {
    const crossMessagingOnMount = async () => {
      if (isChromeCompatible && X_WEB_API.runtime) {
        const response = await communicatorService.send<string, string>({
          message: MESSAGE_GET_CLIENT_ID,
        });
        if (response) localStorage.setItem('BROWSER_CLIENT_ID', response);
      }
    };
    crossMessagingOnMount();
  }, []);
};
