/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';

import { Button, Space } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { updateRepositoryBannerMutation } from '@/graphql';
import { usePersonal, useRepositoryContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { handleFetchBanner, useSnackbarStore } from '@/stores';
import { formatBytes } from '@/utils';

import { CustomStyledDropzone, LoadableContainer, SplashScreen } from '..';

type Props = any;

const RepositoryBannerSetting = (props: Props) => {
  const MAX_SIZE_BANNER = 1_000_000;
  const { repository, workspace, onRefresh } = useRepositoryContext();
  const { isWorkspaceAdmin } = usePersonal();
  const { enqueueNotification } = useSnackbarStore();
  const [originalImageUrl, setOriginalImageUrl] = useState<string>('');
  const [bannerImageUrl, setBannerImageUrl] = useState<string>('');
  const [bannerImageFile, setBannerImageFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleDiscardChanges = () => {
    setBannerImageFile(undefined);
    setBannerImageUrl(originalImageUrl);
  };

  const handleSaveChanges = async () => {
    if (!repository || !bannerImageFile) return;
    setLoading(true);
    try {
      await apolloClient.mutate({
        context: {
          Headers: {
            'Content-Type': 'multipart/form-data',
            'apollo-require-preflight': true,
          },
        },
        mutation: updateRepositoryBannerMutation,
        variables: {
          repositoryId: repository.id,
          bannerData: bannerImageFile,
          mimeType: bannerImageFile.type,
        },
      });
      await enqueueNotification({
        name: 'Updated repository info',
        description: 'Successfully updating repository info',
        type: 'Success',
      });
      onRefresh();
    } catch (error) {
      console.log(error);
      await enqueueNotification({
        name: 'Failed to update repository info',
        description: 'Error updating repository info',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const hasEdittingPermission = isWorkspaceAdmin(workspace);

  const handleFileOnUpload = async (files: (File & { preview: string })[]) => {
    const selectedFile: File & { preview: string } = files[0];
    if (selectedFile) {
      setBannerImageUrl(selectedFile.preview);
      setBannerImageFile(selectedFile);
    }
  };

  const handleRefresh = async (isCached: boolean) => {
    if (!repository) return;
    try {
      if (repository.bannerUrl) {
        const bannerImage = await handleFetchBanner(repository.id);
        setBannerImageUrl(bannerImage);
        setOriginalImageUrl(bannerImage);
      } else {
        setBannerImageUrl('');
        setOriginalImageUrl('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const initContent = async () => {
      if (!repository) return;
      setLoading(true);
      await handleRefresh(false);
      setLoading(false);
    };
    initContent();
  }, [repository]);

  const hasError = useMemo(
    () => !bannerImageFile || bannerImageFile?.size > MAX_SIZE_BANNER,
    [bannerImageFile]
  );

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>Page</h3>
          {!hasEdittingPermission && <p>⚠️ You don't have permission to update the repository</p>}
          <Space style={{ marginBottom: 20, marginTop: 10 }}>
            <Button
              disabled={hasError}
              type="primary"
              className="btn-error"
              onClick={handleDiscardChanges}>
              Discard
            </Button>
            <Button
              disabled={hasError}
              type="primary"
              className="btn-success"
              onClick={handleSaveChanges}>
              Save changes
            </Button>
          </Space>
        </div>
        <CustomStyledDropzone
          dropzoneTitle={
            <div>
              <p>
                Drag 'n' drop some files here, or click to select files (900x300) (not larger than
                1MB)
              </p>
            </div>
          }
          maxFiles={1}
          multiple={false}
          handleFileOnUpload={handleFileOnUpload}
        />
        <div style={{ ...MIDDLE_STYLE, marginTop: 30, marginBottom: 30 }}>
          <div>
            {bannerImageUrl.length > 0 && (
              <img
                style={{
                  aspectRatio: '3 / 1',
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                src={bannerImageFile ? bannerImageUrl : `data:image/jpeg;base64,${bannerImageUrl}`}
                onLoad={() => {
                  URL.revokeObjectURL(bannerImageUrl);
                }}
              />
            )}
            <LoadableContainer isLoading={!bannerImageFile} loadComponent={<></>}>
              <LoadableContainer
                isLoading={(bannerImageFile?.size || 0) > MAX_SIZE_BANNER}
                loadComponent={
                  <div
                    style={{
                      height: '50px',
                      ...MIDDLE_STYLE,
                      color: GLOBAL_THEME_COLOR.$error_color,
                    }}>
                    Exceed limit size of banner image
                  </div>
                }>
                <p>Size: {formatBytes(bannerImageFile?.size || 0)}</p>
              </LoadableContainer>
            </LoadableContainer>
          </div>
        </div>
      </React.Fragment>
    </LoadableContainer>
  );
};

export default RepositoryBannerSetting;
