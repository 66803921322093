import React, { useMemo } from 'react';

import { MIDDLE_STYLE } from '@/constants';
import { Directory, RepositoryTab } from '@/gql/graphql';
import { useRepositoryContext } from '@/hooks';
import { TabTreeDirectory, TreeNode, UnionTab } from '@/models';
import { useModalStore } from '@/stores';
import { buildTree, tabTreeWalk } from '@/utils';

import ContextMenuButton from '../context-menu-button';

type Props = {
  treeNode: TreeNode;
};

const RepositoryItemContextMenu = ({ treeNode }: Props) => {
  const {
    setTreeNodes: setCurrentTreeNodes,
    treeNodes,
    selectedTreeNodes,
  } = useRepositoryContext();
  const { openModal } = useModalStore();

  // handleUngroup
  const handleUngroup = (clickedDirectory: TabTreeDirectory) => {
    const tabs: RepositoryTab[] = [];
    const directories: Directory[] = [];
    tabTreeWalk({
      treeNodes: treeNodes,
      handleDirectoryItem(directory) {
        const nonDuplicated =
          !directories.some(recorded => recorded.id === directory.id) &&
          clickedDirectory.id !== directory.id;
        const children = directory.parentDirectory === clickedDirectory.id;
        if (nonDuplicated && !children) directories.push(directory);
        else if (nonDuplicated && children)
          directories.push({ ...directory, parentDirectory: clickedDirectory.parentDirectory });
      },
      handleTabItem(item: any) {
        const nonDuplicated = !tabs.some(recorded => recorded.id === item.id);
        const children = item.parentDirectory === clickedDirectory.id;
        if (nonDuplicated && !children) tabs.push(item);
        else if (nonDuplicated && children)
          tabs.push({ ...item, parentDirectory: clickedDirectory.parentDirectory });
      },
    });
    const _updatedTreeNodes = buildTree(tabs, directories);
    setCurrentTreeNodes(_updatedTreeNodes);
  };

  const handleMoveItemTo = () => {
    openModal('directoryList', {
      treeNodes,
      selectedTreeNodes: selectedTreeNodes || {},
    });
  };

  const handleOpenInNewTab = (tab: UnionTab) => {
    window.open(tab.url);
  };

  const selectedNodeCounter = useMemo<number>(() => {
    return (
      Object.values(selectedTreeNodes).reduce((acc, value) => acc + (value === true ? 1 : 0), 0) ||
      0
    );
  }, [selectedTreeNodes]);

  let component = <></>;
  if (treeNode.type === 'directory') {
    component = (
      <React.Fragment>
        <ContextMenuButton
          handler={() => handleUngroup(treeNode.value as TabTreeDirectory)}
          title="Ungroup"
        />
      </React.Fragment>
    );
  }
  if (treeNode.type === 'tab') {
    component = (
      <div style={{ ...MIDDLE_STYLE, width: '100%', flexDirection: 'column' }}>
        <ContextMenuButton
          handler={() => handleOpenInNewTab(treeNode.value as UnionTab)}
          title="Open in new tab"
        />
      </div>
    );
  }
  return (
    <div style={{ width: '150px' }}>
      {selectedNodeCounter > 0 && (
        <ContextMenuButton
          handler={handleMoveItemTo}
          title={`Move ${selectedNodeCounter} items to ...`}
        />
      )}
      {component}
    </div>
  );
};

export default RepositoryItemContextMenu;
