import gql from 'graphql-tag';

export const triggerOnBrowsingEventUpdateMutation = gql(`
mutation TriggerOnBrowsingEventUpdated($triggerBrowsingEventArgs:TriggerBrowsingEventArgs!) {
  triggerOnBrowsingEventUpdated(triggerBrowsingEventArgs:$triggerBrowsingEventArgs){
    trigger_at
    browser_client_id
    event_name
    user_id
    properties
  }
}
`);

export const onTabManagerUpdatedSubscription = gql(`
subscription OnBrowsingEventUpdated($onBrowsingEventUpdatedArgs: OnBrowsingEventUpdatedArgs!){
  onBrowsingEventUpdated(onBrowsingEventArgs:$onBrowsingEventUpdatedArgs){
    trigger_at
    browser_client_id
    event_name
    user_id
    properties
  }
}
`);

export const pongSubscription = gql(`
subscription Pong {
  pong {
    pingId
  }
}`);
