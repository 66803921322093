import React from 'react';

import AppRoutesRenderer from '../app-router/route-render';
import { timeTrackerRouteList } from './tab-lens-router-list';

type Props = any;

const TimeTrackerRouter = (props: Props) => {
  return <AppRoutesRenderer routes={timeTrackerRouteList} />;
};

export default TimeTrackerRouter;
