/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { useFormik } from 'formik';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import {
  NewWorkspaceValues,
  newWorkspaceDefaultValues,
  newWorkspaceValidationSchema,
} from '@/forms';
import { AccessVisibility, Workspace } from '@/gql/graphql';
import { createWorkspaceMutation } from '@/graphql';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore, useModalStore, useWorkspaceStore } from '@/stores';
import { buildSlug } from '@/utils';

import ReusableModalWrapper from '../ReusableModalWrapper';

type Props = any;

const NewWorkspaceModal = (props: Props) => {
  const { user } = useAuthStore();
  const {
    setRefreshing,
    setWorkspaces,
    workspaces,
    fetchWorkspaceBySlug,
    fetchAllPublicWorkspaces,
    fetchWorkspaces,
  } = useWorkspaceStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { closeModal, modalName } = useModalStore();
  const [publicWorkspaces, setPublicWorkspaces] = useState<Workspace[]>([]);
  const [myWorkspaces, setMyWorkspaces] = useState<Workspace[]>([]);
  const [isNameTaken, setIsNameTaken] = useState<boolean>(false);

  const formik = useFormik<NewWorkspaceValues>({
    validationSchema: newWorkspaceValidationSchema,
    initialValues: newWorkspaceDefaultValues,
    onSubmit: async values => {
      setLoading(true);
      await apolloClient.mutate({
        mutation: createWorkspaceMutation,
        variables: {
          description: values.workspaceDescription,
          name: values.workspaceName,
          visibility: values.visibility === AccessVisibility.Public ? 1 : 0,
        },
      });
      if (user) {
        const workspace = await fetchWorkspaceBySlug(buildSlug(values.workspaceName, true));
        if (!workspace) return;
        setWorkspaces(workspaces.concat([workspace]));
      }
      setRefreshing();
      formik.resetForm();
      closeModal();
      setLoading(false);
    },
  });

  useEffect(() => {
    const init = () => {
      const workspaceName = formik.values.workspaceName;
      const concatWorkspaces = [...publicWorkspaces, ...myWorkspaces];
      setIsNameTaken(
        concatWorkspaces.some(
          workspace =>
            workspace.name === workspaceName || workspace.slug === buildSlug(workspaceName, true)
        )
      );
    };
    init();
  }, [formik.values.workspaceName, publicWorkspaces, myWorkspaces]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const _publicWorkspaces = await fetchAllPublicWorkspaces();
      const _myWorkspaces = await fetchWorkspaces();
      setPublicWorkspaces(_publicWorkspaces);
      setMyWorkspaces(_myWorkspaces);
      setLoading(false);
    };
    if (modalName === 'newWorkspace') {
      init();
    }
  }, [modalName]);

  return (
    <ReusableModalWrapper
      width={500}
      title={<div>🗂 Create a new workspace</div>}
      modalName="newWorkspace"
      okText="Create workspace"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={formik.handleSubmit}
      okButtonDisabled={
        isNameTaken ||
        Object.keys(formik.errors).some(key => formik.errors[key as keyof NewWorkspaceValues]) ||
        loading
      }>
      <React.Fragment>
        <h4>Workspace name</h4>
        <Input
          status={isNameTaken || formik.errors.workspaceName ? 'error' : ''}
          value={formik.values.workspaceName}
          onChange={e => formik.setFieldValue('workspaceName', e.target.value)}
          placeholder="Enter workspace name"
        />
        {(isNameTaken || formik.errors.workspaceName) && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
            {isNameTaken ? 'Workspace name is taken already' : formik.errors.workspaceName}
          </p>
        )}
        <h4>Workspace description (Optional)</h4>
        <Input.TextArea
          status={formik.errors.workspaceDescription ? 'error' : ''}
          value={formik.values.workspaceDescription}
          onChange={e => formik.setFieldValue('workspaceDescription', e.target.value)}
          autoSize={{ minRows: 6, maxRows: 5 }}
          maxLength={255}
          placeholder="Enter workspace description"
        />
        {formik.errors.workspaceDescription && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_text_color }}>
            {formik.errors.workspaceDescription}
          </p>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Workspace visibility</h4>
          <div style={MIDDLE_STYLE}>
            <Select
              style={{ width: 120 }}
              value={formik.values.visibility}
              onChange={value => formik.setFieldValue('visibility', value)}
              options={[
                {
                  value: AccessVisibility.Public,
                  label: (
                    <span>
                      <EyeOutlined /> Public
                    </span>
                  ),
                },
                {
                  value: AccessVisibility.Private,
                  label: (
                    <span>
                      <EyeInvisibleOutlined /> Private
                    </span>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </React.Fragment>
    </ReusableModalWrapper>
  );
};

export default NewWorkspaceModal;
