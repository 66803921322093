import React from 'react';

import { Progress } from 'antd';

import { MIDDLE_STYLE } from '@/constants';
import { TimeTrackSheet, TimelessTabChartData } from '@/models';
import {
  convertSecondsToTime,
  generateHSLColor,
  renderTime,
  shortenString,
  sortTrackTimeSheetByValue,
} from '@/utils';

import TimelessTabsPieChart from '../timeless-tabs-pie-chart';

type Props = {
  totalTime: number;
  piechartData: TimelessTabChartData[];
  trackTimeSheet: TimeTrackSheet;
  descripitonComponent?: React.ReactNode | React.ReactNode[];
  limit: number;
};

const TimelessTimeAllocationChart = ({
  totalTime,
  piechartData,
  trackTimeSheet,
  limit,
  descripitonComponent,
}: Props) => {
  return (
    <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
      <TimelessTabsPieChart data={piechartData}>
        <div style={{ fontWeight: 'bold', fontSize: 25 }}>
          {convertSecondsToTime(parseInt(totalTime.toString()))}
        </div>
        {descripitonComponent}
      </TimelessTabsPieChart>
      <div style={{ textAlign: 'left', width: '100%' }}>
        {sortTrackTimeSheetByValue(trackTimeSheet, 'desc')
          .slice(0, limit)
          .map(urlHost => (
            <div key={urlHost}>
              <div
                style={{
                  ...MIDDLE_STYLE,
                  justifyContent: 'space-between',
                  fontSize: 'smaller',
                }}>
                <span>{shortenString(urlHost, 30)}</span>
                <span>{renderTime(trackTimeSheet[urlHost].value)}</span>
              </div>
              <Progress
                percent={(trackTimeSheet[urlHost].value / totalTime) * 100}
                strokeColor={generateHSLColor(urlHost)}
                showInfo={false}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TimelessTimeAllocationChart;
