import React from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Lottie from 'lottie-react';

import { WebBrowsingLottieFile } from '@/animations';
import { GLOBAL_THEME_COLOR } from '@/constants';

type Props = any;

const BrowserNotCompatible = (props: Props) => {
  const handleReadDocumentation = () => {
    window.open('https://docs.tabhub.io/get-started/overview');
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Lottie
        style={{
          height: 300,
        }}
        animationData={WebBrowsingLottieFile}
      />
      <h2>
        <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>Your browser</span> is not
        supported by TabHub!
      </h2>
      <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
        Please switch to these browser below to use the full features of TabHub
      </div>
      <Button
        onClick={handleReadDocumentation}
        type="primary"
        style={{ width: 'fit-content', fontWeight: 'bold', marginTop: 20 }}>
        Read documentation <ArrowRightOutlined />
      </Button>
    </div>
  );
};

export default BrowserNotCompatible;
