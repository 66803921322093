import { AccessVisibility, Workspace } from '@/gql/graphql';

export const LOCAL_WORKSPACE_ID = 'default';

export const buildWorkspaceKey = (workspaceId: string) => `workspace-${workspaceId}`;
export const buildPinnedTabKey = () => `pinned-tabs`;

export const localWorkspace: Workspace = {
  id: LOCAL_WORKSPACE_ID,
  slug: 'local-workspace',
  members: [],
  created_date: 1,
  name: 'local-workspace',
  description: 'Temporary workspace store in your browser. This will be lost if not synced',
  repositories: [],
  updated_date: 1,
  visibility: AccessVisibility.Private,
  owner: '',
  roles: [],
};
