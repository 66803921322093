import React from 'react';

import { useModalStore } from '@/stores';

type Props = any;

const PowerfulSearchButton = (props: Props) => {
  const { openModal } = useModalStore();
  const handleOpenPowerfulSearch = () => {
    openModal('superSearch');
  };
  return (
    <div className="powerful-search-button" onClick={handleOpenPowerfulSearch}>
      <button className="powerful-search-input">
        🔍 <span style={{ marginLeft: 15 }}>Search window tabs, repositories & workspaces</span>
      </button>
    </div>
  );
};

export default PowerfulSearchButton;
