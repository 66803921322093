import gql from 'graphql-tag';

export const createNewUserMutation = gql`
  mutation CreateNewUser(
    $uid: String!
    $profile_image: String!
    $email: String!
    $username: String!
    $full_name: String
    $provider: String
  ) {
    createNewUser(
      createNewUserArgs: {
        uid: $uid
        profile_image: $profile_image
        email: $email
        username: $username
        full_name: $full_name
        provider: $provider
      }
    ) {
      message
      type
    }
  }
`;

export const smartTabGroupingMutation = gql(`
mutation SmartTabGrouping($tabs:[RepositoryTabAsInput!]!, $group: [String!]!) {
  smartTabGrouping(
    smartTabGroupingArgs:{
      tabs:$tabs,
      groups:$group
    }
  ){
    category
    url
  }
}
`);

export const updateUserMutation = gql(`
mutation UpdateUser($username:String!, $full_name: String!, $profile_image: String!) {
  updateUser(updateUserArgs:{
    username:$username,
    full_name:$full_name,
    profile_image:$profile_image
  }) {
    type
    message
  }
}
`);
