import React from 'react';

import Browser from 'webextension-polyfill';

import { MIDDLE_STYLE } from '@/constants';
import { useBrowserDevService, useWindowTabActions } from '@/hooks';
import { TabTreeDirectory, TreeNode, UnionTab } from '@/models';

import ContextMenuButton from '../context-menu-button';

type Props = {
  treeNode: TreeNode;
  browserWindow: Browser.Windows.Window;
};

const WindowItemContextMenu = ({ browserWindow, treeNode }: Props) => {
  const browserDevService = useBrowserDevService();
  const { handleSwitchTab, handleCloseTab, onRefresh } = useWindowTabActions();

  const handleUngroup = async (directory: TabTreeDirectory) => {
    for (const tab of directory.items || []) {
      await browserDevService.ungroupTab([parseInt(tab.value.id as any)]);
    }
    await onRefresh();
  };

  const handleOpenDirectory = () => {
    //
  };

  let component = <></>;
  if (treeNode.type === 'directory') {
    component = (
      <React.Fragment>
        <ContextMenuButton title="Open" handler={handleOpenDirectory} />
        <ContextMenuButton
          title="Ungroup"
          handler={() => handleUngroup(treeNode.value as TabTreeDirectory)}
        />
      </React.Fragment>
    );
  }
  if (treeNode.type === 'tab') {
    component = (
      <div style={{ ...MIDDLE_STYLE, width: '100%', flexDirection: 'column', textAlign: 'left' }}>
        <ContextMenuButton
          title="Switch"
          handler={() => handleSwitchTab(browserWindow, treeNode.value as any)}
        />
        <ContextMenuButton
          title="Close"
          handler={() => handleCloseTab(treeNode.value as UnionTab)}
        />
      </div>
    );
  }
  return <div style={{ width: '150px' }}>{component}</div>;
};

export default WindowItemContextMenu;
