import React from 'react';

import { Card, Tabs, TabsProps } from 'antd';

import { ANT_DESIGN_THEME, LOCAL_WORKSPACE_ID } from '@/constants';
import { Repository } from '@/gql/graphql';
import { useBreakpoint } from '@/hooks';

import { RepositoryBannerSetting, RepositoryReadmeEditor } from '..';
import RepositoryContributors from '../repository-contributors';
import RepositoryGeneralSetting from '../repository-general-setting';

type Props = {
  repository: Repository | undefined | null;
};

enum RepositorySettingTab {
  Page = 'page',
  Banner = 'banner',
  General = 'general',
  Members = 'Members',
}

const RepositorySettingsTab = ({ repository }: Props) => {
  const { isMobile } = useBreakpoint();
  const items: TabsProps['items'] = [
    {
      key: RepositorySettingTab.General,
      label: `General`,
      children: <RepositoryGeneralSetting />,
    },
    ...(repository?.workspaceId !== LOCAL_WORKSPACE_ID
      ? [
          {
            key: RepositorySettingTab.Banner,
            label: `Banner`,
            children: <RepositoryBannerSetting />,
          },
          {
            key: RepositorySettingTab.Page,
            label: `Page`,
            children: <RepositoryReadmeEditor />,
          },
          {
            key: RepositorySettingTab.Members,
            label: `Contributors`,
            children: <RepositoryContributors />,
          },
        ]
      : []),
  ];

  return (
    <Card
      title={<div style={{ marginLeft: 20 }}>Settings</div>}
      style={{
        textAlign: 'left',
        border: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
      }}>
      <Tabs tabPosition={isMobile ? 'top' : 'left'} items={items} />
    </Card>
  );
};

export default RepositorySettingsTab;
