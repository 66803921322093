import React from 'react';

import { Avatar } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { User } from '@/gql/graphql';

type Props = {
  user: User;
};

const UserListItem = ({ user }: Props) => {
  return (
    <div style={{ ...MIDDLE_STYLE }}>
      <Avatar size={35} src={user.profile_image} />{' '}
      <div style={{ marginLeft: 15 }}>
        <div style={{ fontWeight: 'bold' }}>{user.full_name}</div>
        <div style={{ fontSize: 13, color: GLOBAL_THEME_COLOR.$dark_text_color }}>{user.email}</div>
      </div>
    </div>
  );
};

export default UserListItem;
