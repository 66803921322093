/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { onAuthStateChanged } from 'firebase/auth';

import { DATA_FIREBASE_ENCRYPTION_PASS, DATA_FIREBASE_KEY } from '@/constants/env';
import { auth } from '@/services/FirebaseService';
import { useAuthStore } from '@/stores';
import { FirebaseAuthData } from '@/typings/auth.type';
import { encryptData, getSecondTimestamp } from '@/utils';
import { persistAuthData } from '@/utils/auth.util';

export const useAuthStateChanged = (redirect?: boolean) => {
  const history = useHistory();
  const { setUser, fetchCurrentUser, setLoadingUser } = useAuthStore();

  const noUserFallback = async () => {
    await auth.signOut();
    if (redirect) history.push('/sign-in');
    return;
  };

  const handleFetchMe = async () => {
    try {
      const user = await fetchCurrentUser();
      if (!user) throw new Error('No user found');
      setUser(user);
    } catch (error) {
      console.log(error);
      setUser(undefined);
      await noUserFallback();
    }
  };
  useEffect(() => {
    onAuthStateChanged(auth, async currentUser => {
      setLoadingUser(true);
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(true);
          const data: FirebaseAuthData = {
            ...currentUser,
            token,
            refreshToken: currentUser.refreshToken,
            lastAccessedAt: getSecondTimestamp(),
          };
          localStorage.setItem(DATA_FIREBASE_KEY, encryptData(data, DATA_FIREBASE_ENCRYPTION_PASS));
          persistAuthData(data);
          await handleFetchMe();
        } catch (error: any) {
          setUser(undefined);
        }
      } else {
        setUser(undefined);
      }
      setLoadingUser(false);
    });
    const init = async () => {
      await handleFetchMe();
    };
    init();
  }, []);
};
