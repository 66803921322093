import React from 'react';

import { Button, Radio, Space, Tag, Tooltip } from 'antd';

import { ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { useBreakpoint, useRepositoryContext } from '@/hooks';
import { ViewMode } from '@/stores';
import { isChromeCompatible } from '@/utils';

import IconContainer from '../icon-container';
import RepositoryEditor from '../repository-editor';
import RepositoryMetaInfo from '../repository-meta-info';

type Props = {
  preview?: boolean;
};

const RepositoryAppBar = ({ preview }: Props) => {
  const { isMobile } = useBreakpoint();
  const { repository, onRefresh, viewMode, onViewModeChanged } = useRepositoryContext();

  const handleRefresh = () => {
    onRefresh();
  };

  return (
    <React.Fragment>
      <RepositoryMetaInfo />
      {(isChromeCompatible || !isMobile) && (
        <div style={{ ...MIDDLE_STYLE, flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <RepositoryEditor actionable />
          <Space style={{ ...MIDDLE_STYLE, flexWrap: 'wrap' }}>
            <Tag color="blue">{`${repository?.tabs.length} tabs saved`}</Tag>
            <Button onClick={handleRefresh}>
              <IconContainer icon={ICON_REGISTRY.REFRESH} style={{ marginRight: 10 }} /> Refresh
            </Button>
            <Radio.Group
              value={viewMode}
              defaultValue={viewMode}
              onChange={e => onViewModeChanged(e.target.value)}>
              <Tooltip title="Table Mode">
                <Radio.Button value={ViewMode.TableMode}>
                  <IconContainer icon={ICON_REGISTRY.VIEW_TABLE_MODE} />
                </Radio.Button>
              </Tooltip>
              <Tooltip title="Card Mode">
                <Radio.Button value={ViewMode.CardMode}>
                  <IconContainer icon={ICON_REGISTRY.VIEW_CARD_MODE} />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
          </Space>
        </div>
      )}
    </React.Fragment>
  );
};

export default RepositoryAppBar;
