/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { CheckOutlined, CloseOutlined, CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Select, Skeleton } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { AppEnvironment } from '@/constants/env';
import {
  AccessPermission,
  AccessVisibility,
  Repository,
  User,
  UserRole,
  Workspace,
} from '@/gql/graphql';
import { updateRepositoryPermissionMutation } from '@/graphql';
import { usePersonal } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore, useModalStore, useRepositoryStore, useWorkspaceStore } from '@/stores';
import { findUserRoleInWorkspace } from '@/utils';

import LoadableContainer from '../loadable-container';
import UserListItem from '../user-list-item';
import UserRoleTag from '../user-role-tag';
import WorkspaceAvatar from '../workspace-avatar';

type UserWhoHasAccess = {
  value: Partial<User>;
  type: UserRole;
};

type Props = { repositoryId: string; workspace: Workspace };

const APP_EDNDPOINT = AppEnvironment.REACT_APP_WEB_APP_URL;

const RepositoryShareModal = ({ repositoryId, workspace }: Props) => {
  const { isWorkspaceAdmin } = usePersonal();
  const { fetchUserByEmail, fetchUserById } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [refreshing, setRefreshing] = useState<number>(+new Date());
  const [repository, setRepository] = useState<Repository | undefined>(undefined);
  const [addingUser, setAddingUser] = useState<boolean>(false);
  const { fetchRepositoryById } = useRepositoryStore();
  const { fetchWorkspaceById } = useWorkspaceStore();
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [userWhoHasAccess, setUserWhoHasAccess] = useState<UserWhoHasAccess[]>([]);
  const isPublic = useMemo(() => repository?.visibility === AccessVisibility.Public, [repository]);
  const [accessPermission, setAccessPermission] = useState<AccessPermission>(
    repository?.accessPermission || AccessPermission.EveryoneWithTheLink
  );
  const [permittedUsers, setPermittedUsers] = useState<string[]>(repository?.permittedUsers || []);
  const [copied, setCopied] = useState(false);
  const { closeModal, modalName } = useModalStore();

  const handleCloseModal = () => {
    resetForm();
    closeModal();
  };

  const resetForm = () => {
    setNewUserEmail('');
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`${APP_EDNDPOINT}/shared/${workspace.slug}/${repository?.slug}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handlePermissionChanged = async (value: AccessPermission) => {
    await handleUpdatePermission(value, permittedUsers);
    setAccessPermission(value);
  };

  const handleRemoveUserPermission = async (userId: string) => {
    try {
      const updatedPermittedUsers = [...userWhoHasAccess].filter(user => user.value.id !== userId);
      await handleUpdatePermission(
        accessPermission,
        updatedPermittedUsers.map(user => user.value.id as any)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddUser = async () => {
    setAddingUser(true);
    try {
      const user = await fetchUserByEmail(newUserEmail);
      if (!user) throw new Error('User email is not valid');
      if ([...permittedUsers, ...workspace.members].some(member => member === user.id))
        throw new Error('User already exist');
      const updatedPermittedUsers = [...userWhoHasAccess].concat([
        {
          type: UserRole.PublicUser,
          value: user as any,
        },
      ]);
      await handleUpdatePermission(
        accessPermission,
        updatedPermittedUsers.map(user => user.value.id as any)
      );
    } catch (error) {
      console.log(error);
    }
    resetForm();
    setAddingUser(false);
  };

  const hasEdittingPermission = isWorkspaceAdmin(workspace);

  useEffect(() => {
    const init = async () => {
      if (!repository) return;
      setLoading(true);
      const users: UserWhoHasAccess[] = [];
      const workspace = await fetchWorkspaceById(repository.workspaceId);
      const visited = {};
      if (!workspace) return;
      for (const member of workspace.members) {
        if (visited[member]) continue;
        const user = await fetchUserById(member);
        if (user) {
          users.push({
            type: findUserRoleInWorkspace(workspace, member) as any,
            value: user,
          });
          visited[member] = true;
        }
      }
      for (const contributor of repository?.contributors || []) {
        if (visited[contributor]) continue;
        const user = await fetchUserById(contributor);
        if (user) {
          users.push({
            type: UserRole.RepositoryContributor,
            value: user,
          });
          visited[contributor] = true;
        }
      }
      for (const permittedUser of permittedUsers) {
        if (visited[permittedUser]) continue;
        const user = await fetchUserById(permittedUser);
        if (user) {
          users.push({
            type: UserRole.PublicUser,
            value: user,
          });
          visited[permittedUser] = true;
        }
      }
      setUserWhoHasAccess(users);
      setLoading(false);
    };
    init();
  }, [permittedUsers, repository?.contributors, workspace]);

  const handleUpdatePermission = async (
    accessPermission: AccessPermission,
    permittedUsers: string[]
  ) => {
    if (!hasEdittingPermission) return;
    if (!repository) return;
    setLoading(true);
    try {
      await apolloClient.mutate({
        mutation: updateRepositoryPermissionMutation,
        variables: {
          accessPermission: accessPermission,
          permittedUsers: permittedUsers,
          repositoryId: repository.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setRefreshing(+new Date());
  };

  useEffect(() => {
    const initRepo = async () => {
      setLoading(true);
      try {
        /** Local workspace does not have functionality to share repository */
        const repository = await fetchRepositoryById(undefined, repositoryId);
        if (!repository) throw new Error('No repository found');

        setRepository(repository);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    initRepo();
  }, [repositoryId, refreshing]);

  useEffect(() => {
    if (!repository) return;
    setAccessPermission(repository.accessPermission);
    setPermittedUsers(repository.permittedUsers);
  }, [repository]);
  return (
    <React.Fragment>
      <Modal
        title={`Share "${repository?.name}"`}
        footer={[]}
        closable
        onCancel={handleCloseModal}
        open={modalName === 'shareRepository'}>
        <div style={{ marginTop: 20 }}>
          <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
            <React.Fragment>
              <span>
                <span style={{ marginRight: 10 }}>From</span>
                <WorkspaceAvatar workspace={workspace} /> {workspace.name}
              </span>
              <div>
                <h4>Access permission</h4>
                <Select
                  style={{ width: '100%' }}
                  value={accessPermission}
                  disabled={isPublic || !hasEdittingPermission}
                  onChange={handlePermissionChanged}
                  options={[
                    {
                      value: AccessPermission.EveryoneWithTheLink,
                      label: <span>🌐 Everyone who has the link can access</span>,
                    },
                    {
                      value: AccessPermission.OnlyPeopleWhoHasAccess,
                      label: <span>🔒 Only people added can open with this link</span>,
                    },
                  ]}
                />
              </div>
              {accessPermission === AccessPermission.OnlyPeopleWhoHasAccess && (
                <div>
                  <h4>People with access</h4>
                  <div
                    style={{
                      maxHeight: 250,
                      padding: '10px 20px',
                      backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
                      borderRadius: 10,
                    }}>
                    {hasEdittingPermission && (
                      <React.Fragment>
                        <div style={{ display: 'flex' }}>
                          <Input
                            value={newUserEmail}
                            onChange={e => setNewUserEmail(e.target.value)}
                            prefix={<SearchOutlined />}
                            placeholder="Enter user email"
                          />
                          <Button
                            loading={addingUser}
                            onClick={handleAddUser}
                            style={{ marginLeft: 10 }}
                            disabled={newUserEmail.length === 0}>
                            Add
                          </Button>
                        </div>
                        <Divider />
                      </React.Fragment>
                    )}
                    {userWhoHasAccess.map(_user => (
                      <div
                        style={{
                          ...MIDDLE_STYLE,
                          margin: '10px 0px',
                          justifyContent: 'space-between',
                        }}>
                        <UserListItem user={_user.value as any} />
                        <div style={{ display: 'flex' }}>
                          <UserRoleTag userRole={_user.type} />
                          {hasEdittingPermission && _user.type === UserRole.PublicUser && (
                            <CloseOutlined
                              style={{ marginLeft: 10 }}
                              onClick={() => handleRemoveUserPermission(_user.value.id as any)}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                <Button onClick={handleCopyToClipboard}>
                  {copied ? (
                    <CheckOutlined style={{ color: GLOBAL_THEME_COLOR.$success_color }} />
                  ) : (
                    <CopyOutlined />
                  )}{' '}
                  Copy link to repository
                </Button>
              </div>
            </React.Fragment>
          </LoadableContainer>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RepositoryShareModal;
