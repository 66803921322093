/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import { WindowsOutlined } from '@ant-design/icons';
import Fuse from 'fuse.js';
import Browser from 'webextension-polyfill';

import { MIDDLE_STYLE } from '@/constants';
import { useBrowserDevService } from '@/hooks';

import AnimatedComponent from '../animated-component';
import WindowTabSearchItem from '../window-tab-search-item';

type SearchItemActiveWindowProps = {
  window: Browser.Windows.Window;
  searchInput: string;
};

const SearchItemActiveWindow: React.FC<SearchItemActiveWindowProps> = ({
  window,
  searchInput,
}: SearchItemActiveWindowProps) => {
  const browserDevService = useBrowserDevService();
  const filterTabs = useMemo(() => {
    if (searchInput.length === 0) return window.tabs || [];
    if (window.tabs) {
      const fuse = new Fuse(window.tabs, { keys: ['title', 'customName'] });
      const results = fuse.search(searchInput);
      return results.map(result => result.item);
    }
    return [];
  }, [searchInput]);

  const handleOnTabClicked = (windowTab: Browser.Tabs.Tab) => {
    if (!windowTab.id) return;
    browserDevService.selectTab(windowTab.windowId, windowTab.id);
  };

  if (filterTabs.length === 0) return <></>;
  return (
    <React.Fragment>
      <div style={MIDDLE_STYLE} className="beautiful-section-header-container">
        <h4 className="beautiful-section-header">Active window</h4>
        {window.focused ? <div>Focused</div> : <WindowsOutlined />}
      </div>
      {filterTabs &&
        filterTabs.map((tab, index) => (
          <AnimatedComponent.OpacityFadeInDiv delay={100 + 50 * index}>
            <WindowTabSearchItem window={window} windowTab={tab} onClick={handleOnTabClicked} />
          </AnimatedComponent.OpacityFadeInDiv>
        ))}
    </React.Fragment>
  );
};

export default SearchItemActiveWindow;
