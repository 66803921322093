import React, { useMemo, useState } from 'react';

import { ClockCircleOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Card, Empty, Popover, Select } from 'antd';

import { GENERAL_EMPTY_IMAGE, MIDDLE_STYLE } from '@/constants';
import { TimeTrackerProductivityMode } from '@/gql/graphql';
import { useTimeMachineContext } from '@/hooks';
import {
  TimeAllocationCasualModeVariant,
  TimeAllocationFocusModeVariant,
  TimelessTabChartData,
} from '@/models';
import { convertPieChartDateFromTimeSheet } from '@/utils/time-tracker.util';

import AnimatedComponent from '../animated-component';
import TimelessTimeAllocationChart from '../timeless-time-allocation-chart';

type Props = any;

const TimelessTimeAllocationSection = (props: Props) => {
  const {
    productivityMode: mode,
    realtimeUrlHostTimeTrack,
    urlHostTimeTrack,
    realtimeTabTimeTrack,
    trackedTabs,
    realtimeTotalTime,
  } = useTimeMachineContext();
  const chartVariant = useMemo(
    () =>
      mode === TimeTrackerProductivityMode.FocusMode
        ? TimeAllocationFocusModeVariant
        : TimeAllocationCasualModeVariant,
    [mode]
  );
  const [openTimeAllocationSetting, setOpenTimeAllocationSetting] = useState<boolean>(false);
  const [filteredBy, setFilteredBy] = useState(chartVariant.Hostname);
  /** REALTIME: Data for pie chart */
  const realtimeUrlHostPieChartData = useMemo<TimelessTabChartData[]>(() => {
    return convertPieChartDateFromTimeSheet(realtimeUrlHostTimeTrack);
  }, [realtimeUrlHostTimeTrack]);

  /** REALTIME: Data for pie chart */
  const realtimeTabPieChartData = useMemo<TimelessTabChartData[]>(() => {
    return convertPieChartDateFromTimeSheet(realtimeTabTimeTrack);
  }, [realtimeTabTimeTrack]);

  const handleCloseTimeAllocationSetting = () => {
    setOpenTimeAllocationSetting(false);
  };

  const handleOpenTimeAllocationSetting = () => {
    setOpenTimeAllocationSetting(true);
  };

  const handleFilteredChanged = (
    value: TimeAllocationCasualModeVariant | TimeAllocationFocusModeVariant
  ) => {
    setFilteredBy(value);
  };

  return (
    <Card
      style={{ marginTop: 10 }}
      title={
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', padding: '0px 20px' }}>
          <h4 style={{ margin: 0 }}>
            <ClockCircleOutlined /> Time Allocation
          </h4>
          <Popover
            content={
              <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '250px' }}>
                <div>Filtered by</div>
                <Select
                  onChange={handleFilteredChanged}
                  options={Object.keys(chartVariant)
                    .map(key => chartVariant[key])
                    .map(variant => ({
                      value: variant,
                      label: variant,
                    }))}
                  defaultValue={filteredBy}
                  style={{ width: 'fit-content' }}></Select>
              </div>
            }
            title={
              <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                <span>Setting</span>
                <CloseOutlined
                  onClick={handleCloseTimeAllocationSetting}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            }
            trigger="click"
            placement={'bottomLeft'}
            open={openTimeAllocationSetting}
            onOpenChange={handleOpenTimeAllocationSetting}>
            <FilterOutlined />
          </Popover>
        </div>
      }>
      {trackedTabs.length > 0 ? (
        <React.Fragment>
          {[
            {
              limit: 5,
              filterBy: chartVariant.Hostname,
              timeSheet: realtimeUrlHostTimeTrack,
              pieChartData: realtimeUrlHostPieChartData,
              descriptionComponent: (
                <div style={{ fontSize: 'smaller' }}>
                  Accessed {Object.keys(urlHostTimeTrack).length} websites
                </div>
              ),
            },
            {
              limit: 5,
              filterBy: chartVariant.WebsiteURL,
              timeSheet: realtimeTabTimeTrack,
              pieChartData: realtimeTabPieChartData,
              descriptionComponent: (
                <div style={{ fontSize: 'smaller' }}>Accessed {trackedTabs.length} tabs</div>
              ),
            },
          ].map(allocationData => (
            <div key={allocationData.filterBy}>
              {filteredBy === allocationData.filterBy && (
                <AnimatedComponent.OpacityFadeInDiv delay={300}>
                  <TimelessTimeAllocationChart
                    piechartData={allocationData.pieChartData}
                    limit={allocationData.limit}
                    totalTime={realtimeTotalTime}
                    descripitonComponent={allocationData.descriptionComponent}
                    trackTimeSheet={allocationData.timeSheet}
                  />
                </AnimatedComponent.OpacityFadeInDiv>
              )}
            </div>
          ))}
        </React.Fragment>
      ) : (
        <div
          style={{
            ...MIDDLE_STYLE,
            width: '100%',
            height: '200px',
          }}>
          <Empty
            image={GENERAL_EMPTY_IMAGE}
            imageStyle={{ height: 60 }}
            description={<span>No data found</span>}></Empty>
        </div>
      )}
    </Card>
  );
};

export default TimelessTimeAllocationSection;
