/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { TbTrash } from 'react-icons/tb';

import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, TimePicker } from 'antd';
import { startOfToday } from 'date-fns';
import dayjs from 'dayjs';
import moment from 'moment';

import { LoadableContainer, NumericInput, SplashScreen } from '@/components';
import { CalendarPickerModal } from '@/components/modals';
import { MIDDLE_STYLE } from '@/constants';
import { TimeTrackerSessionSetting } from '@/gql/graphql';
import {
  fetchUserTimeTrackerInfo,
  useModalStore,
  useSnackbarStore,
  useTimeTrackerStore,
} from '@/stores';
import { dateToUnix, getCurrentHoursTimestamp } from '@/utils';

type Props = any;

const TimeTrackerSettingScreen = (props: Props) => {
  const { userTimeTrackerInfo, setUserTimeTrackerInfo, updateUserTimeTrackerInfo } =
    useTimeTrackerStore();
  const { openModal } = useModalStore();
  const { enqueueNotification } = useSnackbarStore();
  const [loading, setLoading] = useState<boolean>(true);
  const setting = useMemo<TimeTrackerSessionSetting>(
    () => userTimeTrackerInfo.time_tracker_setting as any,
    [userTimeTrackerInfo]
  );
  const [sessionEnabledFrom, setSessionEnabledFrom] = useState<number>(
    setting?.setting_enabled_from
  );
  const [sessionEnabledTo, setSessionEnabledTo] = useState<number | null | undefined>(
    setting?.setting_enabled_to
  );
  const [sessionStartTime, setSessionStartTime] = useState<number>(
    getCurrentHoursTimestamp(setting?.session_start_time)
  );
  const [sessionEndTime, setSessionEndTime] = useState<number>(
    getCurrentHoursTimestamp(setting?.session_end_time)
  );

  const [limitLeastUsedTime, setLimitLeastUsedTime] = useState<number>(
    setting?.limit_least_used_time || 0
  );

  const onTimePeriodChanged = (values: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (!values) return;
    if (!values[0]) return;
    setSessionStartTime(values[0].unix());
    if (!values[1]) return;
    setSessionEndTime(values[1].unix());
  };

  const handleSelectSessionStartDate = () => {
    openModal('sessionStartDateModal');
  };

  const handleSelectSessionEndDate = () => {
    openModal('sessionEndDateModal');
  };

  const handleSetDefaultSetting = (_setting: TimeTrackerSessionSetting) => {
    setSessionEnabledFrom(_setting.setting_enabled_from);
    setSessionEnabledTo(
      _setting.setting_enabled_to
        ? getCurrentHoursTimestamp(_setting.setting_enabled_to)
        : _setting.setting_enabled_to
    );
    setSessionStartTime(getCurrentHoursTimestamp(_setting.session_start_time));
    setSessionEndTime(getCurrentHoursTimestamp(_setting.session_end_time));
    setLimitLeastUsedTime(_setting.limit_least_used_time);
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const getHoursOfTheDay = (timestamp: number) => {
      return (timestamp - dateToUnix(startOfToday())) / 3600;
    };
    try {
      await updateUserTimeTrackerInfo({
        limit_least_used_time: limitLeastUsedTime,
        session_end_time: getHoursOfTheDay(sessionEndTime),
        session_start_time: getHoursOfTheDay(sessionStartTime),
        setting_enabled_from: sessionEnabledFrom,
        setting_enabled_to: sessionEnabledTo,
      });
      await enqueueNotification({
        name: 'Updated time tracker setting successfully',
        description: 'Successfully update time tracker setting',
        type: 'Success',
      });
    } catch (error) {
      await enqueueNotification({
        name: 'Error update changes',
        description: 'Error updating time tracker setting',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const isUpdated = useMemo(() => {
    return setting
      ? sessionEnabledFrom !== setting?.setting_enabled_from ||
          sessionEnabledTo !== setting?.setting_enabled_to ||
          sessionStartTime !== getCurrentHoursTimestamp(setting?.session_start_time) ||
          sessionEndTime !== getCurrentHoursTimestamp(setting?.session_end_time) ||
          limitLeastUsedTime !== setting?.limit_least_used_time
      : false;
  }, [
    sessionEnabledFrom,
    sessionEnabledTo,
    sessionStartTime,
    sessionEndTime,
    limitLeastUsedTime,
    setting,
  ]);

  useEffect(() => {
    if (!setting) return;
    handleSetDefaultSetting(setting);
  }, [setting]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const timeTrackerInfo = await fetchUserTimeTrackerInfo();
        if (timeTrackerInfo) {
          setUserTimeTrackerInfo(timeTrackerInfo);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    init();
  }, []);

  if (!setting) return <div>No setting found</div>;
  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <React.Fragment>
        <div style={{ ...MIDDLE_STYLE, width: '100%' }}>
          <Card title={<h3>Time Tracker Setting</h3>} style={{ maxWidth: 800, width: '100%' }}>
            <Divider style={{ marginTop: 0 }} />
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <div style={{ textAlign: 'left' }}>
                <h4 style={{ fontWeight: 'bold', margin: 0 }}>
                  <ClockCircleOutlined /> Session Time Period
                </h4>
                <p style={{ margin: 0, fontSize: 12 }}>How long is the session tracked?</p>
              </div>
              <TimePicker.RangePicker
                showSecond={false}
                size="large"
                value={[dayjs.unix(sessionStartTime), dayjs.unix(sessionEndTime)]}
                onChange={values => onTimePeriodChanged(values)}
              />
            </div>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', marginTop: 20 }}>
              <div style={{ textAlign: 'left' }}>
                <h4 style={{ fontWeight: 'bold', margin: 0 }}>
                  <CalendarOutlined /> Session Start Date
                </h4>
                <p style={{ margin: 0, fontSize: 12 }}>When does the session start?</p>
              </div>
              <Button onClick={handleSelectSessionStartDate}>
                <CalendarOutlined /> {moment.unix(sessionEnabledFrom).format('DD-MM-YYYY')}
              </Button>
            </div>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', marginTop: 20 }}>
              <div style={{ textAlign: 'left' }}>
                <h4 style={{ fontWeight: 'bold', margin: 0 }}>
                  <CalendarOutlined /> Session End Date
                </h4>
              </div>
              <Button onClick={handleSelectSessionEndDate}>
                <CalendarOutlined />{' '}
                {sessionEnabledTo ? moment.unix(sessionEnabledTo).format('DD-MM-YYYY') : 'Unknown'}
              </Button>
            </div>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', marginTop: 20 }}>
              <div style={{ textAlign: 'left' }}>
                <h4 style={{ fontWeight: 'bold', margin: 0 }}>
                  <TbTrash /> Limit least used time
                </h4>
                <p style={{ margin: 0, fontSize: 12 }}>
                  After the configured time without any action, tabs become deprecated
                </p>
              </div>
              <div>
                <NumericInput
                  style={{ width: 'fit-content', maxWidth: 50, marginRight: 10 }}
                  value={limitLeastUsedTime.toString()}
                  onChange={value => setLimitLeastUsedTime(parseInt(value) || 0)}
                />
                minutes
              </div>
            </div>
            {isUpdated && (
              <React.Fragment>
                <Divider />
                <Row gutter={10}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      className="btn-error"
                      size="large"
                      onClick={() => handleSetDefaultSetting(setting)}
                      style={{ width: '100%' }}>
                      Discard
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      className="btn-success"
                      size="large"
                      onClick={handleSaveChanges}
                      style={{ width: '100%' }}>
                      Save changes
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Card>
        </div>
        <CalendarPickerModal
          disabledDate={(date: dayjs.Dayjs) =>
            date.isBefore(dayjs.unix(sessionEnabledFrom), 'date')
          }
          defaultValue={dayjs.unix(sessionEnabledFrom)}
          modalName="sessionStartDateModal"
          okText="Save Date"
          title="Session Start Date"
          onSubmit={value => setSessionEnabledFrom(value.unix())}
        />
        <CalendarPickerModal
          disabledDate={(date: dayjs.Dayjs) =>
            date.isBefore(dayjs.unix(sessionEnabledFrom).add(1, 'day'))
          }
          defaultValue={dayjs.unix(sessionEnabledTo || moment().add('years', 1).unix())}
          modalName="sessionEndDateModal"
          okText="Save Date"
          title="Session End Date"
          onSubmit={value => setSessionEnabledTo(value.unix())}
        />
      </React.Fragment>
    </LoadableContainer>
  );
};

export default TimeTrackerSettingScreen;
