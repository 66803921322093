import axios from 'axios';

import { AppEnvironment, DATA_FIREBASE_ENCRYPTION_PASS, DATA_FIREBASE_KEY } from '@/constants/env';
import { FirebaseAuthData } from '@/typings/auth.type';

import { decryptData, encryptData } from './string.util';

export const persistAuthData = (payload: FirebaseAuthData) => {
  localStorage.setItem(
    DATA_FIREBASE_KEY,
    encryptData<FirebaseAuthData>(payload, DATA_FIREBASE_ENCRYPTION_PASS)
  );
  return payload;
};

export const getPersistedAuthData = (): FirebaseAuthData | null => {
  const localData = localStorage.getItem(DATA_FIREBASE_KEY);
  return localData ? decryptData<FirebaseAuthData>(localData, DATA_FIREBASE_ENCRYPTION_PASS) : null;
};

type RefreshTokenData = {
  access_token: string;
  expires_in: string;
  id_token: string;
  project_id: string;
  refresh_token: string;
  token_type: string;
  user_id: string;
};

export const requestRefreshTokenData = async (refreshToken: string): Promise<RefreshTokenData> => {
  const response = await axios.post(
    `https://securetoken.googleapis.com/v1/token?key=${AppEnvironment.REACT_APP_FIREBASE_API_KEY}`,
    {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }
  );
  const responseData = response.data;
  return responseData;
};
