/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import Meta from 'antd/es/card/Meta';

import { Card, Skeleton, Tooltip } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { useOpengraphMetadata } from '@/hooks';
import { shortenString } from '@/utils';

import LoadableContainer from '../loadable-container';
import TabCardImageBanner from '../tab-card-image-banner';

type Props = {
  tab: RepositoryTab;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, windowTab: RepositoryTab) => void;
};

const WindowTabCard = ({ tab, isSelected, onClick }: Props) => {
  const { ogLoading, ogMetadata } = useOpengraphMetadata(tab.url);

  return (
    <Tooltip title={tab.title}>
      <Card
        onClick={e => onClick && onClick(e, tab)}
        hoverable
        style={{
          width: 300,
          height: 325,
          textAlign: 'left',
          overflow: 'hidden',
        }}>
        <LoadableContainer isLoading={ogLoading} loadComponent={<Skeleton />}>
          <React.Fragment>
            {ogMetadata?.ogBanner ? (
              <TabCardImageBanner photoUrl={ogMetadata?.ogBanner || ''} />
            ) : (
              <div
                style={{
                  height: 150,
                  overflow: 'hidden',
                  ...MIDDLE_STYLE,
                }}>
                <img src={tab.favIconUrl} width={50} alt={tab.title} />
              </div>
            )}
            <Meta
              style={{
                marginTop: 15,
              }}
              title={
                <span
                  style={{
                    color: isSelected
                      ? GLOBAL_THEME_COLOR.$highlight_color
                      : GLOBAL_THEME_COLOR.$text_color,
                  }}>
                  {tab.title || ogMetadata?.ogTitle}
                </span>
              }
              description={shortenString(ogMetadata?.ogDescription || 'No description', 100)}
            />
          </React.Fragment>
        </LoadableContainer>
      </Card>
    </Tooltip>
  );
};

export default WindowTabCard;
