/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Permission for repository share mode */
export enum AccessPermission {
  EveryoneWithTheLink = 'EveryoneWithTheLink',
  OnlyPeopleWhoHasAccess = 'OnlyPeopleWhoHasAccess',
}

/** Visibility used for repository and workspace */
export enum AccessVisibility {
  Private = 'Private',
  Public = 'Public',
}

export type AddContributorArgs = {
  id: Scalars['String'];
  member_email: Scalars['String'];
};

export type AddNewMemberArgs = {
  id: Scalars['String'];
  member_email: Scalars['String'];
  role: UserRole;
};

export type AppResponse = {
  __typename?: 'AppResponse';
  message: Scalars['String'];
  type: ResponseType;
};

export type BrowsingEvent = {
  __typename?: 'BrowsingEvent';
  browser_client_id: Scalars['String'];
  event_name: Scalars['String'];
  properties?: Maybe<Scalars['String']>;
  trigger_at: Scalars['Float'];
  user_id?: Maybe<Scalars['String']>;
};

export type ChangeWorkspaceVisibilityArgs = {
  id: Scalars['String'];
  updated_date?: Scalars['Float'];
  visibility: AccessVisibility;
};

export type CreateNewRepositoryArgs = {
  description: Scalars['String'];
  directories: Array<DirectoryAsInput>;
  icon: Scalars['String'];
  name: Scalars['String'];
  repositorySlug?: InputMaybe<Scalars['String']>;
  tabs: Array<RepositoryTabAsInput>;
  visibility?: AccessVisibility;
  workspaceId: Scalars['String'];
};

export type CreateNewTimeTrackerArgs = {
  date: Scalars['Float'];
  mode: TimeTrackerProductivityMode;
  sessionEndTime: Scalars['Float'];
  sessionStartTime: Scalars['Float'];
  trackedTabs: Scalars['String'];
};

export type CreateNewUserArgs = {
  email: Scalars['String'];
  full_name?: InputMaybe<Scalars['String']>;
  profile_image: Scalars['String'];
  provider?: Scalars['String'];
  uid: Scalars['String'];
  username: Scalars['String'];
};

export type CreateNewWorkspaceArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  visibility?: Scalars['Float'];
};

export type DeleteRepositoryArgs = {
  id: Scalars['String'];
};

export type DeleteWorkspaceArgs = {
  id: Scalars['String'];
};

export type Directory = {
  __typename?: 'Directory';
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentDirectory?: Maybe<Scalars['String']>;
};

export type DirectoryAsInput = {
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentDirectory?: InputMaybe<Scalars['String']>;
};

export type FullRepositoryTabAsInput = {
  customName?: InputMaybe<Scalars['String']>;
  description?: Scalars['String'];
  favIconUrl: Scalars['String'];
  id: Scalars['String'];
  /** List of labels */
  labels?: Array<Scalars['String']>;
  lastAccessed?: InputMaybe<Scalars['Float']>;
  parentDirectory?: InputMaybe<Scalars['String']>;
  /** List of repository tabs IDs */
  pinned?: Array<Scalars['String']>;
  repositoryId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type GatherUrlsMetadataArgs = {
  websiteUrls: Array<Scalars['String']>;
};

export type GetAllPublicRepositoriesArgs = {
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type GetRepositoryBannerArgs = {
  repositoryId: Scalars['String'];
};

export type GetRepositoryByIdArgs = {
  id: Scalars['String'];
};

export type GetRepositoryBySlugArgs = {
  slug: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type GetUserByEmailArgs = {
  email: Scalars['String'];
};

export type GetUserByIdArgs = {
  id: Scalars['String'];
};

export type GetUserByUsernameArgs = {
  username: Scalars['String'];
};

export type GetUserRepositoriesArgs = {
  userId: Scalars['String'];
};

export type GetUserWorkspacesArgs = {
  userId: Scalars['String'];
};

export type GetWorkspaceByIdArgs = {
  id: Scalars['String'];
};

export type GetWorkspaceByNameArgs = {
  workspace_name: Scalars['String'];
};

export type GetWorkspaceBySlugArgs = {
  workspace_slug: Scalars['String'];
};

export type GetWorkspaceRepositoriesArgs = {
  workspaceId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addRepositoryContributor: AppResponse;
  addWorkspaceMember: AppResponse;
  changeWorkspaceVisibility: AppResponse;
  createNewRepository: AppResponse;
  createNewTimeTrackerSession: AppResponse;
  createNewUser: AppResponse;
  createNewWorkspace: AppResponse;
  deleteRepository: AppResponse;
  deleteUser: AppResponse;
  deleteWorkspace: AppResponse;
  pinRepository: AppResponse;
  pinRepositoryTab: AppResponse;
  ping: Ping;
  removeRepositoryContributor: AppResponse;
  removeTabsFromRepository: AppResponse;
  removeWorkspaceMember: AppResponse;
  selectQuickAccessWorkspace: AppResponse;
  setRepositoryTabs?: Maybe<AppResponse>;
  smartTabGrouping: Array<TabWithCategory>;
  toggleLikeRepository: AppResponse;
  toggleTimeTracker: AppResponse;
  triggerOnBrowsingEventUpdated: BrowsingEvent;
  unpinRepository: AppResponse;
  unpinRepositoryTab: AppResponse;
  updateReadme: AppResponse;
  updateRepositoryAccess: AppResponse;
  updateRepositoryBanner: AppResponse;
  updateRepositoryInfo: Repository;
  updateTimeTrackerSetting: TimeTrackerSessionSetting;
  updateUser: AppResponse;
  updateWorkspace: AppResponse;
};

export type MutationAddRepositoryContributorArgs = {
  addRepositoryContributorArgs: AddContributorArgs;
};

export type MutationAddWorkspaceMemberArgs = {
  addWorkspaceMemberArgs: AddNewMemberArgs;
};

export type MutationChangeWorkspaceVisibilityArgs = {
  changeWorkspaceVisibilityArgs: ChangeWorkspaceVisibilityArgs;
};

export type MutationCreateNewRepositoryArgs = {
  createRepositoryArgs: CreateNewRepositoryArgs;
};

export type MutationCreateNewTimeTrackerSessionArgs = {
  createNewTimeTrackerArgs: CreateNewTimeTrackerArgs;
};

export type MutationCreateNewUserArgs = {
  createNewUserArgs: CreateNewUserArgs;
};

export type MutationCreateNewWorkspaceArgs = {
  createNewWorksapceArgs: CreateNewWorkspaceArgs;
};

export type MutationDeleteRepositoryArgs = {
  deleteRepositoryArgs: DeleteRepositoryArgs;
};

export type MutationDeleteWorkspaceArgs = {
  deleteWorkspaceArgs: DeleteWorkspaceArgs;
};

export type MutationPinRepositoryArgs = {
  pinRepositoryArgs: PinRepositoryArgs;
};

export type MutationPinRepositoryTabArgs = {
  pinRepositoryTabArgs: PinRepositoryTabArgs;
};

export type MutationRemoveRepositoryContributorArgs = {
  removeRepositoryContributorArgs: RemoveContributorArgs;
};

export type MutationRemoveTabsFromRepositoryArgs = {
  removeTabsFromRepositoryArgs: RemoveTabsFromRepositoryArgs;
};

export type MutationRemoveWorkspaceMemberArgs = {
  removeWorkspaceMemberArgs: RemoveMemberArgs;
};

export type MutationSelectQuickAccessWorkspaceArgs = {
  selectQuickAccessWorkspaceArgs: SelectQuickAccessWorkspaceArgs;
};

export type MutationSetRepositoryTabsArgs = {
  setRepositoryTabsArgs: SetRepositoryTabsArgs;
};

export type MutationSmartTabGroupingArgs = {
  smartTabGroupingArgs: SmartTabGroupingArgs;
};

export type MutationToggleLikeRepositoryArgs = {
  toggleLikeRepositoryArgs: ToggleLikeRepositoryArgs;
};

export type MutationTriggerOnBrowsingEventUpdatedArgs = {
  triggerBrowsingEventArgs: TriggerBrowsingEventArgs;
};

export type MutationUnpinRepositoryArgs = {
  unpinRepositoryArgs: PinRepositoryArgs;
};

export type MutationUnpinRepositoryTabArgs = {
  unpinRepositoryTabArgs: UnpinRepositoryTabArgs;
};

export type MutationUpdateReadmeArgs = {
  updateReadmeArgs: UpdateReadmeArgs;
};

export type MutationUpdateRepositoryAccessArgs = {
  updateRepositoryAccessArgs: UpdateRepositoryAccessArgs;
};

export type MutationUpdateRepositoryBannerArgs = {
  updateRepositoryBannerArgs: UpdateRepositoryBannerArgs;
};

export type MutationUpdateRepositoryInfoArgs = {
  updateRepositoryInfoArgs: UpdateRepositoryArgs;
};

export type MutationUpdateTimeTrackerSettingArgs = {
  updateTimeTrackerSettingArgs: TimeTrackerSessionSettingInputType;
};

export type MutationUpdateUserArgs = {
  updateUserArgs: UpdateUserArgs;
};

export type MutationUpdateWorkspaceArgs = {
  updateWorkspaceArgs: UpdateWorkspaceArgs;
};

export type OnBrowsingEventUpdatedArgs = {
  browser_client_id: Scalars['String'];
  subscribed_events: Array<Scalars['String']>;
};

export type PinRepositoryArgs = {
  id: Scalars['String'];
};

export type PinRepositoryTabArgs = {
  repositoryId: Scalars['String'];
  tabId: Scalars['String'];
};

export type Ping = {
  __typename?: 'Ping';
  id: Scalars['String'];
};

export type Pong = {
  __typename?: 'Pong';
  pingId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  gatherUrlsMetadata: Array<RepositoryTab>;
  getAllPublicRepositories: Array<Repository>;
  getAllUsers: Array<User>;
  getAllWorkspaces: Array<Workspace>;
  getCurrentUser?: Maybe<User>;
  getMyWorkspaces: Array<Workspace>;
  getReadmeContent: Scalars['String'];
  getRepositoryBanner: Scalars['String'];
  getRepositoryById: Repository;
  getRepositoryBySlug: Repository;
  getUserByEmail?: Maybe<User>;
  getUserById?: Maybe<User>;
  getUserByUsername?: Maybe<User>;
  getUserRepositories: Array<Repository>;
  getUserTimeTrackerSessions: Array<TimeTrackerSession>;
  getUserWorkspaces: Array<Workspace>;
  getWorkspaceById?: Maybe<Workspace>;
  getWorkspaceByName?: Maybe<Workspace>;
  getWorkspaceBySlug?: Maybe<Workspace>;
  getWorkspaceRepositories: Array<Repository>;
  queryOpenGraphMetadata: AppResponse;
  searchOnWeb: Scalars['String'];
};

export type QueryGatherUrlsMetadataArgs = {
  gatherUrlsMetadataArgs: GatherUrlsMetadataArgs;
};

export type QueryGetAllPublicRepositoriesArgs = {
  getAllPublicRepositoriesArgs: GetAllPublicRepositoriesArgs;
};

export type QueryGetReadmeContentArgs = {
  readReadmeArgs: ReadReadmeArgs;
};

export type QueryGetRepositoryBannerArgs = {
  getRepositoryBannerArgs: GetRepositoryBannerArgs;
};

export type QueryGetRepositoryByIdArgs = {
  getRepositoryByIdArgs: GetRepositoryByIdArgs;
};

export type QueryGetRepositoryBySlugArgs = {
  getRepositoryBySlugArgs: GetRepositoryBySlugArgs;
};

export type QueryGetUserByEmailArgs = {
  getUserByEmailArgs: GetUserByEmailArgs;
};

export type QueryGetUserByIdArgs = {
  getUserByIdArgs: GetUserByIdArgs;
};

export type QueryGetUserByUsernameArgs = {
  getUserByUsernameArgs: GetUserByUsernameArgs;
};

export type QueryGetUserRepositoriesArgs = {
  getUserRepositoriesArgs: GetUserRepositoriesArgs;
};

export type QueryGetUserWorkspacesArgs = {
  getUserWorkspacesArgs: GetUserWorkspacesArgs;
};

export type QueryGetWorkspaceByIdArgs = {
  getWorkspaceByIdArgs: GetWorkspaceByIdArgs;
};

export type QueryGetWorkspaceByNameArgs = {
  getWorkspaceByNameArgs: GetWorkspaceByNameArgs;
};

export type QueryGetWorkspaceBySlugArgs = {
  getWorkspaceBySlugArgs: GetWorkspaceBySlugArgs;
};

export type QueryGetWorkspaceRepositoriesArgs = {
  getWorkspaceRepositoriesArgs: GetWorkspaceRepositoriesArgs;
};

export type QueryQueryOpenGraphMetadataArgs = {
  queryOpenGraphMetadata: QueryOpenGraphMetadataArgs;
};

export type QuerySearchOnWebArgs = {
  searchTabOnWebArgs: SearchTabOnWebArgs;
};

export type QueryOpenGraphMetadataArgs = {
  websiteUrl: Scalars['String'];
};

export type ReadReadmeArgs = {
  repositoryId: Scalars['String'];
};

export type RemoveContributorArgs = {
  id: Scalars['String'];
  member_email: Scalars['String'];
};

export type RemoveMemberArgs = {
  id: Scalars['String'];
  member_email: Scalars['String'];
};

export type RemoveTabsFromRepositoryArgs = {
  id: Scalars['String'];
  tabs: Array<Scalars['String']>;
};

export type Repository = {
  __typename?: 'Repository';
  accessPermission: AccessPermission;
  bannerUrl?: Maybe<Scalars['String']>;
  /** List of user IDs */
  contributors: Array<Scalars['String']>;
  created_date: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  /** List of directory IDs */
  directories: Array<Directory>;
  /** Number of favorites */
  favorite_count: Scalars['Float'];
  /** List of user IDs */
  favorites: Array<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  owner: Scalars['String'];
  /** List of user IDs who can access the repository shared mode */
  permittedUsers: Array<Scalars['String']>;
  /** List of repository tabs IDs */
  pinned: Array<Scalars['String']>;
  readme?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  /** List of repository tab IDs */
  tabs: Array<RepositoryTab>;
  updated_date: Scalars['Float'];
  visibility: AccessVisibility;
  workspaceId: Scalars['String'];
};

export type RepositoryTab = {
  __typename?: 'RepositoryTab';
  customName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  favIconUrl: Scalars['String'];
  id: Scalars['String'];
  /** List of labels */
  labels: Array<Scalars['String']>;
  lastAccessed?: Maybe<Scalars['Float']>;
  parentDirectory?: Maybe<Scalars['String']>;
  /** List of repository tabs IDs */
  pinned: Array<Scalars['String']>;
  repositoryId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type RepositoryTabAsInput = {
  customName?: InputMaybe<Scalars['String']>;
  favIconUrl?: InputMaybe<Scalars['String']>;
  parentDirectory?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type RepositoryTabInput = {
  customName?: InputMaybe<Scalars['String']>;
  description?: Scalars['String'];
  favIconUrl: Scalars['String'];
  id: Scalars['String'];
  /** List of labels */
  labels?: Array<Scalars['String']>;
  lastAccessed?: InputMaybe<Scalars['Float']>;
  parentDirectory?: InputMaybe<Scalars['String']>;
  /** List of repository tabs IDs */
  pinned?: Array<Scalars['String']>;
  repositoryId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum ResponseType {
  Error = 'Error',
  Success = 'Success',
}

export type SearchTabOnWebArgs = {
  websiteUrl: Scalars['String'];
};

export type SelectQuickAccessWorkspaceArgs = {
  id?: InputMaybe<Scalars['String']>;
  updated_date?: Scalars['Float'];
};

export type SetRepositoryTabsArgs = {
  directories: Array<DirectoryAsInput>;
  id: Scalars['String'];
  tabs: Array<FullRepositoryTabAsInput>;
};

export type SmartTabGroupingArgs = {
  groups: Array<Scalars['String']>;
  tabs: Array<RepositoryTabAsInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onBrowsingEventUpdated: BrowsingEvent;
  pong: Pong;
};

export type SubscriptionOnBrowsingEventUpdatedArgs = {
  onBrowsingEventArgs: OnBrowsingEventUpdatedArgs;
};

export type TabWithCategory = {
  __typename?: 'TabWithCategory';
  category: Scalars['String'];
  url: Scalars['String'];
};

/** Engine state for Time Tracker */
export enum TimeTrackerEngineState {
  Idle = 'Idle',
  Offline = 'Offline',
  Running = 'Running',
  Void = 'Void',
}

/** Productivity mode for Time Tracker */
export enum TimeTrackerProductivityMode {
  CasualMode = 'CasualMode',
  FocusMode = 'FocusMode',
}

export type TimeTrackerSession = {
  __typename?: 'TimeTrackerSession';
  date: Scalars['Float'];
  id: Scalars['String'];
  mode: TimeTrackerProductivityMode;
  session_end_time: Scalars['Float'];
  session_start_time: Scalars['Float'];
  state: TimeTrackerEngineState;
  trackedTabs: Scalars['String'];
};

export type TimeTrackerSessionInputType = {
  date: Scalars['Float'];
  id: Scalars['String'];
  mode: TimeTrackerProductivityMode;
  session_end_time: Scalars['Float'];
  session_start_time: Scalars['Float'];
  state: TimeTrackerEngineState;
  trackedTabs?: Scalars['String'];
};

export type TimeTrackerSessionSetting = {
  __typename?: 'TimeTrackerSessionSetting';
  interrupt_on_close: Scalars['Boolean'];
  limit_least_used_time: Scalars['Float'];
  mode: TimeTrackerProductivityMode;
  session_end_time: Scalars['Float'];
  session_start_time: Scalars['Float'];
  setting_enabled_from: Scalars['Float'];
  setting_enabled_to?: Maybe<Scalars['Float']>;
};

export type TimeTrackerSessionSettingInputType = {
  interrupt_on_close?: Scalars['Boolean'];
  limit_least_used_time: Scalars['Float'];
  mode: TimeTrackerProductivityMode;
  session_end_time: Scalars['Float'];
  session_start_time: Scalars['Float'];
  setting_enabled_from: Scalars['Float'];
  setting_enabled_to?: InputMaybe<Scalars['Float']>;
};

export type ToggleLikeRepositoryArgs = {
  id: Scalars['String'];
};

export type TriggerBrowsingEventArgs = {
  browser_client_id?: InputMaybe<Scalars['String']>;
  event_name?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['String']>;
  trigger_at?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type UnpinRepositoryTabArgs = {
  tabId: Scalars['String'];
};

export type UpdateReadmeArgs = {
  readmeData: Scalars['String'];
  repositoryId: Scalars['String'];
};

export type UpdateRepositoryAccessArgs = {
  accessPermission?: AccessPermission;
  id: Scalars['String'];
  permittedUsers?: Array<Scalars['String']>;
};

export type UpdateRepositoryArgs = {
  accessPermission?: InputMaybe<AccessPermission>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  /** List of user IDs */
  contributors?: InputMaybe<Array<Scalars['String']>>;
  created_date?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  /** List of directory IDs */
  directories?: InputMaybe<Array<DirectoryAsInput>>;
  /** Number of favorites */
  favorite_count?: InputMaybe<Scalars['Float']>;
  /** List of user IDs */
  favorites?: InputMaybe<Array<Scalars['String']>>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  /** List of user IDs who can access the repository shared mode */
  permittedUsers?: InputMaybe<Array<Scalars['String']>>;
  /** List of repository tabs IDs */
  pinned?: InputMaybe<Array<Scalars['String']>>;
  readme?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  /** List of repository tab IDs */
  tabs?: InputMaybe<Array<RepositoryTabInput>>;
  updated_date?: InputMaybe<Scalars['Float']>;
  visibility?: InputMaybe<AccessVisibility>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type UpdateRepositoryBannerArgs = {
  bannerData: Scalars['Upload'];
  mimeType: Scalars['String'];
  repositoryId: Scalars['String'];
};

export type UpdateUserArgs = {
  created_date?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  favorites?: InputMaybe<Array<Scalars['String']>>;
  /** List of user IDs */
  followers?: InputMaybe<Array<Scalars['String']>>;
  /** List of user IDs */
  following?: InputMaybe<Array<Scalars['String']>>;
  full_name?: InputMaybe<Scalars['String']>;
  /** List of repositories IDs */
  pinned_repositories?: InputMaybe<Array<Scalars['String']>>;
  /** List of repository tabs IDs */
  pinned_tabs?: InputMaybe<Array<RepositoryTabInput>>;
  profile_image?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  /** ID of selected workspace */
  selected_workspace?: InputMaybe<Scalars['String']>;
  time_tracker_enabled?: InputMaybe<Scalars['Boolean']>;
  time_tracker_sessions?: InputMaybe<Array<TimeTrackerSessionInputType>>;
  time_tracker_setting?: InputMaybe<TimeTrackerSessionSettingInputType>;
  updated_date?: InputMaybe<Scalars['Float']>;
  username?: InputMaybe<Scalars['String']>;
  /** List of workspace IDs */
  workspaces?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateWorkspaceArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  updated_date?: Scalars['Float'];
  visibility: AccessVisibility;
};

export type User = {
  __typename?: 'User';
  created_date: Scalars['Float'];
  email: Scalars['String'];
  favorites: Array<Scalars['String']>;
  /** List of user IDs */
  followers: Array<Scalars['String']>;
  /** List of user IDs */
  following: Array<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** List of repositories IDs */
  pinned_repositories: Array<Scalars['String']>;
  /** List of repository tabs IDs */
  pinned_tabs: Array<RepositoryTab>;
  profile_image?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  /** ID of selected workspace */
  selected_workspace?: Maybe<Scalars['String']>;
  time_tracker_enabled: Scalars['Boolean'];
  time_tracker_sessions: Array<TimeTrackerSession>;
  time_tracker_setting?: Maybe<TimeTrackerSessionSetting>;
  updated_date: Scalars['Float'];
  username: Scalars['String'];
  /** List of workspace IDs */
  workspaces: Array<Scalars['String']>;
};

/** Type of user roles in the system */
export enum UserRole {
  PublicUser = 'PublicUser',
  RepositoryContributor = 'RepositoryContributor',
  WorkspaceMember = 'WorkspaceMember',
  WorkspaceModerator = 'WorkspaceModerator',
  WorkspaceOwner = 'WorkspaceOwner',
}

export type Workspace = {
  __typename?: 'Workspace';
  created_date: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  members: Array<Scalars['String']>;
  name: Scalars['String'];
  owner: Scalars['String'];
  repositories: Array<Scalars['String']>;
  roles: Array<UserRole>;
  slug: Scalars['String'];
  updated_date: Scalars['Float'];
  visibility: AccessVisibility;
};

export type TriggerOnBrowsingEventUpdatedMutationVariables = Exact<{
  triggerBrowsingEventArgs: TriggerBrowsingEventArgs;
}>;

export type TriggerOnBrowsingEventUpdatedMutation = {
  __typename?: 'Mutation';
  triggerOnBrowsingEventUpdated: {
    __typename?: 'BrowsingEvent';
    trigger_at: number;
    browser_client_id: string;
    event_name: string;
    user_id?: string | null;
    properties?: string | null;
  };
};

export type OnBrowsingEventUpdatedSubscriptionVariables = Exact<{
  onBrowsingEventUpdatedArgs: OnBrowsingEventUpdatedArgs;
}>;

export type OnBrowsingEventUpdatedSubscription = {
  __typename?: 'Subscription';
  onBrowsingEventUpdated: {
    __typename?: 'BrowsingEvent';
    trigger_at: number;
    browser_client_id: string;
    event_name: string;
    user_id?: string | null;
    properties?: string | null;
  };
};

export type PongSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PongSubscription = {
  __typename?: 'Subscription';
  pong: { __typename?: 'Pong'; pingId: string };
};

export type SetRepositoryTabsMutationVariables = Exact<{
  repositoryId: Scalars['String'];
  directories: Array<DirectoryAsInput> | DirectoryAsInput;
  repositoryTabs: Array<FullRepositoryTabAsInput> | FullRepositoryTabAsInput;
}>;

export type SetRepositoryTabsMutation = {
  __typename?: 'Mutation';
  setRepositoryTabs?: { __typename?: 'AppResponse'; message: string; type: ResponseType } | null;
};

export type GetAllPublicRepositoriesQueryVariables = Exact<{
  limit: Scalars['Float'];
  offset: Scalars['Float'];
}>;

export type GetAllPublicRepositoriesQuery = {
  __typename?: 'Query';
  getAllPublicRepositories: Array<{
    __typename?: 'Repository';
    icon: string;
    contributors: Array<string>;
    created_date: number;
    description?: string | null;
    favorites: Array<string>;
    favorite_count: number;
    id: string;
    name: string;
    slug: string;
    readme?: string | null;
    bannerUrl?: string | null;
    owner: string;
    pinned: Array<string>;
    accessPermission: AccessPermission;
    permittedUsers: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
    workspaceId: string;
    tabs: Array<{
      __typename?: 'RepositoryTab';
      customName?: string | null;
      favIconUrl: string;
      id: string;
      pinned: Array<string>;
      lastAccessed?: number | null;
      repositoryId?: string | null;
      description: string;
      parentDirectory?: string | null;
      labels: Array<string>;
      title: string;
      url: string;
    }>;
    directories: Array<{
      __typename?: 'Directory';
      id: string;
      color: string;
      name: string;
      parentDirectory?: string | null;
    }>;
  }>;
};

export type GetWorkspaceRepositoriesQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;

export type GetWorkspaceRepositoriesQuery = {
  __typename?: 'Query';
  getWorkspaceRepositories: Array<{
    __typename?: 'Repository';
    icon: string;
    contributors: Array<string>;
    created_date: number;
    description?: string | null;
    favorites: Array<string>;
    favorite_count: number;
    id: string;
    name: string;
    slug: string;
    readme?: string | null;
    bannerUrl?: string | null;
    owner: string;
    pinned: Array<string>;
    accessPermission: AccessPermission;
    permittedUsers: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
    workspaceId: string;
    tabs: Array<{
      __typename?: 'RepositoryTab';
      customName?: string | null;
      favIconUrl: string;
      id: string;
      pinned: Array<string>;
      labels: Array<string>;
      lastAccessed?: number | null;
      description: string;
      repositoryId?: string | null;
      parentDirectory?: string | null;
      title: string;
      url: string;
    }>;
    directories: Array<{
      __typename?: 'Directory';
      id: string;
      color: string;
      name: string;
      parentDirectory?: string | null;
    }>;
  }>;
};

export type GetUserRepositoriesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetUserRepositoriesQuery = {
  __typename?: 'Query';
  getUserRepositories: Array<{
    __typename?: 'Repository';
    icon: string;
    contributors: Array<string>;
    created_date: number;
    description?: string | null;
    favorites: Array<string>;
    favorite_count: number;
    id: string;
    name: string;
    slug: string;
    readme?: string | null;
    bannerUrl?: string | null;
    owner: string;
    pinned: Array<string>;
    accessPermission: AccessPermission;
    permittedUsers: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
    workspaceId: string;
    tabs: Array<{
      __typename?: 'RepositoryTab';
      customName?: string | null;
      favIconUrl: string;
      id: string;
      pinned: Array<string>;
      labels: Array<string>;
      lastAccessed?: number | null;
      description: string;
      repositoryId?: string | null;
      parentDirectory?: string | null;
      title: string;
      url: string;
    }>;
    directories: Array<{
      __typename?: 'Directory';
      id: string;
      color: string;
      name: string;
      parentDirectory?: string | null;
    }>;
  }>;
};

export type GetRepositoryByIdQueryVariables = Exact<{
  repositoryId: Scalars['String'];
}>;

export type GetRepositoryByIdQuery = {
  __typename?: 'Query';
  getRepositoryById: {
    __typename?: 'Repository';
    icon: string;
    contributors: Array<string>;
    created_date: number;
    description?: string | null;
    favorites: Array<string>;
    favorite_count: number;
    id: string;
    name: string;
    slug: string;
    readme?: string | null;
    bannerUrl?: string | null;
    owner: string;
    pinned: Array<string>;
    accessPermission: AccessPermission;
    permittedUsers: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
    workspaceId: string;
    tabs: Array<{
      __typename?: 'RepositoryTab';
      customName?: string | null;
      favIconUrl: string;
      id: string;
      pinned: Array<string>;
      labels: Array<string>;
      lastAccessed?: number | null;
      description: string;
      repositoryId?: string | null;
      parentDirectory?: string | null;
      title: string;
      url: string;
    }>;
    directories: Array<{
      __typename?: 'Directory';
      id: string;
      color: string;
      name: string;
      parentDirectory?: string | null;
    }>;
  };
};

export type GetRepositoryBySlugQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  slug: Scalars['String'];
}>;

export type GetRepositoryBySlugQuery = {
  __typename?: 'Query';
  getRepositoryBySlug: {
    __typename?: 'Repository';
    icon: string;
    contributors: Array<string>;
    created_date: number;
    description?: string | null;
    favorites: Array<string>;
    favorite_count: number;
    id: string;
    name: string;
    slug: string;
    readme?: string | null;
    bannerUrl?: string | null;
    owner: string;
    pinned: Array<string>;
    accessPermission: AccessPermission;
    permittedUsers: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
    workspaceId: string;
    tabs: Array<{
      __typename?: 'RepositoryTab';
      customName?: string | null;
      favIconUrl: string;
      id: string;
      pinned: Array<string>;
      labels: Array<string>;
      lastAccessed?: number | null;
      description: string;
      parentDirectory?: string | null;
      repositoryId?: string | null;
      title: string;
      url: string;
    }>;
    directories: Array<{
      __typename?: 'Directory';
      id: string;
      color: string;
      name: string;
      parentDirectory?: string | null;
    }>;
  };
};

export type UpdateRepositoryMutationVariables = Exact<{
  id: Scalars['String'];
  icon?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<AccessVisibility>;
}>;

export type UpdateRepositoryMutation = {
  __typename?: 'Mutation';
  updateRepositoryInfo: {
    __typename?: 'Repository';
    icon: string;
    contributors: Array<string>;
    created_date: number;
    description?: string | null;
    favorites: Array<string>;
    favorite_count: number;
    id: string;
    name: string;
    slug: string;
    readme?: string | null;
    bannerUrl?: string | null;
    owner: string;
    pinned: Array<string>;
    accessPermission: AccessPermission;
    permittedUsers: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
    workspaceId: string;
    tabs: Array<{
      __typename?: 'RepositoryTab';
      customName?: string | null;
      favIconUrl: string;
      id: string;
      pinned: Array<string>;
      lastAccessed?: number | null;
      labels: Array<string>;
      description: string;
      parentDirectory?: string | null;
      repositoryId?: string | null;
      title: string;
      url: string;
    }>;
    directories: Array<{
      __typename?: 'Directory';
      id: string;
      color: string;
      name: string;
      parentDirectory?: string | null;
    }>;
  };
};

export type CreateNewRepositoryMutationVariables = Exact<{
  repositorySlug?: InputMaybe<Scalars['String']>;
  icon: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  visibility: AccessVisibility;
  tabs: Array<RepositoryTabAsInput> | RepositoryTabAsInput;
  directories: Array<DirectoryAsInput> | DirectoryAsInput;
  workspaceId: Scalars['String'];
}>;

export type CreateNewRepositoryMutation = {
  __typename?: 'Mutation';
  createNewRepository: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type DeleteRepositoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteRepositoryMutation = {
  __typename?: 'Mutation';
  deleteRepository: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type PinRepositoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type PinRepositoryMutation = {
  __typename?: 'Mutation';
  pinRepository: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UnpinRepositoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type UnpinRepositoryMutation = {
  __typename?: 'Mutation';
  unpinRepository: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type RemoveTabsFromRepositoryMutationVariables = Exact<{
  tabIds: Array<Scalars['String']> | Scalars['String'];
  repositoryId: Scalars['String'];
}>;

export type RemoveTabsFromRepositoryMutation = {
  __typename?: 'Mutation';
  removeTabsFromRepository: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type RemoveContributorMutationVariables = Exact<{
  repositoryId: Scalars['String'];
  contributorEmail: Scalars['String'];
}>;

export type RemoveContributorMutation = {
  __typename?: 'Mutation';
  removeRepositoryContributor: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type AddContributorMutationVariables = Exact<{
  repositoryId: Scalars['String'];
  contributorEmail: Scalars['String'];
}>;

export type AddContributorMutation = {
  __typename?: 'Mutation';
  addRepositoryContributor: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type ToggleLikeRepositoryMutationVariables = Exact<{
  repositoryId: Scalars['String'];
}>;

export type ToggleLikeRepositoryMutation = {
  __typename?: 'Mutation';
  toggleLikeRepository: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UpdateRepositoryAccessMutationVariables = Exact<{
  accessPermission: AccessPermission;
  repositoryId: Scalars['String'];
  permittedUsers: Array<Scalars['String']> | Scalars['String'];
}>;

export type UpdateRepositoryAccessMutation = {
  __typename?: 'Mutation';
  updateRepositoryAccess: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UpdateReadmeMutationVariables = Exact<{
  repositoryId: Scalars['String'];
  readmeData: Scalars['String'];
}>;

export type UpdateReadmeMutation = {
  __typename?: 'Mutation';
  updateReadme: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UpdateRepositoryBannerMutationVariables = Exact<{
  repositoryId: Scalars['String'];
  bannerData: Scalars['Upload'];
  mimeType: Scalars['String'];
}>;

export type UpdateRepositoryBannerMutation = {
  __typename?: 'Mutation';
  updateRepositoryBanner: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type GetReadmeContentQueryVariables = Exact<{
  repositoryId: Scalars['String'];
}>;

export type GetReadmeContentQuery = { __typename?: 'Query'; getReadmeContent: string };

export type GetRepositoryBannerQueryVariables = Exact<{
  repositoryId: Scalars['String'];
}>;

export type GetRepositoryBannerQuery = { __typename?: 'Query'; getRepositoryBanner: string };

export type PinRepositoryTabMutationVariables = Exact<{
  tabId: Scalars['String'];
  repositoryId: Scalars['String'];
}>;

export type PinRepositoryTabMutation = {
  __typename?: 'Mutation';
  pinRepositoryTab: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UnpinRepositoryTabMutationVariables = Exact<{
  tabId: Scalars['String'];
}>;

export type UnpinRepositoryTabMutation = {
  __typename?: 'Mutation';
  unpinRepositoryTab: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type SearchTabOnWebQueryVariables = Exact<{
  websiteUrl: Scalars['String'];
}>;

export type SearchTabOnWebQuery = { __typename?: 'Query'; searchOnWeb: string };

export type GatherUrlsMetadataQueryVariables = Exact<{
  websiteUrls: Array<Scalars['String']> | Scalars['String'];
}>;

export type GatherUrlsMetadataQuery = {
  __typename?: 'Query';
  gatherUrlsMetadata: Array<{
    __typename?: 'RepositoryTab';
    customName?: string | null;
    favIconUrl: string;
    id: string;
    pinned: Array<string>;
    labels: Array<string>;
    description: string;
    parentDirectory?: string | null;
    repositoryId?: string | null;
    title: string;
    url: string;
  }>;
};

export type QueryOpenGraphMetadataQueryVariables = Exact<{
  websiteUrl: Scalars['String'];
}>;

export type QueryOpenGraphMetadataQuery = {
  __typename?: 'Query';
  queryOpenGraphMetadata: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UpdateTimeTrackerSettingMutationMutationVariables = Exact<{
  setting: TimeTrackerSessionSettingInputType;
}>;

export type UpdateTimeTrackerSettingMutationMutation = {
  __typename?: 'Mutation';
  updateTimeTrackerSetting: {
    __typename?: 'TimeTrackerSessionSetting';
    limit_least_used_time: number;
    mode: TimeTrackerProductivityMode;
    session_start_time: number;
    session_end_time: number;
    setting_enabled_from: number;
    setting_enabled_to?: number | null;
    interrupt_on_close: boolean;
  };
};

export type ToggleTimeTrackerMutationVariables = Exact<{ [key: string]: never }>;

export type ToggleTimeTrackerMutation = {
  __typename?: 'Mutation';
  toggleTimeTracker: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type CreateNewTimeTrackerSessionMutationVariables = Exact<{
  createNewTimeTrackerSessionArgs: CreateNewTimeTrackerArgs;
}>;

export type CreateNewTimeTrackerSessionMutation = {
  __typename?: 'Mutation';
  createNewTimeTrackerSession: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type Core_User_Workspace_FragmentFragment = {
  __typename?: 'User';
  pinned_repositories: Array<string>;
  selected_workspace?: string | null;
  workspaces: Array<string>;
  pinned_tabs: Array<{
    __typename?: 'RepositoryTab';
    customName?: string | null;
    favIconUrl: string;
    id: string;
    pinned: Array<string>;
    repositoryId?: string | null;
    description: string;
    parentDirectory?: string | null;
    labels: Array<string>;
    title: string;
    url: string;
  }>;
} & { ' $fragmentName'?: 'Core_User_Workspace_FragmentFragment' };

export type Core_User_Fields_FragmentFragment = {
  __typename?: 'User';
  created_date: number;
  email: string;
  followers: Array<string>;
  following: Array<string>;
  full_name?: string | null;
  id: string;
  username: string;
  favorites: Array<string>;
  profile_image?: string | null;
  provider: string;
  updated_date: number;
} & { ' $fragmentName'?: 'Core_User_Fields_FragmentFragment' };

export type User_Time_Tracker_Setting_FragmentFragment = {
  __typename?: 'User';
  time_tracker_enabled: boolean;
  time_tracker_setting?: {
    __typename?: 'TimeTrackerSessionSetting';
    limit_least_used_time: number;
    mode: TimeTrackerProductivityMode;
    session_start_time: number;
    session_end_time: number;
    setting_enabled_from: number;
    setting_enabled_to?: number | null;
  } | null;
  time_tracker_sessions: Array<{
    __typename?: 'TimeTrackerSession';
    id: string;
    mode: TimeTrackerProductivityMode;
    state: TimeTrackerEngineState;
    session_start_time: number;
    session_end_time: number;
    date: number;
    trackedTabs: string;
  }>;
} & { ' $fragmentName'?: 'User_Time_Tracker_Setting_FragmentFragment' };

export type CreateNewUserMutationVariables = Exact<{
  uid: Scalars['String'];
  profile_image: Scalars['String'];
  email: Scalars['String'];
  username: Scalars['String'];
  full_name?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
}>;

export type CreateNewUserMutation = {
  __typename?: 'Mutation';
  createNewUser: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type SmartTabGroupingMutationVariables = Exact<{
  tabs: Array<RepositoryTabAsInput> | RepositoryTabAsInput;
  group: Array<Scalars['String']> | Scalars['String'];
}>;

export type SmartTabGroupingMutation = {
  __typename?: 'Mutation';
  smartTabGrouping: Array<{ __typename?: 'TabWithCategory'; category: string; url: string }>;
};

export type UpdateUserMutationVariables = Exact<{
  username: Scalars['String'];
  full_name: Scalars['String'];
  profile_image: Scalars['String'];
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'AppResponse'; type: ResponseType; message: string };
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  getUserById?:
    | ({ __typename?: 'User' } & {
        ' $fragmentRefs'?: {
          Core_User_Fields_FragmentFragment: Core_User_Fields_FragmentFragment;
          Core_User_Workspace_FragmentFragment: Core_User_Workspace_FragmentFragment;
        };
      })
    | null;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type GetUserByEmailQuery = {
  __typename?: 'Query';
  getUserByEmail?:
    | ({ __typename?: 'User' } & {
        ' $fragmentRefs'?: {
          Core_User_Fields_FragmentFragment: Core_User_Fields_FragmentFragment;
          Core_User_Workspace_FragmentFragment: Core_User_Workspace_FragmentFragment;
        };
      })
    | null;
};

export type GetUserByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;

export type GetUserByUsernameQuery = {
  __typename?: 'Query';
  getUserByUsername?:
    | ({ __typename?: 'User' } & {
        ' $fragmentRefs'?: {
          Core_User_Fields_FragmentFragment: Core_User_Fields_FragmentFragment;
          Core_User_Workspace_FragmentFragment: Core_User_Workspace_FragmentFragment;
        };
      })
    | null;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: 'Query';
  getAllUsers: Array<
    { __typename?: 'User' } & {
      ' $fragmentRefs'?: {
        Core_User_Fields_FragmentFragment: Core_User_Fields_FragmentFragment;
        Core_User_Workspace_FragmentFragment: Core_User_Workspace_FragmentFragment;
      };
    }
  >;
};

export type GetAllWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllWorkspacesQuery = {
  __typename?: 'Query';
  getAllWorkspaces: Array<{
    __typename?: 'Workspace';
    created_date: number;
    description?: string | null;
    id: string;
    slug: string;
    members: Array<string>;
    roles: Array<UserRole>;
    name: string;
    owner: string;
    repositories: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
  }>;
};

export type GetUserWorkspacesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetUserWorkspacesQuery = {
  __typename?: 'Query';
  getUserWorkspaces: Array<{
    __typename?: 'Workspace';
    created_date: number;
    description?: string | null;
    id: string;
    slug: string;
    members: Array<string>;
    roles: Array<UserRole>;
    name: string;
    owner: string;
    repositories: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
  }>;
};

export type GetWorkspaceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetWorkspaceByIdQuery = {
  __typename?: 'Query';
  getWorkspaceById?: {
    __typename?: 'Workspace';
    created_date: number;
    description?: string | null;
    id: string;
    slug: string;
    members: Array<string>;
    roles: Array<UserRole>;
    name: string;
    owner: string;
    repositories: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
  } | null;
};

export type GetWorkspaceByNameQueryVariables = Exact<{
  workspaceName: Scalars['String'];
}>;

export type GetWorkspaceByNameQuery = {
  __typename?: 'Query';
  getWorkspaceByName?: {
    __typename?: 'Workspace';
    created_date: number;
    description?: string | null;
    id: string;
    slug: string;
    members: Array<string>;
    roles: Array<UserRole>;
    name: string;
    owner: string;
    repositories: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
  } | null;
};

export type GetWorkspaceBySlugQueryVariables = Exact<{
  workspaceSlug: Scalars['String'];
}>;

export type GetWorkspaceBySlugQuery = {
  __typename?: 'Query';
  getWorkspaceBySlug?: {
    __typename?: 'Workspace';
    created_date: number;
    description?: string | null;
    id: string;
    slug: string;
    members: Array<string>;
    roles: Array<UserRole>;
    name: string;
    owner: string;
    repositories: Array<string>;
    updated_date: number;
    visibility: AccessVisibility;
  } | null;
};

export type GetMyWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyWorkspacesQuery = {
  __typename?: 'Query';
  getMyWorkspaces: Array<{
    __typename?: 'Workspace';
    id: string;
    slug: string;
    name: string;
    owner: string;
    repositories: Array<string>;
    visibility: AccessVisibility;
    members: Array<string>;
    roles: Array<UserRole>;
    created_date: number;
    description?: string | null;
    updated_date: number;
  }>;
};

export type CreateNewWorkspaceMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  visibility: Scalars['Float'];
}>;

export type CreateNewWorkspaceMutation = {
  __typename?: 'Mutation';
  createNewWorkspace: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type UpdateWorkspaceMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  visibility: AccessVisibility;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation';
  updateWorkspace: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type DeleteWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['String'];
}>;

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  deleteWorkspace: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type AddWorkspaceMemberMutationVariables = Exact<{
  workspaceId: Scalars['String'];
  memberEmail: Scalars['String'];
  role: UserRole;
}>;

export type AddWorkspaceMemberMutation = {
  __typename?: 'Mutation';
  addWorkspaceMember: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type RemoveWorkspaceMemberMutationVariables = Exact<{
  workspaceId: Scalars['String'];
  memberEmail: Scalars['String'];
}>;

export type RemoveWorkspaceMemberMutation = {
  __typename?: 'Mutation';
  removeWorkspaceMember: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export type SelectQuickAccessWorkspaceMutationVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['String']>;
}>;

export type SelectQuickAccessWorkspaceMutation = {
  __typename?: 'Mutation';
  selectQuickAccessWorkspace: { __typename?: 'AppResponse'; message: string; type: ResponseType };
};

export const Core_User_Workspace_FragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pinned_repositories' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinned_tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected_workspace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workspaces' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Core_User_Workspace_FragmentFragment, unknown>;
export const Core_User_Fields_FragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'following' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profile_image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Core_User_Fields_FragmentFragment, unknown>;
export const User_Time_Tracker_Setting_FragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'USER_TIME_TRACKER_SETTING_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'time_tracker_enabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'time_tracker_setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'limit_least_used_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'session_start_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'session_end_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setting_enabled_from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setting_enabled_to' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'time_tracker_sessions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'session_start_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'session_end_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackedTabs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<User_Time_Tracker_Setting_FragmentFragment, unknown>;
export const TriggerOnBrowsingEventUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TriggerOnBrowsingEventUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'triggerBrowsingEventArgs' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerBrowsingEventArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerOnBrowsingEventUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'triggerBrowsingEventArgs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'triggerBrowsingEventArgs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trigger_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'browser_client_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TriggerOnBrowsingEventUpdatedMutation,
  TriggerOnBrowsingEventUpdatedMutationVariables
>;
export const OnBrowsingEventUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnBrowsingEventUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onBrowsingEventUpdatedArgs' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OnBrowsingEventUpdatedArgs' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onBrowsingEventUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onBrowsingEventArgs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'onBrowsingEventUpdatedArgs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'trigger_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'browser_client_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'event_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnBrowsingEventUpdatedSubscription,
  OnBrowsingEventUpdatedSubscriptionVariables
>;
export const PongDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Pong' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pong' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pingId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PongSubscription, PongSubscriptionVariables>;
export const SetRepositoryTabsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetRepositoryTabs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directories' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryAsInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryTabs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FullRepositoryTabAsInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setRepositoryTabs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setRepositoryTabsArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryTabs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'directories' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'directories' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetRepositoryTabsMutation, SetRepositoryTabsMutationVariables>;
export const GetAllPublicRepositoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPublicRepositories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllPublicRepositories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getAllPublicRepositoriesArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorite_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permittedUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workspaceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllPublicRepositoriesQuery, GetAllPublicRepositoriesQueryVariables>;
export const GetWorkspaceRepositoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkspaceRepositories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceRepositories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getWorkspaceRepositoriesArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorite_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permittedUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workspaceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceRepositoriesQuery, GetWorkspaceRepositoriesQueryVariables>;
export const GetUserRepositoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserRepositories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserRepositories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUserRepositoriesArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorite_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permittedUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workspaceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserRepositoriesQuery, GetUserRepositoriesQueryVariables>;
export const GetRepositoryByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRepositoryById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRepositoryById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getRepositoryByIdArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorite_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permittedUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workspaceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRepositoryByIdQuery, GetRepositoryByIdQueryVariables>;
export const GetRepositoryBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRepositoryBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRepositoryBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getRepositoryBySlugArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorite_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permittedUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workspaceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRepositoryBySlugQuery, GetRepositoryBySlugQueryVariables>;
export const UpdateRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'icon' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessVisibility' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRepositoryInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateRepositoryInfoArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'icon' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'icon' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favorite_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessPermission' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permittedUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workspaceId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRepositoryMutation, UpdateRepositoryMutationVariables>;
export const CreateNewRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositorySlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'icon' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessVisibility' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'RepositoryTabAsInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directories' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryAsInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createRepositoryArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'repositorySlug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositorySlug' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'icon' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'icon' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tabs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'directories' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'directories' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNewRepositoryMutation, CreateNewRepositoryMutationVariables>;
export const DeleteRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteRepositoryArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRepositoryMutation, DeleteRepositoryMutationVariables>;
export const PinRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PinRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pinRepositoryArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PinRepositoryMutation, PinRepositoryMutationVariables>;
export const UnpinRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnpinRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unpinRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'unpinRepositoryArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpinRepositoryMutation, UnpinRepositoryMutationVariables>;
export const RemoveTabsFromRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveTabsFromRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeTabsFromRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removeTabsFromRepositoryArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tabIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveTabsFromRepositoryMutation,
  RemoveTabsFromRepositoryMutationVariables
>;
export const RemoveContributorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveContributor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributorEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeRepositoryContributor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removeRepositoryContributorArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contributorEmail' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveContributorMutation, RemoveContributorMutationVariables>;
export const AddContributorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddContributor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contributorEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addRepositoryContributor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addRepositoryContributorArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contributorEmail' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddContributorMutation, AddContributorMutationVariables>;
export const ToggleLikeRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleLikeRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleLikeRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toggleLikeRepositoryArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ToggleLikeRepositoryMutation, ToggleLikeRepositoryMutationVariables>;
export const UpdateRepositoryAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRepositoryAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accessPermission' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessPermission' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permittedUsers' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRepositoryAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateRepositoryAccessArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accessPermission' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accessPermission' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'permittedUsers' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'permittedUsers' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRepositoryAccessMutation,
  UpdateRepositoryAccessMutationVariables
>;
export const UpdateReadmeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateREADME' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'readmeData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReadme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateReadmeArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'repositoryId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'readmeData' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'readmeData' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateReadmeMutation, UpdateReadmeMutationVariables>;
export const UpdateRepositoryBannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRepositoryBanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bannerData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mimeType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRepositoryBanner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateRepositoryBannerArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'repositoryId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bannerData' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bannerData' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mimeType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'mimeType' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRepositoryBannerMutation,
  UpdateRepositoryBannerMutationVariables
>;
export const GetReadmeContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReadmeContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReadmeContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'readReadmeArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'repositoryId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReadmeContentQuery, GetReadmeContentQueryVariables>;
export const GetRepositoryBannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRepositoryBanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRepositoryBanner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getRepositoryBannerArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'repositoryId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRepositoryBannerQuery, GetRepositoryBannerQueryVariables>;
export const PinRepositoryTabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PinRepositoryTab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinRepositoryTab' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pinRepositoryTabArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tabId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'repositoryId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'repositoryId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PinRepositoryTabMutation, PinRepositoryTabMutationVariables>;
export const UnpinRepositoryTabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnpinRepositoryTab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unpinRepositoryTab' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'unpinRepositoryTabArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tabId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnpinRepositoryTabMutation, UnpinRepositoryTabMutationVariables>;
export const SearchTabOnWebDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchTabOnWeb' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'websiteUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOnWeb' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTabOnWebArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'websiteUrl' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'websiteUrl' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchTabOnWebQuery, SearchTabOnWebQueryVariables>;
export const GatherUrlsMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GatherUrlsMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'websiteUrls' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gatherUrlsMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gatherUrlsMetadataArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'websiteUrls' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'websiteUrls' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GatherUrlsMetadataQuery, GatherUrlsMetadataQueryVariables>;
export const QueryOpenGraphMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryOpenGraphMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'websiteUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'queryOpenGraphMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'queryOpenGraphMetadata' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'websiteUrl' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'websiteUrl' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryOpenGraphMetadataQuery, QueryOpenGraphMetadataQueryVariables>;
export const UpdateTimeTrackerSettingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTimeTrackerSettingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setting' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TimeTrackerSessionSettingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTimeTrackerSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateTimeTrackerSettingArgs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setting' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'limit_least_used_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'session_start_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'session_end_time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setting_enabled_from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setting_enabled_to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interrupt_on_close' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTimeTrackerSettingMutationMutation,
  UpdateTimeTrackerSettingMutationMutationVariables
>;
export const ToggleTimeTrackerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleTimeTracker' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleTimeTracker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ToggleTimeTrackerMutation, ToggleTimeTrackerMutationVariables>;
export const CreateNewTimeTrackerSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewTimeTrackerSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createNewTimeTrackerSessionArgs' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateNewTimeTrackerArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewTimeTrackerSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createNewTimeTrackerArgs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createNewTimeTrackerSessionArgs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewTimeTrackerSessionMutation,
  CreateNewTimeTrackerSessionMutationVariables
>;
export const CreateNewUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profile_image' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'full_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createNewUserArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'uid' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profile_image' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'profile_image' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'full_name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'full_name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNewUserMutation, CreateNewUserMutationVariables>;
export const SmartTabGroupingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SmartTabGrouping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'RepositoryTabAsInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'group' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'smartTabGrouping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'smartTabGroupingArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tabs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'group' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SmartTabGroupingMutation, SmartTabGroupingMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'full_name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profile_image' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateUserArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'full_name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'full_name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profile_image' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'profile_image' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUserByIdArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'following' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profile_image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pinned_repositories' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinned_tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected_workspace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workspaces' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUserByEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserByEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserByEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUserByEmailArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'following' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profile_image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pinned_repositories' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinned_tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected_workspace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workspaces' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUserByUsernameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserByUsername' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserByUsername' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUserByUsernameArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'following' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profile_image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pinned_repositories' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinned_tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected_workspace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workspaces' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>;
export const GetAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_FIELDS_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'following' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'favorites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profile_image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CORE_USER_WORKSPACE_FRAGMENT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pinned_repositories' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pinned_tabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favIconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositoryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentDirectory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected_workspace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workspaces' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetAllWorkspacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllWorkspaces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllWorkspaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllWorkspacesQuery, GetAllWorkspacesQueryVariables>;
export const GetUserWorkspacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserWorkspaces' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserWorkspaces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUserWorkspacesArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserWorkspacesQuery, GetUserWorkspacesQueryVariables>;
export const GetWorkspaceByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkspaceById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getWorkspaceByIdArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceByIdQuery, GetWorkspaceByIdQueryVariables>;
export const GetWorkspaceByNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkspaceByName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceByName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getWorkspaceByNameArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspace_name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceName' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceByNameQuery, GetWorkspaceByNameQueryVariables>;
export const GetWorkspaceBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkspaceBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getWorkspaceBySlugArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspace_slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceSlug' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceBySlugQuery, GetWorkspaceBySlugQueryVariables>;
export const GetMyWorkspacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMyWorkspaces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMyWorkspaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repositories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyWorkspacesQuery, GetMyWorkspacesQueryVariables>;
export const CreateNewWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createNewWorksapceArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNewWorkspaceMutation, CreateNewWorkspaceMutationVariables>;
export const UpdateWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessVisibility' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateWorkspaceArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const DeleteWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteWorkspaceArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const AddWorkspaceMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddWorkspaceMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserRole' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addWorkspaceMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addWorkspaceMemberArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'memberEmail' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddWorkspaceMemberMutation, AddWorkspaceMemberMutationVariables>;
export const RemoveWorkspaceMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveWorkspaceMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removeWorkspaceMemberArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'memberEmail' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveWorkspaceMemberMutation, RemoveWorkspaceMemberMutationVariables>;
export const SelectQuickAccessWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SelectQuickAccessWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectQuickAccessWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectQuickAccessWorkspaceArgs' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectQuickAccessWorkspaceMutation,
  SelectQuickAccessWorkspaceMutationVariables
>;
