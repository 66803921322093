import { EXTENSION_ID } from '@/constants/env';

import { X_WEB_API } from '../constants/x-web-api';

export type ResponsePayload<T> = {
  data: T;
  success: boolean;
  error?: string;
};

export type MessagePayload<T> = {
  message: string;
  params?: T;
};

class PostComunicatorService {
  private extensionId: string;
  constructor(_extensionId: string) {
    this.extensionId = _extensionId;
  }

  async send<T, P>({ message, params }: MessagePayload<P>): Promise<T> {
    if (!X_WEB_API.runtime) throw new Error('No runtime');
    return new Promise((resolve, reject) => {
      X_WEB_API.runtime.sendMessage(
        this.extensionId,
        {
          message,
          params,
        },
        (response: ResponsePayload<T>) => {
          if (!response.success) {
            reject(new Error(response.error));
            return;
          }
          resolve(response.data);
        }
      );
    });
  }
}

export const communicatorService = new PostComunicatorService(EXTENSION_ID);
