import React from 'react';

import Lottie from 'lottie-react';

import { WebSearchLottieFile } from '@/animations';
import { GLOBAL_THEME_COLOR } from '@/constants';

import SplashScreenPlaceholder from '../splash-screen-placeholder';

type Props = any;

const WebSearchBlankPage = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <SplashScreenPlaceholder>
        <div>
          <Lottie
            style={{
              height: 400,
              marginTop: '-150px',
            }}
            animationData={WebSearchLottieFile}
          />
          <h2>No website is searched!</h2>
          <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            Please enter a valid URL to search all links from the website
          </span>
        </div>
      </SplashScreenPlaceholder>
    </div>
  );
};

export default WebSearchBlankPage;
