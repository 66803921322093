import React from 'react';

import { ExportOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

import { Repository, Workspace } from '@/gql/graphql';

import RepositoryExportDropdown from '../repository-export-dropdown';

type Props = { repository: Repository; workspace: Workspace };

const RepositoryExportButton = ({ repository, workspace }: Props) => {
  return (
    <Popover
      content={
        <RepositoryExportDropdown workspace={workspace as any} repository={repository as any} />
      }
      trigger={'hover'}
      placement={'left'}
      title="Export">
      <Button>
        <ExportOutlined /> Export
      </Button>
    </Popover>
  );
};

export default RepositoryExportButton;
