import React from 'react';

import { Tag } from 'antd';

import { TimeTrackerProductivityMode } from '@/gql/graphql';

type Props = { productivityMode: TimeTrackerProductivityMode | undefined };

const TimeTrackerModeTag = ({ productivityMode }: Props) => {
  switch (productivityMode) {
    case TimeTrackerProductivityMode.CasualMode:
      return <Tag color="green">Casual Mode</Tag>;
    case TimeTrackerProductivityMode.FocusMode:
      return <Tag color="purple">Focus Mode</Tag>;
  }
  return <></>;
};

export default TimeTrackerModeTag;
