/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';

import { PushpinOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import { BuildAppEditorButton } from '@/components/app-extensions';
import { ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { RepositoryTab, RepositoryTabAsInput } from '@/gql/graphql';
import { useBrowserDevService, usePersonal, useRepositoryContext } from '@/hooks';
import { TabTreeDirectory, TreeNode, UnionTab } from '@/models';
import { useDrawerStore, useRepositoryTabStore } from '@/stores';
import {
  breakdownTreeNodes,
  buildTree,
  getUpdatedCheck,
  sortTreeNodes,
  tabTreeWalk,
} from '@/utils';

import AnimatedComponent from '../animated-component';

type Props = {
  actionable?: boolean;
};

const RepositoryEditor = ({ actionable }: Props) => {
  const {
    repository,
    workspace,
    treeNodes: currentTreeNodes,
    setTreeNodes: setCurrentTreeNodes,
    setSelectedTreeNodes,
    selectedTreeNodes,
    repositoryTabs,
    setLoading,
  } = useRepositoryContext();
  const browserDevService = useBrowserDevService();
  /** Tab state */
  const { storeLocalPinnedTab, removeLocalPinnedTab, getLocalPinnedTabs, findDuplicateTabs } =
    useRepositoryTabStore();

  const { openDrawer } = useDrawerStore();
  const { isWorkspaceAdmin } = usePersonal();

  const collectSelectedTabs = (treeNodes: TreeNode[]): RepositoryTab[] => {
    const { tabs } = breakdownTreeNodes(treeNodes);
    const selectedTabs = tabs.filter(tab => selectedTreeNodes[tab.id as any]);
    return selectedTabs as RepositoryTab[];
  };

  const handleCreateNewWindow = async () => {
    const tabs = collectSelectedTabs(currentTreeNodes);
    await browserDevService.openTabs(tabs as any);
  };

  const handleOpenTabs = async () => {
    const tabs = collectSelectedTabs(currentTreeNodes);
    for (const tab of tabs) {
      await browserDevService.createTab(tab);
    }
  };

  const handleSortTreeNodes = () => {
    const sortedTreeNodes = sortTreeNodes(currentTreeNodes);
    setCurrentTreeNodes(sortedTreeNodes);
  };

  const handleRemoveTabs = async () => {
    if (!repository) return;
    const _remainningDirectories: TabTreeDirectory[] = [];
    const _remainningTabs: UnionTab[] = [];
    tabTreeWalk({
      treeNodes: currentTreeNodes,
      handleDirectoryItem(directory) {
        _remainningDirectories.push(directory);
      },
      handleTabItem(item) {
        if (item.id && !selectedTreeNodes[item.id]) {
          _remainningTabs.push(item);
        }
      },
    });
    setCurrentTreeNodes(buildTree(_remainningTabs, _remainningDirectories));
  };

  const handlePinTabs = async () => {
    const { tabs } = breakdownTreeNodes(currentTreeNodes);
    for (const repositoryTab of tabs) {
      if (!repositoryTab.id) continue;
      if (selectedTreeNodes[repositoryTab.id]) {
        await storeLocalPinnedTab(repositoryTab as any);
      }
    }
    await getLocalPinnedTabs();
  };

  const handleUnpinTabs = async () => {
    const { tabs } = breakdownTreeNodes(currentTreeNodes);
    for (const repositoryTab of tabs) {
      if (!repositoryTab.id) continue;
      if (selectedTreeNodes[repositoryTab.id]) {
        await removeLocalPinnedTab(repositoryTab as any);
      }
    }
    await getLocalPinnedTabs();
  };

  const handleDeselectAll = () => {
    setSelectedTreeNodes({});
  };

  const handleSelectAll = () => {
    let _selectedTreeNodes: Record<string, boolean> = {};
    for (const currentTreeNode of currentTreeNodes) {
      if (!currentTreeNode) return;
      _selectedTreeNodes = {
        ..._selectedTreeNodes,
        ...getUpdatedCheck([currentTreeNode], true),
      };
    }
    setSelectedTreeNodes(_selectedTreeNodes);
  };

  const handleRemoveDuplicate = () => {
    const { tabs: currentTabs, directories } = breakdownTreeNodes(currentTreeNodes);
    const [, remainingTabs] = findDuplicateTabs((currentTabs as any) || []);
    setCurrentTreeNodes(buildTree(remainingTabs, directories));
    handleDeselectAll();
  };

  const handleSmartGrouping = () => {
    const _selectedTabs: Partial<RepositoryTabAsInput>[] = [];
    /** Prepare for grouping actions */
    const { tabs: currentTabs } = breakdownTreeNodes(currentTreeNodes);
    for (const repositoryTab of currentTabs) {
      if (!repositoryTab.id) continue;
      /** Convert tab into iput form if selected */
      if (selectedTreeNodes[repositoryTab.id]) {
        _selectedTabs.push(repositoryTab);
      }
    }
    openDrawer('smartTabGrouping', {
      tabs: _selectedTabs.length === 0 ? repositoryTabs : _selectedTabs,
      onTabsGrouped: async (groupedTabs: TreeNode[]) => {
        setLoading(true);
        try {
          if (!repository) return;
          const _groupedTabsVisited: Record<string, boolean> = {};
          const updatedTreeNodes: TreeNode[] = [];
          /** Walk the tree and track the visited tabs */
          tabTreeWalk({
            treeNodes: groupedTabs,
            handleDirectoryItem: dirNode => {
              const directoryNode: TreeNode = {
                type: 'directory',
                value: dirNode,
              };
              updatedTreeNodes.push(directoryNode);
            },
            handleTabItem: item => {
              const tabNode: TreeNode = {
                type: 'tab',
                value: item,
              };
              if (item.id) {
                _groupedTabsVisited[item.id] = true;
              }
              updatedTreeNodes.push(tabNode);
            },
          });
          /** Collect all tabs which are not grouped or changed */
          for (const tab of repositoryTabs) {
            if (tab.id in _groupedTabsVisited) continue;
            updatedTreeNodes.push({
              type: 'tab',
              value: tab,
            });
          }
          setCurrentTreeNodes(updatedTreeNodes);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      },
    });
  };
  const selectedTreeNodeKeys = useMemo(
    () => Object.keys(selectedTreeNodes).filter(key => selectedTreeNodes[key]),
    [selectedTreeNodes]
  );

  useEffect(() => {
    const originalTreeNodes = buildTree(repositoryTabs, repository?.directories || []);
    setCurrentTreeNodes(originalTreeNodes);
  }, [repositoryTabs, repository]);

  return (
    <AnimatedComponent.OpacityFadeInDiv delay={50}>
      <Space style={{ ...MIDDLE_STYLE, overflow: 'auto', flexWrap: 'wrap' }}>
        {[
          <BuildAppEditorButton
            action={{
              actionHandler: handleSelectAll,
              title: 'Select All',
              icon: ICON_REGISTRY.SELECT_ALL,
            }}
          />,
          <BuildAppEditorButton
            action={{
              actionHandler: handleDeselectAll,
              title: 'Deselect All',
              icon: ICON_REGISTRY.DESELECT_ALL,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(
                actionable &&
                selectedTreeNodeKeys.length > 0 &&
                isWorkspaceAdmin(workspace)
              ),
              actionHandler: handleRemoveTabs,
              tooltipTitle: 'Remove selected tabs',
              icon: ICON_REGISTRY.CLOSE_TABS,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(selectedTreeNodeKeys.length > 0),
              actionHandler: handleOpenTabs,
              tooltipTitle: 'Open in this window',
              icon: ICON_REGISTRY.NEW_TABS,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(selectedTreeNodeKeys.length > 0),
              actionHandler: handleCreateNewWindow,
              tooltipTitle: 'Create new window',
              icon: ICON_REGISTRY.OPEN_WINDOW,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(actionable && isWorkspaceAdmin(workspace)),
              actionHandler: handleRemoveDuplicate,
              tooltipTitle: 'Remove duplicate',
              icon: ICON_REGISTRY.REMOVE_DUPLICATE,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(actionable && isWorkspaceAdmin(workspace)),
              actionHandler: handleSmartGrouping,
              tooltipTitle: 'Smart grouping',
              icon: '🦄',
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(actionable && isWorkspaceAdmin(workspace)),
              actionHandler: handleSortTreeNodes,
              title: 'Sort',
              icon: ICON_REGISTRY.SORT,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(selectedTreeNodeKeys.length > 0),
              actionHandler: handleUnpinTabs,
              title: 'Unpin',
              icon: <PushpinOutlined />,
            }}
          />,
          <BuildAppEditorButton
            action={{
              hidden: !(selectedTreeNodeKeys.length > 0),
              actionHandler: handlePinTabs,
              title: 'Pin',
              icon: <PushpinOutlined />,
            }}
            props={{
              type: 'primary',
            }}
          />,
        ]}
      </Space>
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default RepositoryEditor;
