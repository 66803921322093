import React, { useMemo } from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';

import { MIDDLE_STYLE } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { useBreakpoint } from '@/hooks';
import { TreeNode } from '@/models';
import { useRepositoryTabStore } from '@/stores';
import { getURLHost, shortenString } from '@/utils';

import PinTabButton from '../pin-tab-button';
import WindowTabIcon from '../window-tab-icon';

type Props = {
  id?: string;
  windowTab: RepositoryTab;
  actionable?: boolean;
  ref?: any;
  style?: React.CSSProperties;
  isSelected?: boolean;
  onRightClick?: (treeItem: TreeNode) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, windowTab: RepositoryTab) => void;
  isForceMobile?: boolean;
};

const WindowTabItem = ({
  id,
  ref,
  actionable,
  windowTab,
  onClick,
  onRightClick,
  isSelected,
  isForceMobile,
  style,
  ...props
}: Props) => {
  const { isTablet: isMediaTablet, isMobile } = useBreakpoint();
  const isTablet = useMemo(() => isMediaTablet || isForceMobile, [isMediaTablet, isForceMobile]);
  const { pinnedMap } = useRepositoryTabStore();
  const lastAccessedTimestamp = useMemo(
    () => (windowTab.lastAccessed ? moment.unix(windowTab.lastAccessed / 1000) : undefined),
    [windowTab.lastAccessed]
  );
  const handleOnContextMenu = () => {
    return (
      onRightClick &&
      onRightClick({
        type: 'tab',
        value: windowTab,
      })
    );
  };
  return (
    <div
      onContextMenu={handleOnContextMenu}
      ref={ref}
      {...props}
      style={{ cursor: 'pointer', ...style }}>
      <Row
        id={id}
        className={`window-tab-item ${isSelected ? 'window-tab-item-selected' : ''}`}
        style={{ textAlign: 'left', ...MIDDLE_STYLE }}
        gutter={isTablet ? 10 : 20}
        onClick={event =>
          isMobile ? window.open(windowTab.url) : onClick && onClick(event, windowTab)
        }>
        <Col span={!isTablet ? 1 : 3}>
          <WindowTabIcon windowTab={windowTab} />
        </Col>
        {!isTablet && (
          <Col span={actionable ? 12 : 13}>
            <div style={{ wordBreak: 'break-all', fontSize: 12 }}>
              {shortenString(windowTab.title as any, 90)}
            </div>
          </Col>
        )}
        <Col span={isTablet ? 21 : 7}>
          <div
            style={{
              fontSize: 12,
              wordBreak: 'break-word',
            }}>
            {isTablet && (
              <div style={{ wordBreak: 'break-all', fontSize: 12 }}>
                {shortenString(windowTab.title.split('-')[0] as any, 30)}
              </div>
            )}
            <div className="tab-href">{shortenString(getURLHost(windowTab.url), 30)}</div>
          </div>
        </Col>
        {!isTablet && (
          <React.Fragment>
            <Col span={3}>
              {lastAccessedTimestamp && (
                <div style={{ fontSize: 12, wordBreak: 'break-word' }}>
                  <div className="tab-href">
                    {moment().unix() - (windowTab.lastAccessed as any) / 1000 > 60 * 60 * 24
                      ? lastAccessedTimestamp.format('DD/MM HH:mm A')
                      : lastAccessedTimestamp.fromNow()}
                  </div>
                </div>
              )}
            </Col>
          </React.Fragment>
        )}
        {!isTablet && actionable && (
          <Col span={1}>
            <PinTabButton
              pinned={pinnedMap[windowTab.id as any]}
              repositoryTab={{
                favIconUrl: windowTab.favIconUrl as any,
                id: windowTab.id as any,
                pinned: [],
                title: windowTab.title as any,
                url: windowTab.url as any,
                description: '',
                labels: [],
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default WindowTabItem;
