import React, { useMemo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle: React.CSSProperties = {
  borderColor: '#2196f3',
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676',
};

const rejectStyle: React.CSSProperties = {
  borderColor: '#ff1744',
};

type Props = DropzoneOptions & {
  dropzoneTitle: React.ReactNode | string;
  handleFileOnUpload: (files: (File & { preview: string })[]) => void;
};

const StyledDropzone = ({ dropzoneTitle, handleFileOnUpload, ...dropzoneOptions }: Props) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    ...dropzoneOptions,
    accept: { 'image/*': [] },
    onDrop: acceptedFiles => {
      handleFileOnUpload(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo<React.CSSProperties>(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <React.Fragment>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {dropzoneTitle}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StyledDropzone;
