/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { HelmetWrapper, TabManagerLayout } from '@/components';

type Props = Record<string, any>;

const DashboardScreen = (props: Props) => {
  return (
    <HelmetWrapper title="Dashboard">
      <TabManagerLayout />
    </HelmetWrapper>
  );
};

export default DashboardScreen;
