import React from 'react';

import { Card, Tabs, TabsProps } from 'antd';

import { ANT_DESIGN_THEME } from '@/constants';
import { useBreakpoint } from '@/hooks';

import UserGeneralSetting from '../user-general-setting';

type Props = any;

enum UserTab {
  General = 'general',
}

const UserSettingTab = ({ workspaceId }: Props) => {
  const { isMobile } = useBreakpoint();

  const items: TabsProps['items'] = [
    {
      key: UserTab.General,
      label: `General`,
      children: <UserGeneralSetting />,
    },
  ];

  return (
    <Card
      title={<div style={{ marginLeft: 20 }}>Settings</div>}
      style={{
        textAlign: 'left',
        border: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
      }}>
      <Tabs tabPosition={isMobile ? 'top' : 'left'} items={items} />
    </Card>
  );
};

export default UserSettingTab;
