/* eslint-disable no-restricted-globals */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Card, Skeleton, Tabs, TabsProps } from 'antd';

import { ANT_DESIGN_THEME } from '@/constants';
import { Workspace } from '@/gql/graphql';
import { getWorkspaceByIdQuery } from '@/graphql';
import { useBreakpoint } from '@/hooks';
import { WorkspaceContext } from '@/hooks/useWorkspaceContext';
import { apolloClient } from '@/services/ApolloService';
import { useWorkspaceStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import WorkspaceGeneralSetting from '../workspace-general-setting';
import WorkspaceMember from '../workspace-member';

type Props = {
  workspaceId: string | undefined;
};

enum WorkspaceSettingTab {
  General = 'general',
  Members = 'Members',
}

const WorkspaceSettingsTab = ({ workspaceId }: Props) => {
  const { isMobile } = useBreakpoint();
  const [loading, setLoading] = useState<boolean>(true);
  const { setRefreshing: setWorkspacesRefresh, isRefreshing: workspacesRefreshing } =
    useWorkspaceStore();
  const [refreshing, setRefreshing] = useState(+new Date());
  const [workspace, setWorkspace] = useState<Workspace | undefined>(undefined);

  const handleRefresh = () => {
    setRefreshing(+new Date());
    setWorkspacesRefresh();
  };

  const items: TabsProps['items'] = [
    {
      key: WorkspaceSettingTab.General,
      label: `General`,
      children: <WorkspaceGeneralSetting />,
    },
    {
      key: WorkspaceSettingTab.Members,
      label: `Members`,
      children: <WorkspaceMember />,
    },
  ];

  useEffect(() => {
    const init = async () => {
      if (!workspaceId) return;
      try {
        const {
          data: { getWorkspaceById },
        } = await apolloClient.query({
          query: getWorkspaceByIdQuery,
          variables: {
            id: workspaceId,
          },
        });
        if (!getWorkspaceById) throw new Error('No workspace found');
        setWorkspace(getWorkspaceById);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    init();
  }, [workspaceId, refreshing, workspacesRefreshing]);
  return (
    <Card
      title={<div style={{ marginLeft: 20 }}>Settings</div>}
      style={{
        textAlign: 'left',
        border: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
      }}>
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        <WorkspaceContext.Provider value={{ workspace: workspace, onRefresh: handleRefresh }}>
          <Tabs tabPosition={isMobile ? 'top' : 'left'} items={items} />
        </WorkspaceContext.Provider>
      </LoadableContainer>
    </Card>
  );
};

export default WorkspaceSettingsTab;
