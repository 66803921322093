/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { AppEnvironment } from '@/constants/env';
import { Repository, RepositoryTab, Workspace } from '@/gql/graphql';
import { RepositoryContext, RepositoryContextType } from '@/hooks';
import { TabTreeDirectory, TreeNode } from '@/models';
import { ViewMode, useRepositoryStore, useWorkspaceStore } from '@/stores';
import { buildTree } from '@/utils';

type SelectRepositoryContextProps = {
  repository?: Repository | undefined | null;
  workspace?: Workspace | undefined | null;
  directories?: TabTreeDirectory[];
  repositoryTabs?: RepositoryTab[];
  defaultViewMode?: ViewMode;
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
  setRefreshing?: () => void;
};

type Props = SelectRepositoryContextProps & {
  children: (props: RepositoryContextType) => React.ReactNode;
};

const SelectedRepositoryProvider = ({
  children,
  loading,
  setLoading,
  setRefreshing,
  repository,
  directories,
  workspace,
  repositoryTabs: externalRepositoryTabs,
  defaultViewMode,
}: Props) => {
  const workspaceUrl = `${AppEnvironment.REACT_APP_WEB_APP_URL}/workspace/`;
  const [viewMode, setViewMode] = useState<ViewMode>(defaultViewMode || ViewMode.TableMode);
  const [currentTreeNodes, setCurrentTreeNodes] = useState<TreeNode[]>([]);
  const [selectedTreeNodes, setSelectedTreeNodes] = useState<Record<string, boolean>>({});
  const [treeUpdated, setTreeUpdated] = useState<boolean>(false);
  const { fetchRepositoryBySlug } = useRepositoryStore();
  const { fetchWorkspaceBySlug } = useWorkspaceStore();
  const [linkedRepositories, setLinkedRepositories] = useState<Repository[]>([]);

  const [updated, setUpdated] = useState(+new Date());
  const isWorkspaceUrl = (url: string) => {
    return url.includes(workspaceUrl) && url.slice(workspaceUrl.length).split('/').length === 1;
  };

  const isRepositoryUrl = (url: string) => {
    return url.includes(workspaceUrl) && url.slice(workspaceUrl.length).split('/').length === 2;
  };

  const onUpdated = () => setUpdated(+new Date());

  const updateSelectedTreeNodes = (selectedTreeNodes: Record<string, boolean>) => {
    setSelectedTreeNodes(selectedTreeNodes);
    onUpdated();
  };

  const updateTreeNodes = (treeNodes: TreeNode[]) => {
    setCurrentTreeNodes(treeNodes);
    onUpdated();
  };

  const repositoryTabs = useMemo(
    () => repository?.tabs || externalRepositoryTabs || [],
    [repository, externalRepositoryTabs]
  );

  const normalTabs = useMemo(
    () =>
      repositoryTabs.filter(
        tab => !isWorkspaceUrl(tab.url as any) && !isRepositoryUrl(tab.url as any)
      ) || [],
    [repositoryTabs]
  );

  const repositoriesUrls = useMemo(
    () => repositoryTabs.filter(tab => isRepositoryUrl(tab.url as any)).map(tab => tab.url) || [],
    [repositoryTabs]
  );

  const originalTreeNodes = useMemo(
    () => buildTree(repositoryTabs, repository?.directories || directories || []),
    [repositoryTabs, repository, directories]
  );

  useEffect(() => {
    setTreeUpdated(!_.isEqual(originalTreeNodes, currentTreeNodes));
  }, [originalTreeNodes, currentTreeNodes]);

  const handleRefresh = () => {
    setRefreshing && setRefreshing();
    onUpdated();
  };

  useEffect(() => {
    setCurrentTreeNodes(originalTreeNodes);
  }, [originalTreeNodes]);

  useEffect(() => {
    const init = async () => {
      const _linkedRepositories: Repository[] = [];
      for (const repositoryUrl of repositoriesUrls) {
        if (!repositoryUrl) continue;
        const [workspaceSlug, repositorySlug] = repositoryUrl.slice(workspaceUrl.length).split('/');
        const workspace = await fetchWorkspaceBySlug(workspaceSlug);
        if (!workspace) continue;
        const linkedRepository = await fetchRepositoryBySlug(workspace?.id, repositorySlug);
        if (!linkedRepository) continue;
        _linkedRepositories.push(linkedRepository);
      }
      setLinkedRepositories(_linkedRepositories);
    };
    init();
  }, [repositoriesUrls]);

  const values = useMemo<RepositoryContextType>(
    () => ({
      repository: repository,
      workspace: workspace,
      viewMode: viewMode,
      treeNodes: currentTreeNodes,
      selectedTreeNodes: selectedTreeNodes,
      isUpdated: treeUpdated,
      repositoryTabs: normalTabs,
      linkedRepositories,
      setTreeNodes: updateTreeNodes,
      setIsUpdated: setTreeUpdated,
      setSelectedTreeNodes: updateSelectedTreeNodes,
      onViewModeChanged: (viewMode: ViewMode) => setViewMode(viewMode),
      loading,
      setLoading,
      onRefresh: handleRefresh,
    }),
    [repository, workspace, currentTreeNodes, viewMode, treeUpdated, updated]
  );

  return <RepositoryContext.Provider value={values}>{children(values)}</RepositoryContext.Provider>;
};

export default SelectedRepositoryProvider;
