import React, { useState } from 'react';

import { Button, Row } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { Repository, User } from '@/gql/graphql';
import { removeContributorMutation } from '@/graphql';
import { useRepositoryContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore } from '@/stores';

import CenteredCardColumn from '../centered-card-column';
import LoadableContainer from '../loadable-container';

type Props = {
  repository: Repository | undefined | null;
  contributors: Partial<User>[];
};

const RepositoryContributorList = ({ repository, contributors }: Props) => {
  const { user } = useAuthStore();
  const { onRefresh } = useRepositoryContext();
  const [loading, setLoading] = useState<number | undefined>(undefined);
  const isOwner = (member: Partial<User>) => member?.id === repository?.owner;

  const handleRemoveRepositoryContributor = async (index: number, contributorEmail: string) => {
    setLoading(index);
    if (!repository) return;
    try {
      await apolloClient.mutate({
        mutation: removeContributorMutation,
        variables: {
          contributorEmail,
          repositoryId: repository.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(undefined);
    onRefresh();
  };

  return (
    <LoadableContainer
      isLoading={contributors.length === 0}
      loadComponent={<div>No contributor found</div>}>
      <React.Fragment>
        {contributors.map((contributor, index) => (
          <div
            style={{
              padding: '15px 0px ',
              borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            }}>
            <Row>
              <CenteredCardColumn span={2}>
                <img
                  referrerPolicy="no-referrer"
                  style={{ marginLeft: 20, width: 40, borderRadius: '50%' }}
                  src={contributor.profile_image || ''}
                  alt={contributor.profile_image || ''}
                />
              </CenteredCardColumn>
              <CenteredCardColumn span={14}>
                <React.Fragment>
                  <div style={{ display: 'flex' }}>
                    <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                      {contributor.full_name}
                    </h3>
                  </div>
                  {contributor.email}
                </React.Fragment>
              </CenteredCardColumn>
              <CenteredCardColumn span={2}>
                <div>{isOwner(contributor) ? 'Owner' : 'Member'}</div>
              </CenteredCardColumn>
              <CenteredCardColumn span={4}>
                <React.Fragment>
                  {!isOwner(contributor) && contributor.id !== user?.id && (
                    <Button
                      loading={index === loading}
                      onClick={() =>
                        handleRemoveRepositoryContributor(index, contributor.email as string)
                      }
                      style={{ width: 110, marginLeft: 50 }}>
                      🗑 Remove
                    </Button>
                  )}
                </React.Fragment>
              </CenteredCardColumn>
            </Row>
          </div>
        ))}
      </React.Fragment>
    </LoadableContainer>
  );
};

export default RepositoryContributorList;
