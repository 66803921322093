import React from 'react';

import { Card, Tag } from 'antd';

import {
  ANT_DESIGN_THEME,
  GLOBAL_THEME_COLOR,
  LOCAL_WORKSPACE_ID,
  MIDDLE_STYLE,
} from '@/constants';
import { AccessVisibility, Workspace } from '@/gql/graphql';
import { useBreakpoint } from '@/hooks';
import { shortenString } from '@/utils';

import MemberAvatarsGroup from '../member-avatars-group';
import RenderUpdatedTime from '../render-updated-time';
import WorkspaceAvatar from '../workspace-avatar';

type Props = { workspace: Workspace; onClick: any; key: string; style?: React.CSSProperties };

const WorkspaceCard = ({ workspace, onClick, style, key }: Props) => {
  const { isMobile } = useBreakpoint();
  return (
    <Card
      key={key}
      onClick={onClick}
      style={{
        ...style,
        width: isMobile ? 300 : 350,
        cursor: 'pointer',
        textAlign: 'left',
        border: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
      }}
      title={
        <div
          className="card-header-inner"
          style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
          <div style={{ ...MIDDLE_STYLE }}>
            <WorkspaceAvatar workspace={workspace} />
            <span style={{ marginLeft: 15 }}>{shortenString(workspace.name, 20)}</span>
          </div>
          <Tag>{workspace.visibility === AccessVisibility.Private ? 'Private' : 'Public'}</Tag>
        </div>
      }>
      <div
        style={{
          height: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
          {shortenString(workspace?.description || 'No description', 80)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {workspace.id !== LOCAL_WORKSPACE_ID && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column-reverse',
              }}>
              <div style={{ fontSize: 10, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                <RenderUpdatedTime time={workspace?.updated_date as number} />
              </div>
            </div>
          )}
          <MemberAvatarsGroup compacted={2} members={workspace.members} />
        </div>
      </div>
    </Card>
  );
};

export default WorkspaceCard;
