import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import loadable from '@loadable/component';

import UserProfileRedirect from '../user-profile-redirect';

const UserProfileScreen = loadable(() => import('../../screens/UserProfileScreen'));

export interface CustomRouteProps {
  isExact: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | React.ComponentType<any>;
  path: string;
}

export const userRouteList: CustomRouteProps[] = [
  {
    isExact: true,
    component: () => <UserProfileRedirect />,
    path: '/user/',
  },
  {
    isExact: true,
    component: () => <UserProfileScreen />,
    path: '/user/:username',
  },
];
