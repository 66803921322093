import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import loadable from '@loadable/component';

const AuthScreen = loadable(() => import('../../screens/AuthScreen'));
const DashboardLayout = loadable(() => import('../dashboard-layout'));
const WorkspaceRepositoryPreviewScreen = loadable(
  () => import('../../screens/WorkspaceRepositoryPreviewScreen')
);

export interface CustomRouteProps {
  isExact: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | React.ComponentType<any>;
  path: string;
}

export const routeList: CustomRouteProps[] = [
  {
    isExact: true,
    component: () => <AuthScreen />,
    path: '/auth',
  },
  {
    isExact: true,
    component: () => <AuthScreen />,
    path: '/sign-in',
  },
  {
    isExact: true,
    component: () => <DashboardLayout />,
    path: '/',
  },
  {
    isExact: true,
    component: () => <WorkspaceRepositoryPreviewScreen />,
    path: '/shared/:workspaceSlug/:repositorySlug',
  },
  {
    isExact: false,
    component: () => <DashboardLayout />,
    path: '/:tabName',
  },
];
