import React, { useMemo } from 'react';

import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Tooltip } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { searchTabOnWebQuery } from '@/graphql';
import { UrlWithImage, useBreakpoint, useRepositoryContext, useWebSearchContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { useSnackbarStore } from '@/stores';
import { isValidUrl } from '@/utils';

type Props = any;

const WebSearchAppExtensionSearchBar = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const { enqueueNotification } = useSnackbarStore();
  const { setWebsiteUrl, websiteUrl, searchedUrls, setSearchedUrls } = useWebSearchContext();
  const { setLoading, loading } = useRepositoryContext();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const { data } = await apolloClient.query({
        query: searchTabOnWebQuery,
        fetchPolicy: 'network-only',
        variables: {
          websiteUrl: websiteUrl,
        },
      });
      const urlsWithImage: UrlWithImage[] = JSON.parse(data.searchOnWeb);
      setSearchedUrls(urlsWithImage);
    } catch (error) {
      console.log(error);
      await enqueueNotification({
        name: 'Search tabs from URL failed',
        description: 'Error searching tabs from the website URL',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const handleReset = () => {
    setSearchedUrls([]);
    setWebsiteUrl('');
  };

  const handleInputChange = e => {
    const updatedWebsiteUrl = e.target.value;
    if (updatedWebsiteUrl !== websiteUrl) {
      handleReset();
    }
    setWebsiteUrl(updatedWebsiteUrl);
  };

  const hasError = useMemo(() => !isValidUrl(websiteUrl), [websiteUrl]);

  return (
    <Space style={{ ...MIDDLE_STYLE, width: '100%' }}>
      <div style={{ height: 'fit-content' }}>
        <Tooltip
          color={hasError ? GLOBAL_THEME_COLOR.$error_color : GLOBAL_THEME_COLOR.$success_color}
          title={
            hasError ? 'Invalid website URL. Please try again' : `Collect links from ${websiteUrl}`
          }>
          <Input
            status={hasError ? 'error' : ''}
            disabled={loading}
            style={{ width: isMobile ? '250px' : '500px' }}
            value={websiteUrl}
            onChange={handleInputChange}
            placeholder="Enter website URL to collect links"
          />
        </Tooltip>
      </div>
      <Button
        disabled={hasError || loading}
        type="primary"
        onClick={handleSearch}
        loading={loading}>
        <SearchOutlined />
        Search
      </Button>
      {searchedUrls.length > 0 && (
        <Button disabled={hasError || loading} onClick={handleReset} loading={loading}>
          <ClearOutlined /> Reset
        </Button>
      )}
    </Space>
  );
};

export default WebSearchAppExtensionSearchBar;
