import React from 'react';

type Props = {
  error?: any;
  color: string;
  source: {
    id: string;
    value: number;
    percent: number;
  };
  target: {
    id: string;
    value: number;
    percent: number;
  };
  getTrackProps: any;
  disabled?: boolean;
};

const getTrackConfig = ({ error, color, source, target, disabled }) => {
  const basicStyle = {
    left: `${source.percent}%`,
    width: `calc(${target.percent - source.percent}% - 1px)`,
  };

  if (disabled) return basicStyle;

  const coloredTrackStyle = error
    ? {
        backgroundColor: 'rgba(214,0,11,0.5)',
        borderLeft: '1px solid rgba(214,0,11,0.5)',
        borderRight: '1px solid rgba(214,0,11,0.5)',
      }
    : {
        backgroundColor: color,
        borderLeft: `1px solid ${color}`,
        borderRight: `1px solid ${color}`,
      };

  return { ...basicStyle, ...coloredTrackStyle };
};

const TimelineTimeTrack = ({ disabled, error, getTrackProps, source, target, color }: Props) => {
  return (
    <div
      className={`react_time_range__track${disabled ? '__disabled' : ''}`}
      style={getTrackConfig({ error, source, target, disabled, color })}
      {...getTrackProps()}
    />
  );
};

export default TimelineTimeTrack;
