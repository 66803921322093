import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface MenuState {
  visible: boolean;
  x: number;
  y: number;
  content?: () => React.ReactNode;
}

export interface ContextMenuStore extends MenuState {
  setContextMenuContent: (props: () => React.ReactNode) => void;
  setContextMenu: (props: MenuState) => void;
}

export const useContextMenuStore = create<ContextMenuStore>()(
  devtools(set => ({
    visible: false,
    x: 0,
    y: 0,
    content: undefined,
    setContextMenu(props) {
      set(state => ({ ...props }));
    },
    setContextMenuContent(content: () => React.ReactNode) {
      set(state => ({ ...state, content }));
    },
  }))
);
