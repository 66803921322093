import React, { useContext } from 'react';

import { TimeTrackerEngineState, TimeTrackerProductivityMode } from '@/gql/graphql';
import { BrowsingTabTask, TimeTrackSheet } from '@/models';

type SetTimeTrackMethodType = (timeTrack: TimeTrackSheet) => void;

type TimeRange = [Date, Date];

export type TimeMachineContextType = {
  isTimeMachineReady: boolean;
  productivityMode: TimeTrackerProductivityMode | undefined;
  sessionStartTime: number;
  sessionEndTime: number;
  engineState: TimeTrackerEngineState;
  limitLeastUsedTime: number;
  trackedTabs: BrowsingTabTask[];
  rawTrackedTabs: BrowsingTabTask[];
  garbageCollectedTabs: BrowsingTabTask[];
  urlHostTimeTrack: TimeTrackSheet;
  realtimeUrlHostTimeTrack: TimeTrackSheet;
  tabTimeTrack: TimeTrackSheet;
  realtimeTabTimeTrack: TimeTrackSheet;
  realtimePointer: number;
  currentBrowsingTab: BrowsingTabTask | undefined;
  currentTimeDiff: number;
  realtimeTotalTime: number;
  selectedRangeEnabled: boolean;
  selectedTimePeriod: TimeRange;
  selectedRange: TimeRange | undefined;

  setSelectedTimePeriod: (selectedTimePeriod: TimeRange) => void;
  setProductivityMode: (mode: TimeTrackerProductivityMode | undefined) => void;
  setSessionStartTime: (startTime: number) => void;
  setSessionEndTime: (endTime: number) => void;
  setSelectedRangeEnabled: (selected: boolean) => void;
  setSelectedRange: (range: [Date, Date]) => void;
  setRealtimePointer: (time: number) => void;
  setLimitLeastUsedTime: (leastUsedTime: number) => void;
  setGarbageCollectedTabs: (garbageCollectedTabs: BrowsingTabTask[]) => void;
  setTrackedTabs: (trackedTabs: BrowsingTabTask[]) => void;
  setUrlHostTimeTrack: SetTimeTrackMethodType;
  setTabTimeTrack: SetTimeTrackMethodType;
  setRealtimeUrlHostTimeTrack: SetTimeTrackMethodType;
  setEngineState: (state: TimeTrackerEngineState) => void;
};

export const TimeMachineContext = React.createContext<TimeMachineContextType | undefined>(
  undefined
);

export const useTimeMachineContext = (): TimeMachineContextType => {
  const data = useContext(TimeMachineContext);
  return data as any;
};
