import React, { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { TimeTrackerSession } from '@/gql/graphql';
import { TimeTrackSheet } from '@/models';
import { convertDecimalToTime } from '@/utils';
import {
  collectTotalTimeFromTimeSheet,
  convertPieChartDateFromTimeSheet,
  handleCollectTimeTrackSheet,
} from '@/utils/time-tracker.util';

import LoadableContainer from '../loadable-container';
import TimeTrackerModeTag from '../time-tracker-mode-tag';
import TimelessTimeAllocationChart from '../timeless-time-allocation-chart';

type Props = {
  session: TimeTrackerSession;
  style?: React.CSSProperties;
};

const TimeTrackerSessionCard = ({ session, style }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [pieChartData, setPieChartData] = useState<any[]>([]);
  const [timeSheet, setTimeSheet] = useState<TimeTrackSheet>({});
  const [totalTime, setTotalTime] = useState<number>(0);

  useEffect(() => {
    const init = () => {
      setLoading(true);
      const _trackedTabs = JSON.parse(JSON.parse(session.trackedTabs) || []);
      const _timeTrackSheet = handleCollectTimeTrackSheet(_trackedTabs);
      setTimeSheet(_timeTrackSheet);
      const totalTimeValue = collectTotalTimeFromTimeSheet(_timeTrackSheet);
      setTotalTime(totalTimeValue);
      const _pieChartData = convertPieChartDateFromTimeSheet(_timeTrackSheet);
      setPieChartData(_pieChartData);
      setLoading(false);
    };
    init();
  }, [session]);

  return (
    <div
      style={{
        cursor: 'pointer',
        overflow: 'hidden',
        width: 'fit-content',
        padding: '20px 30px',
        borderRadius: 20,
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
        borderColor: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
        ...style,
      }}>
      <LoadableContainer isLoading={loading} loadComponent={<LoadingOutlined />}>
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
          }}>
          <h3 style={{ margin: 0 }}>{moment.unix(session.date).format('DD-MM-YYYY')}</h3>
          <div
            style={{
              ...MIDDLE_STYLE,
              color: GLOBAL_THEME_COLOR.$dark_text_color,
            }}>
            <TimeTrackerModeTag productivityMode={session.mode} />
          </div>
        </div>
        <TimelessTimeAllocationChart
          limit={0}
          totalTime={totalTime}
          trackTimeSheet={timeSheet}
          piechartData={pieChartData}
          descripitonComponent={
            <span style={{ marginTop: 5, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
              {Object.keys(timeSheet).length} websites
            </span>
          }
        />
        <div>
          From <span>{convertDecimalToTime(session.session_start_time)}</span> to{' '}
          <span>{convertDecimalToTime(session.session_end_time)}</span>
        </div>
      </LoadableContainer>
    </div>
  );
};

export default TimeTrackerSessionCard;
