import React, { useEffect } from 'react';

import { Crisp } from 'crisp-sdk-web';

type Props = { children: React.ReactNode };

const CrispChatProvider = ({ children }: Props) => {
  useEffect(() => {
    Crisp.configure('213d9073-b941-4b84-8c9f-cf7c038a8094');
  }, []);
  return <React.Fragment>{children}</React.Fragment>;
};

export default CrispChatProvider;
