/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import MDEditor from '@uiw/react-md-editor';
import { Card } from 'antd';

import RepositoryBannerImage from '../repository-banner-image';

type Props = {
  bannerImage: string | undefined;
  content: string;
};

const RepositoryPageView = ({ bannerImage, content }: Props) => {
  return (
    <Card
      style={{
        display: 'block',
        textAlign: 'left',
        overflow: 'auto',
        overflowX: 'hidden',
      }}
      cover={bannerImage ? <RepositoryBannerImage bannerImage={bannerImage} /> : <></>}>
      <div data-color-mode="light" style={{ marginTop: 20 }}>
        <MDEditor.Markdown source={content} />
      </div>
    </Card>
  );
};

export default RepositoryPageView;
