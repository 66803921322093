import React from 'react';

import LoadableContainer from '@/components/loadable-container';
import SelectedRepositoryProvider from '@/components/selected-repository-provider';
import SplashScreen from '@/components/splash-screen';
import { ICON_REGISTRY } from '@/constants';
import { usePinnedAssets } from '@/hooks';

import AppExtensionAppBar from '../../app-extension-appbar';
import { RenderAppExtensionLayoutBuilder } from '../../layout-builder';
import BookmarkAppExtensionContent from './content';
import BookmarkAppExtensionEditor from './editor';

type Props = any;

const BookmarkAppExtension = (props: Props) => {
  const { tabs, loading, setLoading, handleRefresh } = usePinnedAssets();

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <SelectedRepositoryProvider
        repositoryTabs={tabs}
        loading={loading}
        setLoading={setLoading}
        setRefreshing={handleRefresh}>
        {values => (
          <RenderAppExtensionLayoutBuilder
            values={values}
            appBar={
              <AppExtensionAppBar
                appIcon={ICON_REGISTRY.BOOKMARK}
                appName="Bookmark Manager"
                description="Manage and sync your bookmark directly in Tabhub"
                editor={<BookmarkAppExtensionEditor />}
                extraAction={<></>}
              />
            }
            content={<BookmarkAppExtensionContent />}
          />
        )}
      </SelectedRepositoryProvider>
    </LoadableContainer>
  );
};

export default BookmarkAppExtension;
