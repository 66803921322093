/* eslint-disable no-restricted-globals */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { DeleteOutlined, FolderAddOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Space, Tag } from 'antd';

import { ANT_DESIGN_THEME, MIDDLE_STYLE } from '@/constants';
import {
  useBreakpoint,
  usePersonal,
  useRepositoryContext,
  useRepositoryTabActions,
  useWindowTabActions,
} from '@/hooks';
import { TabTreeDirectory, TreeNode, UnionTab } from '@/models';
import { useContextMenuStore, useModalStore, useRepositoryStore, useSnackbarStore } from '@/stores';
import { breakdownTreeNodes, buildTree, reorder } from '@/utils';

import AnimatedComponent from '../animated-component';
import DraggableTreeNodeList from '../draggable-tree-node-list';
import LoadableContainer from '../loadable-container';
import RepositoryEditor from '../repository-editor';
import RepositoryItemContextMenu from '../repository-item-context-menu';
import SplashScreen from '../splash-screen';

type Props = {
  actionable?: boolean;
  editorShown?: boolean;
  isForceMobile?: boolean;
};

const RepositoryTabsContainer = ({ actionable, editorShown, isForceMobile }: Props) => {
  const { isMobile: isMediaMobile } = useBreakpoint();
  const isMobile = useMemo(() => isMediaMobile || isForceMobile, [isMediaMobile, isForceMobile]);
  const { onRefresh } = useRepositoryContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueNotification } = useSnackbarStore();

  /** Tree nodes state */
  const repositoryContext = useRepositoryContext();
  const {
    treeNodes: currentTreeNodes,
    setTreeNodes: setCurrentTreeNodes,
    isUpdated,
    repositoryTabs,
    selectedTreeNodes,
    setIsUpdated,
    setSelectedTreeNodes,
    repository,
    workspace,
  } = repositoryContext;
  const { openModal } = useModalStore();
  const { handleSelectTab } = useRepositoryTabActions();
  /** Tab state */
  const { setContextMenuContent } = useContextMenuStore();
  const { setRepositoryTabs } = useRepositoryStore();
  // const { openModal } = useModalStore();
  const { isWorkspaceAdmin } = usePersonal();
  const { handleSaveTabs } = useWindowTabActions();

  const handleTabClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tab: UnionTab) => {
    switch (event.detail) {
      case 2:
        return window.open(tab.url);
      default:
        return handleSelectTab({
          type: 'tab',
          value: tab,
        });
    }
  };

  const handleDirectoryClicked = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    directoryNode: TabTreeDirectory
  ) => {
    const treeNode: TreeNode = {
      type: 'directory',
      value: directoryNode,
    };
    handleSelectTab(treeNode);
  };

  const handleSaveChanges = async () => {
    if (isUpdated) {
      if (!repository) return;
      setLoading(true);

      try {
        /** Break down tree nodes and update the repository */
        const { tabs, directories } = breakdownTreeNodes(currentTreeNodes);
        await setRepositoryTabs(repository, tabs as any, directories);
        setSelectedTreeNodes({});

        onRefresh();
        setLoading(false);
        await enqueueNotification({
          name: 'Saving changes successfully',
          description: 'Successfully saving changes',
          type: 'Success',
        });
        setIsUpdated(false);
      } catch (error) {
        await enqueueNotification({
          name: 'Failed to save',
          description: 'Error saving changes',
          type: 'Error',
        });
      }
    }
  };

  const handleDiscardChanges = async () => {
    if (isUpdated) {
      const originalTreeNodes = buildTree(repositoryTabs, repository?.directories || []);
      setCurrentTreeNodes(originalTreeNodes);
    }
  };

  const handleSaveTabsToRepository = async () => {
    handleSaveTabs(undefined, currentTreeNodes, selectedTreeNodes);
  };

  const handleAddTabs = () => {
    openModal('addRepositoryTabs', {
      repository,
    });
  };

  const handleItemSelectedCondition = tab =>
    (tab && !!tab.id && !!selectedTreeNodes[tab.id]) || false;

  const onDragEnd: OnDragEndResponder = async (result: any) => {
    if (!result.destination) return;
    const items = reorder(currentTreeNodes, result.source.index, result.destination.index);
    setCurrentTreeNodes(items);
  };

  useEffect(() => {
    const originalTreeNodes = buildTree(repositoryTabs, repository?.directories || []);
    setCurrentTreeNodes(originalTreeNodes);
  }, [repositoryTabs, repository]);

  return (
    <Card
      className="window-tab-container"
      style={{
        width: '100%',
        maxWidth: 1500,
        overflow: 'auto',
        border: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
        position: 'relative',
      }}
      title={
        <div className="card-header-inner">
          <p>Tabs</p>
          {actionable && (
            <Space style={MIDDLE_STYLE}>
              <Tag color="blue">{repositoryTabs?.length} tabs</Tag>
              <Tag color="green">{repository?.directories.length} directories</Tag>
              {isWorkspaceAdmin(workspace) && isUpdated && (
                <React.Fragment>
                  <Button type="primary" className="btn-error" onClick={handleDiscardChanges}>
                    <DeleteOutlined /> {!isMobile ? 'Discard' : ''}
                  </Button>
                  <Button type="primary" className="btn-success" onClick={handleSaveChanges}>
                    <SaveOutlined /> {!isMobile ? 'Save' : ''}
                  </Button>
                </React.Fragment>
              )}
              {isWorkspaceAdmin(workspace) && (
                <Button onClick={handleAddTabs}>
                  <FolderAddOutlined /> {!isMobile ? 'Add tabs' : ''}
                </Button>
              )}

              <Button type="primary" onClick={handleSaveTabsToRepository}>
                <SaveOutlined /> {!isMobile ? 'New repository' : ''}
              </Button>
            </Space>
          )}
        </div>
      }>
      <AnimatedComponent.OpacityFadeInDiv delay={50}>
        <LoadableContainer
          isLoading={loading}
          loadComponent={<SplashScreen style={{ height: 300 }} />}>
          <React.Fragment>
            {editorShown && (
              <React.Fragment>
                <RepositoryEditor />
                <Divider />
              </React.Fragment>
            )}
            <DraggableTreeNodeList
              isForceMobile={isForceMobile}
              nodeOnContextMenu={(treeNode: TreeNode) => {
                setContextMenuContent(() => <RepositoryItemContextMenu treeNode={treeNode} />);
              }}
              treeNodes={currentTreeNodes}
              directoryOnClickedEvent={handleDirectoryClicked}
              itemOnClickedEvent={handleTabClicked}
              itemSelectedCondition={handleItemSelectedCondition}
              onItemDragEvent={onDragEnd}
              draggableDisabled={!!repository && !isWorkspaceAdmin(workspace)}
            />
          </React.Fragment>
        </LoadableContainer>
      </AnimatedComponent.OpacityFadeInDiv>
    </Card>
  );
};

export default RepositoryTabsContainer;
