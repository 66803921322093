import React from 'react';

import { useContextMenuStore } from '@/stores';

import ContextMenuPopup from '../context-menu-popup';

type Props = {
  children: React.ReactNode;
  offsetX?: number;
  offsetY?: number;
};

const ContextMenuProvider = ({ children, offsetX, offsetY }: Props) => {
  const { setContextMenu, visible, x, y, content } = useContextMenuStore();
  const onContextMenu: React.MouseEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    if (!visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setContextMenu({ visible: false, x: 0, y: 0, content: undefined });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setContextMenu({
      visible: true,
      x: event.clientX + (offsetX || 0),
      y: event.clientY + (offsetY || 0),
    });
  };

  return (
    <div onContextMenu={onContextMenu}>
      {children}
      {content && <ContextMenuPopup visible={visible} x={x} y={y} content={content} />}
    </div>
  );
};

export default ContextMenuProvider;
