import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

type Props = {
  value: string;
  handleValueChanged: (value: string) => void;
  extraAction?: React.ReactNode;
  placeholder?: string;
};

const PowerfulSearchInput = ({ handleValueChanged, value, extraAction, placeholder }: Props) => {
  const handleClearSearchInput = () => {
    handleValueChanged('');
  };
  return (
    <div className="powerful-search-inner">
      <div>🔍</div>
      <input
        value={value}
        placeholder={placeholder || 'Search for result'}
        className="powerful-search-input"
        onChange={e => handleValueChanged(e.target.value)}
      />
      {value.length > 0 && (
        <div style={{ cursor: 'pointer', marginLeft: 15 }} onClick={handleClearSearchInput}>
          <CloseOutlined />
        </div>
      )}
      {extraAction}
    </div>
  );
};

export default PowerfulSearchInput;
