import { Route, Switch } from 'react-router';

import { NotFoundScreen } from '@/screens';

import { CustomRouteProps } from './route-list';

type Props = {
  routes: CustomRouteProps[];
};

export const renderRoutes = (routes: CustomRouteProps[]) => (
  <Switch>
    {routes.map(route => (
      <Route
        key={route.path}
        path={route.path}
        exact={route.isExact}
        render={route.component as any}></Route>
    ))}
    <Route component={NotFoundScreen} />
  </Switch>
);

const AppRoutesRenderer = (props: Props) => {
  return renderRoutes(props.routes);
};

export default AppRoutesRenderer;
