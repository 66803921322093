/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import { FolderFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import Fuse from 'fuse.js';
import _ from 'lodash';
import * as uuid from 'uuid';

import { MIDDLE_STYLE } from '@/constants';
import { GLOBAL_THEME_COLOR } from '@/constants';
import { Directory, RepositoryTab } from '@/gql/graphql';
import { useRepositoryContext } from '@/hooks';
import { useModalStore } from '@/stores';
import { breakdownTreeNodes, buildTree } from '@/utils';

import AnimatedComponent from '../animated-component';
import DirectorySearchItem from '../directory-search-item';

type SearchDirectoryRepositoryProps = {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
};

const SearchDirectoryRepository: React.FC<SearchDirectoryRepositoryProps> = ({
  searchInput,
  setSearchInput,
}: SearchDirectoryRepositoryProps) => {
  const { closeModal } = useModalStore();
  const { selectedTreeNodes, setTreeNodes, treeNodes, setIsUpdated, setSelectedTreeNodes } =
    useRepositoryContext();
  const COLOR = GLOBAL_THEME_COLOR.$dark_highlight_color;
  const ROOT_DIRECTORY = {
    id: 'ROOT_DIRECTORY',
    color: '',
    name: './ ROOT',
    parentDirectory: '',
  } as Directory;

  const [directories, repositoryTabs] = useMemo<[Directory[], RepositoryTab[]]>(() => {
    const { directories: _directories, tabs: _repositoryTabs } = breakdownTreeNodes(treeNodes);
    return [_directories, _repositoryTabs as any];
  }, [treeNodes]);

  const filterDirs = useMemo(() => {
    if (searchInput.length === 0) return directories || [];
    if (directories.length > 0) {
      const fuse = new Fuse(directories, {
        keys: ['name'],
      });
      const results = fuse.search(searchInput);
      const resultDirs = results.map(result => result.item);
      return resultDirs.length === 0 ? directories : resultDirs;
    }
    return directories;
  }, [searchInput, directories]);

  const handleOnDirClicked = (clickedDirectory: Directory) => {
    const chosenDirectories: Record<string, boolean> = {};
    const [selectedDirectories, unselectedDirectories]: Directory[][] = [[], []];

    const repoDirs = _.cloneDeep(directories || []);
    if (clickedDirectory.id.includes('created')) {
      repoDirs.push(clickedDirectory);
    }

    const isRootDirectory = clickedDirectory.id === 'ROOT_DIRECTORY';
    for (const directory of repoDirs) {
      const isSelected = selectedTreeNodes[directory.id as string];
      const isParentSelected = selectedTreeNodes[directory.parentDirectory as string];

      let parentDirectory: string | undefined | null = directory.parentDirectory;
      /** Directory's parent directory is a selected directory => Don't change */
      if (isParentSelected) parentDirectory = directory.parentDirectory;
      /** Is assigned to ROOT_DIRECTORY => parentDirectory = -1 */
      if (isRootDirectory && isSelected) parentDirectory = undefined;
      /** => If above cases are not reached => parentDirectory = clickedDirectory.id */
      if (!isParentSelected && !isRootDirectory && isSelected)
        parentDirectory = clickedDirectory.id;

      if (isSelected) chosenDirectories[directory.id as string] = true;
      (isSelected ? selectedDirectories : unselectedDirectories).push({
        ...directory,
        parentDirectory,
      });
    }

    const [selectedTabs, unselectedTabs]: RepositoryTab[][] = [[], []];
    for (const repositoryTab of repositoryTabs || []) {
      const isSelected = selectedTreeNodes[repositoryTab.id];
      const isParentSelected = selectedTreeNodes[repositoryTab.parentDirectory as string];

      let parentDirectory: string | undefined | null = repositoryTab.parentDirectory;
      /** Tab's parent directory is a selected directory => Don't change */
      if (isParentSelected) parentDirectory = repositoryTab.parentDirectory;
      /** Is assigned to ROOT_DIRECTORY => parentDirectory = -1 */
      if (isRootDirectory && isSelected) parentDirectory = undefined;
      /** => If above cases are not reached => parentDirectory = clickedDirectory.id */
      if (!isParentSelected && !isRootDirectory && isSelected)
        parentDirectory = clickedDirectory.id;

      (isSelected ? selectedTabs : unselectedTabs).push({
        ...repositoryTab,
        parentDirectory,
      });
    }

    const [updatedTabs, updatedDirs] = [
      [...(selectedTabs as any), ...unselectedTabs],
      [...selectedDirectories, ...unselectedDirectories],
    ];
    const updatedTreeNodes = buildTree(updatedTabs, updatedDirs);

    setSearchInput('');
    setTreeNodes(updatedTreeNodes);
    setSelectedTreeNodes({});
    setIsUpdated(true);
    closeModal();
  };

  const unselectedDirs = useMemo<Directory[]>(
    () => (filterDirs as Directory[]).filter(dir => !selectedTreeNodes[(dir as any).id]),
    [filterDirs, selectedTreeNodes]
  );

  return (
    <React.Fragment>
      <div style={MIDDLE_STYLE} className="beautiful-section-header-container">
        <h4 className="beautiful-section-header">Directory</h4>
        <FolderFilled style={{ color: COLOR, marginLeft: 10 }} />
      </div>
      <React.Fragment>
        {(searchInput.length > 0
          ? [
              {
                id: `created-directory-${uuid.v4()}`,
                color: COLOR,
                name: searchInput,
                parentDirectory: undefined,
              } as Directory,
              ROOT_DIRECTORY,
              ...unselectedDirs,
            ]
          : [ROOT_DIRECTORY, ...unselectedDirs]
        ).map((dir, index) => (
          <div style={{ padding: '0px 20px', marginBottom: 20 }}>
            <AnimatedComponent.OpacityFadeInDiv delay={100 + 50 * index}>
              {searchInput.length > 0 && index === 0 && (
                <div style={{ marginBottom: 10 }}>Create new directory as</div>
              )}
              <DirectorySearchItem key={dir.id} directory={dir} onClick={handleOnDirClicked} />
              {searchInput.length > 0 && index === 0 && <Divider />}
            </AnimatedComponent.OpacityFadeInDiv>
          </div>
        ))}
      </React.Fragment>
    </React.Fragment>
  );
};

export default SearchDirectoryRepository;
