import React, { useContext } from 'react';

import { Repository, RepositoryTab, Workspace } from '@/gql/graphql';
import { TreeNode } from '@/models';
import { ViewMode } from '@/stores';

export type RepositoryContextType = {
  repository: Repository | undefined | null;
  repositoryTabs: RepositoryTab[];
  isUpdated: boolean;
  workspace: Workspace | undefined | null;
  viewMode: ViewMode;
  linkedRepositories: Repository[];
  treeNodes: TreeNode[];
  loading: boolean;
  selectedTreeNodes: Record<string, boolean>;
  onRefresh: () => void;
  setIsUpdated: (isUpdated: boolean) => void;
  setLoading: (loading: boolean) => void;
  onViewModeChanged: (viewMode: ViewMode) => void;
  setSelectedTreeNodes: (selectedTreeNodes: Record<string, boolean>) => void;
  setTreeNodes: (treeNodes: TreeNode[]) => void;
};

export const RepositoryContext = React.createContext<RepositoryContextType | undefined>(undefined);

export const useRepositoryContext = (): RepositoryContextType => {
  const data = useContext(RepositoryContext);
  return data as any;
};
