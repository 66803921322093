import React from 'react';

import { Avatar } from 'antd';
import Browser from 'webextension-polyfill';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { shortenString } from '@/utils';

type Props = {
  window: Browser.Windows.Window;
  windowTab: Browser.Tabs.Tab;
  onClick?: (windowTab: Browser.Tabs.Tab) => void;
};

const WindowTabSearchItem = ({ windowTab, onClick }: Props) => {
  return (
    <div style={{ padding: '0px 12px', cursor: 'pointer' }}>
      <div
        style={{ textAlign: 'left', display: 'flex', fontSize: 'smaller' }}
        className={`window-tab-item`}
        onClick={() => onClick && onClick(windowTab)}>
        <Avatar size={20} src={windowTab.favIconUrl} alt={windowTab.favIconUrl}>
          {windowTab.title?.slice(0, 1)}
        </Avatar>
        <div style={{ marginLeft: 13 }}>
          <div>{shortenString(windowTab.title as any, 70)}</div>
          <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            {shortenString(windowTab.url || '', 50)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WindowTabSearchItem;
