import React, { useContext } from 'react';

export type UrlWithImage = { url: string; image: string };

export const WebSearchContext = React.createContext<{
  started: boolean;
  searchedUrls: UrlWithImage[];
  websiteUrl: string;
  setStarted: (isStarted: boolean) => void;
  setSearchedUrls: (data: UrlWithImage[]) => void;
  setWebsiteUrl: (url: string) => void;
}>({
  searchedUrls: [],
  websiteUrl: '',
  started: false,
  setStarted(isStarted) {
    return;
  },
  setSearchedUrls(urls) {
    return;
  },
  setWebsiteUrl: (url: string) => {
    return;
  },
});

export const useWebSearchContext = () => {
  const data = useContext(WebSearchContext);
  return data;
};
