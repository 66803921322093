import React from 'react';

import { Space } from 'antd';

import { useWindowStore } from '@/stores';

import { WindowColumnContainer } from '..';

type Props = any;

const TabManagerColumnView = (props: Props) => {
  const { windows } = useWindowStore();
  return (
    <div
      style={{
        padding: '10px 30px',
        height: 'auto',
      }}>
      <Space style={{ marginTop: 20, display: 'flex' }}>
        <React.Fragment>
          {windows.map((browserWindow, index) => (
            <div key={browserWindow.id?.toString()} style={{ marginBottom: 20 }}>
              <WindowColumnContainer browserWindow={browserWindow} />
            </div>
          ))}
        </React.Fragment>
      </Space>
    </div>
  );
};

export default TabManagerColumnView;
