import React, { useMemo, useState } from 'react';

import { DeleteOutlined, HourglassOutlined } from '@ant-design/icons';
import { Button, Card, Empty } from 'antd';

import { GENERAL_EMPTY_IMAGE, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useTimeMachineContext, useWindowTabActions } from '@/hooks';
import { TabChecked, UnionTab } from '@/models';
import { useSnackbarStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import WindowTabItem from '../window-tab-item';

type Props = any;

const TimeTrackerGarbageTabsSection = (props: Props) => {
  const {
    garbageCollectedTabs,
    setGarbageCollectedTabs,
    limitLeastUsedTime,
    tabTimeTrack,
    sessionStartTime,
  } = useTimeMachineContext();
  const { enqueueNotification } = useSnackbarStore();
  const { handleCloseTab } = useWindowTabActions();
  const [selectedTab, setSelectedTab] = useState<TabChecked>({});
  const [loading, setLoading] = useState<boolean>(false);
  const handleTabItemClicked = (tab: UnionTab) => {
    if (!tab.id) return;
    const id = tab.id.toString();
    setSelectedTab({
      ...selectedTab,
      [id]: !selectedTab[id],
    });
  };

  const handleEmptyGarbageTabs = async () => {
    setLoading(true);
    try {
      const promises: Promise<void>[] = [];
      const selectedTabs = garbageCollectedTabs.filter(tab => selectedTab[tab.id || '']);
      const emptiedTabs = selectedTabs.length > 0 ? selectedTabs : garbageCollectedTabs;
      for (const tab of emptiedTabs) {
        promises.push(handleCloseTab(tab));
      }
      await Promise.all(promises);
      await enqueueNotification({
        name: 'Empty unused tabs',
        description: 'Garbage is emptied successfully',
        type: 'Success',
      });
      setGarbageCollectedTabs(
        garbageCollectedTabs.filter(
          tab => !emptiedTabs.some(_emptiedTab => _emptiedTab.url === tab.url)
        )
      );
    } catch (error) {
      await enqueueNotification({
        name: 'Failed to empty garbage',
        description: 'Garbage is not emptied successfully',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const selectedGarbageTabs = useMemo(
    () => garbageCollectedTabs.filter(tab => tab.id && selectedTab[tab.id.toString()]),
    [garbageCollectedTabs, selectedTab]
  );

  return (
    <Card
      style={{ marginTop: 10 }}
      title={
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            padding: '0px 20px',
          }}>
          <h4 style={{ margin: 0 }}>
            <HourglassOutlined /> Least used tabs ({limitLeastUsedTime} minutes)
            <span
              style={{
                fontSize: 12,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                fontWeight: 'normal',
                marginLeft: 20,
              }}>
              Remove unused tabs to free browser memory
            </span>
          </h4>
          <Button
            loading={loading}
            onClick={handleEmptyGarbageTabs}
            type="primary"
            className="btn-error">
            <DeleteOutlined /> Empty (
            {selectedGarbageTabs.length > 0
              ? selectedGarbageTabs.length
              : garbageCollectedTabs.length}
            )
          </Button>
        </div>
      }>
      <div
        style={{
          maxHeight: '300px',
          height: 'fit-content',
          overflow: 'auto',
          overflowX: 'hidden',
        }}>
        <LoadableContainer
          isLoading={garbageCollectedTabs.length === 0}
          loadComponent={
            <Empty
              image={GENERAL_EMPTY_IMAGE}
              imageStyle={{ height: 60 }}
              description={<span>No deprecated tabs found</span>}></Empty>
          }>
          <React.Fragment>
            {garbageCollectedTabs.map((tab, index) => (
              <div key={tab.url} className={index % 2 === 0 ? 'even-treenode' : 'odd-treenode'}>
                <WindowTabItem
                  key={`${tab.id}`}
                  isSelected={selectedTab[tab.id?.toString() || '']}
                  onClick={() => handleTabItemClicked(tab)}
                  windowTab={{
                    ...(tab as any),
                    lastAccessed:
                      tabTimeTrack[tab.url || '']?.lastAccessedAt * 1000 || sessionStartTime * 1000,
                  }}
                />
              </div>
            ))}
          </React.Fragment>
        </LoadableContainer>
      </div>
    </Card>
  );
};

export default TimeTrackerGarbageTabsSection;
