import { TabChecked, TreeNode } from '@/models';
import { getUpdatedCheck } from '@/utils';

import { useRepositoryContext } from './useRepositoryContext';

export const useRepositoryTabActions = () => {
  /** Tree nodes state */
  const repositoryContext = useRepositoryContext();
  const { selectedTreeNodes, setSelectedTreeNodes } = repositoryContext;

  const handleUpdateSelectedTreeNodes = (
    node: TreeNode,
    oldSelectedTreeNodes: TabChecked,
    isChecked: boolean
  ) => {
    setSelectedTreeNodes(
      node.type === 'directory'
        ? {
            ...oldSelectedTreeNodes,
            ...getUpdatedCheck([node], isChecked),
          }
        : {
            ...oldSelectedTreeNodes,
            [node.value.id?.toString() || '']: isChecked,
          }
    );
  };

  const handleSelectTab = (treeNode: TreeNode) => {
    if (!treeNode.value.id) return;
    const isChecked = selectedTreeNodes[treeNode.value.id];
    if (isChecked) {
      const updatedSelectedTreeNodes = selectedTreeNodes;
      delete updatedSelectedTreeNodes[treeNode.value.id];
      handleUpdateSelectedTreeNodes(treeNode, updatedSelectedTreeNodes, false);
    } else {
      handleUpdateSelectedTreeNodes(treeNode, selectedTreeNodes, true);
    }
  };

  return {
    handleSelectTab,
    handleUpdateSelectedTreeNodes,
  };
};
