/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router';

import { RepositoryLayout } from '@/components';

type Props = Record<string, any>;

const WorkspaceRepositoryScreen = (props: Props) => {
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  if (!workspaceSlug) return <div>No workspace provided</div>;
  return (
    <React.Fragment>
      <RepositoryLayout />
    </React.Fragment>
  );
};

export default WorkspaceRepositoryScreen;
