import * as yup from 'yup';

export type UpdateUserArgs = {
  username: string;
  firstName: string;
  lastName: string;
  profile_image: string;
};

export const updateUserInfoValidationSchema = yup.object({
  username: yup
    .string()
    .matches(
      /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/,
      'Usernamename must not include blank space and invalid character.'
    )
    .required('Username is required'),
  firstName: yup.string().max(30, 'First name must be under 50 characters').required(),
  lastName: yup.string().max(30, 'First name must be under 50 characters').required(),
  profile_image: yup.string().required(),
} as Record<keyof UpdateUserArgs, any>);
