import React from 'react';
import { CgStack } from 'react-icons/cg';
import { TbWaveSine } from 'react-icons/tb';
import { useHistory, useParams } from 'react-router';

import { SettingOutlined } from '@ant-design/icons';
import { Layout, Space } from 'antd';

import { ANT_DESIGN_THEME, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useLoadTimeTrackerInfo, useTimeMachineContext } from '@/hooks';
import { TimeTrackerAppRoutes, TimeTrackerTabItem } from '@/models';
import { useTimeTrackerStore } from '@/stores';

import TimeTrackerModeTag from '../time-tracker-mode-tag';

type Props = any;

const styles: Record<string, React.CSSProperties> = {
  NavigationTabIcon: {
    ...MIDDLE_STYLE,
    fontWeight: '600',
    cursor: 'pointer',
  },
  NavigationTabName: {
    marginLeft: 10,
  },
};

const TimeTrackerAppBar = (props: Props) => {
  useLoadTimeTrackerInfo();
  const history = useHistory();
  const { productivityMode } = useTimeMachineContext();
  const { userTimeTrackerInfo } = useTimeTrackerStore();
  const { tabLensTabName } = useParams<{
    tabLensTabName: TimeTrackerAppRoutes;
  }>();
  const generalTabs: TimeTrackerTabItem[] = [
    {
      icon: <CgStack />,
      title: 'All sessions',
      key: 'sessions',
    },
  ];
  const tabs: TimeTrackerTabItem[] =
    tabLensTabName === 'landing'
      ? generalTabs
      : [
          {
            icon: <TbWaveSine />,
            title: 'Active session',
            key: 'active',
          },
          ...generalTabs,
        ];
  const handleNavigateTab = (tabName: TimeTrackerAppRoutes) => {
    history.push(`/time-tracker/${tabName}`);
  };
  return (
    <Layout.Header
      style={{
        ...MIDDLE_STYLE,
        padding: '10px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
        boxShadow: 'rgba(149, 157, 165, 0.065) 0px 8px 24px !important',
      }}>
      <Space style={{ ...MIDDLE_STYLE }} size={30}>
        <TimeTrackerModeTag productivityMode={productivityMode} />
        {tabs.map(tab => (
          <div
            key={tab.key}
            onClick={() => handleNavigateTab(tab.key)}
            style={{
              ...styles.NavigationTabIcon,
              color:
                tab.key === tabLensTabName
                  ? GLOBAL_THEME_COLOR.$highlight_color
                  : GLOBAL_THEME_COLOR.$text_color,
            }}>
            {tab.icon}
            <span style={styles.NavigationTabName}>{tab.title}</span>
          </div>
        ))}
      </Space>
      {userTimeTrackerInfo?.time_tracker_setting && (
        <div
          onClick={() => handleNavigateTab('settings')}
          style={{
            ...styles.NavigationTabIcon,
            color:
              tabLensTabName === 'settings'
                ? GLOBAL_THEME_COLOR.$highlight_color
                : GLOBAL_THEME_COLOR.$text_color,
          }}>
          <SettingOutlined /> <span style={styles.NavigationTabName}>Settings</span>
        </div>
      )}
    </Layout.Header>
  );
};

export default TimeTrackerAppBar;
