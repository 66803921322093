import React from 'react';

import { Modal } from 'antd';

import PowerfulSearchContainer from '@/components/powerful-search-container';
import { useModalStore } from '@/stores';

import './index.scss';

type Props = any;

const SuperSearchModal = (props: Props) => {
  const { closeModal, modalName: selectedModal } = useModalStore();
  return (
    <Modal
      width={'100%'}
      className="super-search-modal"
      footer={[]}
      closeIcon={<></>}
      onCancel={closeModal}
      open={selectedModal === 'superSearch'}>
      <PowerfulSearchContainer defaultActionList={[]} onClose={closeModal} />
    </Modal>
  );
};

export default SuperSearchModal;
