/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { notification } from 'antd';

import { useSnackbarStore } from '@/stores';
import { delay } from '@/utils/date.util';

type Props = {
  children: React.ReactElement;
};

const GLOBAL_SNACKBAR_DELAY = 300;

const SnackbarWrapper = ({ children }: Props) => {
  const { front, dequeueNotification, lastModifiedDate } = useSnackbarStore();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const handleNotification = async () => {
      dequeueNotification();
      await delay(GLOBAL_SNACKBAR_DELAY);
    };
    handleNotification();
  }, [lastModifiedDate]);

  useEffect(() => {
    if (front) {
      let method = api.open;
      switch (front.type) {
        case 'Error':
          method = api.error;
          break;
        case 'Info':
          method = api.info;
          break;
        case 'Success':
          method = api.success;
          break;
      }
      method({
        message: front.name,
        description: front.description,
        placement: 'top',
      });
    }
  }, [front]);

  return (
    <React.Fragment>
      {contextHolder}
      {children}
    </React.Fragment>
  );
};

export default SnackbarWrapper;
