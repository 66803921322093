import React from 'react';

import { Avatar, Badge, Card, Divider } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { TimeTrackerEngineState } from '@/gql/graphql';
import { useTimeMachineContext } from '@/hooks';
import { generateHSLColor, maybeZero, renderTime, shortenString } from '@/utils';

import TimeTrackerButton from '../time-tracker-button';

type Props = any;

const TimelessCurrentBrowsingTab = (props: Props) => {
  const { engineState, trackedTabs, currentBrowsingTab, realtimeTabTimeTrack } =
    useTimeMachineContext();
  const RenderCardContent = () => {
    if (currentBrowsingTab && trackedTabs.length > 0) {
      return (
        <div style={{ width: '100%' }}>
          <div>
            <Badge
              key={'current-browsing-tab'}
              color={generateHSLColor(currentBrowsingTab.url || '')}
              text={
                <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                  <Avatar
                    size={20}
                    src={currentBrowsingTab?.favIconUrl}
                    style={{
                      backgroundColor: GLOBAL_THEME_COLOR.$dark_secondary_color,
                      marginRight: 10,
                    }}
                    alt={currentBrowsingTab?.favIconUrl}>
                    {currentBrowsingTab.title?.slice(0, 1)}
                  </Avatar>
                  {shortenString(currentBrowsingTab.url || '', 40)}
                </span>
              }
            />
          </div>
          <Divider />
        </div>
      );
    }
    return (
      <Badge key={'current-browsing-tab'} color={'grey'} text={'No tab is currently active'} />
    );
  };

  return (
    <Card>
      <RenderCardContent />
      <div
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
        <div
          style={{
            fontWeight: 'bold',
            color: GLOBAL_THEME_COLOR.$dark_highlight_color,
          }}>
          {renderTime(maybeZero(realtimeTabTimeTrack[currentBrowsingTab?.url as any]?.value))}
        </div>
        <TimeTrackerButton />
      </div>
      {engineState === TimeTrackerEngineState.Offline && (
        <p>Session is interrupted. Please turn "Tracking On" to continue time tracking</p>
      )}
    </Card>
  );
};

export default TimelessCurrentBrowsingTab;
