import React from 'react';

import { AnimatedComponent, TimeTrackerFocusModeSetting } from '@/components';
import TabLensLandingSection from '@/components/time-tracker-landing-section';
import { MIDDLE_STYLE } from '@/constants';
import { TimeTrackerProductivityMode } from '@/gql/graphql';
import { useTimeMachineContext } from '@/hooks';

type Props = any;

const TimeTrackerGoalSettingScreen = (props: Props) => {
  const { productivityMode: mode } = useTimeMachineContext();
  return (
    <div style={{ ...MIDDLE_STYLE }}>
      <AnimatedComponent.OpacityFadeInDiv delay={300}>
        {mode === TimeTrackerProductivityMode.FocusMode ? (
          <TimeTrackerFocusModeSetting />
        ) : (
          <TabLensLandingSection />
        )}
      </AnimatedComponent.OpacityFadeInDiv>
    </div>
  );
};

export default TimeTrackerGoalSettingScreen;
