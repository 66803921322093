/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { FieldTimeOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { endOfToday, startOfToday } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';

import { MIDDLE_STYLE } from '@/constants';
import { useTimeMachineContext } from '@/hooks';
import { BrowsingTabTask } from '@/models';
import { generateHSLColor, getLastHourDateRange, unixToDate } from '@/utils';
import { sortAndCoalesceTabs } from '@/utils/time-tracker.util';

import TimelessTimelineRangeSlider from '../timeless-timeline-range-slider';

type Props = any;

enum TimePeriod {
  LastHour = 'Last hour',
  Last3Hour = 'Last 3 hours',
  Today = 'Today',
  SessionTime = 'Only session time',
}

const TimelessTimelineRangeContainer = (props: Props) => {
  const {
    realtimePointer,
    selectedTimePeriod,
    sessionStartTime,
    sessionEndTime,
    trackedTabs,
    selectedRange,
    selectedRangeEnabled,
    setSelectedRange,
    setSelectedRangeEnabled,
    setSelectedTimePeriod,
  } = useTimeMachineContext();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.LastHour);

  const handleOnChangeCallback = data => {
    setSelectedRange(data);
  };

  const handleSelectRange = () => {
    setSelectedRangeEnabled(!selectedRangeEnabled);
  };

  const onTimePeriodChanged = value => {
    setTimePeriod(value);
  };

  const rangeTrackedTabs = useMemo(() => {
    const range: {
      range: [Date, Date];
      color: string;
      startTab: BrowsingTabTask;
      endTab: BrowsingTabTask;
    }[] = [];
    sortAndCoalesceTabs(
      _.cloneDeep(trackedTabs),
      (startTab, endTab) => {
        if (startTab.url && endTab?.url) {
          range.push({
            startTab,
            endTab,
            range: [unixToDate(startTab.timestamp), unixToDate(endTab.timestamp)],
            color: generateHSLColor(startTab.url || ''),
          });
        }
      },
      'dsc'
    );
    return range;
  }, [trackedTabs, realtimePointer]);

  useEffect(() => {
    const timePeriodRange = (): [Date, Date] => {
      switch (timePeriod) {
        case TimePeriod.LastHour:
          return getLastHourDateRange(0, 1);
        case TimePeriod.Last3Hour:
          return getLastHourDateRange(-2, 1);
        case TimePeriod.SessionTime:
          return [unixToDate(sessionStartTime), unixToDate(sessionEndTime)];
        case TimePeriod.Today:
          return [startOfToday(), endOfToday()];
      }
    };
    const _timePeriod = timePeriodRange();
    setSelectedTimePeriod(_timePeriod);
  }, [timePeriod, sessionStartTime, sessionEndTime]);

  return (
    <div style={{ marginTop: 10, padding: '0px 10px' }}>
      <TimelessTimelineRangeSlider
        selectedEnable={selectedRangeEnabled}
        timelineInterval={selectedTimePeriod}
        intervals={trackedTabs.length >= 2 ? rangeTrackedTabs : []}
        onChangeCallback={handleOnChangeCallback}
      />
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-end', marginTop: 10 }}>
        {selectedRangeEnabled && selectedRange && (
          <div style={{ marginRight: 10 }}>
            From{' '}
            <span style={{ fontWeight: 'bold' }}>{moment(selectedRange[0]).format('HH:mm')}</span>{' '}
            {''}
            to{' '}
            <span style={{ fontWeight: 'bold' }}>{moment(selectedRange[1]).format('HH:mm')}</span>
          </div>
        )}
        <Button onClick={handleSelectRange}>
          <FieldTimeOutlined /> Range {selectedRangeEnabled ? 'OFF' : 'ON'}
        </Button>
        <Select
          style={{ marginLeft: 20, width: 150 }}
          value={timePeriod}
          onChange={onTimePeriodChanged}
          defaultValue={TimePeriod.LastHour}
          options={Object.keys(TimePeriod).map(timeOption => ({
            label: TimePeriod[timeOption],
            value: TimePeriod[timeOption],
          }))}
        />
      </div>
    </div>
  );
};

export default TimelessTimelineRangeContainer;
