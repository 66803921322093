import React from 'react';

import { Card, Popover, Timeline } from 'antd';

import { MIDDLE_STYLE } from '@/constants';

type Props = {
  item: {
    icon: React.ReactNode;
    steps: string[];
    title: string;
    description: string;
  };
};

const GuideCard = ({ item }: Props) => {
  return (
    <Popover
      placement="left"
      content={
        <div style={{ padding: '10px 20px' }}>
          <Timeline
            style={{ fontWeight: 'normal' }}
            items={item.steps.map(step => ({
              children: step,
            }))}
          />
        </div>
      }
      title={
        <div style={{ padding: '10px 20px', width: 350 }}>
          <h2>Learn</h2>
          <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
            <div
              style={{
                color: '#1890ff',
                margin: '20px 0px',
              }}>
              {item.icon}
            </div>
          </div>
          <p>{item.title}</p>
        </div>
      }
      trigger="click">
      <Card hoverable style={{ maxWidth: 300, height: 230 }}>
        <div
          style={{
            color: '#1890ff',
          }}>
          {item.icon}
        </div>
        <div style={{ marginTop: 20 }}>
          <h3>{item.title}</h3>
          <p style={{ marginTop: 10 }}>{item.description}</p>
        </div>
        {/* <Button type="primary" style={{ marginTop: 20 }}>
          Read docs <RightOutlined />
        </Button> */}
      </Card>
    </Popover>
  );
};

export default GuideCard;
