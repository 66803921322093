import { graphql } from '@/gql';

export const pinRepositoryTab = graphql(`
  mutation PinRepositoryTab($tabId: String!, $repositoryId: String!) {
    pinRepositoryTab(pinRepositoryTabArgs: { tabId: $tabId, repositoryId: $repositoryId }) {
      message
      type
    }
  }
`);

export const unpinRepositoryTab = graphql(`
  mutation UnpinRepositoryTab($tabId: String!) {
    unpinRepositoryTab(unpinRepositoryTabArgs: { tabId: $tabId }) {
      message
      type
    }
  }
`);

export const searchTabOnWebQuery = graphql(`
  query SearchTabOnWeb($websiteUrl: String!) {
    searchOnWeb(searchTabOnWebArgs: { websiteUrl: $websiteUrl })
  }
`);

export const gatherUrlsMetadataQuery = graphql(`
  query GatherUrlsMetadata($websiteUrls: [String!]!) {
    gatherUrlsMetadata(gatherUrlsMetadataArgs: { websiteUrls: $websiteUrls }) {
      customName
      favIconUrl
      id
      pinned
      labels
      description
      parentDirectory
      repositoryId
      title
      url
    }
  }
`);

export const queryOpenGraphMetadata = graphql(`
  query QueryOpenGraphMetadata($websiteUrl: String!) {
    queryOpenGraphMetadata(queryOpenGraphMetadata: { websiteUrl: $websiteUrl }) {
      message
      type
    }
  }
`);
