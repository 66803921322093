/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Button } from 'antd';

import PowerfulSearchInput from '../powerful-search-input';
import SearchDirectoryRepository from '../search-directory-repository';

type Props = {
  onClose?: any;
};

const DirectorySearchContainer = ({ onClose }: Props) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const handleSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  return (
    <div className="powerful-search-container">
      <PowerfulSearchInput
        handleValueChanged={handleSearchInputChange}
        value={searchInput}
        extraAction={
          <Button style={{ marginLeft: 15 }} onClick={onClose}>
            Close
          </Button>
        }
        placeholder="Search or Create directory"
      />
      <div className="powerful-search-scrollable-area">
        <React.Fragment>
          <SearchDirectoryRepository
            searchInput={searchInput}
            setSearchInput={value => setSearchInput(value)}
          />
        </React.Fragment>
      </div>
    </div>
  );
};

export default DirectorySearchContainer;
