import React from 'react';

import { Tooltip } from 'antd';

import { MIDDLE_STYLE } from '@/constants';

export type ActionItemProps = {
  prefix: React.ReactNode;
  actionName: string;
  description: string;
  actionHandler: any;
  shortcutCombo?: string;
};

const PowerfulActionItem: React.FC<ActionItemProps> = ({
  prefix,
  actionName,
  description,
  shortcutCombo,
  actionHandler,
}: ActionItemProps) => {
  return (
    <Tooltip title={description}>
      <div className="powerful-action-item" onClick={actionHandler}>
        <div style={{ ...MIDDLE_STYLE }}>
          <div style={{ marginRight: 15 }}>{prefix}</div>
          {actionName}
        </div>
        {shortcutCombo && (
          <div className="powerful-shortcut-tag">
            <div className="powerful-shortcut-inner">{shortcutCombo}</div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default PowerfulActionItem;
