/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Redirect } from 'react-router';

import { LoadableContainer, SplashScreen } from '@/components';
import { useLoadTimeTrackerInfo } from '@/hooks';
import { SessionStatus } from '@/models';
import { getTimeTrackerSessionStatus, useAuthStore, useTimeTrackerStore } from '@/stores';

type Props = any;

const TimeTrackerLandingRedirectScreen = (props: Props) => {
  useLoadTimeTrackerInfo();
  const { user } = useAuthStore();
  const { timeTrackerLoading, userTimeTrackerInfo } = useTimeTrackerStore();
  const sessionSetting = useMemo(
    () => userTimeTrackerInfo.time_tracker_setting,
    [userTimeTrackerInfo, user]
  );
  const sessionStatus = useMemo(
    () => getTimeTrackerSessionStatus(userTimeTrackerInfo.time_tracker_setting),
    [userTimeTrackerInfo, user]
  );
  const isSessionRunning = useMemo(
    () => sessionSetting && sessionStatus === SessionStatus.Running,
    [sessionSetting, sessionStatus]
  );

  return (
    <LoadableContainer isLoading={timeTrackerLoading} loadComponent={<SplashScreen />}>
      {isSessionRunning ? (
        <Redirect to={`/time-tracker/active`} />
      ) : (
        <Redirect to={`/time-tracker/landing`} />
      )}
    </LoadableContainer>
  );
};

export default TimeTrackerLandingRedirectScreen;
