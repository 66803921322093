import Browser from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import * as Messenger from '@/messaging/messenger';
import { TreeNode } from '@/models';

import { IBrowserDevService } from './IBrowserDevService';
import { communicatorService } from './PostCommunicatorService';

export class BrowserDevRemoteService implements IBrowserDevService {
  async authenticateUser(token: string) {
    await communicatorService.send<void, Messenger.MessageAuthenticateUserParams>({
      message: Messenger.MESSAGE_AUTHENTICATE_USER,
      params: {
        authenticateToken: token,
      },
    });
    return;
  }

  async ungroupTab(tabIds: number[]): Promise<void> {
    await communicatorService.send<void, Messenger.MessageUngroupTabParams>({
      message: Messenger.MESSAGE_UNGROUP_TAB,
      params: {
        tabIds,
      },
    });
    return;
  }

  async fetchWindows() {
    const response = await communicatorService.send<Browser.Windows.Window[], string>({
      message: Messenger.MESSAGE_GET_WINDOWS,
    });
    return response;
  }

  async selectTab(windowId: number | undefined, tabId: number | undefined) {
    if (!tabId || !windowId) return;
    await communicatorService.send<void, Messenger.MessageSelectTabParams>({
      message: Messenger.MESSAGE_SELECT_TAB,
      params: {
        tabId: parseInt(tabId as any),
        windowId,
      },
    });
    return;
  }

  async closeWindow(window: Browser.Windows.Window) {
    await communicatorService.send<void, Messenger.MessageCloseWindowParams>({
      message: Messenger.MESSAGE_CLOSE_WINDOW,
      params: {
        window,
      },
    });
    return;
  }

  async moveTreeItems(treeItems: TreeNode[]): Promise<void> {
    await communicatorService.send<void, Messenger.MessageMoveTabsParams>({
      message: Messenger.MESSAGE_MOVE_TABS,
      params: {
        treeItems,
      },
    });
    return;
  }

  async groupTabs(
    tabIds: number[],
    windowProperties?: chrome.tabGroups.UpdateProperties,
    groupId?: number,
    windowId?: number
  ) {
    return communicatorService.send<number, Messenger.MessageGroupTabsParams>({
      message: Messenger.MESSAGE_GROUP_TABS,
      params: {
        tabIds,
        windowProperties,
        groupId,
        windowId,
      },
    });
  }

  async deleteTab(tabId: number | undefined) {
    if (!tabId) return;
    await communicatorService.send<void, Messenger.MessageDeleteTabParams>({
      message: Messenger.MESSAGE_DELETE_TAB,
      params: {
        tabId: parseInt(tabId as any),
      },
    });
    return;
  }

  async createTab(tab: RepositoryTab) {
    await communicatorService.send<void, Messenger.MessageCreateTabParams>({
      message: Messenger.MESSAGE_CREATE_TAB,
      params: {
        tab,
      },
    });
    return;
  }

  async openTabs(tabs: RepositoryTab[]) {
    await communicatorService.send<void, Messenger.MessageOpenTabsParams>({
      message: Messenger.MESSAGE_OPEN_TABS,
      params: {
        tabs,
      },
    });
    return;
  }

  queryTabGroups(windowId: number): void {
    throw communicatorService.send<void, any>({
      message: Messenger.MESSAGE_QUERY_TAB_GROUPS,
      params: {
        windowId,
      },
    });
  }

  getTabGroup(groupId: number): void {
    throw communicatorService.send<void, Messenger.MessageGetTabGroupParams>({
      message: Messenger.MESSAGE_GET_TAB_GROUPS,
      params: {
        groupId,
      },
    });
  }

  updateTabGroup(groupId: number, windowProperties: chrome.tabGroups.UpdateProperties): void {
    throw communicatorService.send<void, Messenger.MessageUpdateTabGroupParams>({
      message: Messenger.MESSAGE_UPDATE_TAB_GROUPS,
      params: {
        groupId,
        windowProperties,
      },
    });
  }

  moveTabGroup(
    groupId: number,
    moveProperties: { index: number; windowId?: number | undefined }
  ): void {
    throw communicatorService.send<void, Messenger.MessageMoveTabGroupParams>({
      message: Messenger.MESSAGE_QUERY_TAB_GROUPS,
      params: {
        groupId,
        moveProperties,
      },
    });
  }
}
