/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { EditOutlined, TeamOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Empty, Input, Select, Space } from 'antd';
import Fuse from 'fuse.js';

import { GENERAL_EMPTY_IMAGE, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { User, UserRole } from '@/gql/graphql';
import { addWorkspaceMemberMutation, getAllUsersQuery } from '@/graphql';
import { useBreakpoint, usePersonal, useWorkspaceContext } from '@/hooks';
import { apolloClient } from '@/services/ApolloService';
import { useAuthStore } from '@/stores';
import { stringInArray } from '@/utils';

import LoadableContainer from '../loadable-container';
import WorkspaceMemberList from '../workspace-member-list';

type Props = any;

const WorkspaceMember = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const { workspace, onRefresh } = useWorkspaceContext();
  const { isWorkspaceAdmin } = usePersonal();
  const [loading, setLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<Partial<User>[]>([]);
  const [refreshing, setRefreshing] = useState(+new Date());
  const [users, setUsers] = useState<Partial<User>[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<UserRole>(UserRole.WorkspaceMember);
  const [filterUsers, setFilterUsers] = useState<Partial<User>[]>([]);
  const { fetchUserById } = useAuthStore();

  const handleSearchUser = async (text: string) => {
    const { data } = await apolloClient.query({
      query: getAllUsersQuery,
    });
    setUsers(data.getAllUsers);
    const fuse = new Fuse(data.getAllUsers, { keys: ['email', 'username'] });
    const results = fuse.search(text);
    setFilterUsers(results.map(result => result.item));
    setSearchInput(text);
  };

  const handleAddMember = async () => {
    if (!workspace) return;
    setLoading(true);
    try {
      await apolloClient.mutate({
        mutation: addWorkspaceMemberMutation,
        variables: {
          memberEmail: searchInput,
          workspaceId: workspace.id,
          role: selectedRole,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    handleRefresh();
    onRefresh();
  };

  const handleRefresh = () => {
    setRefreshing(+new Date());
  };

  useEffect(() => {
    const init = async () => {
      if (!workspace) return;
      setLoading(true);
      try {
        const fetchUsers: Partial<User>[] = [];
        for (const member of workspace.members) {
          const user = await fetchUserById(member);
          if (user) {
            fetchUsers.push(user);
          }
        }
        setMembers(fetchUsers);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    init();
  }, [workspace, refreshing]);

  return (
    <div>
      <Space style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <h3>
          <TeamOutlined /> Members
        </h3>
        {!isMobile && (
          <LoadableContainer isLoading={!isWorkspaceAdmin(workspace)} loadComponent={<></>}>
            <React.Fragment>
              <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  dropdownMatchSelectWidth={500}
                  style={{ width: isMobile ? '100%' : '350px' }}
                  onChange={(value: string) => handleSearchUser(value)}
                  placeholder="Search user by username or email address"
                  options={filterUsers.map(user => ({
                    value: user.email,
                  }))}>
                  <Input.Search size="middle" value={searchInput} style={{ marginRight: 10 }} />
                </AutoComplete>
                <div style={MIDDLE_STYLE}>
                  <Select
                    style={{ width: 120 }}
                    value={selectedRole}
                    onChange={value => setSelectedRole(value)}
                    options={[
                      {
                        value: UserRole.WorkspaceMember,
                        label: (
                          <span>
                            <UserOutlined /> Member
                          </span>
                        ),
                      },
                      {
                        value: UserRole.WorkspaceModerator,
                        label: (
                          <span>
                            <EditOutlined /> Moderator
                          </span>
                        ),
                      },
                    ]}
                  />
                </div>
                <Button
                  disabled={
                    stringInArray(members.map(member => member.email || '') || [], searchInput) ||
                    !stringInArray(users.map(user => user.email || '') || [], searchInput)
                  }
                  type="primary"
                  onClick={handleAddMember}>
                  <UserAddOutlined /> Add new member
                </Button>
              </Space>
            </React.Fragment>
          </LoadableContainer>
        )}
      </Space>
      <LoadableContainer
        isLoading={loading}
        loadComponent={
          <div style={{ ...MIDDLE_STYLE }}>
            <ReactLoading
              type={'cylon'}
              color={GLOBAL_THEME_COLOR.$highlight_color}
              height={'10%'}
              width={'10%'}
            />
          </div>
        }>
        <LoadableContainer
          isLoading={members.length === 0}
          loadComponent={
            <div
              style={{
                ...MIDDLE_STYLE,
                width: '100%',
                height: '200px',
              }}>
              <Empty
                image={GENERAL_EMPTY_IMAGE}
                imageStyle={{ height: 60 }}
                description={<span>No member found</span>}></Empty>
            </div>
          }>
          <WorkspaceMemberList workspace={workspace} members={members} />
        </LoadableContainer>
      </LoadableContainer>
    </div>
  );
};

export default WorkspaceMember;
