import Browser from 'webextension-polyfill';

import { Directory, RepositoryTab } from '@/gql/graphql';
import { BrowsingTabTask, TabTreeDirectory, TreeNode, UnionTab } from '@/models';
import { BrowserDevServiceType } from '@/typings/browserService.type';

import { getURLHost } from './string.util';

// 'chrome://', 'chrome-extension://'
export const sanitizeWindowTabs = (window: Browser.Windows.Window) => {
  const blacklist: string[] = [];
  return window?.tabs?.filter(tab => !blacklist.some(t => tab.url?.includes(t))) || [];
};

export const fetchTabGroups = async (
  window: Browser.Windows.Window,
  browserDevService: BrowserDevServiceType
) => {
  let data: chrome.tabGroups.TabGroup[] = [];
  try {
    if (!window.id) return [];
    await browserDevService.queryTabGroups(window.id);
  } catch (_data: any) {
    data = _data;
  }
  return data;
};

export const convertWindowTabToRepositoryTab = (tabs: Browser.Tabs.Tab[]): RepositoryTab[] => {
  return tabs.map<RepositoryTab>(windowTab => ({
    ...windowTab,
    id: windowTab.id?.toString() as any,
    title: windowTab.title as any,
    url: windowTab.url as any,
    favIconUrl: windowTab.favIconUrl || '',
    description: '',
    parentDirectory: (windowTab as any).groupId > 0 ? (windowTab as any).groupId : '',
    pinned: [],
    labels: [],
  }));
};

export const convertWindowTabGroupsToDirectory = (
  tabGroups: chrome.tabGroups.TabGroup[]
): Directory[] => {
  return tabGroups.map<Directory>(tabGroup => ({
    id: tabGroup.id.toString(),
    color: tabGroup.color,
    name: tabGroup.title || '',
  }));
};

export const tabTreeWalk = ({
  treeNodes,
  handleDirectoryItem,
  handleTabItem,
}: {
  treeNodes: TreeNode[];
  handleDirectoryItem?: (directory: TabTreeDirectory) => void;
  handleTabItem?: (item: UnionTab) => void;
}) => {
  for (const tab of treeNodes) {
    if (tab.type === 'directory') {
      const directory: TabTreeDirectory = tab.value as TabTreeDirectory;
      handleDirectoryItem && handleDirectoryItem(directory);
      tabTreeWalk({
        treeNodes: directory.items,
        handleDirectoryItem,
        handleTabItem,
      });
    } else {
      handleTabItem && handleTabItem(tab.value as UnionTab);
    }
  }
};

export const breakdownTreeNodes = (treeNodes: TreeNode[]) => {
  const tabs: UnionTab[] = [];
  const directories: Directory[] = [];
  tabTreeWalk({
    treeNodes: treeNodes,
    handleDirectoryItem(directory) {
      directories.push({
        id: directory.id,
        color: directory.color,
        name: directory.name,
        parentDirectory: directory.parentDirectory,
      });
    },
    handleTabItem(item) {
      tabs.push(item as any);
    },
  });
  return { tabs, directories };
};

/** Build tree from tabs and directories */
export const buildTree = (tabs: UnionTab[], directories: Directory[]): TreeNode[] => {
  /** Accepts two types: Directory and Tab */
  const _treeNodes: TreeNode[] = [];
  const visitedTab: Record<string, boolean> = {};
  const visitedDir: Record<string, boolean> = {};

  const collectTreeNodesOfDirectory = (directoryId: any): TreeNode[] => {
    /** Collect all directory nodes which has current dir as parent pointer */
    const dirNodes: TreeNode[] = directories
      .filter(childDir => childDir.parentDirectory === directoryId && !visitedDir[childDir.id])
      .map(dirNode => {
        if (dirNode.id) {
          visitedDir[dirNode.id] = true;
        }
        return {
          type: 'directory',
          value: {
            ...dirNode,
            items: collectTreeNodesOfDirectory(dirNode.id),
          },
        };
      });
    /** Collect all tab nodes which has current dir as parent pointer */
    const tabNodes: TreeNode[] = tabs
      .filter(tab => {
        const tabParentDir = (tab as RepositoryTab).parentDirectory?.toString();
        return tabParentDir === directoryId && !visitedTab[tab.id as string];
      })
      .map(tab => {
        if (tab.id) {
          visitedTab[tab.id] = true;
        }
        return {
          value: tab,
          type: 'tab',
        };
      });

    const sanitizedDirNodes = dirNodes.filter(
      dirNode => (dirNode.value as TabTreeDirectory).items.length > 0
    );
    return [...sanitizedDirNodes, ...tabNodes];
  };

  for (const dir of directories.filter(_dir => !_dir.parentDirectory)) {
    /** Collect all directory nodes which has current dir as parent pointer */
    const items = collectTreeNodesOfDirectory(dir.id);
    if (items.length > 0) {
      _treeNodes.push({
        type: 'directory',
        value: {
          ...dir,
          items: items,
        },
      });
    }
  }
  for (let indx = 0; indx < tabs.length; indx++) {
    const tab = tabs[indx];
    if (tab.id && !(tab.id in visitedTab)) {
      _treeNodes.push({
        type: 'tab',
        value: tab,
      });
    }
  }
  return _treeNodes;
};

export const sortTreeNodes = (treeNodes: TreeNode[]): TreeNode[] => {
  const sortPair = (_treeNodes: TreeNode[]) =>
    _treeNodes.sort((a, b) => {
      if (a.type === 'directory' && b.type === 'directory') {
        /** If pair is directory - directory => sort by name */
        const [dirA, dirB] = [a.value as TabTreeDirectory, b.value as TabTreeDirectory];
        return dirA.name > dirB.name ? 1 : -1;
      } else if (a.type === 'tab' && b.type === 'tab') {
        /** If pair is tab - tab => sort by url */
        const [tabA, tabB] = [a.value as UnionTab, b.value as UnionTab];
        if (!tabA.url || !tabB.url) return 1;
        const hostNameA = getURLHost(tabA.url).toLowerCase();
        const hostNameB = getURLHost(tabB.url).toLowerCase();
        return hostNameA > hostNameB ? 1 : -1;
      }
      return 1;
    });
  /** Recursively call the method to sort tabs, directories and tabs of directory */
  const _treeNodes: TreeNode[] = [];
  for (const node of sortPair(treeNodes)) {
    if (node.type === 'directory') {
      const dirNode = node.value as TabTreeDirectory;
      // sort the treeNode item inside the directory
      dirNode.items = sortTreeNodes((dirNode as TabTreeDirectory).items);
      node.value = dirNode;
    }
    _treeNodes.push(node);
  }
  return _treeNodes;
};

export const getUpdatedCheck = (
  treeNodes: TreeNode[],
  isChecked: boolean
): Record<string, boolean> => {
  const _updatedChecked = {};
  tabTreeWalk({
    treeNodes,
    handleDirectoryItem(directory) {
      _updatedChecked[directory.id] = isChecked;
    },
    handleTabItem(item) {
      if (!item.id) return;
      _updatedChecked[item.id] = isChecked;
    },
  });
  return _updatedChecked;
};

export const convertTaskIntoTab = (browsingTabTask: BrowsingTabTask): UnionTab => {
  return {
    ...browsingTabTask,
    lastAccessed: browsingTabTask.timestamp,
  };
};

export const buildTreeNodeId = (treeNode: TreeNode, type: 'tab' | 'directory') =>
  `${treeNode.value.id}-${type}`;

export const blacklistedTabs = ['https://app.tabhub.io'];
