import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import Avatar from 'boring-avatars';

import { GLOBAL_THEME_COLOR, LOCAL_WORKSPACE_ID } from '@/constants';
import { Workspace } from '@/gql/graphql';

type Props = {
  workspace: Workspace;
};

const WorkspaceAvatar = ({ workspace }: Props) => {
  return workspace.id === LOCAL_WORKSPACE_ID ? (
    <UserOutlined />
  ) : (
    <Avatar
      size={30}
      name={workspace.name}
      variant="beam"
      colors={[
        GLOBAL_THEME_COLOR.$highlight_color,
        GLOBAL_THEME_COLOR.$dark_highlight_color,
        GLOBAL_THEME_COLOR.$light_highlight_color,
      ]}
    />
  );
};

export default WorkspaceAvatar;
