import React from 'react';

type Props = {
  getRailProps: any;
  className: string;
};

const TimelineSliderRail = ({ getRailProps, className }: Props) => {
  return (
    <div className={className}>
      <div className="react_time_range__rail__outer" {...getRailProps()} />
      <div className="react_time_range__rail__inner" />
    </div>
  );
};

export default TimelineSliderRail;
