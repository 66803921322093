import React, { useEffect, useState } from 'react';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { Repository } from '@/gql/graphql';
import { queryOpenGraphMetadata } from '@/graphql';
import { apolloClient } from '@/services/ApolloService';
import { shortenString, toHex } from '@/utils';

import LoadableContainer from '../loadable-container';
import RepositoryBannerImage from '../repository-banner-image';

type Props = {
  bannerImage: string | undefined;
  repository: Repository;
};

const RepositoryCardImageBanner = ({ photoUrl }: { photoUrl: string }) => {
  return (
    <div
      style={{
        background: `url("${photoUrl}")`,
        aspectRatio: '3 / 1',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        padding: '10px 20px',
      }}></div>
  );
};

const RepositoryCardBanner = ({ repository, bannerImage }: Props) => {
  const [firstItemBanner, setFirstItemBanner] = useState<string | undefined>(undefined);
  const BlankBanner = (props: any) => {
    return (
      <div
        style={{
          ...MIDDLE_STYLE,
          backgroundColor: `#${toHex(`${repository.name}`).slice(0, 6)}`,
          width: '100%',
          aspectRatio: '3 / 1',
        }}>
        <div
          style={{
            ...MIDDLE_STYLE,
            color: GLOBAL_THEME_COLOR.$text_color_contrast,
            fontWeight: 'bold',
            fontSize: '20px',
          }}>
          &nbsp;&nbsp;{shortenString(repository.name, 24)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const initOpengraph = async () => {
      if (!repository.tabs?.[0]?.url) return;
      const firstItem = repository.tabs[0];
      try {
        const { data } = await apolloClient.query({
          query: queryOpenGraphMetadata,
          fetchPolicy: 'cache-first',
          variables: {
            websiteUrl: firstItem.url,
          },
        });
        const openGraphMetadata = JSON.parse(data.queryOpenGraphMetadata.message);
        if (openGraphMetadata) {
          setFirstItemBanner(openGraphMetadata?.ogImage?.[0]?.url || undefined);
        }
      } catch (error) {
        //
      }
    };
    initOpengraph();
  }, [repository]);

  return (
    <React.Fragment>
      {bannerImage ? (
        <RepositoryBannerImage bannerImage={bannerImage} />
      ) : (
        <LoadableContainer
          isLoading={!!firstItemBanner}
          loadComponent={<RepositoryCardImageBanner photoUrl={firstItemBanner || ''} />}>
          <BlankBanner />
        </LoadableContainer>
      )}
    </React.Fragment>
  );
};

export default RepositoryCardBanner;
