import React, { useMemo } from 'react';

import { PushpinFilled, PushpinOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import Browser from 'webextension-polyfill';

import { ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { useBrowserDevService, useWindowTabActions } from '@/hooks';
import { TabChecked, TabTreeDirectory, TreeNode } from '@/models';
import {
  ALL_SESSION,
  useDrawerStore,
  useRepositoryTabStore,
  useSnackbarStore,
  useTabManagerStore,
  useWindowStore,
} from '@/stores';
import { breakdownTreeNodes, sortTreeNodes } from '@/utils';

import IconContainer from '../icon-container';

type Props = any;

const TabManagerEditor = (props: Props) => {
  const { storeLocalPinnedTab, removeLocalPinnedTab, getLocalPinnedTabs, findDuplicateTabs } =
    useRepositoryTabStore();
  const { handleSaveTabs } = useWindowTabActions();
  const { openDrawer, closeDrawer } = useDrawerStore();
  const { enqueueNotification } = useSnackbarStore();
  const browserDevService = useBrowserDevService();
  const {
    multiverseTreeNodes,
    selectedMultiverseTreeNodes,
    deselectAllTreeNodes,
    selectMultiverseTreeNodes,
  } = useTabManagerStore();
  const { fetchWindows, windows } = useWindowStore();
  const { selectedSession } = useTabManagerStore();

  const collectedTreeNodes = useMemo<TreeNode[]>(() => {
    let _treeNodes: TreeNode[] = [];
    for (const multiverseKey in multiverseTreeNodes) {
      const currentTreeNodes = multiverseTreeNodes[multiverseKey];
      _treeNodes = _treeNodes.concat(currentTreeNodes);
    }
    return _treeNodes;
  }, [multiverseTreeNodes]);

  const collectedTabs = useMemo<Browser.Tabs.Tab[]>(() => {
    const { tabs } = breakdownTreeNodes(collectedTreeNodes);
    return (tabs as Browser.Tabs.Tab[]) || [];
  }, [collectedTreeNodes]);

  const collectedSelectedTabs = useMemo<Browser.Tabs.Tab[]>(() => {
    return collectedTabs.filter(
      (tab: Browser.Tabs.Tab) => selectedMultiverseTreeNodes?.[tab.windowId as any]?.[tab.id as any]
    );
  }, [collectedTabs, selectedMultiverseTreeNodes]);

  const collectSelectedTabsByWindow = (windowId: string) => {
    return collectedTabs.filter(
      (tab: Browser.Tabs.Tab) => selectedMultiverseTreeNodes[windowId as any][tab.id as any]
    );
  };

  const onRefresh = async () => {
    handleDeselectAll();
    await fetchWindows(browserDevService);
  };

  const handleSortTreeNodes = async () => {
    if (!collectedTabs) return;
    const sortedTreeNodes = sortTreeNodes(collectedTreeNodes);
    await browserDevService.moveTreeItems(sortedTreeNodes);
    await onRefresh();
  };

  const handleRemoveDuplicate = async () => {
    const promises: Promise<void>[] = [];
    const [closeTabs] = findDuplicateTabs(collectedTabs || []);
    if (closeTabs.length > 0) {
      for (const closeTab of closeTabs) {
        const tabId = parseInt(closeTab.id as any);
        promises.push(browserDevService.deleteTab(tabId));
      }
    }
    await Promise.all(promises);
    await onRefresh();
  };

  const handleCreateNewWindow = async () => {
    const promises: Promise<void>[] = [];
    const tabs = collectedSelectedTabs;
    for (const tab of tabs) {
      const tabId = parseInt(tab.id as any);
      promises.push(browserDevService.deleteTab(tabId));
    }
    await Promise.all(promises);
    await browserDevService.openTabs(tabs as any);
  };

  const handleOpenTabs = async () => {
    const promises: Promise<void>[] = [];
    for (const tab of collectedSelectedTabs) {
      promises.push(browserDevService.createTab(tab as any));
    }
    await Promise.all(promises);
  };

  const handleDeleteTab = async () => {
    try {
      const promises: Promise<void>[] = [];
      for (const windowId in selectedMultiverseTreeNodes) {
        for (const windowTab of collectSelectedTabsByWindow(windowId)) {
          if (!windowTab.id) continue;
          promises.push(browserDevService.deleteTab(windowTab.id));
        }
      }
      await Promise.all(promises);
      await enqueueNotification({
        name: 'Tabs deleted successfully',
        description: 'Finished closing tabs',
        type: 'Success',
      });
    } catch (error) {
      await enqueueNotification({
        name: 'Tabs deleted failed',
        description: 'Failed closing tabs',
        type: 'Error',
      });
    }
    await onRefresh();
  };

  const handlePinTabs = async () => {
    for (const windowTab of collectedSelectedTabs) {
      if (!windowTab.id) continue;
      if (collectedSelectedTabs) {
        await storeLocalPinnedTab(windowTab as any);
      }
    }
    await getLocalPinnedTabs();
  };

  const handleUnpinTabs = async () => {
    for (const windowTab of collectedSelectedTabs) {
      if (!windowTab.id) continue;
      if (collectedSelectedTabs) {
        await removeLocalPinnedTab(windowTab as any);
      }
    }
    await getLocalPinnedTabs();
  };

  const handleDeselectAll = () => {
    for (const windowId in selectedMultiverseTreeNodes) {
      deselectAllTreeNodes(windowId);
    }
  };

  const handleSelectAll = () => {
    for (const multiverseKey in multiverseTreeNodes) {
      const currentTreeNodes = multiverseTreeNodes[multiverseKey];
      selectMultiverseTreeNodes(multiverseKey, currentTreeNodes);
    }
  };

  const handleSmartGrouping = () => {
    const _selectedTabs: Browser.Tabs.Tab[] = [];
    for (const tab of collectedSelectedTabs) {
      if (!tab.id) continue;
      _selectedTabs.push(tab);
    }
    const tabs = _selectedTabs.length === 0 ? collectedTabs : _selectedTabs;
    openDrawer('smartTabGrouping', {
      tabs:
        selectedSession === ALL_SESSION
          ? tabs
          : tabs.filter(tab => tab.windowId?.toString() === selectedSession),
      onTabsGrouped: async (nodes: TreeNode[]) => {
        /** Group selected tabs into directory, non-selected tabs remain unchanged */
        const promises: Promise<number>[] = [];
        for (const node of nodes.map(n => n.value)) {
          const directory = node as TabTreeDirectory;
          promises.push(
            browserDevService.groupTabs(
              directory.items.map(item => parseInt(item.value.id as any)),
              {
                title: directory.name,
                color: 'blue',
                collapsed: true,
              },
              undefined,
              selectedSession === ALL_SESSION ? undefined : parseInt(selectedSession)
            )
          );
        }
        await Promise.all(promises);
        await onRefresh();
        closeDrawer();
      },
    });
  };

  const handleSaveAllTabs = () => {
    let treeNodes: TreeNode[] = [];
    let selectedTreeNodes: TabChecked = {};
    const allSelectedTreeNodes: TabChecked = {};
    let currentWindow: Browser.Windows.Window | undefined = undefined;
    for (const window of windows) {
      if (window.focused) currentWindow = window;
      const windowId = window.id;
      if (!windowId) continue;

      const currentTreeNodes = multiverseTreeNodes[windowId];
      if (!currentTreeNodes) continue;
      treeNodes = treeNodes.concat(currentTreeNodes);
      selectedTreeNodes = {
        ...selectedTreeNodes,
        ...selectedMultiverseTreeNodes?.[windowId],
      };
      for (const tab of window.tabs || []) {
        if (!tab.id) continue;
        allSelectedTreeNodes[tab.id.toString()] = true;
      }
    }
    return handleSaveTabs(
      currentWindow,
      treeNodes,
      Object.keys(selectedTreeNodes).length === 0 ? allSelectedTreeNodes : selectedTreeNodes
    );
  };

  return (
    <Space style={{ ...MIDDLE_STYLE, flexWrap: 'wrap' }}>
      <Button type="primary" className="btn-success" onClick={handleSaveAllTabs}>
        <SaveOutlined /> Save
      </Button>
      <Tooltip title="Select All">
        <Button onClick={handleSelectAll}>
          <IconContainer icon={ICON_REGISTRY.SELECT_ALL} />
        </Button>
      </Tooltip>
      <Tooltip title="Deselect All">
        <Button onClick={handleDeselectAll}>
          <IconContainer icon={ICON_REGISTRY.DESELECT_ALL} />
        </Button>
      </Tooltip>
      {collectedSelectedTabs.length > 0 && (
        <Tooltip title="Close tabs">
          <Button onClick={handleDeleteTab}>
            <IconContainer icon={ICON_REGISTRY.CLOSE_TABS} />
          </Button>
        </Tooltip>
      )}
      {collectedSelectedTabs.length > 0 && (
        <Tooltip title="Open in this window">
          <Button onClick={handleOpenTabs}>
            <IconContainer icon={ICON_REGISTRY.NEW_TABS} />
          </Button>
        </Tooltip>
      )}
      {collectedSelectedTabs.length > 0 && (
        <Tooltip title="Create new window">
          <Button onClick={handleCreateNewWindow}>
            <IconContainer icon={ICON_REGISTRY.OPEN_WINDOW} />
          </Button>
        </Tooltip>
      )}
      <Tooltip title="Remove duplicate">
        <Button onClick={handleRemoveDuplicate}>
          <IconContainer icon={ICON_REGISTRY.REMOVE_DUPLICATE} />
        </Button>
      </Tooltip>
      <Tooltip title="Smart grouping">
        <Button onClick={handleSmartGrouping}>🦄</Button>
      </Tooltip>
      <Tooltip title="Sort">
        <Button onClick={handleSortTreeNodes}>
          <IconContainer icon={ICON_REGISTRY.SORT} />
        </Button>
      </Tooltip>
      {collectedSelectedTabs.length > 0 && (
        <React.Fragment>
          <Button onClick={handleUnpinTabs}>
            <PushpinOutlined /> Unpin
          </Button>
          <Button type="primary" onClick={handlePinTabs}>
            <PushpinFilled /> Pin
          </Button>
        </React.Fragment>
      )}
    </Space>
  );
};

export default TabManagerEditor;
