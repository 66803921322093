import Browser from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import { TreeNode } from '@/models';

import { BrowserDevRemoteService } from './BrowserDevRemoteService';
import { IBrowserDevService } from './IBrowserDevService';

export default class BrowserDevService implements IBrowserDevService {
  service: IBrowserDevService;

  constructor(isChromeExtension: boolean) {
    if (!isChromeExtension) {
      this.service = new BrowserDevRemoteService();
      return;
    }
    /** Load script for BrowserDevExtensionService */
    const { BrowserDevExtensionService } = require('./BrowserDevExtensionService');
    this.service = new BrowserDevExtensionService();
  }

  async authenticateUser(token: string): Promise<void> {
    return;
  }

  async ungroupTab(tabIds: number[]): Promise<void> {
    return this.service.ungroupTab(tabIds);
  }

  async fetchWindows() {
    return this.service.fetchWindows();
  }

  async selectTab(windowId: number | undefined, tabId: number | undefined) {
    return this.service.selectTab(windowId, tabId);
  }

  async closeWindow(window: Browser.Windows.Window) {
    return this.service.closeWindow(window);
  }

  async deleteTab(tabId: number | undefined) {
    return this.service.deleteTab(tabId);
  }

  async createTab(tab: RepositoryTab) {
    return this.service.createTab(tab);
  }

  async openTabs(tabs: RepositoryTab[]) {
    return this.service.openTabs(tabs);
  }

  async moveTreeItems(treeItems: TreeNode[]) {
    return this.service.moveTreeItems(treeItems);
  }

  async getTabGroup(groupId: number) {
    return this.service.getTabGroup(groupId);
  }

  async queryTabGroups(windowId: number) {
    return this.service.queryTabGroups(windowId);
  }

  async groupTabs(
    tabIds: number[],
    windowProperties?: chrome.tabGroups.UpdateProperties,
    groupId?: number,
    windowId?: number
  ): Promise<number> {
    return this.service.groupTabs(tabIds, windowProperties, groupId, windowId);
  }

  async updateTabGroup(groupId: number, windowProperties: chrome.tabGroups.UpdateProperties) {
    return this.service.updateTabGroup(groupId, windowProperties);
  }

  async moveTabGroup(
    groupId: number,
    moveProperties: {
      index: number;
      windowId?: number;
    }
  ) {
    return this.service.moveTabGroup(groupId, moveProperties);
  }
}
