/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { CgChevronDown, CgChevronUp } from 'react-icons/cg';

import { CloseOutlined, SelectOutlined } from '@ant-design/icons';
import { SelectionEvent } from '@viselect/react';
import { Badge, Button, Card, Space, Tag } from 'antd';
import Browser from 'webextension-polyfill';

import { ANT_DESIGN_THEME, MIDDLE_STYLE } from '@/constants';
import { useBreakpoint, useWindowTabActions, useWindowTabActionsWithContext } from '@/hooks';
import { useBrowserDevService } from '@/hooks/useBrowserDevService';
import { TreeNode } from '@/models';
import { useContextMenuStore, useTabManagerStore } from '@/stores';
import { reorder, sanitizeWindowTabs } from '@/utils';

import ContextMenuProvider from '../context-menu-provider';
import DragToSelectContainer from '../drag-to-select-container';
import DraggableTreeNodeList from '../draggable-tree-node-list';
import WindowItemContextMenu from '../window-item-context-menu';

type Props = {
  browserWindow: Browser.Windows.Window;
};

const WindowContainer = ({ browserWindow }: Props) => {
  const [multiSelectToggle, setMultiSelectToggle] = useState<boolean>(false);
  const { isMobile } = useBreakpoint();
  const windowId = useMemo(() => browserWindow.id as any, [browserWindow]);
  const browserDevService = useBrowserDevService();
  const [showContent, setShowContent] = useState(true);
  const { selectedMultiverseTreeNodes, multiverseTreeNodes, setMultiverseTreeNodes } =
    useTabManagerStore();
  const { handleCloseAll } = useWindowTabActions();
  const { handleDirectoryClicked, handleTabClicked, handleWindowSelect, handleTreeNodeClicked } =
    useWindowTabActionsWithContext(browserWindow);
  const { setContextMenuContent } = useContextMenuStore();

  const onDragEnd: OnDragEndResponder = async (result: any) => {
    const items: TreeNode[] = reorder(
      multiverseTreeNodes[windowId],
      result.source.index,
      result.destination.index
    );
    try {
      await browserDevService.moveTreeItems(items);
    } catch (error) {
      console.log(error);
    }
    setMultiverseTreeNodes(windowId, items);
  };

  const handleOnStart = ({ event, selection }: SelectionEvent) => {
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
    }
  };

  const handleOnMove = ({
    store: {
      changed: { added, removed },
    },
  }: SelectionEvent) => {
    const handleOnMoveEvent = (events: any[], isChecked: boolean) => {
      for (const divElement of events as HTMLElement[]) {
        const divId = divElement.id;
        const [id] = divId.split('-');
        const treeNodes = multiverseTreeNodes[windowId];
        const treeNode = treeNodes.find(treeNode => treeNode.value.id === id);
        if (treeNode) handleTreeNodeClicked(windowId, treeNode, isChecked);
      }
    };
    handleOnMoveEvent(added, false);
    handleOnMoveEvent(removed, true);
  };

  useEffect(() => {
    function downHandler(e: KeyboardEvent) {
      setMultiSelectToggle(e.ctrlKey);
    }
    function upHandler(e: KeyboardEvent) {
      setMultiSelectToggle(e.ctrlKey);
    }
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    };
  }, []);

  return (
    <ContextMenuProvider offsetX={0} offsetY={0}>
      <DragToSelectContainer
        disabled={!multiSelectToggle}
        selectables=".window-tab-item"
        handleOnStart={handleOnStart}
        handleOnMove={handleOnMove}>
        <Card
          style={{
            maxWidth: 1500,
            width: '100%',
            overflow: 'auto',
            border: `1px solid ${ANT_DESIGN_THEME.token?.colorBorder}`,
            position: 'relative',
          }}
          title={
            <div className="card-header-inner" style={{ flexWrap: 'wrap' }}>
              <p>
                <Badge
                  color={browserWindow.focused ? 'green' : 'orange'}
                  text={browserWindow.focused ? 'Active window' : 'Window'}
                />
              </p>
              <Space style={MIDDLE_STYLE}>
                <Tag>{sanitizeWindowTabs(browserWindow)?.length} opened</Tag>
                <Button onClick={() => handleCloseAll(browserWindow)}>
                  <CloseOutlined /> {!isMobile ? 'Close all' : ''}
                </Button>
                <Button type="primary" onClick={() => handleWindowSelect(browserWindow)}>
                  <SelectOutlined />{' '}
                  {!isMobile ? `Select ${multiverseTreeNodes[windowId]?.length} tabs` : ''}
                </Button>
                <div style={{ cursor: 'pointer', ...MIDDLE_STYLE, padding: '0px 20px' }}>
                  {showContent ? (
                    <CgChevronDown onClick={() => setShowContent(false)} />
                  ) : (
                    <CgChevronUp onClick={() => setShowContent(true)} />
                  )}
                </div>
              </Space>
            </div>
          }>
          {showContent && (
            <DraggableTreeNodeList
              nodeOnContextMenu={(treeNode: TreeNode) => {
                setContextMenuContent(() => (
                  <WindowItemContextMenu browserWindow={browserWindow} treeNode={treeNode} />
                ));
              }}
              treeNodes={multiverseTreeNodes[windowId] || []}
              itemOnClickedEvent={handleTabClicked}
              directoryOnClickedEvent={handleDirectoryClicked}
              itemSelectedCondition={tab =>
                (tab && !!tab.id && !!selectedMultiverseTreeNodes?.[windowId]?.[tab.id]) || false
              }
              onItemDragEvent={onDragEnd}
            />
          )}
        </Card>
      </DragToSelectContainer>
    </ContextMenuProvider>
  );
};

export default WindowContainer;
