import { Windows } from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import { TreeNode } from '@/models';

import { IBrowserDevService } from './IBrowserDevService';

export class PhantomRemoteService implements IBrowserDevService {
  authenticateUser(token: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  fetchWindows(): Promise<Windows.Window[]> {
    throw new Error('Method not implemented.');
  }
  selectTab(windowId: number | undefined, tabId: number | undefined): Promise<void> {
    throw new Error('Method not implemented.');
  }
  closeWindow(window: Windows.Window): Promise<void> {
    throw new Error('Method not implemented.');
  }
  createTab(tab: RepositoryTab): Promise<void> {
    throw new Error('Method not implemented.');
  }
  deleteTab(tabId: number | undefined): Promise<void> {
    throw new Error('Method not implemented.');
  }
  openTabs(tabs: RepositoryTab[]): Promise<void> {
    throw new Error('Method not implemented.');
  }
  moveTreeItems(treeItems: TreeNode[]): Promise<void> {
    throw new Error('Method not implemented.');
  }
  ungroupTab(tabIds: number[]): Promise<void> {
    throw new Error('Method not implemented.');
  }
  groupTabs(
    tabIds: number[],
    windowProperties?: chrome.tabGroups.UpdateProperties | undefined,
    groupId?: number | undefined,
    windowId?: number | undefined
  ): Promise<number> {
    throw new Error('Method not implemented.');
  }
  queryTabGroups(windowId: number): void {
    throw new Error('Method not implemented.');
  }
  getTabGroup(groupId: number): void {
    throw new Error('Method not implemented.');
  }
  updateTabGroup(groupId: number, windowProperties: chrome.tabGroups.UpdateProperties): void {
    throw new Error('Method not implemented.');
  }
  moveTabGroup(
    groupId: number,
    moveProperties: { index: number; windowId?: number | undefined }
  ): void {
    throw new Error('Method not implemented.');
  }
}
