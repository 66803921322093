import React from 'react';

import moment from 'moment';

import { useBreakpoint } from '@/hooks';

type Props = {
  time: number;
};

const RenderUpdatedTime = ({ time }: Props) => {
  const { isMobile } = useBreakpoint();
  return (
    <React.Fragment>
      {!isMobile && `Last updated at `}
      {moment.unix(time).format('MM/DD/YYYY')}
    </React.Fragment>
  );
};

export default RenderUpdatedTime;
