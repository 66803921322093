import React from 'react';

import { makeid } from '@/utils';

type Props = {
  bannerImage: string | undefined;
};

const RepositoryBannerImage = ({ bannerImage }: Props) => {
  return (
    <img
      key={makeid(10)}
      alt={makeid(10)}
      style={{
        aspectRatio: '3 / 1',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
      src={bannerImage ? `data:image/jpeg;base64,${bannerImage}` : ''}
      onLoad={() => {
        if (bannerImage) URL.revokeObjectURL(bannerImage);
      }}
    />
  );
};

export default RepositoryBannerImage;
