import React from 'react';

import { HelmetWrapper } from '@/components';
import PinAppExtension from '@/components/app-extensions/extensions/app:pin';

type Props = any;

const PinnedScreen = (props: Props) => {
  return (
    <HelmetWrapper title="Pin">
      <PinAppExtension />
    </HelmetWrapper>
  );
};

export default PinnedScreen;
