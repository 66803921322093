/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';

import { useRepositoryContext } from '@/hooks';
import { UnionTab } from '@/models';
import { buildTree } from '@/utils';

import TreeNodeCardList from '../tree-node-card-list';

type Props = any;

const RepositoryCardView = (props: Props) => {
  const repositoryContext = useRepositoryContext();
  const {
    treeNodes: currentTreeNodes,
    setTreeNodes: setCurrentTreeNodes,
    repositoryTabs: repositoryTabsFromContext,
    selectedTreeNodes,
    setSelectedTreeNodes,
    repository,
  } = repositoryContext;

  const repositoryTabs = useMemo(() => repositoryTabsFromContext, [repositoryTabsFromContext]);
  useEffect(() => {
    const originalTreeNodes = buildTree(repositoryTabs, repository?.directories || []);
    setCurrentTreeNodes(originalTreeNodes);
  }, [repositoryTabs, repository]);

  const handleSelectTab = (tab: UnionTab) => {
    if (!tab.id) return;
    setSelectedTreeNodes({
      ...selectedTreeNodes,
      [tab.id]: !selectedTreeNodes[tab.id],
    });
  };

  const handleTabClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tab: UnionTab) => {
    switch (event.detail) {
      case 2:
        return window.open(tab.url);
      default:
        return handleSelectTab(tab);
    }
  };

  return (
    <TreeNodeCardList
      treeNodes={currentTreeNodes}
      itemOnClickedEvent={handleTabClicked}
      itemSelectedCondition={tab => (tab && !!tab.id && !!selectedTreeNodes[tab.id]) || false}
    />
  );
};

export default RepositoryCardView;
