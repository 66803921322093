import { UserRole, Workspace } from '@/gql/graphql';

export const findUserRoleInWorkspace = (
  _workspace: Workspace,
  userId: string
): UserRole | undefined => {
  if (!_workspace) return undefined;
  const userWorkspaceIndx = _workspace.members.findIndex(member => member === userId);
  if (userWorkspaceIndx === -1) return undefined;
  const userRole = _workspace.roles[userWorkspaceIndx];
  return userRole;
};

export const includeUserRoleInWorkspace = (
  _workspace: Workspace,
  userId: string,
  roles: UserRole[]
): boolean => {
  const userRole = findUserRoleInWorkspace(_workspace, userId);
  return roles.some(role => userRole === role);
};

export const getUserWorkspaceRole = (workspace: Workspace, userId: string): string => {
  const role = findUserRoleInWorkspace(workspace, userId);
  if (role === undefined) return 'Unknown';
  return convertRoleToStr(role);
};

export const convertRoleToStr = (role: UserRole) => {
  return {
    [UserRole.WorkspaceOwner]: 'Owner',
    [UserRole.WorkspaceMember]: 'Member',
    [UserRole.WorkspaceModerator]: 'Moderator',
    [UserRole.RepositoryContributor]: 'Contributor',
    [UserRole.PublicUser]: 'Public',
  }[role];
};

export const convertRoleToColor = (role: UserRole) => {
  return {
    [UserRole.WorkspaceOwner]: 'red',
    [UserRole.WorkspaceMember]: 'blue',
    [UserRole.WorkspaceModerator]: 'yellow',
    [UserRole.RepositoryContributor]: 'green',
    [UserRole.PublicUser]: 'default',
  }[role];
};
