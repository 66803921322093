/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { LOCAL_WORKSPACE_ID, localWorkspace } from '@/constants';
import { Repository, Workspace } from '@/gql/graphql';
import { getRepositoryReadmeQuery } from '@/graphql';
import { apolloClient } from '@/services/ApolloService';
import { handleFetchBanner, useRepositoryStore, useWorkspaceStore } from '@/stores';

/** Load workspace and repository info fetched by name */
export const useWorkspaceRepositoryLoad = (workspaceSlug: string, repositorySlug: string) => {
  const { fetchRepositoryBySlug, refreshing, setRefreshing } = useRepositoryStore();
  const { fetchWorkspaceBySlug, isRefreshing } = useWorkspaceStore();
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState<boolean>(true);
  const [isLoadingRepository, setIsLoadingRepository] = useState<boolean>(true);
  const [workspace, setWorkspace] = useState<Workspace | undefined | null>();
  const [repository, setRepository] = useState<Repository | undefined | null>();
  const [readmeContent, setReadmeContent] = useState<string | undefined>(undefined);
  const [bannerImage, setBannerImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const initWorkspace = async () => {
      setIsLoadingWorkspace(true);
      try {
        let fetchedWorkspace: Workspace | null | undefined = null;
        if (workspaceSlug === localWorkspace.slug) {
          fetchedWorkspace = localWorkspace;
          setWorkspace(localWorkspace);
        } else if (workspaceSlug) {
          fetchedWorkspace = await fetchWorkspaceBySlug(workspaceSlug);
          setWorkspace(fetchedWorkspace);
        }
      } catch (error: any) {
        console.log(error);
      }
      setIsLoadingWorkspace(false);
    };
    initWorkspace();
  }, [isRefreshing, workspaceSlug]);

  useEffect(() => {
    const initRepository = async () => {
      setIsLoadingRepository(true);
      try {
        if (workspace) {
          const fetchedRepository = await fetchRepositoryBySlug(workspace.id, repositorySlug);
          setRepository(fetchedRepository);
          // Fetch repository README content
          if (fetchedRepository && fetchedRepository.workspaceId !== LOCAL_WORKSPACE_ID) {
            if (fetchedRepository.readme) {
              const { data } = await apolloClient.query({
                query: getRepositoryReadmeQuery,
                fetchPolicy: 'network-only',
                variables: {
                  repositoryId: fetchedRepository.id,
                },
              });
              const readmeContent = data.getReadmeContent;
              setReadmeContent(readmeContent);
            } else {
              setReadmeContent(undefined);
            }
            const bannerImage = await handleFetchBanner(fetchedRepository.id);
            setBannerImage(bannerImage);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
      setIsLoadingRepository(false);
    };
    initRepository();
  }, [refreshing, workspace, repositorySlug]);

  return {
    bannerImage,
    workspace,
    repository,
    isLoadingWorkspace,
    isLoadingRepository,
    setIsLoadingRepository,
    setIsLoadingWorkspace,
    setRefreshing,
    readmeContent,
  };
};
