import { CgBrowser } from 'react-icons/cg';
import {
  MdOutlineExtension,
  MdOutlineLensBlur,
  MdOutlineScreenSearchDesktop,
} from 'react-icons/md';
import {
  TbBriefcase,
  TbPin,
  TbCategory,
  TbArrowsUpDown,
  TbScreenShare,
  TbGripHorizontal,
  TbFolders,
  TbFoldersOff,
  TbFolderPlus,
  TbFolderX,
  TbDownload,
  TbDragDrop2,
  TbCards,
  TbBorderAll,
  TbBorderNone,
  TbSwipe,
  TbSquaresDiagonal,
  TbTable,
  TbColumns2,
  TbRefresh,
  TbShare,
  TbBookmark,
  TbLayoutList,
  TbTableImport,
  TbFolderFilled,
  TbHome,
} from 'react-icons/tb';

import { DeleteOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';

export const ICON_REGISTRY = {
  HOME: <TbHome />,
  BOOKMARK: <TbBookmark />,
  WORKSPACE: <TbBriefcase />,
  PIN: <TbPin />,
  EXPLORE: <TbCategory />,
  TAB_LENS: <MdOutlineLensBlur />,
  TAB_MANAGER: <CgBrowser />,
  EXTENSION: <MdOutlineExtension />,
  WEBSEARCH: <MdOutlineScreenSearchDesktop />,
  SEARCH: <SearchOutlined />,
  SAVE: <SaveOutlined />,
  SORT: <TbArrowsUpDown />,
  SMART_GROUP: <div>🦄</div>,
  SHARE: <TbShare />,
  OPEN_WINDOW: <TbScreenShare />,
  DRAG_BUTTON: <TbGripHorizontal />,
  DRAG_AND_DROP: <TbDragDrop2 />,
  GROUP_TABS: <TbFolders />,
  UNGROUP_TABS: <TbFoldersOff />,
  CREATE_GROUP: <TbFolderPlus />,
  CLOSE_GROUP: <TbFolderX />,
  DOWNLAOD: <TbDownload />,
  NEW_TABS: <TbTableImport />,
  SELECT_ALL: <TbBorderAll />,
  DESELECT_ALL: <TbBorderNone />,
  CLOSE_TABS: <DeleteOutlined />,
  SWITCH_TAB: <TbSwipe />,
  REMOVE_DUPLICATE: <TbSquaresDiagonal />,
  VIEW_TABLE_MODE: <TbTable />,
  VIEW_COLUMN_MDOE: <TbColumns2 />,
  VIEW_LIST_MODE: <TbLayoutList />,
  VIEW_CARD_MODE: <TbCards />,
  REFRESH: <TbRefresh />,
  DIRECTORY: <TbFolderFilled />,
};
