import React from 'react';
import { useParams } from 'react-router';

import { Menu, MenuProps } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import { GLOBAL_THEME_COLOR, ICON_REGISTRY } from '@/constants';
import { useAppNavigation, useBreakpoint } from '@/hooks';
import { useDrawerStore } from '@/stores';
import { AppRoutes, getNavigationItem } from '@/utils';

type Props = any;

const NavigationMenu = (props: Props) => {
  const { closeDrawer } = useDrawerStore();
  const { isTablet } = useBreakpoint();
  const { tabName } = useParams<{ tabName: AppRoutes }>();
  const { navigate } = useAppNavigation();

  const handleNavigation: MenuClickEventHandler = info => {
    navigate(info.key as any);
    closeDrawer();
  };

  const WorkspaceNavigationItems: MenuProps['items'] = [];

  const NavigationItems: MenuProps['items'] = [
    getNavigationItem<AppRoutes>('Home', 'home', false, ICON_REGISTRY.HOME),
    getNavigationItem<AppRoutes>('Workspace', 'workspace', false, ICON_REGISTRY.WORKSPACE),
    getNavigationItem<AppRoutes>('Time Tracker', 'time-tracker', false, ICON_REGISTRY.TAB_LENS),
    getNavigationItem<AppRoutes>('Tab Manager', 'dashboard', false, ICON_REGISTRY.TAB_MANAGER),
    getNavigationItem<AppRoutes>('Search on Web', 'web-search', false, ICON_REGISTRY.WEBSEARCH),
    getNavigationItem<AppRoutes>('Pin', 'pinned', false, ICON_REGISTRY.PIN),
    getNavigationItem<AppRoutes>('Explore', 'explore', false, ICON_REGISTRY.EXPLORE),
  ];

  const AppExtensionNavigationItems: MenuProps['items'] = [
    // getNavigationItem('Bookmark', 'bookmark', false, ICON_REGISTRY.BOOKMARK),
  ];
  return (
    <React.Fragment>
      {[
        { key: 'workspace-navigation-items', items: WorkspaceNavigationItems },
        { key: 'navigation-items', items: NavigationItems },
        { key: 'app-extension-navigation-items', items: AppExtensionNavigationItems },
      ].map(({ items, key }) => (
        <Menu
          key={key}
          className={isTablet ? '' : 'desktop-navigation'}
          style={{
            color: `${GLOBAL_THEME_COLOR.$text_color} !important`,
            backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
          }}
          theme="dark"
          mode="inline"
          onClick={handleNavigation}
          selectedKeys={[tabName]}
          items={items}
        />
      ))}
    </React.Fragment>
  );
};

export default NavigationMenu;
