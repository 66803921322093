import React from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import { useMount } from '@/hooks';
import { TreeNode } from '@/models';

import AnimatedComponent from '../animated-component';

type Props = {
  disabled?: boolean;
  onDragEnd: OnDragEndResponder;
  treeNodes: TreeNode[];
  renderTreeComponent: (treeItem: TreeNode) => React.ReactNode;
};

const getItemStyle = (draggableStyle: any) => ({
  ...draggableStyle,
});

const DraggableTreeNodeContainer = ({
  onDragEnd,
  treeNodes,
  renderTreeComponent,
  disabled,
}: Props) => {
  const { isMounted } = useMount();
  return (
    <AnimatedComponent.OpacityFadeInDiv delay={200}>
      <DragDropContext onDragEnd={onDragEnd}>
        {isMounted && (
          <Droppable isDropDisabled={disabled} droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {treeNodes &&
                  treeNodes.length > 0 &&
                  treeNodes.map((treeItem, index) => (
                    <Draggable
                      isDragDisabled={disabled}
                      key={treeItem.value.id?.toString()}
                      draggableId={treeItem.value.id?.toString() as any}
                      index={index}>
                      {provided => (
                        <div
                          key={treeItem.value.id?.toString()}
                          className={index % 2 === 1 ? 'even-treenode' : 'odd-treenode'}
                          ref={provided.innerRef}
                          style={getItemStyle(provided.draggableProps.style)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          {renderTreeComponent(treeItem)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default DraggableTreeNodeContainer;
