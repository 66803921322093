import React from 'react';

import { GLOBAL_THEME_COLOR, ICON_REGISTRY, MIDDLE_STYLE } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { TabTreeDirectory } from '@/models';

import TreeNodeCardList from '../tree-node-card-list';

type Props = {
  node: TabTreeDirectory;
  itemSelectedCondition: (tabItem: RepositoryTab) => boolean;
  itemOnClickedEvent: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab: RepositoryTab
  ) => void;
  onClick?: (directoryNode: TabTreeDirectory) => void;
};

const DirectoryNodeCardItem = ({ node, itemOnClickedEvent, itemSelectedCondition }: Props) => {
  return (
    <div style={{ textAlign: 'left', width: '83vw' }}>
      <div
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'flex-start',
          marginBottom: 10,
          marginTop: 20,
          color: GLOBAL_THEME_COLOR.$dark_highlight_color,
        }}>
        {ICON_REGISTRY.DIRECTORY}
        <span style={{ marginLeft: 10 }}>{node.name}</span>
      </div>
      <TreeNodeCardList
        treeNodes={node.items}
        itemOnClickedEvent={itemOnClickedEvent}
        itemSelectedCondition={itemSelectedCondition}
      />
      <br />
    </div>
  );
};

export default DirectoryNodeCardItem;
