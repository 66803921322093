import { endOfToday, startOfToday } from 'date-fns';
import moment from 'moment';

export const delay = (miliseconds: number) => {
  return setTimeout(() => {
    return;
  }, miliseconds);
};

export const getStartOfTodayUnix = () => {
  return moment(startOfToday()).unix();
};

export const getEndOfTodayUnix = () => {
  return moment(endOfToday()).unix();
};

export const dateToUnix = (date: Date) => {
  return moment(date).unix();
};

export const unixToDate = (timestamp: number) => {
  return moment.unix(timestamp).toDate();
};

export const getCurrentHours = () => new Date().getHours();

export const hourToSecond = (hours: number) => hours * 60 * 60;

export const getCurrentHoursTimestamp = (hours: number | undefined) => {
  return getStartOfTodayUnix() + hourToSecond(hours || 0);
};

export const getLastHourDateRange = (from: number, to: number): [Date, Date] => {
  return [
    unixToDate(getCurrentHoursTimestamp(getCurrentHours() + from)),
    unixToDate(getCurrentHoursTimestamp(getCurrentHours() + to)),
  ];
};

export function convertDecimalToTime(decimalHour) {
  // Extract the whole number part (hours) and decimal part (minutes)
  const hours = Math.floor(decimalHour);
  const minutes = Math.round((decimalHour - hours) * 60);

  // Format the minutes to have leading zeros if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Concatenate the hours and minutes to form the final time format
  const timeString = `${hours}:${formattedMinutes}`;

  return timeString;
}
