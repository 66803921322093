import React from 'react';

import AppBarDrawerMobile from './appbar-drawer-mobile';
import SmartGroupingProvider from './smart-grouping-drawer/SmartGroupingProvider';

type Props = Record<string, any>;

const DrawerContainer = (props: Props) => {
  return (
    <React.Fragment>
      <SmartGroupingProvider />
      <AppBarDrawerMobile />
    </React.Fragment>
  );
};

export default DrawerContainer;
