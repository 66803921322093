import React, { useMemo } from 'react';

import { Col, Row } from 'antd';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useBreakpoint } from '@/hooks';

type Props = {
  ref?: any;
  style?: React.CSSProperties;
  isForceMobile?: boolean;
};

const headerTitleStyle: React.CSSProperties = {
  fontSize: 11,
  fontWeight: 'bold',
  color: GLOBAL_THEME_COLOR.$dark_text_color,
};

const TreeNodeListHeader = ({ ref, isForceMobile, style, ...props }: Props) => {
  const { isTablet: isMediaTablet } = useBreakpoint();
  const isTablet = useMemo(() => isMediaTablet || isForceMobile, [isMediaTablet, isForceMobile]);
  return !isTablet ? (
    <div ref={ref} {...props} style={{ cursor: 'pointer', ...style }}>
      <Row
        style={{
          textAlign: 'left',
          ...MIDDLE_STYLE,
          marginBottom: 10,
          borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        }}
        gutter={isTablet ? 10 : 20}>
        {!isTablet && (
          <Col span={13}>
            <span style={headerTitleStyle}>TITLE</span>
          </Col>
        )}
        <Col span={isTablet ? 22 : 7}>
          <span style={headerTitleStyle}>URL</span>
        </Col>
        {!isTablet && (
          <React.Fragment>
            <Col span={4}>
              <span style={headerTitleStyle}>LAST ACCESSED AT</span>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </div>
  ) : (
    <></>
  );
};

export default TreeNodeListHeader;
