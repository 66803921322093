import React from 'react';

import { Button, Empty } from 'antd';

import { GENERAL_EMPTY_IMAGE, MIDDLE_STYLE } from '@/constants';
import { Repository } from '@/gql/graphql';
import { useAppNavigation } from '@/hooks';

import RepositoryCard from '../repository-card';

type Props = {
  repositories: Repository[];
  showButton?: boolean;
  showWorkspaceMetadata?: boolean;
};

const RepositoryList = ({ repositories, showButton, showWorkspaceMetadata }: Props) => {
  const { navigate } = useAppNavigation();

  const handleSaveNewRepository = () => {
    navigate('dashboard');
  };
  return (
    <React.Fragment>
      {repositories?.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {repositories.map(repository => (
            <div style={{ marginRight: 15, marginBottom: 15 }}>
              <RepositoryCard
                repository={repository}
                showWorkspaceMetadata={showWorkspaceMetadata}
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            ...MIDDLE_STYLE,
            width: '100%',
            height: '30vh',
          }}>
          <Empty
            image={GENERAL_EMPTY_IMAGE}
            imageStyle={{ height: 60 }}
            description={<span>No repositories found</span>}>
            {showButton && (
              <Button type="primary" onClick={handleSaveNewRepository}>
                Save new repository
              </Button>
            )}
          </Empty>
        </div>
      )}
    </React.Fragment>
  );
};

export default RepositoryList;
