import React from 'react';
import { Redirect } from 'react-router';

import { useAuthStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import SplashScreen from '../splash-screen';

type Props = Record<string, any>;

const UserProfileRedirect = (props: Props) => {
  const { user } = useAuthStore();
  return (
    <LoadableContainer isLoading={!user} loadComponent={<SplashScreen />}>
      <Redirect to={`/user/${user?.username}`} />
    </LoadableContainer>
  );
};

export default UserProfileRedirect;
