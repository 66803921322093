import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import loadable from '@loadable/component';

const WorkspaceRedirectScreen = loadable(() => import('../../screens/WorkspaceRedirectScreen'));
const WorkspaceScreen = loadable(() => import('../../screens/WorkspaceScreen'));
const SelectedWorkspaceScreen = loadable(() => import('../../screens/SelectedWorkspaceScreen'));
const WorkspaceRepositoryScreen = loadable(() => import('../../screens/WorkspaceRepositoryScreen'));

export interface CustomRouteProps {
  isExact: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | React.ComponentType<any>;
  path: string;
}

export const workspaceRouteList: CustomRouteProps[] = [
  {
    isExact: true,
    component: () => <WorkspaceRedirectScreen />,
    path: '/workspace',
  },
  {
    isExact: true,
    component: () => <WorkspaceScreen />,
    path: '/workspace/browse',
  },
  {
    isExact: true,
    component: () => <SelectedWorkspaceScreen />,
    path: '/workspace/:workspaceSlug',
  },
  {
    isExact: true,
    component: () => <WorkspaceRepositoryScreen />,
    path: '/workspace/:workspaceSlug/:repositorySlug',
  },
];
