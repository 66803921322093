import gql from 'graphql-tag';

export const CORE_USER_WORKSPACE_FRAGMENT = gql`
  fragment CORE_USER_WORKSPACE_FRAGMENT on User {
    pinned_repositories
    pinned_tabs {
      customName
      favIconUrl
      id
      pinned
      repositoryId
      description
      parentDirectory
      labels
      title
      url
    }
    selected_workspace
    workspaces
  }
`;

export const CORE_USER_FIELDS_FRAGMENT = gql`
  fragment CORE_USER_FIELDS_FRAGMENT on User {
    created_date
    email
    followers
    following
    full_name
    id
    username
    favorites
    profile_image
    provider
    updated_date
  }
`;

export const USER_TIME_TRACKER_SETTING_FRAGMENT = gql`
  fragment USER_TIME_TRACKER_SETTING_FRAGMENT on User {
    time_tracker_enabled
    time_tracker_setting {
      limit_least_used_time
      mode
      session_start_time
      session_end_time
      setting_enabled_from
      setting_enabled_to
    }
    time_tracker_sessions {
      id
      mode
      state
      session_start_time
      session_end_time
      date
      trackedTabs
    }
  }
`;
