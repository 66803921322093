import React from 'react';

import AppRoutesRenderer from '../app-router/route-render';
import { userRouteList } from './user-router-list';

type Props = any;

const UserRouter = (props: Props) => {
  return <AppRoutesRenderer routes={userRouteList} />;
};

export default UserRouter;
