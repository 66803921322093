import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { ArrowLeftOutlined, ClockCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { useTimeMachineContext } from '@/hooks';
import { renderTime } from '@/utils';

type Props = any;

const TimeTrackerFocusModeSetting = (props: Props) => {
  const {
    setProductivityMode,
    sessionStartTime,
    sessionEndTime,
    setSessionStartTime,
    setSessionEndTime,
  } = useTimeMachineContext();
  const [goal, setGoal] = useState<string>('');
  const history = useHistory();

  const handleGoalChanged = (goal: string) => {
    setGoal(goal);
  };

  const onTimePeriodChanged = (values: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (!values) return;
    if (!values[0]) return;
    setSessionStartTime(values[0].unix());
    if (!values[1]) return;
    setSessionEndTime(values[1].unix());
  };

  const handleSwitchMode = () => {
    setProductivityMode(undefined);
  };

  const handleConfirmFocusMode = () => {
    history.push('/time-tracker/active?mode=focus');
  };

  const disabled = useMemo(() => goal.length === 0, [goal]);

  return (
    <Card style={{ maxWidth: 1000, width: '100%', padding: '0px 30px', marginTop: 20 }}>
      <React.Fragment>
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'left' }}>
            <h2 style={{ margin: 0 }}>
              <ClockCircleTwoTone style={{ marginRight: 20 }} />
              Focus Mode{' '}
            </h2>
            <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, margin: 0 }}>
              Maximizes online productivity. Set goals, track progress, and stay focused.
            </p>
          </div>
          <Button style={{ marginLeft: 20 }} onClick={handleSwitchMode}>
            <ArrowLeftOutlined /> Switch Mode
          </Button>
        </div>
        <Divider />
        <Row gutter={50}>
          <Col span={14} style={{ ...MIDDLE_STYLE }}>
            <img
              style={{ width: '100%' }}
              src="tab-lens-focus-mode.png"
              alt="Time Tracker Banner"
            />
          </Col>
          <Col span={10}>
            <Space size={30} direction="vertical" style={{ width: '100%' }}>
              <div style={{ textAlign: 'left' }}>
                <h3 style={{ margin: 0, marginBottom: 10 }}>Session Goal</h3>
                <Input
                  value={goal}
                  onChange={e => handleGoalChanged(e.target.value)}
                  size="large"
                  placeholder="What is your goal for today session?"
                />
              </div>
              <div style={{ textAlign: 'left' }}>
                <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                  <h3 style={{ margin: 0, marginBottom: 10 }}>Time Period</h3>
                  <h3
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      color: GLOBAL_THEME_COLOR.$dark_text_color,
                      fontWeight: 'normal',
                      fontSize: 'smaller',
                    }}>
                    ({renderTime(sessionEndTime - sessionStartTime)} in total)
                  </h3>
                </div>
                <TimePicker.RangePicker
                  showSecond={false}
                  size="large"
                  value={[dayjs.unix(sessionStartTime), dayjs.unix(sessionEndTime)]}
                  onChange={values => onTimePeriodChanged(values)}
                  style={{ width: '100%' }}
                />
              </div>
            </Space>
            <Button
              disabled={disabled}
              type="primary"
              size="large"
              className="btn-success"
              onClick={handleConfirmFocusMode}
              style={{ width: '100%', marginTop: 50 }}>
              Apply setting
            </Button>
            {!disabled && (
              <p>
                Session start from{' '}
                <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>
                  {dayjs.unix(sessionStartTime).format('HH:mm')}
                </span>{' '}
                to{' '}
                <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>
                  {dayjs.unix(sessionEndTime).format('HH:mm')}
                </span>{' '}
                with the goal is to{' '}
                <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>{goal}</span>
              </p>
            )}
          </Col>
        </Row>
      </React.Fragment>
    </Card>
  );
};

export default TimeTrackerFocusModeSetting;
