import React from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { RepositoryTab } from '@/gql/graphql';
import { TabTreeDirectory, TreeNode } from '@/models';
import { buildTreeNodeId } from '@/utils';

import DirectoryNodeItem from '../directory-node-item';
import DraggableTreeNodeContainer from '../draggable-tab-container';
import LoadableContainer from '../loadable-container';
import TreeNodeListHeader from '../treenode-list-header';
import WindowTabItem from '../window-tab-item';

type Props = {
  treeNodes: TreeNode[];
  draggableDisabled?: boolean;
  nodeOnContextMenu?: ((treeItem: TreeNode) => void) | undefined;
  itemSelectedCondition: (tabItem: RepositoryTab) => boolean;
  directoryOnClickedEvent: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    directory: TabTreeDirectory
  ) => void;
  itemOnClickedEvent: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab: RepositoryTab
  ) => void;
  onItemDragEvent: OnDragEndResponder;
  disableHeader?: boolean;
  isForceMobile?: boolean;
};

const DraggableTreeNodeList = ({
  treeNodes,
  disableHeader,
  nodeOnContextMenu,
  draggableDisabled,
  itemSelectedCondition,
  directoryOnClickedEvent,
  itemOnClickedEvent,
  onItemDragEvent,
  isForceMobile,
}: Props) => {
  return (
    <LoadableContainer isLoading={treeNodes.length <= 0} loadComponent={<div>No tabs found</div>}>
      {!disableHeader && <TreeNodeListHeader isForceMobile={isForceMobile} />}
      <DraggableTreeNodeContainer
        disabled={draggableDisabled}
        onDragEnd={onItemDragEvent}
        renderTreeComponent={(treeItem: TreeNode) => {
          return (
            <div>
              {treeItem.type === 'tab' ? (
                <WindowTabItem
                  id={buildTreeNodeId(treeItem, 'tab')}
                  actionable
                  key={treeItem.value.id}
                  isForceMobile={isForceMobile}
                  isSelected={itemSelectedCondition(treeItem.value as RepositoryTab)}
                  windowTab={treeItem.value as RepositoryTab}
                  onRightClick={nodeOnContextMenu}
                  onClick={itemOnClickedEvent}
                />
              ) : (
                <DirectoryNodeItem
                  id={buildTreeNodeId(treeItem, 'directory')}
                  onClick={directoryOnClickedEvent}
                  node={treeItem.value as TabTreeDirectory}
                  isForceMobile={isForceMobile}
                  onItemDragEvent={onItemDragEvent}
                  onRightClick={nodeOnContextMenu}
                  draggableDisabled
                  isSelected={itemSelectedCondition(treeItem.value as RepositoryTab)}
                  itemSelectedCondition={itemSelectedCondition}
                  itemOnClickedEvent={itemOnClickedEvent}
                />
              )}
            </div>
          );
        }}
        treeNodes={treeNodes}
      />
    </LoadableContainer>
  );
};

export default DraggableTreeNodeList;
