export type TimeTrackerAppRoutes = 'active' | 'sessions' | 'settings' | 'landing';

export type TimeTrackerTabItem = {
  icon: React.ReactNode;
  title: string;
  key: TimeTrackerAppRoutes;
};

export enum TimeAllocationFocusModeVariant {
  Category = 'Category',
  WebsiteURL = 'Website URL',
  Hostname = 'Hostname',
}

export enum TimeAllocationCasualModeVariant {
  WebsiteURL = 'Website URL',
  Hostname = 'Hostname',
}

export enum SessionStatus {
  Invalid,
  Running,
  NotStarted,
  Ended,
  Cancelled,
}
