/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { LoadingOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import * as moment from 'moment';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { Repository, Workspace } from '@/gql/graphql';
import { useBreakpoint } from '@/hooks';
import { handleFetchBanner, useWorkspaceStore } from '@/stores';

import LoadableContainer from '../loadable-container';
import RepositoryCardBanner from '../repository-card-banner';
import RepositoryCardMetadata from '../repository-card-metadata';
import WorkspaceAvatar from '../workspace-avatar';

type Props = {
  repository: Repository;
  showWorkspaceMetadata?: boolean;
};

const RepositoryCard = ({ repository, showWorkspaceMetadata }: Props) => {
  const { isMobile } = useBreakpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [workspace, setWorkspace] = useState<Workspace | undefined | null>(undefined);
  const { fetchWorkspaceById } = useWorkspaceStore();
  const [bannerImage, setBannerImage] = useState<string | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const workspaceId = repository.workspaceId;
      if (!workspaceId) return;
      try {
        const workspace = await fetchWorkspaceById(workspaceId);
        setWorkspace(workspace);
      } catch (error) {
        console.log(error);
      }

      try {
        const fetchedBanner = await handleFetchBanner(repository.id, true);
        setBannerImage(fetchedBanner);
      } catch (error) {
        setBannerImage(undefined);
      }
      setLoading(false);
    };
    init();
  }, [repository]);

  const handleRepositoryClicked = async () => {
    if (loading) return;
    history.push(`/workspace/${workspace?.slug}/${repository.slug}`);
  };

  const handleWorkspaceClicked = async () => {
    if (loading) return;
    history.push(`/workspace/${workspace?.slug}`);
  };

  return (
    <React.Fragment>
      <Card
        onClick={handleRepositoryClicked}
        cover={<RepositoryCardBanner repository={repository} bannerImage={bannerImage} />}
        style={{
          cursor: 'pointer',
          overflow: 'hidden',
          textAlign: 'left',
          width: isMobile ? '325px' : 350,
          aspectRatio: '4/3',
          backgroundColor: `${GLOBAL_THEME_COLOR.$primary_color} !important`,
        }}>
        <LoadableContainer isLoading={loading} loadComponent={<LoadingOutlined />}>
          <RepositoryCardMetadata repository={repository} workspace={workspace} />
        </LoadableContainer>
      </Card>
      {showWorkspaceMetadata && (
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            padding: '0px 15px',
          }}>
          <LoadableContainer isLoading={!workspace} loadComponent={<div>No workspace found</div>}>
            <React.Fragment>
              <div style={{ ...MIDDLE_STYLE, cursor: 'pointer' }} onClick={handleWorkspaceClicked}>
                <WorkspaceAvatar workspace={workspace as any} />
                <p style={{ marginLeft: 10 }}>{workspace?.name}</p>
              </div>
            </React.Fragment>
          </LoadableContainer>
          <p>{moment.unix(workspace?.updated_date as number).format('MM/DD/YYYY')}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default RepositoryCard;
