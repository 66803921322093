/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import Browser from 'webextension-polyfill';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { useBrowserDevService } from '@/hooks';

import AnimatedComponent from '../animated-component';
import PowerfulActionItem, { ActionItemProps } from '../powerful-action-item';
import PowerfulSearchInput from '../powerful-search-input';
import SearchItemActiveWindow from '../search-item-active-window';

type Props = {
  defaultActionList: ActionItemProps[];
  onClose?: any;
};

const PowerfulSearchContainer = ({ defaultActionList, onClose }: Props) => {
  const browserDevService = useBrowserDevService();
  const [windows, setWindows] = useState<Browser.Windows.Window[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const handleSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  useEffect(() => {
    const init = async () => {
      const fetchedWindows = await browserDevService.fetchWindows();
      setWindows(fetchedWindows);
    };
    init();
  }, []);

  return (
    <div className="powerful-search-container">
      <PowerfulSearchInput
        handleValueChanged={handleSearchInputChange}
        value={searchInput}
        extraAction={
          <Button style={{ marginLeft: 15 }} onClick={onClose}>
            Close
          </Button>
        }
      />
      <AnimatedComponent.OpacityFadeInDiv delay={100}>
        {defaultActionList.length > 0 && <div className="beautiful-divider" />}
        {defaultActionList.map(action => (
          <PowerfulActionItem {...action} />
        ))}
        <div className="beautiful-divider" />
      </AnimatedComponent.OpacityFadeInDiv>
      <div className="powerful-search-scrollable-area">
        <React.Fragment>
          {windows.map((window, index) => (
            <React.Fragment>
              {index > 0 && window && <div className="beautiful-divider" />}
              <SearchItemActiveWindow window={window} searchInput={searchInput} />
            </React.Fragment>
          ))}
        </React.Fragment>
      </div>
      {defaultActionList.length > 0 && (
        <React.Fragment>
          <div className="beautiful-divider" />
          <div
            style={{
              fontSize: 14,
              color: GLOBAL_THEME_COLOR.$dark_text_color,
              textAlign: 'center',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
            Powered by{' '}
            <span
              style={{
                color: GLOBAL_THEME_COLOR.$highlight_color,
                marginLeft: 5,
                fontWeight: '600',
              }}>
              TabHub
            </span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PowerfulSearchContainer;
