import React from 'react';

import AnimatedComponent from '../animated-component';
import RepositoryResourcesTab from '../repository-resources-tab';

type Props = {
  actionable?: boolean;
  isForceMobile?: boolean;
};

const RepositoryTableView = ({ isForceMobile, actionable }: Props) => {
  return (
    <AnimatedComponent.OpacityFadeInDiv delay={200}>
      <RepositoryResourcesTab actionable={actionable} isForceMobile={isForceMobile} />
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default RepositoryTableView;
