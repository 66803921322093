import React from 'react';
import { TbClick, TbHierarchy3 } from 'react-icons/tb';
import { useHistory } from 'react-router';

import {
  ClockCircleOutlined,
  CloudSyncOutlined,
  DeleteOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Row, Space } from 'antd';

import GuideCard from '@/components/guide-card';
import { ICON_REGISTRY } from '@/constants';
import { useBreakpoint } from '@/hooks';
import { useAuthStore } from '@/stores';

import AnimatedComponent from '../components/animated-component';

type Props = any;

const ICON_FONT_SIZE = 25;

const TAB_MANAGER_GUIDES = [
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.TAB_MANAGER}+{ICON_REGISTRY.EXTENSION}
      </Space>
    ),
    steps: ['Go to "Chrome Store" tab', 'Search for "TabHub Extension"', 'Install'],
    title: '1. Install extension',
    description: 'Extension is required for most features on TabHub',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.TAB_MANAGER}
        +
        <TbClick />
      </Space>
    ),
    steps: [
      'Add "TabHub Extension" to your browser app bar',
      'Click on the extension icon',
      'Navigated to TabHub app',
      'Fill in repository information',
      'Save to store window',
    ],
    title: '2. Save current window',
    description: 'After the installation, click on the extension icon to save the session',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.TAB_MANAGER}
        +
        <CloudSyncOutlined />
      </Space>
    ),
    steps: [
      'Go to "Tab Manager" tab',
      'Select tabs',
      'Click on "Save" button',
      'Fill in required repository information',
      'Save',
    ],
    title: '3. Store tabs',
    description: 'Using cloud synchronization feature to store tabs across the Internet',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.TAB_MANAGER}+{ICON_REGISTRY.PIN}
      </Space>
    ),
    steps: ['Go to "Tab Manager" tab', 'Select tabs', 'Choose "Pin" on editor bar'],
    title: '4. Pin',
    description: 'Pin tabs to access without multiple steps',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.TAB_MANAGER}+{ICON_REGISTRY.SMART_GROUP}
      </Space>
    ),
    steps: [
      'Go to "Tab Manager" tab',
      'Select tabs',
      'Choose "Smart Grouping" on editor bar',
      'Click smart group button',
      'Group tabs from drawer',
    ],
    title: '5. Smart grouping',
    description: 'Smart grouping tabs to keep your browser organized automatically',
  },
];

const WORKSPACE_GUIDES = [
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.WORKSPACE}
        /
        <UserAddOutlined />
      </Space>
    ),
    steps: [
      'Sign in',
      'Go to "Workspace" tab',
      'Click on "Create new workspace" button',
      'Fill in workspace information',
      'Save',
    ],
    title: '1. Create new workspace',
    description: 'Workspace is the hub of repositories and tabs',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.WORKSPACE}
        +
        <TbClick />
      </Space>
    ),
    steps: ['Go to "Workspace" tab', 'Choose a workspace', 'Click on "Quick Access" button'],
    title: '2. Select workspace',
    description: 'Authorize other users to help manage your workspaces',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.WORKSPACE}+{ICON_REGISTRY.SHARE}
      </Space>
    ),
    steps: [
      'Go to "Workspace" tab',
      'Choose a shared repository',
      'Click on "Share" button',
      'Edit permission',
      'Share',
    ],
    title: '3. Share tabs with anyone',
    description: 'Easily share resources with different permission to anyone',
  },
  {
    icon: (
      <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
        {ICON_REGISTRY.WORKSPACE}
        +
        <TbHierarchy3 />
      </Space>
    ),
    steps: [
      'Go to "Workspace" tab',
      'Choose a workspace',
      'Click on "Settings" tab',
      'Go to "Members" tab',
      'Add / Remove / Edit member',
      'Save changes',
    ],
    title: '4. Add workspace members',
    description: 'Authorize other users to help manage your workspaces',
  },
];

const TIME_TRACKING_GUIDES = [
  {
    icon: (
      <Space style={{ display: 'flex', alignContent: 'center', fontSize: ICON_FONT_SIZE }}>
        <ClockCircleOutlined />+{ICON_REGISTRY.TAB_LENS}
      </Space>
    ),
    steps: [
      'Go to "Time Tracker" tab',
      'Select "Casual mode" or "Focus mode"',
      'Enter required inputs',
      'Start tracking time',
    ],
    title: '1. Track your browsing time',
    description: 'Tracks browsing activity and indicates the insights of the active session',
  },
  {
    icon: (
      <Space style={{ display: 'flex', alignContent: 'center', fontSize: ICON_FONT_SIZE }}>
        <DeleteOutlined />+{ICON_REGISTRY.TAB_LENS}
      </Space>
    ),
    steps: [
      'Go to "Time Tracker" tab',
      'In the active session, find "Least Used Tabs" section',
      'Select time for active session',
      'Click "Empty" button to remove unused tabs',
    ],
    title: '2. Collect & remove unused tabs',
    description: 'Browse efficiently and save memory by removing unused tabs.',
  },
];

const GettingStartedScreen = (props: Props) => {
  const { user } = useAuthStore();
  const history = useHistory();
  const { isTablet } = useBreakpoint();

  return (
    <div style={{ padding: isTablet ? 0 : '20px 20px', textAlign: 'left' }}>
      <AnimatedComponent.OpacityFadeInDiv delay={300}>
        <Card>
          <div style={{ padding: '10px 20px' }}>
            <h1 style={{ fontSize: 30, color: 'black', margin: 0 }}>
              Welcome to TabHub{user ? `, ${user?.full_name}` : ''}! 🎉
            </h1>
            <p>Manage tabs easily and boost your productivity with powerful features on TabHub</p>
            <Divider />
            <div style={{ fontSize: 20 }}>
              <span style={{ fontWeight: 'bold' }}>Ready to go?</span> Start browsing without an
              ease using our core products
            </div>
            <Row gutter={20} style={{ marginTop: 20, paddingBottom: isTablet ? 0 : 100 }}>
              <Col span={isTablet ? 24 : 8} style={{ marginBottom: isTablet ? 100 : 0 }}>
                <Card
                  hoverable
                  style={{ width: '100%', height: 250 }}
                  onClick={() => history.push('/dashboard')}>
                  <h2>Tab Manager</h2>
                  <p>
                    Manage active session, organize and store open tabs with cloud synchronization
                  </p>
                  <img src="getting-started-tab-manager.png" alt="tab-manager" width={'100%'} />
                </Card>
              </Col>
              <Col span={isTablet ? 24 : 8} style={{ marginBottom: isTablet ? 100 : 0 }}>
                <Card
                  hoverable
                  style={{ width: '100%', height: 250 }}
                  onClick={() => history.push('/time-tracker')}>
                  <h2>Time Tracker</h2>
                  <p>Embrace intentional browsing experience with insightful analytics</p>
                  <img src="getting-started-time-tracker.png" alt="tab-manager" width={'100%'} />
                </Card>
              </Col>
              <Col span={isTablet ? 24 : 8} style={{ marginBottom: isTablet ? 100 : 0 }}>
                <Card
                  hoverable
                  style={{ width: '100%', height: 250 }}
                  onClick={() => history.push('/explore')}>
                  <h2>Explore community</h2>
                  <p>Discover and contribute interesting resources into the community</p>
                  <img src="getting-started-explore.png" alt="explore" width={'100%'} />
                </Card>
              </Col>
            </Row>
            <Divider style={{ marginTop: 50 }} />
            <div style={{ fontSize: 20 }}>
              <span style={{ fontWeight: 'bold' }}>Getting Started</span> Follow steps to maximize
              the power of TabHub
            </div>
            <h3>
              <UserOutlined /> <span style={{ marginLeft: 10 }}>Tab Manager</span>
            </h3>
            <Space size={30} style={{ marginTop: 30, display: 'flex', flexWrap: 'wrap' }}>
              {TAB_MANAGER_GUIDES.map(item => (
                <GuideCard item={item} />
              ))}
            </Space>
            <Divider />
            <h3>
              {ICON_REGISTRY.TAB_LENS} <span style={{ marginLeft: 10 }}>Time Tracker</span>
            </h3>
            <Space size={30} style={{ marginTop: 30, display: 'flex', flexWrap: 'wrap' }}>
              {TIME_TRACKING_GUIDES.map(item => (
                <GuideCard item={item} />
              ))}
            </Space>
            <Divider />
            <h3>
              {ICON_REGISTRY.WORKSPACE} <span style={{ marginLeft: 10 }}>Workspace for Team</span>
            </h3>
            <Space size={30} style={{ marginTop: 30, display: 'flex', flexWrap: 'wrap' }}>
              {WORKSPACE_GUIDES.map(item => (
                <GuideCard item={item} />
              ))}
            </Space>
          </div>
        </Card>
      </AnimatedComponent.OpacityFadeInDiv>
    </div>
  );
};

export default GettingStartedScreen;
