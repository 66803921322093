/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Row, Tag } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { useRepositoryContext } from '@/hooks';

import RepositoryList from '../repository-list';
import RepositoryTabsContainer from '../repository-tabs-container';

type Props = {
  actionable?: boolean;
  isForceMobile?: boolean;
};

const RepositoryResourcesTab = ({ actionable, isForceMobile }: Props) => {
  const { linkedRepositories } = useRepositoryContext();

  const RenderExternalRepositoriesTitle = () => (
    <React.Fragment>
      <h1 style={{ textAlign: 'left' }}>
        External repositories{' '}
        <Tag style={{ marginLeft: 10 }}>{linkedRepositories.length} repositories</Tag>
      </h1>
      <p style={{ textAlign: 'left', color: GLOBAL_THEME_COLOR.$dark_text_color }}>
        Add repository url to the list of tabs to link two repositories
      </p>
    </React.Fragment>
  );

  return (
    <div>
      {linkedRepositories.length > 0 && (
        <React.Fragment>
          <RenderExternalRepositoriesTitle />
          <div
            style={{
              padding: '10px 10px',
            }}>
            <Row gutter={24}>
              <RepositoryList repositories={linkedRepositories} />
            </Row>
          </div>
        </React.Fragment>
      )}
      <RepositoryTabsContainer isForceMobile={isForceMobile} actionable={actionable} />
    </div>
  );
};

export default RepositoryResourcesTab;
