import React, { useState } from 'react';

import { Empty, Tabs, TabsProps } from 'antd';

import AnimatedComponent from '@/components/animated-component';
import LoadableContainer from '@/components/loadable-container';
import RepositoryList from '@/components/repository-list';
import RepositoryTabsContainer from '@/components/repository-tabs-container';
import { GENERAL_EMPTY_IMAGE, MIDDLE_STYLE } from '@/constants';
import { Repository, RepositoryTab } from '@/gql/graphql';

export enum PinAppTab {
  Repositories = 'repositories',
  Tabs = 'tabs',
}

type Props = {
  tabs: RepositoryTab[];
  repositories: Repository[];
};

const PinAppExtensionContent = ({ tabs, repositories }: Props) => {
  const [tab, setTab] = useState<string>(PinAppTab.Tabs);

  const EMPTY_BANNER_STYLE = {
    ...MIDDLE_STYLE,
    width: '100%',
    height: '60vh',
  };
  const items: TabsProps['items'] = [
    {
      key: PinAppTab.Tabs,
      label: `Resources`,
      children: (
        <React.Fragment>
          <LoadableContainer
            isLoading={tabs.length === 0}
            loadComponent={
              <div style={EMPTY_BANNER_STYLE}>
                <Empty
                  image={GENERAL_EMPTY_IMAGE}
                  imageStyle={{ height: 60 }}
                  description={<span>No pinned tabs found</span>}></Empty>
              </div>
            }>
            <React.Fragment>
              <RepositoryTabsContainer />
            </React.Fragment>
          </LoadableContainer>
        </React.Fragment>
      ),
    },
    {
      key: PinAppTab.Repositories,
      label: `Repositories`,
      children: (
        <LoadableContainer
          isLoading={repositories.length === 0}
          loadComponent={
            <div style={EMPTY_BANNER_STYLE}>
              <Empty
                image={GENERAL_EMPTY_IMAGE}
                imageStyle={{ height: 60 }}
                description={<span>No pinned repositories found</span>}></Empty>
            </div>
          }>
          <React.Fragment>
            <AnimatedComponent.OpacityFadeInDiv delay={200}>
              <RepositoryList repositories={repositories} />
            </AnimatedComponent.OpacityFadeInDiv>
          </React.Fragment>
        </LoadableContainer>
      ),
    },
  ];
  return <Tabs defaultActiveKey={tab} items={items} onChange={value => setTab(value)} />;
};

export default PinAppExtensionContent;
