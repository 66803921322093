import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { buildPinnedTabKey } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { UnionTab } from '@/models';
import { BrowserStorageService, WorkspaceService } from '@/services';

type TabPinMap = Record<string, boolean>;

export interface RepositoryTabStoreState {
  pinnedTabs: RepositoryTab[];
  pinnedMap: TabPinMap;
  setPinnedTabs: (pinnedTabs: RepositoryTab[]) => void;
  getLocalPinnedTabs: () => Promise<RepositoryTab[]>;
  storeLocalPinnedTab: (pinnedTab: RepositoryTab) => Promise<void>;
  removeLocalPinnedTab: (pinnedTab: RepositoryTab) => Promise<void>;
  findDuplicateTabs: (tabs: UnionTab[]) => [UnionTab[], UnionTab[]];
}

export const useRepositoryTabStore = create<RepositoryTabStoreState>()(
  devtools(set => ({
    pinnedTabs: [],
    pinnedMap: {},
    findDuplicateTabs: (tabs: UnionTab[]) => {
      const closeTabs: UnionTab[] = [];
      const remainingTabs: UnionTab[] = [];
      const visitedTabs: Record<string, boolean> = {};
      if (!tabs) return [[], []];
      for (const windowTab of tabs) {
        if (!windowTab.url) continue;
        if (visitedTabs[windowTab.url]) {
          closeTabs.push(windowTab);
        } else {
          visitedTabs[windowTab.url] = true;
          remainingTabs.push(windowTab);
        }
      }
      return [closeTabs, remainingTabs];
    },
    setPinnedTabs: (pinnedTabs: RepositoryTab[]) => set(_ => ({ pinnedTabs: pinnedTabs })),
    getLocalPinnedTabs: async () => {
      const pinnedMap: TabPinMap = {};
      const pinnedTabs = await WorkspaceService.getLocalPinnedTabs();
      for (const pinnedTab of pinnedTabs) {
        pinnedMap[pinnedTab.id as any] = true;
      }
      set(_ => ({ pinnedTabs: pinnedTabs, pinnedMap: pinnedMap }));
      return pinnedTabs;
    },
    removeLocalPinnedTab: async (pinnedTab: RepositoryTab) => {
      const key = buildPinnedTabKey();
      const pinnedTabs = await WorkspaceService.getLocalPinnedTabs();
      const newPinnedTabs = pinnedTabs.filter(tab => tab && tab.id !== pinnedTab.id);
      await BrowserStorageService.setValue(key, newPinnedTabs);
      set(_ => ({ pinnedTabs: newPinnedTabs }));
    },
    storeLocalPinnedTab: async (pinnedTab: RepositoryTab) => {
      const key = buildPinnedTabKey();
      const pinnedTabs = await WorkspaceService.getLocalPinnedTabs();
      if (pinnedTabs.some(tab => tab.id === pinnedTab.id)) return;
      const newPinnedTabs = pinnedTabs.concat(pinnedTab);
      await BrowserStorageService.setValue(key, newPinnedTabs);
      set(_ => ({ pinnedTabs: newPinnedTabs }));
    },
  }))
);
