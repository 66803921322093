import React from 'react';

type Props = {
  icon: React.ReactNode;
  style?: React.CSSProperties;
};

const IconContainer = ({ icon, style }: Props) => {
  return <span style={{ ...style, marginTop: 2 }}>{icon}</span>;
};

export default IconContainer;
