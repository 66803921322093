import { useMemo } from 'react';

import { Tag, Tooltip } from 'antd';
import { Emoji, EmojiStyle } from 'emoji-picker-react';

import { GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { AccessVisibility, Repository, Workspace } from '@/gql/graphql';
import { useAuthStore } from '@/stores';
import { shortenString } from '@/utils';

import AuthFavoriteIcon from '../auth-favorite-icon';
import AuthPinIcon from '../auth-pin-icon';

const RepositoryCardMetadata = ({
  repository,
  workspace,
}: {
  repository: Repository;
  workspace: Workspace | undefined | null;
}) => {
  const { user } = useAuthStore();
  const isPinned = useMemo<boolean>(
    () =>
      !!repository?.pinned.some(userWhoPin => user?.id === userWhoPin || userWhoPin === 'PINNED'),
    [user, repository]
  );

  return (
    <div
      style={{
        backgroundColor: `${GLOBAL_THEME_COLOR.$primary_color} !important`,
      }}>
      <div style={{ display: 'flex' }}>
        <div style={{ fontSize: 30 }}>
          <Emoji unified={repository.icon} emojiStyle={EmojiStyle.APPLE} />
        </div>
        <div style={{ marginLeft: 15, width: '100%', textAlign: 'left' }}>
          <Tooltip title={repository.name}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '1rem',
              }}>
              {shortenString(repository.name, 23)}{' '}
            </div>
          </Tooltip>
          <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, margin: 0 }}>
            {shortenString(repository.description || 'No description', 25)}
          </p>
        </div>
      </div>
      <div style={{ marginTop: 30, ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
        <div style={{ ...MIDDLE_STYLE }}>
          <div style={{ marginRight: 15 }}>
            <AuthFavoriteIcon repository={repository} /> {repository.favorites.length}
          </div>
          <div>
            <AuthPinIcon repository={repository} isPinned={isPinned} />
            {repository.pinned.length}
          </div>
        </div>
        <Tag color={repository.visibility === AccessVisibility.Private ? 'red' : 'blue'}>
          {repository.visibility === AccessVisibility.Private ? 'Private' : 'Public'}
        </Tag>
      </div>
    </div>
  );
};

export default RepositoryCardMetadata;
