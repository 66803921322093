import React from 'react';

import { FolderFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Card } from 'antd';

import { GLOBAL_THEME_COLOR } from '@/constants';
import { Directory } from '@/gql/graphql';
import { shortenString } from '@/utils';

type Props = {
  directory: Directory;
  onClick?: (directory: Directory) => void;
};

const DirectorySearchItem = ({ onClick, directory }: Props) => {
  const COLOR = GLOBAL_THEME_COLOR.$dark_highlight_color;

  return (
    <Card hoverable style={{ cursor: 'pointer' }} onClick={() => onClick && onClick(directory)}>
      <Row style={{ display: 'flex' }}>
        <Col span={2}>
          <FolderFilled style={{ color: COLOR, marginLeft: 10 }} />
        </Col>
        <Col span={22}>
          <span style={{ color: COLOR, fontWeight: 'bold' }}>
            {shortenString(directory.name, 35)}
          </span>
        </Col>
      </Row>
    </Card>
  );
};

export default DirectorySearchItem;
