import Browser from 'webextension-polyfill';

import { RepositoryTab } from '@/gql/graphql';
import { TreeNode } from '@/models';

export const MESSAGE_GET_CLIENT_ID = 'GET_CLIENT_ID';
export const MESSAGE_PING = 'PING';
export const MESSAGE_GET_WINDOWS = 'GET_WINDOWS';
export const MESSAGE_SELECT_TAB = 'SELECT_TAB';
export const MESSAGE_DELETE_TAB = 'DELTE_TAB';
export const MESSAGE_CREATE_TAB = 'CREATE_TAB';
export const MESSAGE_OPEN_TABS = 'OPEN_TABS';
export const MESSAGE_MOVE_TABS = 'MOVE_TABS';
export const MESSAGE_GROUP_TABS = 'GROUP_TABS';
export const MESSAGE_CLOSE_WINDOW = 'CLOSE_WINDOW';
export const MESSAGE_TAB_CHANGED = 'TAB_CHANGED';
export const MESSAGE_QUERY_TAB_GROUPS = 'QUERY_TAB_GROUPS';
export const MESSAGE_GET_TAB_GROUPS = 'GET_TAB_GROUPS';
export const MESSAGE_UPDATE_TAB_GROUPS = 'UPDATE_TAB_GROUPS';
export const MESSAGE_MOVE_TAB_GROUPS = 'MOVE_TAB_GROUPS';
export const MESSAGE_UNGROUP_TAB = 'UNGROUP_TABS';
export const MESSAGE_AUTHENTICATE_USER = 'AUTHENTICATE_USER';

export interface MessageSubscribeEventChangedParams {
  browserClientId: string;
}

export interface MessageAuthenticateUserParams {
  authenticateToken: string;
}

export interface MessageUngroupTabParams {
  tabIds: number[];
}

export interface MessageSelectTabParams {
  tabId: number;
  windowId: number;
}

export interface MessageDeleteTabParams {
  tabId: number;
}

export interface MessageCreateTabParams {
  tab: RepositoryTab;
}

export interface MessageOpenTabsParams {
  tabs: RepositoryTab[];
}

export interface MessageMoveTabsParams {
  treeItems: TreeNode[];
}

export interface MessageCloseWindowParams {
  window: Browser.Windows.Window;
}

export interface MessageQueryTabGroupsParams {
  windowId: number;
}

export interface MessageGetTabGroupParams {
  groupId: number;
}

export interface MessageQueryTabGroupsParams {
  windowId: number;
}

export interface MessageUpdateTabGroupParams {
  groupId: number;
  windowProperties: chrome.tabGroups.UpdateProperties;
}

export interface MessageMoveTabGroupParams {
  groupId: number;
  moveProperties: {
    index: number;
    windowId?: number;
  };
}

export interface MessageGroupTabsParams {
  tabIds: number[];
  windowProperties?: chrome.tabGroups.UpdateProperties;
  groupId?: number;
  windowId?: number;
}
