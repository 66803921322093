import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Button } from 'antd';

import { auth } from '@/services/FirebaseService';
import { useAuthStore, useSnackbarStore } from '@/stores';

type Props = any;

const AppBarPopoverDesktop = (props: Props) => {
  const history = useHistory();
  const { enqueueNotification } = useSnackbarStore();
  const { user, setUser } = useAuthStore();
  const [loading, setLoading] = useState(false);

  const handleLogOut = async () => {
    setLoading(true);
    try {
      await auth.signOut();
      localStorage.removeItem('token');
      setUser(undefined);
    } catch (error) {
      await enqueueNotification({
        name: 'Failed to logout',
        description: 'Failed to logout from the account',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  const handleViewProfile = async () => {
    history.push(`/user/${user?.username}`);
  };
  return user ? (
    <div style={{ padding: '5px 10px' }}>
      <div style={{ textAlign: 'left' }}>
        <div>
          <h4 style={{ margin: 0 }}>{user.full_name}</h4>
          <p style={{ margin: 0 }}>{user.email}</p>
        </div>
      </div>
      <Button
        style={{ width: '100%', marginTop: 10, textAlign: 'left' }}
        onClick={handleViewProfile}>
        🙎‍♂️ View profile
      </Button>
      <Button
        loading={loading}
        style={{ width: '100%', marginTop: 5, textAlign: 'left' }}
        onClick={handleLogOut}>
        🏃 Log out
      </Button>
    </div>
  ) : (
    <div>No user found</div>
  );
};

export default AppBarPopoverDesktop;
