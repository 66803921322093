/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { Content } from 'antd/es/layout/layout';

import { Layout } from 'antd';

import {
  LoadableContainer,
  SplashScreen,
  TabManagerAppBar,
  TabManagerColumnView,
  TabManagerTableView,
} from '@/components';
import { GLOBAL_THEME_COLOR } from '@/constants';
import { useBrowsingEventOnMount, useURLQuery, useWindowTabActions } from '@/hooks';
import { useBrowserDevService } from '@/hooks/useBrowserDevService';
import { TabChecked } from '@/models';
import { ViewMode, useRepositoryTabStore, useTabManagerStore, useWindowStore } from '@/stores';
import {
  buildTree,
  convertWindowTabGroupsToDirectory,
  convertWindowTabToRepositoryTab,
  fetchTabGroups,
  isChromeCompatible,
  sanitizeWindowTabs,
} from '@/utils';

import BrowserNotCompatible from '../jumbotrons/browser-not-compatible';
import ExtensionNotInstalled from '../jumbotrons/extension-not-installed';

type Props = any;

const TabManagerLayout = (props: Props) => {
  const query = useURLQuery();
  const action = useMemo<string | undefined | null>(() => query.get('action'), [query]);
  const windowId = useMemo<string | undefined | null>(() => query.get('windowId'), [query]);
  const { data } = useBrowsingEventOnMount([
    'ON_TAB_MANAGER_MOVED',
    'ON_TAB_MANAGER_CREATED',
    'ON_TAB_MANAGER_DELETE',
  ]);
  const browserDevService = useBrowserDevService();
  const { getLocalPinnedTabs } = useRepositoryTabStore();
  const [loading, setLoading] = useState(true);
  const { setMultiverseTreeNodes, viewMode } = useTabManagerStore();
  const { windows } = useWindowStore();
  const { handleSaveTabs } = useWindowTabActions();
  const { fetchWindows } = useWindowStore();

  const handleFetch = async () => {
    await fetchWindows(browserDevService);
    await getLocalPinnedTabs();
  };

  useEffect(() => {
    const watchEventFromExtension = async () => {
      if (action === 'save') {
        try {
          const windows = await browserDevService.fetchWindows();
          const selectedWindow = windows.find(window => window.id?.toString() === windowId);
          if (!selectedWindow) return;
          const treeNodes = buildTree(selectedWindow.tabs || [], []);
          const selectedTreeNodes: TabChecked = {};
          for (const treeNode of treeNodes) {
            if (!treeNode.value.id) continue;
            selectedTreeNodes[treeNode.value.id] = true;
          }
          handleSaveTabs(selectedWindow, treeNodes, selectedTreeNodes);
        } catch (error) {
          console.log(error);
        }
      }
    };
    watchEventFromExtension();
  }, [action, windowId]);

  useEffect(() => {
    const init = async () => {
      await handleFetch();
    };
    init();
  }, []);

  useEffect(() => {
    const eventHandler = async () => {
      if (!data) return;
      await fetchWindows(browserDevService);
    };
    eventHandler();
  }, [data]);

  /** Handle glitch */
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    const init = async () => {
      for (const window of windows) {
        if (!window.id) continue;
        const tabGroups = await fetchTabGroups(window, browserDevService);
        const tabs = sanitizeWindowTabs(window);
        const convertedTabs = convertWindowTabToRepositoryTab(tabs);
        const directories = convertWindowTabGroupsToDirectory(tabGroups);
        const tree = buildTree(convertedTabs, directories);
        setMultiverseTreeNodes(window.id, tree);
      }
    };
    init();
  }, [windows]);

  return (
    <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
      <LoadableContainer
        isLoading={windows.length === 0}
        loadComponent={isChromeCompatible ? <ExtensionNotInstalled /> : <BrowserNotCompatible />}>
        <Layout style={{ minHeight: '100%', position: 'static' }}>
          {isChromeCompatible && <TabManagerAppBar />}
          <Layout>
            <Content
              style={{
                height: '100%',
                overflow: 'auto',
                background: GLOBAL_THEME_COLOR.$secondary_color,
              }}>
              {viewMode === ViewMode.ColumnMode && <TabManagerColumnView />}
              {viewMode === ViewMode.TableMode && <TabManagerTableView />}
            </Content>
          </Layout>
        </Layout>
      </LoadableContainer>
    </LoadableContainer>
  );
};

export default TabManagerLayout;
