/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';

import { FolderFilled } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';

import { GENERAL_EMPTY_IMAGE, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '@/constants';
import { RepositoryTab } from '@/gql/graphql';
import { useBreakpoint } from '@/hooks';
import { TabTreeDirectory, TreeNode } from '@/models';
import { shortenString } from '@/utils';
import { tabTreeWalk } from '@/utils';

import DraggableTreeNodeList from '../draggable-tree-node-list';
import LoadableContainer from '../loadable-container';

type Props = {
  id?: string;
  node: TabTreeDirectory;
  draggableDisabled?: boolean;
  itemSelectedCondition: (tabItem: RepositoryTab) => boolean;
  itemOnClickedEvent: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab: RepositoryTab
  ) => void;
  isSelected?: boolean;
  onItemDragEvent: OnDragEndResponder;
  onRightClick?: (treeItem: TreeNode) => void;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    directoryNode: TabTreeDirectory
  ) => void;
  isForceMobile?: boolean;
};

const DirectoryNodeItem = ({
  id,
  node,
  onClick,
  itemOnClickedEvent,
  itemSelectedCondition,
  onItemDragEvent,
  onRightClick,
  isSelected,
  draggableDisabled,
  isForceMobile,
}: Props) => {
  const { isMobile: isMediaMobile } = useBreakpoint();
  const isMobile = useMemo(() => isMediaMobile || isForceMobile, [isMediaMobile, isForceMobile]);
  const COLOR = GLOBAL_THEME_COLOR.$dark_highlight_color;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const handleClicked = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  const handleRightClicked = () =>
    onRightClick &&
    onRightClick({
      type: 'directory',
      value: node,
    });

  const countItems = (node: TabTreeDirectory) => {
    let count = 0;
    tabTreeWalk({
      treeNodes: [{ type: 'directory', value: node }],
      handleTabItem(tab: any) {
        count = count + 1;
      },
    });
    return count;
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Row
        id={id}
        className={`window-tab-item ${isSelected ? 'window-tab-item-selected' : ''}`}
        style={{ textAlign: 'left', ...MIDDLE_STYLE }}
        onContextMenu={handleRightClicked}
        gutter={isMobile ? 10 : 20}
        onClick={e => onClick && onClick(e, node)}>
        <Col span={isMobile ? 2 : 1}>
          <FolderFilled style={{ color: COLOR, marginLeft: 2 }} />
        </Col>
        <Col span={isMobile ? 22 : 23}>
          <div
            onClick={handleClicked}
            className="directory-name"
            style={{ color: COLOR, width: 'fit-content', fontSize: 12 }}>
            <span style={{ fontWeight: 'bold' }}>{shortenString(node.name as any, 35)} </span>
            <span
              style={{
                marginLeft: 10,
                color: COLOR,
              }}>
              {countItems(node)} items
            </span>
          </div>
        </Col>
      </Row>
      {!collapsed && (
        <div
          style={{
            marginLeft: 15,
            borderLeft: `1px solid ${GLOBAL_THEME_COLOR.$dark_secondary_color}`,
          }}>
          <LoadableContainer
            isLoading={node.items.length === 0}
            loadComponent={
              <Empty
                image={GENERAL_EMPTY_IMAGE}
                imageStyle={{ height: 60 }}
                description={<span>No items found</span>}></Empty>
            }>
            <DraggableTreeNodeList
              disableHeader
              isForceMobile={isForceMobile}
              treeNodes={node.items}
              nodeOnContextMenu={onRightClick}
              directoryOnClickedEvent={(e, directory) => onClick && onClick(e, directory)}
              itemOnClickedEvent={itemOnClickedEvent}
              itemSelectedCondition={tabItem => {
                const isSelected = itemSelectedCondition(tabItem);
                return isSelected;
              }}
              onItemDragEvent={onItemDragEvent}
              draggableDisabled={draggableDisabled}
            />
          </LoadableContainer>
        </div>
      )}
    </div>
  );
};

export default DirectoryNodeItem;
